import Paper from '@mui/material/Paper';
import styled from '@mui/material/styles/styled';

const TableContainerWrapper = styled(Paper)(({ theme }) => ({
  overflow: 'hidden',
  width: '100%',
  borderRadius: '16px',
  boxShadow: '10px 8px 10px 2px rgba(0, 0, 0, 0.06)',

  padding: theme.spacing(2),
}));

export default TableContainerWrapper;
