import { useState } from 'react';
import PropTypes from 'prop-types';
import List from '@mui/material/List';
import Popover from '@mui/material/Popover';
import IconButton from '@mui/material/IconButton';
import ListItemText from '@mui/material/ListItemText';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ListItemButton from '@mui/material/ListItemButton';

import Center from 'core/center/Center';
import CircularProgress from 'core/progress/CircularProgress';
export default function MoreActions({ onExportToCsv, onExportToExcel, onExportAll, isLoading }) {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const id = open ? 'simple-popover' : undefined;

  return (
    <>
      {isLoading ? (
        <Center width="40px">
          <CircularProgress size={20} />
        </Center>
      ) : (
        <IconButton
          sx={{
            '&:hover': {
              color: 'red',
            },
          }}
          disableRipple
          aria-describedby={id}
          variant="contained"
          onClick={handleClick}
          disabled={isLoading}
        >
          <MoreVertIcon />
        </IconButton>
      )}
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <List component="nav" aria-label="exports">
          {Object.prototype.toString.call(onExportAll) === '[object Function]' && (
            <ListItemButton onClick={() => (onExportAll(), handleClose())}>
              <ListItemText primary="Export All" />
            </ListItemButton>
          )}
          {Object.prototype.toString.call(onExportToCsv) === '[object Function]' && (
            <ListItemButton onClick={() => (onExportToCsv(), handleClose())}>
              <ListItemText primary="Export to CSV for selected" />
            </ListItemButton>
          )}
          {Object.prototype.toString.call(onExportToExcel) === '[object Function]' && (
            <ListItemButton onClick={() => (onExportToExcel(), handleClose())}>
              <ListItemText primary="Export to Excel for selected" />
            </ListItemButton>
          )}
        </List>
      </Popover>
    </>
  );
}

MoreActions.propTypes = {
  onExportAll: PropTypes.func,
  onExportToCsv: PropTypes.func,
  onExportToExcel: PropTypes.func,
};
