import { useSelector, useDispatch } from 'react-redux';

import app from 'app/config/app';
import FilterValues from 'core/filter/FilterValues';
import {
  deleteFilterParams,
  resetParam,
} from 'app/features/partner-funding-sources/partnerFundingSourceSlice';

export default function PartnerFundingSourceFilterValues() {
  const dispatch = useDispatch();

  const { params } = useSelector((state) => state.partnerFundingSources.index);

  const handleOnRemoveFilter = (key) => {
    dispatch(deleteFilterParams(key));
  };

  const handleRemoveAllFilter = () => {
    dispatch(resetParam());
  };

  return (
    <FilterValues
      value={params}
      onRemoveFilter={handleOnRemoveFilter}
      ignoreKeys={app.ignoreFilterKeys}
      RemoveAllFilter={handleRemoveAllFilter}
    />
  );
}
