import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import { useDispatch, useSelector } from 'react-redux';
import { useState, useEffect, useCallback } from 'react';

import Button from 'core/buttons/Button';
import SearchBox from 'core/forms/SearchBox';
import ReloadButton from 'core/buttons/ReloadButton';
import ExportDocuments from 'core/lab/ExportDocuments';
import TableGridFilter from 'core/table/TableGridFilter';
import ColumnVisibility from 'core/table/ColumnVisibility';
import SelectPartner from 'components/partners/SelectPartner';
import TableActionsContainer from 'core/table/TableActionsContainer';

import Auth from 'utils/Auth';
import app from 'app/config/app';
import debounce from 'helpers/debounce';
import { currentRouteName } from 'routes';
import FilterType from 'app/config/FilterType';
import useQueryParams from 'hooks/useQueryParams';
import { getCustomerDownloadReport, getCustomers } from 'app/features/customers/customerSaga';
import {
  setPartnerId,
  setParam,
  deleteFilterParams,
  resetCustomerReportDownload,
  toggleFilter,
} from 'app/features/customers/customerSlice';
import FilterButton from 'core/buttons/FilterButton';

export default function CustomersActions({ table, enableFilterByPartner, defaultFilterQueries }) {
  const dispatch = useDispatch();

  const customerState = useSelector((state) => state.customers.index);

  const [queryParams, updateQueryParams] = useQueryParams(app.ignoreFilterKeys);

  let availableFilters = [
    {
      label: '',
      value: 'search',
      type: FilterType.search,
    },
    {
      label: 'Name',
      value: 'name',
      type: FilterType.input,
    },
    {
      label: 'ID Number',
      value: 'id_number',
      type: FilterType.input,
    },
    {
      label: 'Mobile Number',
      value: 'mobile_number',
      type: FilterType.input,
    },
    {
      label: 'Email',
      value: 'email',
      type: FilterType.input,
    },
    {
      label: 'Date of Birth',
      value: 'date_of_birth',
      type: FilterType.datepicker,
    },
    {
      label: 'From Date',
      value: 'fromDate',
      type: FilterType.datepicker,
      props: {
        withStartDayTimezone: true,
      },
    },
    {
      label: 'To Date',
      value: 'toDate',
      type: FilterType.datepicker,
      props: {
        withEndDayTimezone: true,
      },
    },
  ];

  const { params: appliedFilters, success, isFilterOpen } = customerState;

  const [search, setSearch] = useState(appliedFilters?.search ?? '');

  const handleOnFilter = useCallback((data) => {
    dispatch(setParam({ ...data, page_number: 1 }));
    dispatch(toggleFilter());
  }, []);

  const handleOnSearch = useCallback((e) => {
    setSearch(e.target.value);
    debounce(() => {
      dispatch(setParam({ search: e.target.value, page_number: 1 }));
    }, 500);
  }, []);

  const handleOnClearSearch = useCallback(() => {
    setSearch('');
    dispatch(deleteFilterParams('search'));
  }, []);

  const handleOnReset = useCallback((e) => {
    dispatch(deleteFilterParams([...availableFilters.map((filter) => filter.value)]));
  });

  useEffect(() => {
    if (currentRouteName() === 'customers.index') {
      updateQueryParams(appliedFilters);
    }

    setSearch(appliedFilters?.search ?? '');
  }, [JSON.stringify(appliedFilters)]);

  useEffect(() => {
    dispatch(setParam(queryParams));
  }, [JSON.stringify(queryParams)]);

  const handlePdfDownloadReport = () => {
    dispatch(
      getCustomerDownloadReport({
        format: 'pdf',
      }),
    );
  };

  const handleExcelDownloadReport = () => {
    dispatch(
      getCustomerDownloadReport({
        format: 'excel',
      }),
    );
  };

  useEffect(() => {
    if (success) {
      dispatch(resetCustomerReportDownload());
    }
  }, [success, dispatch]);

  return (
    <>
      <Box>
        <TableGridFilter
          showFilter={isFilterOpen}
          appliedFilters={appliedFilters}
          availableFilters={availableFilters}
          onFilter={handleOnFilter}
          onReset={handleOnReset}
        />
      </Box>
      <TableActionsContainer>
        {/* <Box>
          <SearchBox
            onChange={handleOnSearch}
            onClickClearSearch={handleOnClearSearch}
            value={search || ''}
          />
        </Box> */}
        <Box display="flex" gap={1}>
          {Auth.isSuperAdmin() && enableFilterByPartner && (
            <SelectPartner
              id="select-partner"
              onSelected={(value) => {
                dispatch(setPartnerId(value));
              }}
              sx={{ width: '200px', marginRight: 2 }}
            />
          )}
        </Box>

        <Box display="flex" gap={1}>
          {table && <ColumnVisibility table={table} />}
          <ReloadButton
            onClick={() => {
              dispatch(
                getCustomers({
                  ...appliedFilters,
                  page_number: 1,
                  partnerId: customerState.partnerId,
                  ...defaultFilterQueries,
                }),
              );
            }}
          />

          <ExportDocuments
            onExcelDownload={handleExcelDownloadReport}
            onPdfDownload={handlePdfDownloadReport}
          />
        </Box>
      </TableActionsContainer>
    </>
  );
}

CustomersActions.propTypes = {
  table: PropTypes.object,
  enableFilterByPartner: PropTypes.bool,
  defaultFilterQueries: PropTypes.object,
};

CustomersActions.defaultProps = {
  enableFilterByPartner: true,
};
