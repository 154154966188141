import { useEffect } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';

import withRole from 'hoc/withRole';
import Error from 'core/errors/Error';
import Button from 'core/buttons/Button';
import PageContent from 'layouts/PageContent';
import Account from 'components/accounts/Account';

import acl from 'app/config/acl';
import { buildRoute } from 'routes';
import { resetGetAccountFailure } from 'app/features/accounts/accountSlice';
import AccountCard from 'components/accounts/AccountCard';
import NewPageContent from 'layouts/NewPageContent';
import routePaths from 'app/config/routePaths';

function ViewAccount() {
  const params = useParams();

  const { isError, errorCode, errorMessage } = useSelector((state) => state.accounts.show);

  const dispatch = useDispatch();

  const navigate = useNavigate();

  useEffect(() => {
    dispatch(resetGetAccountFailure());
  }, []);

  if (isError)
    return (
      <Error
        status={errorCode || 0}
        message={errorMessage}
        onRetry={() => dispatch(resetGetAccountFailure())}
      />
    );

  return (
    <NewPageContent
      breadcrumbs={[
        {
          label: 'Accounts',
          to: routePaths.ListAccounts,
        },
        {
          label: 'Account Details',
          to: '',
        },
      ]}
      documentTitle="Account Details"
    >
      <AccountCard />
      <Account accountId={params.accountId} />
    </NewPageContent>
  );
}

export default withRole([acl.SUPERADMIN, acl.ADMIN])(ViewAccount);
