import { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import { useDispatch, useSelector } from 'react-redux';

import ActiveBadge from 'core/badges/ActiveBadge';
import VerifiedBadge from 'core/badges/VerifiedBadge';
import GroupedTableView from 'core/table/GroupedTableView';
import PartnerClickAwayDetails from 'components/partners/PartnerClickAwayDetails';

import Auth from 'utils/Auth';
import isEmpty from 'helpers/isEmpty';
import DateUtils from 'utils/DateUtils';
import { getWebhook } from 'app/features/webhooks/webhookSaga';
import useSourceDetail from 'core/source-detail/useSourceDetail';
import SourceDetails from 'core/source-detail/SourceDetails';

export default function Webhook({ webhookId }) {
  const dispatch = useDispatch();

  const {
    isError,
    errorCode,
    isLoading,
    data: webhook,
  } = useSelector((state) => state.webhooks.show);

  useEffect(() => {
    dispatch(getWebhook(webhookId));
  }, [webhookId]);

  const viewWebhookDetail = useSourceDetail([
    {
      title: 'Webhook Details',
      items: [
        ...(Auth.isSuperAdmin()
          ? [
              {
                label: 'Partner',
                accessorKey: 'partner_id',
                cell: ({ partner_id, partner_name }) => (
                  <PartnerClickAwayDetails partnerId={partner_id} label={partner_name} />
                ),
              },
            ]
          : []),
        { label: 'URL', accessorKey: 'url' },
        { label: 'Object Type', accessorKey: 'object_type' },
        { label: 'Description', accessorKey: 'description' },
        {
          label: 'Is Active?',
          accessorKey: 'is_active',
          cell: ({ is_active }) => <ActiveBadge isActive={!!is_active} />,
        },
        {
          label: 'Is Verified?',
          accessorKey: 'is_verified',
          cell: ({ is_verified }) => <VerifiedBadge isVerified={!!is_verified} />,
        },
        {
          label: 'Created At',
          accessorKey: 'created_ts',
          cell: ({ created_ts }) =>
            !isEmpty(created_ts) ? DateUtils.isoToString(created_ts) : '-',
        },
        { label: 'Created By', accessorKey: 'created_by' },
        {
          label: 'Updated At',
          accessorKey: 'updated_ts',
          cell: ({ updated_ts }) =>
            !isEmpty(updated_ts) ? DateUtils.isoToString(updated_ts) : '-',
        },
        { label: 'Updated By', accessorKey: 'updated_by' },
      ],
    },
  ]);

  return (
    <SourceDetails
      enableLabelColon={true}
      rowMode="row"
      viewMode="column"
      definition={viewWebhookDetail}
      data={webhook}
      isLoading={isLoading}
    />
  );
}

Webhook.propTypes = {
  webhookId: PropTypes.string,
};
