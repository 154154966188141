import { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import { useNavigate } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import { useSelector, useDispatch } from 'react-redux';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import AspectRatioIcon from '@mui/icons-material/AspectRatio';
import { getCoreRowModel, useReactTable } from '@tanstack/react-table';
import PopoverButton from 'core/buttons/PopoverButton';
import ListItemButton from '@mui/material/ListItemButton';

import ActiveBadge from 'core/badges/ActiveBadge';
import FormCheckbox from 'core/forms/FormCheckbox';
import VerifiedBadge from 'core/badges/VerifiedBadge';
import TableContainer from 'core/table/TableContainer';
import CustomerKycsActions from './CustomerKycsActions';
import CustomerKycPagination from './CustomerKycPagination';
import TanstackReactTable from 'core/table/TanstackReactTable';

import Auth from 'utils/Auth';
import { buildRoute } from 'routes';
import isEmpty from 'helpers/isEmpty';
import constants from 'app/config/constants';
import KycStatus from 'core/badges/KycStatus';
import useCountries from 'hooks/useCountries';
import Dimensions from 'app/config/Dimensions';
import { getCustomerKycs } from 'app/features/customer-kycs/customerKycSaga';
import { getCustomerKycBriefDetailsInit } from 'app/features/customer-kycs/customerKycSlice';
import TableContainerWrapper from 'core/table/TableContainerWrapper';
import KycStatusBadge from 'core/badges/KycStatusBadge';

export default function CustomerKycs({ filterByCustomer, customerId }) {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const { getCountryNameByIso3Code } = useCountries();

  const [columnVisibility, setColumnVisibility] = useState({});

  const customerKycState = useSelector((state) => state.customerKycs.index);

  const { data, params, isLoading } = customerKycState;

  const columns = useMemo(
    () => [
      {
        id: constants.TABLE_CHECKBOX_SELECT_CHECKBOX_ID,
        header: ({ table }) => (
          <FormCheckbox
            forTableHead={true}
            {...{
              checked: table.getIsAllRowsSelected(),
              indeterminate: table.getIsSomeRowsSelected(),
              onChange: table.getToggleAllRowsSelectedHandler(),
            }}
          />
        ),
        cell: ({ row }) => (
          <div>
            <FormCheckbox
              {...{
                checked: row.getIsSelected(),
                indeterminate: row.getIsSomeSelected(),
                onChange: row.getToggleSelectedHandler(),
              }}
            />
          </div>
        ),
      },
      {
        id: constants.TABLE_SERIAL_NUMBER_COLUMN_ID,
        header: 'SN',
        accessorFn: (row) => row.sn,
      },

      {
        header: 'Customer',
        accessorFn: (row) => row.user_name,
      },
      ...(Auth.isSuperAdmin()
        ? [
            {
              header: 'Partner Name',
              accessorFn: (row) => row.partner_name,
            },
          ]
        : []),
      {
        header: 'KYC Status',
        accessorFn: (row) => row.kyc_status,
        cell: (info) => {
          return <KycStatusBadge status={info.getValue()} />;
        },
      },
      {
        header: 'KYC method',
        accessorFn: (row) => row.kyc_method,
      },
      {
        header: 'KYC Type',
        accessorFn: (row) => row.kyc_type,
      },
      {
        header: 'Country',
        accessorFn: (row) => row.country,
        cell: (info) => getCountryNameByIso3Code(info.getValue()),
      },

      {
        header: 'Is Verified ?',
        accessorFn: (row) => row.is_verified,
        cell: (info) => {
          return <VerifiedBadge isVerified={!!info.getValue()} />;
        },
      },
      {
        header: 'Is Active ?',
        accessorFn: (row) => row.is_active,
        cell: (info) => <ActiveBadge isActive={!!info.getValue()} />,
      },
      {
        id: constants.TABLE_ACTION_COLUMN_ID,
        header: 'Action',
        accessorFn: (row) => row.id,
        cell: (info) => (
          <PopoverButton>
            {({ onClose }) => (
              <>
                <ListItemButton
                  onClick={() => {
                    dispatch(
                      getCustomerKycBriefDetailsInit({
                        showBriefDetailModal: true,
                        customerKycId: info.getValue(),
                      }),
                    );
                    onClose();
                  }}
                >
                  Preview
                </ListItemButton>
                <ListItemButton
                  onClick={() =>
                    navigate(buildRoute('customer-kycs.show', info.getValue()), onClose())
                  }
                >
                  View Details
                </ListItemButton>
              </>
            )}
          </PopoverButton>
        ),
      },
    ],
    [],
  );

  const table = useReactTable({
    data,
    columns,
    state: {
      columnVisibility,
    },
    onColumnVisibilityChange: setColumnVisibility,
    getCoreRowModel: getCoreRowModel(),
  });

  useEffect(() => {
    dispatch(
      getCustomerKycs({
        ...params,
        ...(filterByCustomer
          ? {
              user_id: customerId,
              partner_id: '',
            }
          : {}),
      }),
    );
  }, [JSON.stringify(params)]);

  return (
    <TableContainerWrapper>
      <CustomerKycsActions
        table={table}
        enableFilterByPartner={!filterByCustomer}
        defaultFilterQueries={{
          ...(filterByCustomer
            ? {
                user_id: customerId,
              }
            : {}),
        }}
      />
      <TanstackReactTable
        table={table}
        isLoading={isLoading}
        data={data}
        columnsCount={columns.length}
      />
      <CustomerKycPagination />
    </TableContainerWrapper>
  );
}

CustomerKycs.propTypes = {
  filterByCustomer: PropTypes.bool,
  customerId: (props, propName, componentName) => {
    if (!!props['filterByCustomer'] && isEmpty(props[propName])) {
      return new Error(
        `${propName} is required when 'filterByCustomer' props is 'true' in ${componentName} component`,
      );
    }
  },
};
