import { createSlice } from '@reduxjs/toolkit';

import app from 'app/config/app';
import isEmpty from 'helpers/isEmpty';
import Response from 'app/config/Response';

const initialState = {
  index: {
    data: [],
    params: {
      page_size: app.perPage || 10,
      page_number: 1,
      sort_by: 'created_ts',
      order_by: 'desc',
    },
    isLoading: false,
    totalCount: 0,
    totalPage: 0,
    isError: false,
    errorCode: null,
    errorMessage: null,
    success: false,
    isFilterOpen: false,
  },
  show: {
    showBriefDetailModal: false,
    customerKycId: null,
    isLoading: false,
    isError: false,
    errorCode: null,
    errorMessage: null,
    data: null,
    isStatusProcessing: false,
  },
};

export const customerKycSlice = createSlice({
  name: 'customerKycs',
  initialState,
  reducers: {
    getCustomerKycsInit: (state) => {
      state.index = {
        ...state.index,
        isLoading: true,
        isError: false,
        errorCode: null,
        errorMessage: null,
      };
    },

    getCustomerKycsSuccess: (state, { payload }) => {
      let { data, pagination } = payload;

      const { totalCount, totalPage, currentPage, pageSize } = pagination;

      let startSN = (currentPage - 1) * pageSize;

      data = data?.map((item) => ({ sn: (startSN += 1), ...item }));

      state.index = {
        ...state.index,
        isLoading: false,
        isError: false,
        errorCode: null,
        success: false,
        errorMessage: null,
        data,
        totalCount,
        totalPage,
      };
    },

    getCustomerKycsFailure: (state, { payload }) => {
      state.index = {
        ...state.index,
        isLoading: false,
        isError: true,
        errorCode: payload?.response?.status ?? 0,
        errorMessage: payload?.response?.message,
      };
    },

    resetGetCustomerKycsFailure: (state) => {
      state.index = {
        ...state.index,
        isLoading: false,
        isError: false,
        errorCode: null,
        errorMessage: null,
      };
    },

    getCustomerKycReportDownloadInit: (state) => {
      state.index.isLoading = false;
      state.index.isError = false;
      state.index.errorCode = null;
      state.index.errorMessage = null;
      state.index.success = false;
    },

    getCustomerKycReportDownloadSuccess: (state, { payload }) => {
      let { data } = payload;

      state.index = {
        ...state.index,
        isLoading: false,
        success: true,
        isError: false,
        errorCode: null,
        errorMessage: null,
        data,
      };
    },

    getCustomerKycReportDownloadFailure: (state, { payload }) => {
      state.index.isLoading = false;
      state.index.isError = true;
      state.index.errorCode = payload?.response?.status ?? 0;
      state.index.errorMessage = payload?.response?.message;
    },

    resetCustomerKycReportDownload: (state) => {
      state.index.isLoading = false;
      state.index.isError = false;
      state.index.errorCode = null;
      state.index.errorMessage = null;
      state.index.success = false;
    },

    setParam: (state, { payload }) => {
      state.index.params = { ...state.index.params, ...payload };
    },

    resetParam: (state) => {
      state.index.params = {
        page_size: state.index.params.page_size,
        page_number: 1,
      };
    },

    deleteFilterParams: (state, { payload }) => {
      if (Array.isArray(payload)) payload.forEach((key) => delete state.index.params[key]);
      else delete state.index.params[payload];
    },

    getCustomerKycInit: (state) => {
      state.show = {
        ...state.show,
        isLoading: false,
        isError: false,
        errorCode: null,
        errorMessage: null,
        data: null,
      };
    },

    getCustomerKycSuccess: (state, { payload }) => {
      if (isEmpty(payload.data)) {
        state.show = {
          ...state.show,
          isLoading: false,
          isError: true,
          errorCode: Response.HTTP_NOT_FOUND,
          errorMessage: 'Customer KYC not found',
          data: null,
        };
      } else {
        state.show = {
          ...state.show,
          isLoading: false,
          isError: false,
          errorCode: null,
          errorMessage: null,
          data: payload?.data,
        };
      }
    },

    getCustomerKycFailure: (state, { payload }) => {
      state.show = {
        ...state.show,
        isLoading: false,
        isError: true,
        errorCode: payload?.response?.status ?? 0,
        errorMessage: payload?.response?.message,
        data: null,
      };
    },

    resetGetCustomerKycFailure: (state, { payload }) => {
      state.show = {
        ...state.show,
        isLoading: false,
        isError: false,
        errorCode: null,
        errorMessage: null,
        data: null,
      };
    },

    getCustomerKycBriefDetailsInit: (state, { payload }) => {
      state.show = {
        ...state.show,
        showBriefDetailModal: payload.showBriefDetailModal,
        customerKycId: payload.customerKycId,
      };
    },

    hideCustomerKycBriefDetails: (state) => {
      state.show = {
        showBriefDetailModal: false,
        customerKycId: null,
        isLoading: false,
        isError: false,
        errorCode: null,
        errorMessage: null,
        data: null,
      };
    },

    changeKycStatusInit: (state) => {
      state.show = {
        ...state.show,
        isStatusProcessing: true,
      };
    },

    changeKycStatusSuccess: (state, { payload }) => {
      state.show = {
        ...state.show,
        data: {
          ...state.show.data,
          kyc_status: payload,
        },
        isStatusProcessing: false,
      };
    },

    changeKycStatusFailure: (state) => {
      state.show = {
        ...state.show,
        isStatusProcessing: false,
      };
    },

    toggleFilter: (state) => {
      state.index.isFilterOpen = !state.index.isFilterOpen;
    },
  },
});

export const {
  getCustomerKycsInit,
  getCustomerKycsSuccess,
  getCustomerKycsFailure,
  resetGetCustomerKycsFailure,
  setParam,
  resetParam,
  deleteFilterParams,
  getCustomerKycInit,
  getCustomerKycSuccess,
  getCustomerKycFailure,
  getCustomerKycBriefDetailsInit,
  hideCustomerKycBriefDetails,
  resetGetCustomerKycFailure,
  changeKycStatusInit,
  changeKycStatusSuccess,
  changeKycStatusFailure,
  getCustomerKycReportDownloadFailure,
  getCustomerKycReportDownloadInit,
  getCustomerKycReportDownloadSuccess,
  resetCustomerKycReportDownload,
  toggleFilter,
} = customerKycSlice.actions;

export default customerKycSlice.reducer;
