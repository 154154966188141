import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import ListItemButton from '@mui/material/ListItemButton';
import React, { useMemo, useEffect, useState } from 'react';
import { useReactTable, getCoreRowModel } from '@tanstack/react-table';

import { useConfirm } from 'core/mui-confirm';
import ActiveBadge from 'core/badges/ActiveBadge';
import FormCheckbox from 'core/forms/FormCheckbox';
import PopoverButton from 'core/buttons/PopoverButton';
import TanstackReactTable from 'core/table/TanstackReactTable';
import TableContainerWrapper from 'core/table/TableContainerWrapper';
import AuthUserIsSuperAdmin from 'components/auth/AuthUserIsSuperAdmin';

import Auth from 'utils/Auth';
import app from 'app/config/app';
import { buildRoute } from 'routes';
import isEmpty from 'helpers/isEmpty';
import constants from 'app/config/constants';
import useCountries from 'hooks/useCountries';
import useQueryParams from 'hooks/useQueryParams';
import PartnerFundingSourcesActions from './PartnerFundingSourcesActions';
import PartnerFundingSourcePagination from './PartnerFundingSourcePagination';
import { getPartnerFundingSources } from 'app/features/partner-funding-sources/partnerFundingSourceSaga';
import { deletePartnerFundingSource } from 'app/features/partner-funding-sources/partnerFundingSourceSaga';
import { getPartnerFundingSourceBriefDetailsInit } from 'app/features/partner-funding-sources/partnerFundingSourceSlice';

export default function PartnerFundingSources({ preFilterByPartner, partnerId }) {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const { getCountryNameByIso3Code } = useCountries();

  const confirm = useConfirm();

  const partnerFundingSourcesState = useSelector((state) => state.partnerFundingSources.index);

  const { params, data, isLoading } = partnerFundingSourcesState;

  const [queryParams] = useQueryParams(app.ignoreFilterKeys);

  const [columnVisibility, setColumnVisibility] = useState({});

  /**
   * handle delete partnerFundingSource
   *
   */
  const handleDelete = (partnerFundingSourceId) => {
    confirm({
      icon: 'warning',
      confirmationText: 'Yes, Delete it.',
      description: 'This will permanently delete this partner funding source.',
    })
      .then(() => dispatch(deletePartnerFundingSource(partnerFundingSourceId)))
      .catch(() => {});
  };

  const columns = useMemo(
    () => [
      {
        id: constants.TABLE_CHECKBOX_SELECT_CHECKBOX_ID,
        header: ({ table }) => (
          <FormCheckbox
            forTableHead={true}
            {...{
              checked: table.getIsAllRowsSelected(),
              indeterminate: table.getIsSomeRowsSelected(),
              onChange: table.getToggleAllRowsSelectedHandler(),
            }}
          />
        ),
        cell: ({ row }) => (
          <div>
            <FormCheckbox
              {...{
                checked: row.getIsSelected(),
                indeterminate: row.getIsSomeSelected(),
                onChange: row.getToggleSelectedHandler(),
              }}
            />
          </div>
        ),
      },
      {
        id: constants.TABLE_SERIAL_NUMBER_COLUMN_ID,
        header: 'SN',
        accessorFn: (row) => row.sn,
      },
      ...(Auth.isSuperAdmin()
        ? [
            {
              header: 'Partner',
              accessorFn: (row) => row.partner_name,
            },
          ]
        : []),
      {
        header: 'Funding Source',
        accessorFn: (row) => row.funding_source_name,
      },
      {
        header: 'Country',
        accessorFn: (row) => getCountryNameByIso3Code(row.country),
      },
      {
        header: 'Is Active ?',
        accessorFn: (row) => row.is_active,
        cell: ({ row }) => <ActiveBadge isActive={!!row.original.is_active} />,
      },
      {
        id: constants.TABLE_ACTION_COLUMN_ID,
        header: 'Action',
        accessorFn: (row) => row.id,
        cell: ({ row }) => (
          <PopoverButton>
            {({ onClose }) => (
              <>
                <ListItemButton
                  onClick={() => {
                    dispatch(
                      getPartnerFundingSourceBriefDetailsInit({
                        showBriefDetailModal: true,
                        partnerFundingSourceId: row.original.partner_funding_id,
                      }),
                    );
                    onClose();
                  }}
                >
                  Preview
                </ListItemButton>
                <ListItemButton
                  onClick={() => {
                    navigate(
                      buildRoute('partner-funding-sources.show', row.original.partner_funding_id),
                    );
                    onClose();
                  }}
                >
                  View Details
                </ListItemButton>

                {/* <AuthUserIsSuperAdmin>
                  <ListItemButton
                    onClick={() =>
                      navigate(
                        buildRoute('partner-funding-sources.edit', row.original.partner_funding_id),
                      )
                    }
                  >
                    Edit
                  </ListItemButton>
                </AuthUserIsSuperAdmin> */}

                <AuthUserIsSuperAdmin>
                  <ListItemButton
                    onClick={() => handleDelete(row.original.id)}
                    disabled={row.original?.isDeleting || false}
                    color="error"
                  >
                    Delete
                  </ListItemButton>
                </AuthUserIsSuperAdmin>
              </>
            )}
          </PopoverButton>
        ),
      },
    ],
    [JSON.stringify(data)],
  );

  const table = useReactTable({
    data,
    columns,
    state: {
      columnVisibility,
    },
    onColumnVisibilityChange: setColumnVisibility,
    getCoreRowModel: getCoreRowModel(),
  });

  useEffect(() => {
    dispatch(
      getPartnerFundingSources({
        ...queryParams,
        ...params,
        ...(preFilterByPartner ? { partner_id: partnerId } : {}),
      }),
    );
  }, [JSON.stringify(params)]);

  return (
    <TableContainerWrapper>
      {!preFilterByPartner && <PartnerFundingSourcesActions table={table} />}
      <TanstackReactTable
        table={table}
        data={data}
        isLoading={isLoading}
        columnsCount={
          columns.length - Object.entries(columnVisibility ?? {}).filter(([k, v]) => !v).length
        }
      />
      <PartnerFundingSourcePagination />
    </TableContainerWrapper>
  );
}

PartnerFundingSources.propTypes = {
  preFilterByPartner: PropTypes.bool,
  partnerId: (props, propName, componentName) => {
    if (props['preFilterByPartner'] === true && isEmpty(props[propName])) {
      return new Error(
        `${propName} is required when 'preFilterByPartner' props is 'true' in ${componentName} component`,
      );
    } else if (props['preFilterByPartner'] === true && typeof props[propName] !== 'string') {
      return new Error(`${propName} value must be string ${componentName} component`);
    }
  },
};
