import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import withRole from 'hoc/withRole';
import ActionStatusModal from 'core/modal/ActionStatusModal';
import PartnerCountryForm from 'components/partner-countries/PartnerCountryForm';

import acl from 'app/config/acl';
import { buildRoute } from 'routes';
import ucwords from 'helpers/ucwords';
import routePaths from 'app/config/routePaths';
import NewPageContent from 'layouts/NewPageContent';
import {
  setShowCreateProcessingModal,
  addPartnerCountryReset,
} from 'app/features/partner-countries/partnerCountrySlice';

function AddPartnerCountry() {
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    data: partnerCountry,
    showProcessingModal,
    isCreating,
    isCreated,
    isFailed,
    errors,
    errorMessage,
    retries,
  } = useSelector((state) => state.partnerCountries.create);

  return (
    <NewPageContent
      breadcrumbs={[
        {
          label: 'Partner Countries',
          to: routePaths.ListPartnerCountries,
        },
        {
          label: 'Add Partner Country',
          to: '',
        },
      ]}
      documentTitle="Add Partner Country"
    >
      <PartnerCountryForm isAddMode={true} />
      <ActionStatusModal
        showModal={showProcessingModal}
        closeModal={() => dispatch(setShowCreateProcessingModal(false))}
        data={partnerCountry}
        isSuccess={isCreated}
        isProcessing={isCreating}
        isError={isFailed}
        retriesCount={retries}
        errorMessage={ucwords(errorMessage) ?? 'Failed to create partner country'}
        successMessage="Partner Country created successfully"
        navigateToView={() =>
          navigate(buildRoute('partner-countries.show', partnerCountry.id ?? params.partnerId))
        }
        resetForm={() => dispatch(addPartnerCountryReset())}
      />
    </NewPageContent>
  );
}

export default withRole([acl.SUPERADMIN])(AddPartnerCountry);
