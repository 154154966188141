import { useEffect } from 'react';
import Button from 'core/buttons/Button';
import { compose } from '@reduxjs/toolkit';
import EditIcon from '@mui/icons-material/Edit';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import withRole from 'hoc/withRole';
import Error from 'core/errors/Error';
import withAppState from 'hoc/withAppState';
import PageContent from 'layouts/PageContent';
import LicensingPartner from 'components/LicensingPartner/LicensingPartner';

import acl from 'app/config/acl';
import { buildRoute } from 'routes';
import { resetGetLicensingPartnerFailure } from 'app/features/licensing-partners/licensingPartnerSlice';
import NewPageContent from 'layouts/NewPageContent';
import routePaths from 'app/config/routePaths';
import ActionButton from 'core/buttons/ActionButton';

function ViewLicensingPartner({ isNetworkError }) {
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { isError, errorCode, errorMessage } = useSelector((state) => state.licensingPartners.show);

  useEffect(() => {
    if (isError) dispatch(resetGetLicensingPartnerFailure());
  }, [isNetworkError]);

  if (isError)
    return (
      <Error
        status={errorCode || 0}
        message={errorMessage}
        onRetry={() => dispatch(resetGetLicensingPartnerFailure())}
      />
    );

  return (
    <NewPageContent
      breadcrumbs={[
        {
          label: 'Licensing Partners',
          to: routePaths.ListLicensingPartners,
        },
        { label: 'View Licensing Partner', to: '' },
      ]}
      documentTitle="Licensing Partner Details"
      rightTopEndContent={
        <ActionButton
          onClick={() =>
            navigate(buildRoute('licensing-partners.update', params.licensingPartnerId))
          }
          color="primary"
        >
          Edit Licensing Partner
        </ActionButton>
      }
    >
      <LicensingPartner licensingPartnerId={params.licensingPartnerId} />
    </NewPageContent>
  );
}

export default compose(withAppState, withRole([acl.SUPERADMIN, acl.ADMIN]))(ViewLicensingPartner);
