import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import withRole from 'hoc/withRole';
import RolesForm from 'components/Roles/RolesForm';

import acl from 'app/config/acl';
import routePaths from 'app/config/routePaths';
import NewPageContent from 'layouts/NewPageContent';

function AddRoles() {
  const navigate = useNavigate();

  const handleSubmit = (data) => {
    //
  };

  const handleCancel = () => {
    navigate(-1);
  };

  //const {success, loading: isPending} = useSelector((state) => state.rolesAndPermissions.create)

  const isPending = false;

  return (
    <NewPageContent
      breadcrumbs={[
        {
          label: 'Roles',
          to: routePaths.ListRoles,
        },
        {
          label: 'Add Role',
          to: '',
        },
      ]}
      documentTitle="Add Department Roles"
    >
      <RolesForm
        onSubmit={handleSubmit}
        onCancel={handleCancel}
        isSubmitting={isPending}
        isAddMode={true}
      />
    </NewPageContent>
  );
}

export default withRole([acl.SUPERADMIN, acl.ADMIN])(AddRoles);
