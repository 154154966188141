import { useEffect } from 'react';
import { compose } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';

import Error from 'core/errors/Error';
import withAppState from 'hoc/withAppState';
import Customer from 'components/customers/Customer';
import CustomerCard from 'components/customers/CustomerCard';

import acl from 'app/config/acl';
import withRole from 'hoc/withRole';
import { resetGetCustomerFailure } from 'app/features/customers/customerSlice';
import NewPageContent from 'layouts/NewPageContent';
import routePaths from 'app/config/routePaths';

function ViewCustomer({ isNetworkError }) {
  const params = useParams();

  const { isError, errorCode } = useSelector((state) => state.customers.show);

  const dispatch = useDispatch();

  const navigate = useNavigate();

  useEffect(() => {
    if (isError) dispatch(resetGetCustomerFailure());
  }, [isNetworkError]);

  if (isError)
    return <Error status={errorCode || 0} onRetry={() => dispatch(resetGetCustomerFailure())} />;

  return (
    <NewPageContent
      breadcrumbs={[
        {
          label: 'Customers',
          to: routePaths.ListCustomers,
        },
        {
          label: 'Customer Details',
          to: '',
        },
      ]}
      documentTitle="Customer Details"
    >
      <CustomerCard />
      <Customer customerId={params.customerId} />
    </NewPageContent>
  );
}

export default compose(withAppState, withRole([acl.SUPERADMIN, acl.ADMIN, acl.USER]))(ViewCustomer);
