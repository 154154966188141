import { useEffect, useMemo, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { useDispatch, useSelector } from 'react-redux';

import Button from 'core/buttons/Button';
import TransactionLogs from './TransactionLogs';
import SpringModal from 'core/modal/SpringModal';
import ActiveBadge from 'core/badges/ActiveBadge';
import VerifiedBadge from 'core/badges/VerifiedBadge';
import GroupedTableView from 'core/table/GroupedTableView';
import RejectTransactionForm from './RejectTransactionForm';
import ApproveTransactionForm from './ApproveTransactionForm';
import PartnerClickAwayDetails from 'components/partners/PartnerClickAwayDetails';
import CustomerClickAwayDetails from 'components/customers/CustomerClickAwayDetails';

import Auth from 'utils/Auth';
import isEmpty from 'helpers/isEmpty';
import DateUtils from 'utils/DateUtils';
import useCountries from 'hooks/useCountries';
import { fundingSources } from 'app/config/FundingSource';
import { transactionStatuses } from 'app/config/TransactionStatus';
import { getTransactionsLog } from 'app/features/transactions/transactionLogSaga';
import {
  approveTransaction,
  getTransaction,
  rejectTransaction,
} from 'app/features/transactions/transactionSaga';
import Clipboard from 'core/clipboard/Clipboard';
import useSourceDetail from 'core/source-detail/useSourceDetail';
import SourceDetails from 'core/source-detail/SourceDetails';
import TransactionStatusBadge from 'core/badges/TransactionStatusBadge';

export default function Transaction({ transactionId }) {
  const dispatch = useDispatch();

  const [openApproveTransactionModal, setOpenApproveTransactionModal] = useState(false);
  const [openRejectTransactionModal, setOpenRejectTransactionModal] = useState(false);

  const hideApproveTransactionModal = useCallback(() => setOpenApproveTransactionModal(false), []);
  const hideRejectTransactionModal = useCallback(() => setOpenRejectTransactionModal(false), []);

  const { countries, getCountryNameByIso3Code } = useCountries();

  const viewTransactionData = useSourceDetail([
    {
      title: 'Brief Details',
      items: [
        { label: 'Transaction Id', accessorKey: 'id' },
        { label: 'Order Id', accessorKey: 'order_id' },
        ...(Auth.isSuperAdmin()
          ? [
              {
                label: 'Partner',
                accessorKey: 'partner_id',
                cell: ({ partner_id, partner_name }) => (
                  <PartnerClickAwayDetails partnerId={partner_id} label={partner_name} />
                ),
              },
            ]
          : []),
        { label: 'Funding Id', accessorKey: 'funding_id' },
        { label: 'Funding Type', accessorKey: 'funding_type' },
        {
          label: 'Sending Country',
          cell: ({ sending_country }) => getCountryNameByIso3Code(sending_country),
        },
        { label: 'Sending Amount', accessorKey: 'sending_amount' },
        { label: 'Sending Currency', accessorKey: 'sending_currency' },
        {
          label: 'Payout Country',
          cell: ({ payout_country }) => getCountryNameByIso3Code(payout_country),
        },
        { label: 'Payout Amount', accessorKey: 'payout_amount' },
        { label: 'Payout Currency', accessorKey: 'payout_currency' },
        { label: 'Purpose', accessorKey: 'txn_purpose' },
        {
          label: 'Status',
          accessorKey: 'status',
          cell: ({ status }) => (
            <>
              <TransactionStatusBadge status={status} />
            </>
          ),
        },
        {
          label: 'Transaction/Reference ID',
          accessorKey: 'transaction_reference_number',
          cell: ({ transaction_reference_number }) =>
            transaction_reference_number ? (
              <Clipboard content={transaction_reference_number} />
            ) : (
              'N/A'
            ),
        },
        { label: 'Remarks', accessorKey: 'remarks' },
        {
          label: 'Is Active?',
          accessorKey: 'is_active',
          cell: ({ is_active }) => <ActiveBadge isActive={!!is_active} />,
        },
        {
          label: 'Is Verified?',
          accessorKey: 'is_verified',
          cell: ({ is_verified }) => <VerifiedBadge isVerified={!!is_verified} />,
        },
        {
          label: 'Created At',
          accessorKey: 'created_ts',
          cell: ({ created_ts }) => (created_ts ? DateUtils.isoToString(created_ts) : '-'),
        },
        { label: 'Created By', accessorKey: 'created_by' },
        {
          label: 'Updated At',
          accessorKey: 'updated_ts',
          cell: ({ updated_ts }) => (updated_ts ? DateUtils.isoToString(updated_ts) : '-'),
        },
        { label: 'Updated By', accessorKey: 'updated_by' },
      ],
    },
    {
      title: 'Customer Details',
      items: [
        { label: 'Customer', accessorKey: 'user_name' },
        {
          label: 'Customer ID',
          accessorKey: 'user_id',
          cell: ({ user_id }) => <CustomerClickAwayDetails customerId={user_id} />,
        },
        { label: 'ID Type', accessorKey: 'id_type' },
        { label: 'Email', accessorKey: 'email' },
        { label: 'Date of Birth', accessorKey: 'dob' },
        { label: 'Mobile Number', accessorKey: 'mobile_number' },
        { label: 'Suburb', accessorKey: 'suburb' },
        { label: 'Street Type', accessorKey: 'street' },
        { label: 'Street Number', accessorKey: 'street_number' },
      ],
    },
    {
      title: 'Beneficiary Details',
      items: [
        { label: 'ID', accessorKey: 'beneficiary_id' },
        { label: 'Name', accessorKey: 'beneficiay_name' },
        { label: 'Type', accessorKey: 'beneficiary_type' },
        { label: 'Relation', accessorKey: 'beneficiary_relation' },
        { label: 'Address', accessorKey: 'beneficiary_address' },
        {
          label: 'Country',
          cell: ({ beneficiary_country }) => getCountryNameByIso3Code(beneficiary_country),
        },
        { label: 'Currency', accessorKey: 'beneficiary_currency' },
        { label: 'Mobile Number', accessorKey: 'beneficiary_mobile_number' },
        { label: 'Payment Type', accessorKey: 'beneficiary_payment_type' },
        { label: 'Account Number', accessorKey: 'beneficiary_account_number' },
        { label: 'Branch', accessorKey: 'beneficiary_branch' },
      ],
    },
  ]);

  const handleApproveTransaction = (data) => {
    dispatch(
      approveTransaction({
        ...data,
        transactionId,
      }),
    );
  };

  const handleRejectTransaction = (data) => {
    dispatch(
      rejectTransaction({
        ...data,
        transactionId,
      }),
    );
  };

  const { isLoading, data: transaction } = useSelector((state) => state.transactions.show);

  const { isLoading: isApproving } = useSelector((state) => state.transactions.approve);
  const { isLoading: isRejecting } = useSelector((state) => state.transactions.reject);
  const { isLoading: isTransactionLoading, data: transactionLogData } = useSelector(
    (state) => state.transactionLogs,
  );

  /**
   * Note: For following funding sources we don't need to approve the transaction
   */
  const rejectFundingTypes = [
    // fundingSources.payNowQR.toUpperCase(),
    fundingSources.poli.toUpperCase(),
  ];

  useEffect(() => {
    if (isApproving || isRejecting) return;
    dispatch(getTransaction(transactionId));
    dispatch(getTransactionsLog(transactionId));
  }, [transactionId, isApproving, isRejecting]);

  return (
    <>
      <SourceDetails definition={viewTransactionData} data={transaction} isLoading={isLoading} />

      <>
        {!isLoading &&
          transaction &&
          transaction.status.toUpperCase() === transactionStatuses.created.toUpperCase() &&
          Auth.isSuperAdmin() && (
            <Box display="flex" gap={3}>
              {transaction && !rejectFundingTypes.includes(transaction.funding_type.toUpperCase()) && (
                <>
                  <Button color="success" onClick={() => setOpenApproveTransactionModal(true)}>
                    Approve
                  </Button>

                  <SpringModal
                    header="Approve Transaction"
                    isOpen={openApproveTransactionModal}
                    onClose={() => setOpenApproveTransactionModal(true)}
                    enableMaximizeButton={false}
                    enableDefaultPadding
                    sx={{
                      maxWidth: '600px',
                    }}
                  >
                    <ApproveTransactionForm
                      onSubmit={handleApproveTransaction}
                      onCancel={hideApproveTransactionModal}
                    />
                  </SpringModal>
                </>
              )}

              <Button color="error" onClick={() => setOpenRejectTransactionModal(true)}>
                Reject
              </Button>
              <SpringModal
                header="Reject Transaction"
                isOpen={openRejectTransactionModal}
                onClose={() => setOpenRejectTransactionModal(true)}
                enableMaximizeButton={false}
                enableDefaultPadding
                sx={{
                  maxWidth: '600px',
                }}
              >
                <RejectTransactionForm
                  onSubmit={handleRejectTransaction}
                  onCancel={hideRejectTransactionModal}
                />
              </SpringModal>
            </Box>
          )}
      </>
    </>
  );
}

Transaction.propTypes = {
  transactionId: PropTypes.string,
};
