import { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import { useDispatch, useSelector } from 'react-redux';

import ActiveBadge from 'core/badges/ActiveBadge';
import VerifiedBadge from 'core/badges/VerifiedBadge';
import GroupedTableView from 'core/table/GroupedTableView';
import PartnerClickAwayDetails from 'components/partners/PartnerClickAwayDetails';

import Auth from 'utils/Auth';
import isEmpty from 'helpers/isEmpty';
import DateUtils from 'utils/DateUtils';
import { getWebhookHeader } from 'app/features/webhook-headers/webhookHeaderSaga';
import SourceDetails from 'core/source-detail/SourceDetails';
import useSourceDetail from 'core/source-detail/useSourceDetail';

export default function WebhookHeader({ webhookHeaderId }) {
  const dispatch = useDispatch();

  const viewWebhookHeaderDetail = useSourceDetail([
    {
      title: 'Webhook Header Details',
      items: [
        ...(Auth.isSuperAdmin()
          ? [
              {
                label: 'Partner',
                accessorKey: 'partner_id',
                cell: ({ partner_name, partner_id }) => (
                  <PartnerClickAwayDetails partnerId={partner_id} label={partner_name} />
                ),
              },
            ]
          : []),
        { label: 'Custom Header', accessorKey: 'custom_header' },
        { label: 'Value', accessorKey: 'value' },
        { label: 'Description', accessorKey: 'description' },
        {
          label: 'Is Active ?',
          accessorKey: 'is_active',
          cell: ({ is_active }) => <ActiveBadge isActive={is_active} />,
        },
        {
          label: 'Is Verified ?',
          accessorKey: 'is_verified',
          cell: ({ is_verified }) => <VerifiedBadge isVerified={is_verified} />,
        },
        {
          label: 'Created At',
          accessorKey: 'created_ts',
          cell: ({ created_ts }) =>
            !isEmpty(created_ts) ? DateUtils.isoToString(created_ts) : '-',
        },
        { label: 'Created By', accessorKey: 'created_by' },
        {
          label: 'Updated At',
          accessorKey: 'updated_ts',
          cell: ({ updated_ts }) =>
            !isEmpty(updated_ts) ? DateUtils.isoToString(updated_ts) : '-',
        },
        { label: 'Updated By', accessorKey: 'updated_by' },
      ],
    },
  ]);

  const {
    isError,
    errorCode,
    isLoading,
    data: webhookHeader,
  } = useSelector((state) => state.webhookHeaders.show);

  useEffect(() => {
    dispatch(getWebhookHeader(webhookHeaderId));
  }, [webhookHeaderId]);

  return (
    <SourceDetails
      rowMode="row"
      viewMode="column"
      enableLabelColon
      definition={viewWebhookHeaderDetail}
      data={webhookHeader}
      isLoading={isLoading}
    />
  );
}

WebhookHeader.propTypes = {
  webhookHeaderId: PropTypes.string,
};
