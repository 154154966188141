import { forwardRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import Tooltip from '@mui/material/Tooltip';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import ListItem from './ListItem';
import ListItemContexts from './ListItemContexts';
import styled from '@emotion/styled';
import { Box, List } from '@mui/material';

const StyledListItemIcon = styled(ListItemIcon)(({ theme }) => ({
  padding: 0,
  margin: 0,
  display: 'flex',
  alignItems: 'center',
  '& img': {
    width: '24px',
    height: '24px',
  },
}));

const StyledListItemDropdownIcon = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const DropdownListContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: 4,
  marginLeft: 25,
}));

const ListItemLink = function ListItemLink({
  isDrawerOpen,
  icon,
  primary,
  to,
  isActive,
  context,
  onClick,
  ...rest
}) {
  const CustomLink = useMemo(
    () => forwardRef((linkProps, ref) => <Link ref={ref} to={to} {...linkProps} />),
    [to],
  );

  const renderIcon = () => (
    <StyledListItemIcon sx={{ minWidth: '46px' }}>{icon}</StyledListItemIcon>
  );

  if (!isDrawerOpen)
    return (
      <Tooltip title={primary} placement="right-start">
        <Box
          sx={{
            width: '45px',
            marginLeft: 0.6,
          }}
        >
          <ListItem
            button
            component={CustomLink}
            onClick={onClick}
            context={context}
            isDrawerOpen={isDrawerOpen}
            isActive={isActive}
            {...rest}
          >
            {renderIcon()}
            <ListItemText primary={primary} />
          </ListItem>
        </Box>
      </Tooltip>
    );
  else if (context !== ListItemContexts.dropdownItem) {
    return (
      <ListItem
        button
        component={CustomLink}
        onClick={onClick}
        context={context}
        isDrawerOpen={isDrawerOpen}
        isActive={isActive}
        {...rest}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            paddingY: 1,
          }}
        >
          {renderIcon()}
          <ListItemText primary={primary} />
        </Box>
      </ListItem>
    );
  }

  return (
    <DropdownListContainer>
      <StyledListItemDropdownIcon>{icon}</StyledListItemDropdownIcon>
      <ListItem
        button
        component={CustomLink}
        onClick={onClick}
        context={context}
        isDrawerOpen={isDrawerOpen}
        isActive={isActive}
        {...rest}
      >
        <ListItemText primary={primary} />
      </ListItem>
    </DropdownListContainer>
  );
};

export default ListItemLink;

ListItemLink.propTypes = {
  isDrawerOpen: PropTypes.bool,
  icon: PropTypes.node,
  primary: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  isActive: PropTypes.bool,
  context: PropTypes.oneOf([ListItemContexts.item, ListItemContexts.dropdownItem]),
};

ListItemLink.defaultProps = {
  isDrawerOpen: false,
  context: ListItemContexts.item,
};
