import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import FormCheckbox from 'hook-form-controls/FormCheckbox';
import FormTextField from 'hook-form-controls/FormTextField';

const RoleDetailsContentForm = () => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="body1" fontWeight={600}>
          Role Details
        </Typography>
      </Grid>

      <Grid item xs={12} md={3}>
        <Typography mb={2} variant="body1" fontWeight={500}>
          Name
        </Typography>
        <FormTextField name="name" label="Name" placeholder="Role Name" />
      </Grid>
      <Grid item xs={12} md={3}>
        <Typography mb={2} variant="body1" fontWeight={500}>
          Description
        </Typography>
        <FormTextField name="description" label="Description" multiline />
      </Grid>

      <Grid display="flex" flexDirection="column" item xs={12} md={3}>
        <Typography mb={2} variant="body1" fontWeight={500}>
          Status
        </Typography>

        <Grid item xs={12}>
          <FormCheckbox name="is_active" label="Is Active >" />
        </Grid>
      </Grid>

      <Grid item xs={12} md={3}>
        <Typography mb={2} variant="body1" fontWeight={500}>
          Remarks
        </Typography>
        <FormTextField name="remarks" label="Remarks" placeholder="Write remarks" multiline />
      </Grid>
    </Grid>
  );
};

export default RoleDetailsContentForm;
