import React from 'react';
import PropTypes from 'prop-types';
import LicensePartnerMapper from 'components/license-partners-mapper/LicensePartnerMapper';
import Box from '@mui/material/Box';
import Button from 'core/buttons/Button';
import { useNavigate } from 'react-router-dom';
import { buildRoute } from 'routes';
import Auth from 'utils/Auth';
import ActionButton from 'core/buttons/ActionButton';

export default function LicensePartnerMapperTab({ partnerId }) {
  const navigate = useNavigate();

  return (
    <>
      <Box display="flex" justifyContent="flex-end" sx={{ mb: 2 }}>
        <ActionButton
          onClick={() => {
            navigate(
              buildRoute(
                'license-partner-mapper.create',
                Auth.isSuperAdmin() ? { partner_id: partnerId } : {},
              ),
              {
                state: {
                  partnerId,
                },
              },
            );
          }}
        >
          Add License Partner Mapper
        </ActionButton>
      </Box>
      <LicensePartnerMapper partnerId={partnerId} />
    </>
  );
}

LicensePartnerMapperTab.propTypes = {
  partnerId: PropTypes.string.isRequired,
};
