import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import withRole from 'hoc/withRole';
import Error from 'core/errors/Error';
import ActionStatusModal from 'core/modal/ActionStatusModal';
import WebhookHeaderForm from 'components/webhook-headers/WebhookHeaderForm';
import WebhookHeaderFormSkeleton from 'components/webhook-headers/WebhookHeaderFormSkeleton';

import acl from 'app/config/acl';
import { buildRoute } from 'routes';
import ucwords from 'helpers/ucwords';
import routePaths from 'app/config/routePaths';
import NewPageContent from 'layouts/NewPageContent';
import { editWebhookHeader } from 'app/features/webhook-headers/webhookHeaderSaga';
import {
  updateWebhookHeaderReset,
  setShowEditProcessingModal,
} from 'app/features/webhook-headers/webhookHeaderSlice';

function EditWebhookHeader() {
  const params = useParams();
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const {
    data: webhookHeader,
    isError,
    errorMessage,
    isLoading,
    errorCode,
  } = useSelector((state) => state.webhookHeaders.edit);

  const { showProcessingModal, isUpdating, isUpdated, isFailed, errors } = useSelector(
    (state) => state.webhookHeaders.update,
  );

  useEffect(() => {
    dispatch(editWebhookHeader(params.webhookHeaderId));
  }, [params.webhookHeaderId]);

  if (isError)
    return (
      <Error
        status={errorCode}
        message={errorMessage}
        onRetry={() => dispatch(editWebhookHeader(params.webhookHeaderId))}
      />
    );

  return (
    <NewPageContent
      breadcrumbs={[
        {
          label: 'Webhook Headers',
          to: routePaths.ListWebhookHeaders,
        },
        {
          label: 'Edit Webhook Header',
          to: '',
        },
      ]}
      documentTitle="Edit Webhook Header"
    >
      {isLoading ? (
        <WebhookHeaderFormSkeleton />
      ) : (
        <WebhookHeaderForm isAddMode={false} webhookHeader={webhookHeader} />
      )}
      <ActionStatusModal
        showModal={showProcessingModal}
        closeModal={() => dispatch(setShowEditProcessingModal(false))}
        data={webhookHeader}
        isSuccess={isUpdated}
        isProcessing={isUpdating}
        isError={isFailed}
        errorMessage={ucwords(errorMessage) ?? 'Failed To UpdateWebhook Header'}
        successMessage="Webhook Header Updated Successfully"
        navigateToView={() =>
          navigate(buildRoute('webhook-headers.show', webhookHeader.id ?? params.webhookHeaderId))
        }
        resetForm={() => dispatch(updateWebhookHeaderReset())}
      />
    </NewPageContent>
  );
}

export default withRole([acl.SUPERADMIN, acl.ADMIN])(EditWebhookHeader);
