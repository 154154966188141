const app = {
  version: process.env.REACT_APP_VERSION || 'Latest',
  /**
   * ------------------------------------------
   * App Name
   * ------------------------------------------
   *
   */
  name: process.env.REACT_APP_NAME || 'PaasPay',

  /**
   * ------------------------------------------
   * App Base Url
   * ------------------------------------------
   *
   */
  baseUrl: process.env.REACT_APP_BASE_URL || 'https://gdash.fintech.com.np',

  /**
   * ------------------------------------------
   * API Base Url
   * ------------------------------------------
   * configure a api base url
   *
   */
  apiBaseUrl: process.env.REACT_APP_API_BASE_URL || 'https://gdash.fintech.com.np/dapi',

  /**
   * ------------------------------------------
   * API data pagination length
   * ------------------------------------------
   *
   */
  perPage: 10,

  /**
   * ------------------------------------------
   * Sidebar drawer width
   * ------------------------------------------
   *
   */
  drawerWith: 300,

  /**
   * ------------------------------------------
   * enable or disable application in debug mode
   * ------------------------------------------
   */
  debug: (process.env.REACT_APP_DEBUG || 'false').toLowerCase() === 'true',

  /**
   * ------------------------------------------------
   * Allows to check is app is in development mode
   * ------------------------------------------------
   */
  isDevelopmentMode: () => process.env.NODE_ENV === 'development',

  /**
   * ------------------------------------------------
   * Allows to check is app is in production mode
   * ------------------------------------------------
   */
  isProductionMode: () => process.env.NODE_ENV === 'production',

  /**
   * ------------------------------------------------------------------------------------
   * set how may times you want to try to refresh a token in a unauthorized api requests
   * ------------------------------------------------------------------------------------
   */
  maxRefreshTokenTriesCount: 2,

  /**
   * ------------------------------------------------------------------------------------
   * show grouped error no inline file errors
   * ------------------------------------------------------------------------------------
   */
  showGroupError: true,

  /**
   * ------------------------------------------------------------------------------------
   * ignore these filter keys while showing filter values in filter data
   * ------------------------------------------------------------------------------------
   */
  ignoreFilterKeys: ['order_by', 'sort_by', 'page_number', 'page_size', 'limit', 'page', 'tab'],

  /**
   * ------------------------------------------------------------------------------------
   * support email for this app
   * ------------------------------------------------------------------------------------
   */
  supportEmail: process.env.REACT_APP_SUPPORT_EMAIL,

  /**
   * ------------------------------------------------------------------------------------
   * axios request timeout duration
   * ------------------------------------------------------------------------------------
   */
  requestTimeout: 100000,
};

export default app;
