import { useEffect } from 'react';
import { compose } from '@reduxjs/toolkit';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';

import Error from 'core/errors/Error';
import Button from 'core/buttons/Button';
import withAppState from 'hoc/withAppState';
import PageContent from 'layouts/PageContent';
import ZaiTransaction from 'components/zai-transactions/ZaiTransaction';

import acl from 'app/config/acl';
import { buildRoute } from 'routes';
import withRole from 'hoc/withRole';
import { resetGetTransactionFailure } from 'app/features/transactions/zaiTransactionSlice';
import NewPageContent from 'layouts/NewPageContent';
import routePaths from 'app/config/routePaths';

function ViewZaiTransaction({ isNetworkError }) {
  const params = useParams();

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const { isError, errorCode, errorMessage } = useSelector((state) => state.transactions.show);

  useEffect(() => {
    if (isError) dispatch(resetGetTransactionFailure());
  }, [isNetworkError]);

  if (isError)
    return (
      <Error
        status={errorCode}
        message={errorMessage}
        onRetry={() => dispatch(resetGetTransactionFailure())}
      />
    );

  return (
    <NewPageContent
      breadcrumbs={[
        {
          label: 'Transaction',
          to: '',
        },
        {
          label: 'Zai Transactions',
          to: routePaths.ZaiTransactions,
        },
        {
          label: 'View Details',
          to: '',
        },
      ]}
      documentTitle="Zai Transactions"
    >
      <ZaiTransaction transactionId={params.transactionId} />
    </NewPageContent>
  );
}

export default compose(
  withAppState,
  withRole([acl.SUPERADMIN, acl.ADMIN, acl.USER]),
)(ViewZaiTransaction);
