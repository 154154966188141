import { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import PopoverButton from 'core/buttons/PopoverButton';
import ListItemButton from '@mui/material/ListItemButton';
import { getCoreRowModel, useReactTable } from '@tanstack/react-table';

import Error from 'core/errors/Error';
import ActiveBadge from 'core/badges/ActiveBadge';
import FormCheckbox from 'core/forms/FormCheckbox';
import VerifiedBadge from 'core/badges/VerifiedBadge';
import TableContainer from 'core/table/TableContainer';
import BeneficiariesActions from './BeneficiariesActions.jsx';
import BeneficiaryPagination from './BeneficiaryPagination.jsx';
import TanstackReactTable from 'core/table/TanstackReactTable';

import Auth from 'utils/Auth';
import { buildRoute } from 'routes';
import ucwords from 'helpers/ucwords';
import isEmpty from 'helpers/isEmpty';
import useAuthUser from 'hooks/useAuthUser';
import constants from 'app/config/constants';
import Dimensions from 'app/config/Dimensions';
import { getBeneficiaries } from 'app/features/beneficiaries/beneficiarySaga';
import { getBeneficiaryBriefDetailsInit } from 'app/features/beneficiaries/beneficiarySlice';
import TableContainerWrapper from 'core/table/TableContainerWrapper.jsx';

export default function Beneficiaries({ filterByCustomer, customerId }) {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const user = useAuthUser();

  const [columnVisibility, setColumnVisibility] = useState({});

  const beneficiaryState = useSelector((state) => state.beneficiaries.index);

  const { data, params, isLoading, isError, errorCode } = beneficiaryState;

  const columns = useMemo(
    () => [
      {
        id: constants.TABLE_CHECKBOX_SELECT_CHECKBOX_ID,
        header: ({ table }) => (
          <FormCheckbox
            forTableHead={true}
            {...{
              checked: table.getIsAllRowsSelected(),
              indeterminate: table.getIsSomeRowsSelected(),
              onChange: table.getToggleAllRowsSelectedHandler(),
            }}
          />
        ),
        cell: ({ row }) => (
          <div>
            <FormCheckbox
              {...{
                checked: row.getIsSelected(),
                indeterminate: row.getIsSomeSelected(),
                onChange: row.getToggleSelectedHandler(),
              }}
            />
          </div>
        ),
      },
      {
        id: constants.TABLE_SERIAL_NUMBER_COLUMN_ID,
        header: 'SN',
        accessorFn: (row) => row.sn,
      },
      {
        header: 'Name',
        accessorFn: (row) =>
          [row?.first_name, row?.middle_name, row?.last_name]
            .filter((v) => !isEmpty(v))
            .map((v) => ucwords(v))
            .join(' '),
      },
      {
        header: 'Country',
        accessorFn: (row) => row.country,
      },
      ...(!filterByCustomer
        ? [
            {
              header: 'Customer',
              accessorFn: (row) => row.user_name,
            },
          ]
        : []),
      ...(Auth.isSuperAdmin()
        ? [
            {
              header: 'Partner',
              accessorFn: (row) => row.partner_name,
            },
          ]
        : []),
      {
        header: 'Is Verified ?',
        accessorFn: (row) => row.is_verified,
        cell: (info) => {
          return <VerifiedBadge isVerified={!!info.getValue()} />;
        },
      },
      {
        header: 'Is Active ?',
        accessorFn: (row) => row.is_active,
        cell: (info) => <ActiveBadge isActive={!!info.getValue()} />,
      },
      {
        id: constants.TABLE_ACTION_COLUMN_ID,
        header: 'Action',
        accessorFn: (row) => row.id,

        cell: (info) => {
          return (
            <PopoverButton>
              {({ onClose }) => (
                <>
                  <ListItemButton
                    onClick={() => {
                      dispatch(
                        getBeneficiaryBriefDetailsInit({
                          showBriefDetailModal: true,
                          beneficiaryId: info.getValue(),
                        }),
                      );
                      onClose();
                    }}
                  >
                    Preview
                  </ListItemButton>
                  <ListItemButton
                    onClick={() =>
                      navigate(buildRoute('beneficiaries.show', info.getValue()), onClose())
                    }
                  >
                    View Details
                  </ListItemButton>
                </>
              )}
            </PopoverButton>
          );
        },
      },
    ],
    [],
  );

  const table = useReactTable({
    data,
    columns,
    state: {
      columnVisibility,
    },
    onColumnVisibilityChange: setColumnVisibility,
    getCoreRowModel: getCoreRowModel(),
  });

  useEffect(() => {
    dispatch(
      getBeneficiaries({
        ...params,
        ...(filterByCustomer ? { user_id: customerId } : {}),
      }),
    );
  }, [JSON.stringify(params)]);

  if (isError) return <Error status={errorCode} />;

  return (
    <TableContainerWrapper>
      {!filterByCustomer && <BeneficiariesActions table={table} />}
      <TanstackReactTable
        table={table}
        isLoading={isLoading}
        data={data}
        columnsCount={
          columns.length - Object.entries(columnVisibility ?? {}).filter(([k, v]) => !v).length
        }
      />
      <BeneficiaryPagination />
    </TableContainerWrapper>
  );
}

Beneficiaries.propTypes = {
  filterByCustomer: PropTypes.bool,
  customerId: (props, propName, componentName) => {
    if (!!props['filterByCustomer'] && props[propName] === true) {
      return new Error(
        `${propName} is required when 'filterByCustomer' props is 'true' in ${componentName} component`,
      );
    } else if (typeof props[propName] !== 'string') {
      return new Error(`${propName} value must be string ${componentName} component`);
    }
  },
};

Beneficiaries.defaultProps = {
  filterByCustomer: false,
  customerId: null,
};
