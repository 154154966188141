import { useEffect } from 'react';
import { compose } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';

import Error from 'core/errors/Error';
import Button from 'core/buttons/Button';
import withAppState from 'hoc/withAppState';
import PageContent from 'layouts/PageContent';
import Beneficiary from 'components/beneficiaries/Beneficiary';

import { buildRoute } from 'routes';
import { resetGetBeneficiaryFailure } from 'app/features/beneficiaries/beneficiarySlice';
import NewPageContent from 'layouts/NewPageContent';
import routePaths from 'app/config/routePaths';

function ViewBeneficiary({ isNetworkError }) {
  const params = useParams();

  const { isError, errorCode } = useSelector((state) => state.beneficiaries.show);

  const dispatch = useDispatch();

  const navigate = useNavigate();

  useEffect(() => {
    if (isError) dispatch(resetGetBeneficiaryFailure());
  }, [isNetworkError]);

  if (isError)
    return <Error status={errorCode || 0} onRetry={() => dispatch(resetGetBeneficiaryFailure())} />;

  return (
    <NewPageContent
      breadcrumbs={[
        {
          label: 'Beneficiaries',
          to: routePaths.ListBeneficiary,
        },
        {
          label: 'Beneficiary Details',
          to: '',
        },
      ]}
      documentTitle="Beneficiary Details"
    >
      <Beneficiary beneficiaryId={params.beneficiaryId} />
    </NewPageContent>
  );
}

export default compose(withAppState)(ViewBeneficiary);
