import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import useAuthUser from 'hooks/useAuthUser';

export default function Dashboard() {
  const user = useAuthUser();

  return (
    <Box
      style={{
        paddingLeft: 50,
        paddingTop: 20,
      }}
    >
      <Typography variant="subtitle" color="grey.700">
        Hey <strong>{user?.first_name}</strong>, Feel like your home.
      </Typography>
    </Box>
  );
}
