import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import styled from '@mui/material/styles/styled';
import Typography from '@mui/material/Typography';

import Spacer from 'core/spacer/Spacer';
import Logo from 'components/layouts/Logo';

const Wrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
}));

const Container = styled(Paper)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: theme.palette.background.paper,
  gap: 2,
  padding: theme.spacing(0),
  width: '100%',
  maxWidth: '400px',
}));

function AuthFormWrapper({ children, title, subtitle }) {
  return (
    <Wrapper>
      <div>
        <Logo height="60px" />
      </div>
      <Container elevation={0}>
        <Spacer />
        <Typography component="h1" variant="h5" fontWeight="700" color="">
          {title}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {subtitle}
        </Typography>
        <Spacer />
        {children}
      </Container>
      <Spacer />
    </Wrapper>
  );
}

export default AuthFormWrapper;

AuthFormWrapper.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
  title: PropTypes.string,
  subtitle: PropTypes.string,
};
