import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import MuiModal from '@mui/material/Modal';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import { Divider } from '@mui/material';

const CloseButton = styled('div')(({ theme }) => ({
  cursor: 'pointer',
  width: '40px',
  height: '40px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  background: theme.palette.primary.soft,
  borderRadius: '50%',
  '& .MuiSvgIcon-root': {
    color: theme.palette.primary.main,
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

const ModalHeader = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.primary.light,
  borderRadius: '8px 8px 0 0',

  fontWeight: '600',
  color: theme.palette.grey[900],
}));

const ModalBody = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3, 2),
  maxHeight: '90vh',
  overflowY: 'auto',
}));

const FullDetailsNavigator = styled(Typography)(({ theme }) => ({
  padding: theme.spacing(2),
  color: theme.palette.primary.main,
  fontWeight: '400',
  textAlign: 'right',
  cursor: 'pointer',
}));

export default function Modal({
  title,
  open,
  onClose,
  sx,
  children,
  handleFullDetails,
  DetailNavigator,
}) {
  return (
    <MuiModal open={open} onClose={onClose}>
      <Box
        sx={{
          position: 'absolute',
          borderRadius: '8px',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          // width: 400,
          bgcolor: 'background.paper',
          boxShadow: 24,
          // p: 2,
          ...sx,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: (theme) => theme.spacing(2),
          }}
        >
          {!!title && (
            <ModalHeader>
              {Object.prototype.toString.call(title) === '[object String]' ? (
                <Typography variant="subtitle">{title}</Typography>
              ) : (
                title
              )}
            </ModalHeader>
          )}
          {typeof onClose === 'function' && (
            <CloseButton onClick={onClose}>
              <CloseIcon />
            </CloseButton>
          )}
        </Box>
        <ModalBody>{children}</ModalBody>

        <Divider />
        {DetailNavigator === true ? (
          <FullDetailsNavigator onClick={handleFullDetails}>View Full Details</FullDetailsNavigator>
        ) : (
          ''
        )}
      </Box>
    </MuiModal>
  );
}

Modal.propTypes = {
  title: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  sx: PropTypes.object,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
  handleFullDetails: PropTypes.func,
  DetailNavigator: PropTypes.bool,
};

Modal.defaultProps = {
  DetailNavigator: true,
};
