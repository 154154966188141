import Error from 'core/errors/Error';
import React, { useEffect } from 'react';
import { compose } from '@reduxjs/toolkit';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import Tabs from 'core/tabs/Tabs';
import withRole from 'hoc/withRole';
import withAppState from 'hoc/withAppState';
import ActionButton from 'core/buttons/ActionButton';
import LicensingPartners from 'components/LicensingPartner/LicensingPartners';
import LicensingPartnersTableFilterValues from 'components/LicensingPartner/LicensingPartnersTableFilterValues';
import LicensingPartnerPreviewDetailsModal from 'components/LicensingPartner/LicensingPartnerPreviewDetailsModal';

import acl from 'app/config/acl';
import { buildRoute } from 'routes';
import NewPageContent from 'layouts/NewPageContent';
import { resetGetLicensingPartnerFailure } from 'app/features/licensing-partners/licensingPartnerSlice';

const ListLicensingPartner = ({ isNetworkError }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const licensingPartnerState = useSelector((state) => state.licensingPartners.index);

  const { isError, errorCode } = licensingPartnerState;

  useEffect(() => {
    if (isError) dispatch(resetGetLicensingPartnerFailure());
  }, [isNetworkError]);

  if (isError)
    return (
      <Error
        status={errorCode}
        withInDashboard={true}
        onRetry={() => dispatch(resetGetLicensingPartnerFailure())}
      />
    );

  return (
    <NewPageContent
      breadcrumbs={[
        {
          label: 'Licensing Partner',
          to: '',
        },
      ]}
      documentTitle="Licensing Partner"
      rightTopEndContent={
        <ActionButton onClick={() => navigate(buildRoute('licensing-partners.create'))}>
          ADD LICENSING PARTNER
        </ActionButton>
      }
    >
      <LicensingPartnersTableFilterValues />
      <Tabs
        tabs={[
          {
            key: 'Active Licensing Partners',
            tabName: 'Active Partners',
            tabContent: <LicensingPartners isActive={true} />,
          },
          {
            key: 'Inactive Licensing Partners',
            tabName: 'Inactive Partners',
            tabContent: <LicensingPartners isActive={false} />,
          },
        ]}
      />

      <LicensingPartnerPreviewDetailsModal />
    </NewPageContent>
  );
};

export default compose(withAppState, withRole([acl.SUPERADMIN, acl.ADMIN]))(ListLicensingPartner);
