import NewPageContent from 'layouts/NewPageContent';

import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

import SettingItem from './components/SettingItem';

import { styled } from '@mui/material/styles';

import app from 'app/config/app';

let _settingItems = [
  {
    key: 'about',
    label: 'App Version',
    content: app.version,
    contentSelected: '',
    actions: '',
  },
];

const WrapperBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.primary.light,
  padding: 15,
  borderRadius: 15,
  // boxShadow: '1px 3px 6px 1px rgba(0,0,0,0.3)',
  // boxShadow: '0px 8px 0px 0px rgba(0, 0, 0, 0.06)',
  boxShadow: '10px 8px 10px 2px rgba(0, 0, 0, 0.06)',
  marginBottom: 20,
  marginRight: 20,
}));

const About = () => {
  let [activeSetting, setActiveSetting] = useState(null);

  return (
    <NewPageContent
      breadcrumbs={[
        {
          label: 'About',
          to: '',
        },
      ]}
      title="About"
    >
      <WrapperBox>
        <Stack sx={{ mt: 2 }}>
          {_settingItems.map((item) => (
            <SettingItem
              key={item.key}
              labelPrimary={item.label}
              content={activeSetting === item.key ? item.contentSelected : item.content}
              actions={item.actions}
              isActive={activeSetting === item.key}
            />
          ))}
        </Stack>
      </WrapperBox>
    </NewPageContent>
  );
};

export default About;
