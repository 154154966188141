import React, { useMemo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import ActiveBadge from 'core/badges/ActiveBadge';
import { useSelector, useDispatch } from 'react-redux';
import { useReactTable, getCoreRowModel } from '@tanstack/react-table';
import PopoverButton from 'core/buttons/PopoverButton';
import ListItemButton from '@mui/material/ListItemButton';

import FormCheckbox from 'core/forms/FormCheckbox';
import TanstackReactTable from 'core/table/TanstackReactTable';

import Auth from 'utils/Auth';
import app from 'app/config/app';
import { buildRoute } from 'routes';
import isEmpty from 'helpers/isEmpty';
import ucwords from 'helpers/ucwords';
import constants from 'app/config/constants';
import { useConfirm } from 'core/mui-confirm';
import useQueryParams from 'hooks/useQueryParams';
import { deleteAccount, updateIsActiveStatus } from 'app/features/accounts/accountSaga';
import TableContainerWrapper from 'core/table/TableContainerWrapper';
import { getRolesAndPermissions } from 'app/features/roles-and-permissions/rolesAndPermissionsSaga';

export default function Roles() {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const confirm = useConfirm();

  const accountsState = useSelector((state) => state.accounts.index);

  const { params, data2, isLoading } = accountsState;

  const data = [];

  const [queryParams] = useQueryParams(app.ignoreFilterKeys);

  const [columnVisibility, setColumnVisibility] = useState({});

  /**
   * handle toggle status
   *
   */
  const handleToggleStatus = (accountId) => {
    let account = data.filter((item) => item.id === accountId)?.[0];

    if (isEmpty(account)) return;

    const confirmationText = account.is_active ? 'Yes, set as Inactive' : 'Yes, set as Active';

    confirm({
      icon: 'warning',
      confirmationText,
      description: 'This will toggle the status of account.',
    })
      .then(() =>
        dispatch(updateIsActiveStatus({ accountId: account.id, isActive: !account.is_active })),
      )
      .catch(() => {});
  };

  /**
   * handle delete account
   *
   */
  const handleDelete = (accountId) => {
    confirm({
      icon: 'warning',
      confirmationText: 'Yes, Delete it.',
      description: 'This will permanently delete this account.',
    })
      .then(() => dispatch(deleteAccount(accountId)))
      .catch(() => {});
  };

  const columns = useMemo(
    () => [
      {
        id: constants.TABLE_CHECKBOX_SELECT_CHECKBOX_ID,
        header: ({ table }) => (
          <FormCheckbox
            forTableHead={true}
            {...{
              checked: table.getIsAllRowsSelected(),
              indeterminate: table.getIsSomeRowsSelected(),
              onChange: table.getToggleAllRowsSelectedHandler(),
            }}
          />
        ),
        cell: ({ row }) => (
          <div>
            <FormCheckbox
              {...{
                checked: row.getIsSelected(),
                indeterminate: row.getIsSomeSelected(),
                onChange: row.getToggleSelectedHandler(),
              }}
            />
          </div>
        ),
      },
      {
        id: constants.TABLE_SERIAL_NUMBER_COLUMN_ID,
        header: 'SN',
        accessorFn: (row) => row.sn,
      },
      {
        header: 'Name',
        accessorFn: (row) => row.name,
      },
      {
        id: 'roleType',
        header: 'Role Type',
        accessorFn: (row) => ucwords(row.role_Type),
      },

      {
        header: 'description',
        accessorFn: (row) => row.description,
      },
      {
        header: 'Status',
        accessorFn: (row) => row.status,
      },
      {
        id: 'email',
        header: 'Email',
        accessorFn: (row) => row.email,
      },
      {
        header: 'Created',
        accessorFn: (row) => row.created,
      },
      {
        id: constants.TABLE_ACTION_COLUMN_ID,
        header: 'Action',
        accessorFn: (row) => row.id,

        cell: (info) => {
          return (
            <PopoverButton>
              {({ onClose }) => (
                <>
                  <ListItemButton>Preview</ListItemButton>
                  <ListItemButton
                    onClick={() => navigate(buildRoute('roles.show', info.getValue()), onClose())}
                  >
                    View Details
                  </ListItemButton>
                  <ListItemButton>Edit</ListItemButton>
                </>
              )}
            </PopoverButton>
          );
        },
      },
    ],
    [JSON.stringify(data)],
  );

  const table = useReactTable({
    data,
    columns,
    state: {
      columnVisibility,
    },
    onColumnVisibilityChange: setColumnVisibility,
    getCoreRowModel: getCoreRowModel(),
  });

  // useEffect(() => {
  //   dispatch(getRolesAndPermissions({ ...queryParams, ...params }));
  // }, [JSON.stringify(params)]);

  return (
    <TableContainerWrapper>
      <TanstackReactTable
        table={table}
        data={data}
        isLoading={isLoading}
        columnsCount={
          columns.length - Object.entries(columnVisibility ?? {}).filter(([k, v]) => !v).length
        }
      />
    </TableContainerWrapper>
  );
}

Roles.propTypes = {
  partnerId: PropTypes.string,
};
