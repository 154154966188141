import * as React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import styled from '@mui/material/styles/styled';
import MenuItem from '@mui/material/MenuItem';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import ListAltIcon from '@mui/icons-material/ListAlt';
import SimCardDownloadOutlinedIcon from '@mui/icons-material/SimCardDownloadOutlined';

const ExportButton = styled(Button)(({ theme }) => ({
  borderRadius: '50%',
  height: 40,
  width: 40,
  minWidth: 0,
  padding: 0,
  border: 'none',
  backgroundColor: theme.palette.info.lighter,
  transition: 'background-color 0.3s ease-in-out, color 0.3s ease-in-out',
  outline: 'none',
  '&:hover': {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    border: 'none',
    outline: 'none',

    '& .MuiSvgIcon-root': {
      color: `${theme.palette.common.white}`,
    },
  },
  '& .MuiButtonBase-root': {
    ripple: 'none',
  },
}));

const ExportDocuments = ({ onPdfDownload, onExcelDownload, isLoading }) => {
  return (
    <PopupState variant="popover" popupId="demo-popup-menu">
      {(popupState) => (
        <React.Fragment>
          <ExportButton
            disableRipple
            disabled={isLoading}
            variant="outlined"
            {...bindTrigger(popupState)}
          >
            <SimCardDownloadOutlinedIcon
              sx={{
                fontSize: 20,
                color: 'red',
              }}
            />
          </ExportButton>
          <Menu {...bindMenu(popupState)}>
            <MenuItem
              onClick={() => {
                onExcelDownload();
                popupState.close();
              }}
            >
              <ListAltIcon
                sx={{
                  marginRight: 1,
                }}
              />
              Excel
            </MenuItem>
            <MenuItem
              onClick={() => {
                onPdfDownload();
                popupState.close();
              }}
            >
              <PictureAsPdfIcon
                sx={{
                  marginRight: 1,
                }}
              />
              Pdf
            </MenuItem>
          </Menu>
        </React.Fragment>
      )}
    </PopupState>
  );
};

export default ExportDocuments;
