import { useDispatch, useSelector } from 'react-redux';

import NewPageContent from 'layouts/NewPageContent';
import FilterButton from 'core/buttons/FilterButton';
import ZaiWebhookLogs from 'components/zai-logs/ZaiWebhookLogs';

import { toggleFilterWebHookLogs } from 'app/features/transactions/zaiTransactionSlice';

function ListZaiWebhookLogs() {
  const dispatch = useDispatch()

  const ZaiTransctionState = useSelector((state) => state.zaiTransactions.webhookLogs);

  const { isFilterOpen } = ZaiTransctionState;

  return (
    <NewPageContent
      breadcrumbs={[{ label: 'Zai Webhook logs', to: '' }]}
      documentTitle="Zai Webhook logs"
      rightTopEndContent={
        <FilterButton
          isOpen={isFilterOpen}
          onClick={() => dispatch(toggleFilterWebHookLogs())}
        />
      }
    >
      <ZaiWebhookLogs />
    </NewPageContent>
  );
}

export default ListZaiWebhookLogs;
