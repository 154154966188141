import { createSlice } from '@reduxjs/toolkit';
import app from 'app/config/app';
import Response from 'app/config/Response';
import isEmpty from 'helpers/isEmpty';

const initialQueryParams = {
  page_size: app.perPage || 10,
  page_number: 1,
  sort_by: 'created_ts',
  order_by: 'desc',
};

const initialState = {
  index: {
    data: [],
    params: initialQueryParams,
    isLoading: false,
    totalCount: 0,
    totalPage: 0,
    isError: false,
    errorCode: null,
    errorMessage: null,
    isFilterOpen: false,
  },
  show: {
    showBriefDetailModal: false,
    transactionId: null,
    isLoading: false,
    isError: false,
    errorCode: null,
    errorMessage: null,
    data: null,
  },
  approve: {
    isLoading: false,
    isError: false,
    errorCode: null,
    errorMessage: null,
  },
  reject: {
    isLoading: false,
    isError: false,
    errorCode: null,
    errorMessage: null,
  },
  export: {
    isLoading: false,
    isError: false,
    errorCode: null,
    errorMessage: null,
    data: null,
  },
};

export const transactionSlice = createSlice({
  name: 'transactions',
  initialState,
  reducers: {
    getTransactionsInit: (state) => {
      state.index = {
        ...state.index,
        isLoading: true,
        isError: false,
        errorCode: null,
        errorMessage: null,
      };
    },

    getTransactionsSuccess: (state, { payload }) => {
      let { data, pagination } = payload;

      const { totalCount, totalPage, currentPage, pageSize } = pagination;

      let startSN = (currentPage - 1) * pageSize;

      data = data?.map((item) => ({ sn: (startSN += 1), ...item }));

      state.index = {
        ...state.index,
        isLoading: false,
        isError: false,
        errorCode: null,
        errorMessage: null,
        data,
        totalCount,
        totalPage,
      };
    },

    getTransactionsFailure: (state, { payload }) => {
      state.index = {
        ...state.index,
        isLoading: false,
        isError: true,
        errorCode: payload?.response?.status ?? 0,
        errorMessage: payload?.response?.message,
      };
    },

    resetGetTransactionsFailure: (state) => {
      state.index = {
        ...state.index,
        isLoading: false,
        isError: false,
        errorCode: null,
        errorMessage: null,
      };
    },

    setParam: (state, { payload }) => {
      state.index.params = { ...state.index.params, ...payload };
    },

    resetParam: (state) => {
      state.index.params = {
        page_size: state.index.params.page_size,
        page_number: 1,
      };
    },

    deleteFilterParams: (state, { payload }) => {
      if (Array.isArray(payload)) payload.forEach((key) => delete state.index.params[key]);
      else delete state.index.params[payload];
    },

    getTransactionInit: (state) => {
      state.show = {
        ...state.show,
        isLoading: true,
        isError: false,
        errorCode: null,
        errorMessage: null,
        data: null,
      };
    },

    getTransactionSuccess: (state, { payload }) => {
      if (isEmpty(payload?.data)) {
        state.show = {
          ...state.show,
          isLoading: false,
          isError: true,
          errorCode: Response.HTTP_NOT_FOUND,
          errorMessage: 'Transaction not found',
          data: null,
        };
      } else {
        state.show = {
          ...state.show,
          isLoading: false,
          isError: false,
          errorCode: null,
          errorMessage: null,
          data: payload.data,
        };
      }
    },

    getTransactionFailure: (state, { payload }) => {
      state.show = {
        ...state.show,
        isLoading: false,
        isError: true,
        errorCode: payload?.response?.status ?? 0,
        errorMessage: payload?.response?.message,
        data: null,
      };
    },

    resetGetTransactionFailure: (state) => {
      state.show = {
        ...state.show,
        isLoading: false,
        isError: false,
        errorCode: null,
        errorMessage: null,
        data: null,
      };
    },

    getTransactionBriefDetailsInit: (state, { payload }) => {
      state.show = {
        ...state.show,
        showBriefDetailModal: payload.showBriefDetailModal,
        transactionId: payload.transactionId,
      };
    },

    hideTransactionBriefDetails: (state) => {
      state.show = {
        showBriefDetailModal: false,
        transactionId: null,
        isLoading: false,
        isError: false,
        errorCode: null,
        errorMessage: null,
        data: null,
      };
    },

    approveTransactionInit: (state) => {
      state.approve = {
        ...state.approve,
        isLoading: true,
        isError: false,
        errorCode: null,
        errorMessage: null,
      };
    },

    approveTransactionSuccess: (state) => {
      state.approve = {
        ...state.approve,
        isLoading: false,
        isError: false,
        errorCode: null,
        errorMessage: null,
      };
    },

    approveTransactionFailure: (state, { payload }) => {
      state.approve = {
        ...state.approve,
        isLoading: false,
        isError: true,
        errorCode: payload?.response?.status ?? 0,
        errorMessage: payload?.response?.message,
      };
    },

    rejectTransactionInit: (state) => {
      state.reject = {
        ...state.reject,
        isLoading: true,
        isError: false,
        errorCode: null,
        errorMessage: null,
      };
    },

    rejectTransactionSuccess: (state) => {
      state.reject = {
        ...state.reject,
        isLoading: false,
        isError: false,
        errorCode: null,
        errorMessage: null,
      };
    },

    rejectTransactionFailure: (state, { payload }) => {
      state.reject = {
        ...state.reject,
        isLoading: false,
        isError: true,
        errorCode: payload?.response?.status ?? 0,
        errorMessage: payload?.response?.message,
      };
    },

    exportTransactionInit: (state) => {
      state.export = {
        ...state.export,
        isLoading: true,
        isError: false,
        errorCode: null,
        errorMessage: null,
        data: null,
      };
    },

    exportTransactionSuccess: (state, { payload }) => {
      state.export = {
        ...state.export,
        isLoading: false,
        isError: false,
        errorCode: null,
        errorMessage: null,
        data: payload.data,
      };
    },

    exportTransactionFailure: (state, { payload }) => {
      state.export = {
        ...state.export,
        isLoading: false,
        isError: true,
        errorCode: payload?.response?.status ?? 0,
        errorMessage: payload?.response?.message,
        data: null,
      };
    },

    resetExportTransactionFailure: (state) => {
      state.export = {
        ...state.export,
        isLoading: false,
        isError: false,
        errorCode: null,
        errorMessage: null,
        data: null,
      };
    },

    toggleFilter: (state) => {
      state.index.isFilterOpen = !state.index.isFilterOpen;
    },
  },
});

export const {
  getTransactionsInit,
  getTransactionsSuccess,
  getTransactionsFailure,
  resetGetTransactionsFailure,
  setParam,
  resetParam,
  deleteFilterParams,
  setShowCreateProcessingModal,
  setShowEditProcessingModal,
  getTransactionInit,
  getTransactionSuccess,
  getTransactionFailure,
  resetGetTransactionFailure,
  getTransactionBriefDetailsInit,
  hideTransactionBriefDetails,
  approveTransactionInit,
  approveTransactionSuccess,
  approveTransactionFailure,
  rejectTransactionInit,
  rejectTransactionSuccess,
  rejectTransactionFailure,
  exportTransactionInit,
  exportTransactionSuccess,
  exportTransactionFailure,
  toggleFilter,
} = transactionSlice.actions;

export default transactionSlice.reducer;
