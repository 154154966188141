import React from 'react';

const ProcessingStatusIcon = (props) => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M0 20C0 8.95431 8.95431 0 20 0C31.0457 0 40 8.95431 40 20C40 31.0457 31.0457 40 20 40C8.95431 40 0 31.0457 0 20Z"
        fill="#4EBAEA"
      />
      <path
        d="M30 20C30 25.52 25.52 30 20 30C14.48 30 10 25.52 10 20C10 14.48 14.48 10 20 10C25.52 10 30 14.48 30 20Z"
        stroke="white"
      />
      <path
        opacity="0.4"
        d="M23.71 23.18L20.61 21.33C20.07 21.01 19.63 20.24 19.63 19.61V15.51"
        stroke="white"
      />
    </svg>
  );
};

export default ProcessingStatusIcon;
