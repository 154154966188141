import { useEffect } from 'react';
import { compose } from '@reduxjs/toolkit';
import { useSelector, useDispatch } from 'react-redux';

import withRole from 'hoc/withRole';
import Error from 'core/errors/Error';
import withAppState from 'hoc/withAppState';
import FinmoPayins from 'components/finmo-payins/FinmoPayins';
// import FinmoPayinFilterValues from 'components/finmo-payins/FinmoPayinFilterValues';
import { resetGetFinmoPayinsFailure, toggleFilter } from 'app/features/finmo-payins/finmoPayinSlice';

import acl from 'app/config/acl';
import NewPageContent from 'layouts/NewPageContent';
import FinmoPayinBriefDetailsModal2 from 'components/finmo-payins/FinmoPayinBriefDetailsModal2';
import FinmoPayinsTableFilterValues from 'components/finmo-payins/FinmoPayinsTableFilterValues';
import FilterButton from 'core/buttons/FilterButton';

function ListFinmoPayin({ isNetworkError }) {
  const finmoPayinState = useSelector((state) => state.finmoPayins.index);

  const { isError, errorCode, isFilterOpen } = finmoPayinState;

  const dispatch = useDispatch();

  useEffect(() => {
    if (isError) dispatch(resetGetFinmoPayinsFailure());
  }, [isNetworkError]);

  if (isError)
    return (
      <Error
        status={errorCode}
        withInDashboard={true}
        onRetry={() => dispatch(resetGetFinmoPayinsFailure())}
      />
    );

  return (
    <NewPageContent
      breadcrumbs={[
        {
          label: 'Finmo Payins',
          to: '',
        },
      ]}
      documentTitle="Finmo Payins"
      rightTopEndContent={<FilterButton isOpen={isFilterOpen} onClick={() => dispatch(toggleFilter())} />}
    >
      {/* <FinmoPayinFilterValues /> */}
      <FinmoPayinsTableFilterValues />
      <FinmoPayins />
      <FinmoPayinBriefDetailsModal2 />
    </NewPageContent>
  );
}

export default compose(withAppState, withRole([acl.SUPERADMIN]))(ListFinmoPayin);
