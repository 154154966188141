import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import PopoverButton from 'core/buttons/PopoverButton';
import ListItemButton from '@mui/material/ListItemButton';
import ActiveBadge from 'core/badges/ActiveBadge';
import { useSelector, useDispatch } from 'react-redux';
import React, { useMemo, useEffect, useState } from 'react';
import { useReactTable, getCoreRowModel } from '@tanstack/react-table';

import FormCheckbox from 'core/forms/FormCheckbox';
import TanstackReactTable from 'core/table/TanstackReactTable';
import AuthUserIsSuperAdmin from 'components/auth/AuthUserIsSuperAdmin';
import FundingSourcesInfoPagination from './FundingSourcesInfoPagination';

import Auth from 'utils/Auth';
import app from 'app/config/app';
import { buildRoute } from 'routes';
import isEmpty from 'helpers/isEmpty';
import DateUtils from 'utils/DateUtils';
import constants from 'app/config/constants';
import { useConfirm } from 'core/mui-confirm';
import useQueryParams from 'hooks/useQueryParams';
import {
  getFundingSourcesInfo,
  deleteFundingSourceInfo,
} from 'app/features/funding-sources-info/fundingSourceInfoSaga';
import { getFundingSourceInfoBriefDetailsInit } from 'app/features/funding-sources-info/fundingSourceInfoSlice';
import FundingSourcesInfoActions from './FundingSourceInfoActions';
import TableContainerWrapper from 'core/table/TableContainerWrapper';

export default function FundingSourcesInfo({ preFilterByPartner, partnerId }) {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const confirm = useConfirm();

  const FundingSourcesInfoState = useSelector((state) => state.fundingSourcesInfo.index);

  const { params, data, isLoading } = FundingSourcesInfoState;

  const [queryParams] = useQueryParams(app.ignoreFilterKeys);

  const [columnVisibility, setColumnVisibility] = useState({});

  /**
   * handle delete partnerFundingSource
   *
   */
  const handleDelete = (FundingSourceInfoId) => {
    confirm({
      icon: 'warning',
      confirmationText: 'Yes, Delete it.',
      description: 'This will permanently delete this partner funding source.',
    })
      .then(() => dispatch(deleteFundingSourceInfo(FundingSourceInfoId)))
      .catch(() => {});
  };

  const columns = useMemo(
    () => [
      {
        id: constants.TABLE_CHECKBOX_SELECT_CHECKBOX_ID,
        header: ({ table }) => (
          <FormCheckbox
            forTableHead={true}
            {...{
              checked: table.getIsAllRowsSelected(),
              indeterminate: table.getIsSomeRowsSelected(),
              onChange: table.getToggleAllRowsSelectedHandler(),
            }}
          />
        ),
        cell: ({ row }) => (
          <div>
            <FormCheckbox
              {...{
                checked: row.getIsSelected(),
                indeterminate: row.getIsSomeSelected(),
                onChange: row.getToggleSelectedHandler(),
              }}
            />
          </div>
        ),
      },
      {
        id: constants.TABLE_SERIAL_NUMBER_COLUMN_ID,
        header: 'SN',
        accessorFn: (row) => row.sn,
      },
      ...(Auth.isSuperAdmin()
        ? [
            {
              header: 'Partner Name',
              accessorFn: (row) => (row.partner_name ? row.partner_name : '-'),
            },
          ]
        : []),
      {
        header: 'Payment Name',
        accessorFn: (row) => (row.payment_name ? row.payment_name : '-'),
      },
      {
        header: 'Payment Value',
        accessorFn: (row) => (row.payment_value ? row.payment_value : '-'),
      },
      {
        header: 'Payment Funding Key',
        accessorFn: (row) => (row.funding_key ? row.funding_key : '-'),
      },

      {
        header: 'Is Active ?',
        accessorFn: (row) => row.is_active,
        cell: ({ row }) => <ActiveBadge isActive={!!row.original.is_active} />,
      },
      {
        header: 'Created At',
        accessorFn: (row) => (row.created_ts ? DateUtils.isoToString(row.created_ts) : '-'),
      },
      {
        id: constants.TABLE_ACTION_COLUMN_ID,
        header: 'Action',
        accessorFn: (row) => row.id,

        cell: ({ row }) => (
          <PopoverButton>
            {({ onClose }) => (
              <>
                <ListItemButton
                  onClick={() => {
                    dispatch(
                      getFundingSourceInfoBriefDetailsInit({
                        showBriefDetailModal: true,
                        fundingSourceInfoId: row.original.id,
                      }),
                    );
                    onClose();
                  }}
                >
                  Preview
                </ListItemButton>
                <ListItemButton
                  onClick={() => {
                    navigate(buildRoute('funding-sources-info.show', row.original.id));
                    onClose();
                  }}
                >
                  View Details
                </ListItemButton>
                <ListItemButton
                  onClick={() => navigate(buildRoute('funding-sources-info.edit', row.original.id))}
                >
                  Edit
                </ListItemButton>
                <AuthUserIsSuperAdmin>
                  <ListItemButton
                    onClick={() => {
                      handleDelete(row.original.id);
                      onClose();
                    }}
                    disabled={row.original?.isDeleting || false}
                  >
                    Delete
                  </ListItemButton>
                </AuthUserIsSuperAdmin>
              </>
            )}
          </PopoverButton>
        ),
      },
    ],
    [JSON.stringify(data)],
  );

  const table = useReactTable({
    data,
    columns,
    state: {
      columnVisibility,
    },
    onColumnVisibilityChange: setColumnVisibility,
    getCoreRowModel: getCoreRowModel(),
  });

  useEffect(() => {
    dispatch(
      getFundingSourcesInfo({
        ...queryParams,
        ...params,
        ...(preFilterByPartner ? { partner_id: partnerId } : {}),
      }),
    );
  }, [JSON.stringify(params)]);

  return (
    <TableContainerWrapper>
      <FundingSourcesInfoActions table={table} />

      <TanstackReactTable
        table={table}
        data={data}
        isLoading={isLoading}
        columnsCount={
          columns.length - Object.entries(columnVisibility ?? {}).filter(([k, v]) => !v).length
        }
      />
      <FundingSourcesInfoPagination />
    </TableContainerWrapper>
  );
}

FundingSourcesInfo.propTypes = {
  preFilterByPartner: PropTypes.bool,
  partnerId: (props, propName, componentName) => {
    if (props['preFilterByPartner'] === true && isEmpty(props[propName])) {
      return new Error(
        `${propName} is required when 'preFilterByPartner' props is 'true' in ${componentName} component`,
      );
    } else if (props['preFilterByPartner'] === true && typeof props[propName] !== 'string') {
      return new Error(`${propName} value must be string ${componentName} component`);
    }
  },
};
