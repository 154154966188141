import { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import SidebarBriefModal from 'core/modal/SidebarBriefModal';
import PartnerClickAwayDetails from 'components/partners/PartnerClickAwayDetails';
import CustomerClickAwayDetails from 'components/customers/CustomerClickAwayDetails';

import Auth from 'utils/Auth';
import { buildRoute } from 'routes';
import isEmpty from 'helpers/isEmpty';
import ucwords from 'helpers/ucwords';
import useCountries from 'hooks/useCountries';
import { getBeneficiary } from 'app/features/beneficiaries/beneficiarySaga';
import { hideBeneficiaryBriefDetails } from 'app/features/beneficiaries/beneficiarySlice';
import useSourceDetail from 'core/source-detail/useSourceDetail';
import Modal from 'app/components/Modal';
import SourceDetails from 'core/source-detail/SourceDetails';

function BeneficiaryPreviewDetailsModal() {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const { getCountryNameByIso3Code } = useCountries();

  const state = useSelector((state) => state.beneficiaries.show);

  const { showBriefDetailModal, beneficiaryId, isLoading, data } = state;

  const viewDetailColumn = useSourceDetail([
    {
      title: '',
      items: [
        {
          label: 'Beneficiary Name',
          accessorKey: 'beneficiary_name',
          cell: ({ first_name, middle_name, last_name }) =>
            [first_name, middle_name, last_name]
              .filter((v) => !isEmpty(v))
              .map((v) => ucwords(v))
              .join(' '),
        },
        {
          label: 'Customer',
          accessorKey: 'user_id',
          cell: ({ user_id, user_name }) => (
            <CustomerClickAwayDetails customerId={user_id} label={user_name} />
          ),
        },
        ...(Auth.isSuperAdmin()
          ? [
              {
                label: 'Partner',
                accessorKey: 'partner_id',
                cell: ({ partner_id, partner_name }) => (
                  <PartnerClickAwayDetails partnerId={partner_id} label={partner_name} />
                ),
              },
            ]
          : []),
        {
          label: 'Mobile Number',
          accessorKey: 'mobile_number',
        },
        {
          label: 'Country',
          accessorKey: 'country',
          cell: ({ country }) => getCountryNameByIso3Code(country),
        },
        {
          label: 'Is Verified ?',
          accessorKey: 'is_verified',
          cell: ({ data }) => (data?.is_verified ? 'Yes' : 'No'),
        },
        {
          label: 'Is Active ?',
          accessorKey: 'is_active',
          cell: ({ data }) => (data?.is_active ? 'Yes' : 'No'),
        },
      ],
    },
  ]);

  useEffect(() => {
    if (beneficiaryId) dispatch(getBeneficiary(beneficiaryId));
  }, [beneficiaryId]);

  if (!showBriefDetailModal && !beneficiaryId) return <></>;

  return (
    <Modal
      open={showBriefDetailModal ?? false}
      onClose={() => dispatch(hideBeneficiaryBriefDetails())}
      title="Transaction Details"
      handleFullDetails={() => (
        dispatch(hideBeneficiaryBriefDetails()),
        navigate(buildRoute('beneficiaries.show', beneficiaryId))
      )}
    >
      <SourceDetails
        showContainerDesign={false}
        definition={viewDetailColumn}
        data={data}
        isLoading={isLoading}
        enableLabelColon={false}
      />
    </Modal>
  );
}

export default BeneficiaryPreviewDetailsModal;
