import React from 'react';

const RejectedStatusIcon = (props) => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M0 20C0 8.95431 8.95431 0 20 0C31.0457 0 40 8.95431 40 20C40 31.0457 31.0457 40 20 40C8.95431 40 0 31.0457 0 20Z"
        fill="#FF554A"
      />
      <path
        d="M20 30C25.5 30 30 25.5 30 20C30 14.5 25.5 10 20 10C14.5 10 10 14.5 10 20C10 25.5 14.5 30 20 30Z"
        stroke="white"
      />
      <g opacity="0.4">
        <path d="M17.1699 22.8299L22.8299 17.1699" stroke="white" />
        <path d="M22.8299 22.8299L17.1699 17.1699" stroke="white" />
      </g>
    </svg>
  );
};

export default RejectedStatusIcon;
