import { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';

import SidebarBriefModal from 'core/modal/SidebarBriefModal';

import { buildRoute } from 'routes';
import useAuthUser from 'hooks/useAuthUser';
import { getFundingSource } from 'app/features/funding-sources/fundingSourceSaga';
import PartnerClickAwayDetails from 'components/partners/PartnerClickAwayDetails';
import { hideFundingSourceBriefDetails } from 'app/features/funding-sources/fundingSourceSlice';
import useCountries from 'hooks/useCountries';
import useSourceDetail from 'core/source-detail/useSourceDetail';
import Modal from 'app/components/Modal';
import SourceDetails from 'core/source-detail/SourceDetails';

function FundingSourceBriefDetailsModal2() {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const user = useAuthUser();

  const { getCountryNameByIso3Code } = useCountries();

  const viewData = useMemo(
    () => [
      {
        label: 'Country',
        get: ({ country }) => getCountryNameByIso3Code(country),
      },
      {
        label: 'Payment Name',
        data: 'payment_name',
      },
      {
        label: 'Payment Value',
        data: 'payment_value',
      },
      {
        label: 'Description',
        data: 'description',
      },
      {
        label: 'Is Verified ?',
        get: ({ is_verified }) => (is_verified ? 'Yes' : 'No'),
      },
      {
        label: 'Is Active ?',
        get: ({ is_active }) => (is_active ? 'Yes' : 'No'),
      },
    ],
    [],
  );

  const viewColumnDefinition = useSourceDetail(
    () => [
      {
        title: '',
        items: [
          {
            label: 'Country',
            accessorKey: 'country',
            cell: ({ country }) => getCountryNameByIso3Code(country),
          },
          {
            label: 'Payment Name',
            accessorKey: 'payment_name',
          },
          {
            label: 'Payment Value',
            accessorKey: 'payment_value',
          },
          {
            label: 'Description',
            accessorKey: 'description',
          },
          {
            label: 'Is Verified?',
            accessorKey: 'is_verified',
            cell: ({ is_verified }) => (is_verified ? 'Yes' : 'No'),
          },
          {
            label: 'Is Active?',
            accessorKey: 'is_active',
            cell: ({ is_active }) => (is_active ? 'Yes' : 'No'),
          },
        ],
      },
    ],
    [],
  );

  const state = useSelector((state) => state.fundingSources.show);

  const { showBriefDetailModal, fundingSourceId, isLoading, data } = state;

  useEffect(() => {
    if (fundingSourceId) dispatch(getFundingSource(fundingSourceId));
  }, [fundingSourceId]);

  if (!showBriefDetailModal && !fundingSourceId) return <></>;

  return (
    // <SidebarBriefModal
    //   open={showBriefDetailModal ?? false}
    //   onClose={() => dispatch(hideFundingSourceBriefDetails())}
    //   title="Funding Source Details"
    //   onClickView={() => (
    //     dispatch(hideFundingSourceBriefDetails()),
    //     navigate(buildRoute('funding-sources.show', fundingSourceId))
    //   )}
    //   viewData={viewData}
    //   item={data}
    //   isLoading={isLoading}
    // />

    <Modal
      open={showBriefDetailModal ?? false}
      onClose={() => dispatch(hideFundingSourceBriefDetails())}
      title="Funding Source Details"
      handleFullDetails={() => (
        dispatch(hideFundingSourceBriefDetails()),
        navigate(buildRoute('funding-sources.show', fundingSourceId))
      )}
    >
      <SourceDetails
        showContainerDesign={false}
        definition={viewColumnDefinition}
        data={data}
        isLoading={isLoading}
      />
    </Modal>
  );
}

export default FundingSourceBriefDetailsModal2;
