import { useEffect } from 'react';
import Cookies from 'js-cookie';
import LoginForm from 'components/auth/LoginForm';

import OpenLockSvg from 'assets/svg/open-lock.svg';
import GuestLoginSvg from 'assets/svg/GuestLogin.svg';
import GuestContainer from 'layouts/GuestContainer';
import app from 'app/config/app';

export default function Login() {
  useEffect(() => {
    Cookies.set('guest_page_rendered', 'true');
  }, []);

  return (
    <GuestContainer
      lTitle={
        Cookies.get('guest_page_rendered') == 'true'
          ? 'Hi, Welcome Back'
          : `Welcome ! to ${app.name}`
      }
      lImage={GuestLoginSvg}
      rChildren={<LoginForm />}
    />
  );
}
