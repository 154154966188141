import Grid from '@mui/material/Grid';
import { useSelector } from 'react-redux';

import ActiveBadge from 'core/badges/ActiveBadge';
import VerifiedBadge from 'core/badges/VerifiedBadge';
import PartnerClickAwayDetails from 'components/partners/PartnerClickAwayDetails';

import Auth from 'utils/Auth';
import app from 'app/config/app';
import DateUtils from 'utils/DateUtils';
import KycStatus from 'core/badges/KycStatus';
import useSourceDetail from 'core/source-detail/useSourceDetail';
import SourceDetails from 'core/source-detail/SourceDetails';

export default function CustomerDetailsTab() {
  const { isLoading, data: customer } = useSelector((state) => state.customers.show);

  const viewCustomerBasicDetails = useSourceDetail([
    {
      title: 'Basic Information',
      items: [
        { label: 'First Name', accessorKey: 'first_name' },
        { label: 'Last Name', accessorKey: 'last_name' },
        {
          label: 'Date of Birth',
          accessorKey: 'dob',
          cell: ({ dob }) => (dob ? DateUtils.isoToString(dob, false) : ''),
        },
        { label: 'Email', accessorKey: 'email' },
        { label: 'Mobile Number', accessorKey: 'mobile_number' },
      ],
    },
    {
      title: 'Address Information',
      items: [
        { label: 'Post Code', accessorKey: 'post_code' },
        { label: 'Unit', accessorKey: 'unit' },
        { label: 'Street', accessorKey: 'street' },
        { label: 'Street Number', accessorKey: 'street_number' },
        { label: 'Street Type', accessorKey: 'street_type' },
        { label: 'Suburb', accessorKey: 'suburb' },
        { label: 'State', accessorKey: 'state' },
        { label: 'Country', accessorKey: 'country' },
      ],
    },
  ]);

  const viewCustomerOtherDetails = useSourceDetail([
    {
      title: 'Other Information',
      items: [
        { label: 'Occupation', accessorKey: 'occupation' },
        {
          label: 'KYC Status',
          accessorKey: 'kyc_status',
          cell: ({ kyc_status }) => <KycStatus status={kyc_status} />,
        },
        { label: 'ID Type', accessorKey: 'id_type' },
        { label: 'ID Number', accessorKey: 'id_number' },
        { label: 'ID Issued By', accessorKey: 'id_issued_by' },
        {
          label: 'ID Expiry Date',
          accessorKey: 'id_expire_date',
          cell: ({ id_expire_date }) =>
            id_expire_date ? DateUtils.isoToString(id_expire_date, false) : '',
        },
        { label: 'IP Address', accessorKey: 'ip_address' },
        ...(Auth.isSuperAdmin()
          ? [
              {
                label: 'Partner',
                accessorKey: 'partner_id',
                cell: ({ partner_id, partner_name }) => (
                  <PartnerClickAwayDetails partnerId={partner_id} label={partner_name} />
                ),
              },
            ]
          : []),
        { label: 'Partner ID', accessorKey: 'partner_id' },
        { label: 'Source ID', accessorKey: 'source_id' },
        { label: 'Account ID', accessorKey: 'account_id' },
        { label: 'Nationality', accessorKey: 'nationality' },
        { label: 'Birth Country', accessorKey: 'birth_country' },
        { label: 'Source of Income', accessorKey: 'source_of_income' },
        {
          label: 'Is Verified?',
          accessorKey: 'is_verified',
          cell: ({ is_verified }) => <VerifiedBadge isVerified={!!is_verified} />,
        },
        {
          label: 'Is Active?',
          accessorKey: 'is_active',
          cell: ({ is_active }) => <ActiveBadge isActive={!!is_active} />,
        },
        { label: 'Card Number', accessorKey: 'card_number' },
        {
          label: 'Created At',
          accessorKey: 'created_ts',
          cell: ({ created_ts }) => (created_ts ? DateUtils.isoToString(created_ts) : ''),
        },
        { label: 'Created By', accessorKey: 'created_by' },
        {
          label: 'Updated At',
          accessorKey: 'updated_ts',
          cell: ({ updated_ts }) => (updated_ts ? DateUtils.isoToString(updated_ts) : ''),
        },
        { label: 'Updated By', accessorKey: 'updated_by' },
      ],
    },
  ]);

  return (
    <Grid container>
      <Grid item sm={12} md={6}>
        <SourceDetails
          viewMode="column"
          rowMode="row"
          definition={viewCustomerBasicDetails}
          data={customer}
          isLoading={isLoading}
        />
      </Grid>

      <Grid item ms={12} md={6}>
        <SourceDetails
          viewMode="column"
          rowMode="row"
          definition={viewCustomerOtherDetails}
          data={customer}
          isLoading={isLoading}
        />
      </Grid>
    </Grid>
  );
}
