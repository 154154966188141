import React, { useEffect, useRef } from 'react';
import Lottie, { AnimationItem, AnimationConfigWithData } from 'lottie-web';
import PropTypes from 'prop-types';

const LottieAnimation = ({ animationData, loop = true, autoplay = true, speed = 1 }) => {
  const containerRef = useRef(null);

  useEffect(() => {
    if (containerRef.current) {
      const animationInstance = Lottie.loadAnimation({
        container: containerRef.current,
        renderer: 'svg',
        autoplay: autoplay,
        animationData: animationData,
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice',
          progressiveLoad: true,
        },
      });

      animationInstance.setSpeed(speed);

      return () => {
        animationInstance.destroy();
      };
    }
  }, [animationData, loop, autoplay, speed]);

  return (
    <div
      style={{
        height: '8rem',
        width: '8rem',
        margin: 'auto',
      }}
      ref={containerRef}
    />
  );
};

LottieAnimation.propTypes = {
  animationData: PropTypes.object.isRequired,
  loop: PropTypes.bool,
  autoplay: PropTypes.bool,
  speed: PropTypes.number,
};

export default LottieAnimation;
