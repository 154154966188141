import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import withRole from 'hoc/withRole';
import Error from 'core/errors/Error';
import ActionStatusModal from 'core/modal/ActionStatusModal';
import FundingSourceInfoForm from 'components/Funding-source-info/FundingSourcesInfoForm';
import FundingSourceInfoFormSkeleton from 'components/Funding-source-info/FundingSourceInfoFormSkeleton';

import acl from 'app/config/acl';
import routePaths from 'app/config/routePaths';
import NewPageContent from 'layouts/NewPageContent';
import { buildRoute } from 'routes';
import ucwords from 'helpers/ucwords';
import {
  updateFundingSourceInfo,
  getFundingSourceInfo,
} from 'app/features/funding-sources-info/fundingSourceInfoSaga';
import {
  setShowEditProcessingModal,
  updateFundingSourceInfoReset,
} from 'app/features/funding-sources-info/fundingSourceInfoSlice';

function EditFundingSourceInfo() {
  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { data: fundingSourceInfo } = useSelector((state) => state.fundingSourcesInfo.show);

  const {
    isError,
    errorMessage,
    isLoading,
    errorCode,
    showProcessingModal,
    isFailed,
    isUpdated,
    isUpdating,
  } = useSelector((state) => state.fundingSourcesInfo.update);

  useEffect(() => {
    dispatch(getFundingSourceInfo(params.fundingSourceInfoId));
  }, [params.fundingSourceInfoId]);

  if (isError)
    return (
      <Error
        status={errorCode}
        message={errorMessage}
        onRetry={() => dispatch(updateFundingSourceInfo(params.fundingSourceInfoId))}
      />
    );

  return (
    <NewPageContent
      breadcrumbs={[
        {
          label: 'Funding Sources Info',
          to: routePaths.ListFundingSourceInfo,
        },
        {
          label: 'Edit Funding Source Info',
          to: '',
        },
      ]}
      documentTitle="Edit Partner Funding Source Information"
    >
      {isLoading ? (
        <FundingSourceInfoFormSkeleton />
      ) : (
        <FundingSourceInfoForm isAddMode={false} fundingSourcesInfo={fundingSourceInfo} />
      )}

      <ActionStatusModal
        showModal={showProcessingModal}
        closeModal={() => dispatch(setShowEditProcessingModal(false))}
        data={fundingSourceInfo}
        isSuccess={isUpdated}
        isProcessing={isUpdating}
        isError={isFailed}
        errorMessage={ucwords(errorMessage) ?? 'Failed To Update Funding Source Information'}
        successMessage="Funding Source Information Updated Successfully"
        navigateToView={() =>
          navigate(
            buildRoute('funding-sources-info.show', fundingSourceInfo.id ?? params.fundingSourceId),
          )
        }
        resetForm={() => dispatch(updateFundingSourceInfoReset())}
      />
    </NewPageContent>
  );
}

export default withRole([acl.SUPERADMIN, acl.ADMIN])(EditFundingSourceInfo);
