import { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';

import SidebarBriefModal from 'core/modal/SidebarBriefModal';
import PartnerClickAwayDetails from 'components/partners/PartnerClickAwayDetails';

import { buildRoute } from 'routes';
import useAuthUser from 'hooks/useAuthUser';
import useCountries from 'hooks/useCountries';
import { getPartnerFundingSource } from 'app/features/partner-funding-sources/partnerFundingSourceSaga';
import { hidePartnerFundingSourceBriefDetails } from 'app/features/partner-funding-sources/partnerFundingSourceSlice';
import Auth from 'utils/Auth';
import useSourceDetail from 'core/source-detail/useSourceDetail';
import Modal from 'app/components/Modal';
import SourceDetails from 'core/source-detail/SourceDetails';

function PartnerFundingSourcePreviewDetailsModal() {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const user = useAuthUser();

  const { getCountryNameByIso3Code } = useCountries();

  const viewDetailColumn = useSourceDetail([
    {
      title: '',
      items: [
        ...(Auth.isSuperAdmin()
          ? [
              {
                label: 'Partner',
                accessorKey: 'partner_name',
                cell: ({ partner_id, partner_name }) => (
                  <PartnerClickAwayDetails partnerId={partner_id} label={partner_name} />
                ),
              },
            ]
          : []),
        {
          label: 'Country',
          accessorKey: 'country',
          cell: ({ country }) => getCountryNameByIso3Code(country),
        },
        {
          label: 'Funding Source Name',
          accessorKey: 'funding_source_name',
        },
        {
          label: 'Funding Source Value',
          accessorKey: 'funding_source',
        },
        {
          label: 'Is Active?',
          accessorKey: 'is_active',
          cell: ({ is_active }) => (is_active ? 'Yes' : 'No'),
        },
      ],
    },
  ]);

  const state = useSelector((state) => state.partnerFundingSources.show);

  const { showBriefDetailModal, partnerFundingSourceId, isLoading, data } = state;

  useEffect(() => {
    if (partnerFundingSourceId) dispatch(getPartnerFundingSource(partnerFundingSourceId));
  }, [partnerFundingSourceId]);

  if (!showBriefDetailModal && !partnerFundingSourceId) return <></>;

  return (
    <Modal
      open={showBriefDetailModal ?? false}
      onClose={() => dispatch(hidePartnerFundingSourceBriefDetails())}
      title="Funding Source Details"
      handleFullDetails={() => (
        dispatch(hidePartnerFundingSourceBriefDetails()),
        navigate(buildRoute('partner-funding-sources.show', partnerFundingSourceId))
      )}
    >
      <SourceDetails
        showContainerDesign={false}
        definition={viewDetailColumn}
        data={data}
        isLoading={isLoading}
      />
    </Modal>
  );
}

export default PartnerFundingSourcePreviewDetailsModal;
