import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useState, useEffect } from 'react';
import FilterAltIcon from '@mui/icons-material/FilterAlt';

import SearchBox from 'core/forms/SearchBox';
import MoreActions from 'core/lab/MoreActions';
import ReloadButton from 'core/buttons/ReloadButton';
import FilterButton from 'core/buttons/FilterButton';
import TableGridFilter from 'core/table/TableGridFilter';
import ColumnVisibility from 'core/table/ColumnVisibility';
import TableActionsContainer from 'core/table/TableActionsContainer';

import Sheet from 'utils/Sheet';
import app from 'app/config/app';
import acl from 'app/config/acl';
import debounce from 'helpers/debounce';
import FilterType from 'app/config/FilterType';
import useQueryParams from 'hooks/useQueryParams';
import { getAccounts } from 'app/features/accounts/accountSaga';
import { setParam, deleteFilterParams } from 'app/features/accounts/accountSlice';

export default function AccountsActions({ table }) {
  const dispatch = useDispatch();

  const [showFilter, setShowFilter] = useState(false);

  const accountState = useSelector((state) => state.accounts.index);

  const [queryParams, updateQueryParams] = useQueryParams(app.ignoreFilterKeys);

  const { params: appliedFilters } = accountState;

  const [search, setSearch] = useState(appliedFilters?.search ?? '');

  const handleOnSearch = useCallback((e) => {
    setSearch(e.target.value);
    debounce(() => {
      dispatch(setParam({ search: e.target.value, page_number: 1 }));
    }, 500);
  }, []);

  const handleOnClearSearch = useCallback(() => {
    setSearch('');
    dispatch(deleteFilterParams('search'));
  }, []);

  const handleOnFilter = useCallback((data) => {
    dispatch(setParam({ ...data, page_number: 1 }));
  }, []);

  let availableFilters = [
    // {
    //   label: '',
    //   value: 'search',
    //   type: FilterType.search,
    // },
    {
      label: 'Role',
      value: 'user_type',
      type: FilterType.select,
      options: [
        { label: 'Super Admin', value: acl.SUPERADMIN },
        { label: 'Admin', value: acl.ADMIN },
        { label: 'User', value: acl.USER },
      ],
    },
  ];

  const handleOnReset = useCallback((e) => {
    dispatch(deleteFilterParams([...availableFilters.map((filter) => filter.value)]));
  });

  useEffect(() => {
    updateQueryParams({ ...queryParams, ...appliedFilters });
    setSearch(appliedFilters?.search ?? '');
  }, [JSON.stringify(appliedFilters)]);

  useEffect(() => {
    dispatch(setParam(queryParams ?? {}));
  }, []);

  return (
    <>
      <Box>
        <TableGridFilter
          showFilter={showFilter}
          appliedFilters={appliedFilters}
          availableFilters={availableFilters}
          onFilter={handleOnFilter}
          onReset={handleOnReset}
        />
      </Box>
      <TableActionsContainer>
        <SearchBox
          sx={{
            marginRight: 1,
          }}
          onChange={handleOnSearch}
          value={search || ''}
          onClickClearSearch={handleOnClearSearch}
        />

        <Box display="flex" gap={1}>
          <FilterButton
            variant="outlined"
            onClick={() => setShowFilter(!showFilter)}
            uppercase
          >
            Filter
          </FilterButton>

          {table && <ColumnVisibility table={table} />}
          {/* <DownloadButton onClick={() => {}} /> */}
          <ReloadButton
            onClick={() => {
              dispatch(getAccounts({ ...appliedFilters, page_number: 1 }));
            }}
          />
          <MoreActions
            onExportToCsv={() => Sheet.exportReactTableSelectedRowsToCsv(table, 'accounts_')}
            onExportToExcel={() => Sheet.exportReactTableSelectedRowsToExcel(table, 'accounts_')}
          />
        </Box>
      </TableActionsContainer>
    </>
  );
}

AccountsActions.propTypes = {
  table: PropTypes.object,
};
