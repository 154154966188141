import React, { useEffect } from 'react';
import { Modal, Box, Typography, styled } from '@mui/material';
import LottieAnimation from 'core/animations/LottieAnimation';

import successAnimation from 'assets/lottie/successCheck.json';
import FailedAnimation from 'assets/lottie/errorCross.json';
import ProcessingAnimation from 'assets/lottie/loadingSpinner.json';

import withTimeout from 'helpers/withTimeout';

const StyledModalContent = styled(Box)(({ theme }) => ({
  position: 'absolute',
  borderRadius: '8px',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.shadows[24],
  width: '21rem',
  height: '13rem',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  overflow: 'hidden',
  padding: '1rem',
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
  textAlign: 'center',
  paddingBottom: '0.5rem',
}));

const ItemsContainer = styled(Box)(({ theme }) => ({}));

const ActionStatusModal = ({
  showModal,
  closeModal,
  retry,
  successMessage,
  data,
  navigateToView,
  resetForm,
  isProcessing,
  isError,
  isSuccess,
  errorMessage,
  dispatchAction,
}) => {
  let lottieAnimation = null;
  let itemsContainerContent = null;

  useEffect(() => {
    if (data && isSuccess) {
      const clearTimeoutFn = withTimeout(() => {
        closeModal();
        navigateToView();
        resetForm();

        if (dispatchAction) {
          dispatchAction();
        }
      }, 4000);

      return () => clearTimeoutFn();
    }

    return () => {};
  }, [isSuccess, data, navigateToView, closeModal, resetForm, dispatchAction]);

  useEffect(() => {
    if (isError) {
      setTimeout(() => {
        closeModal();
      }, 4000);
    }

    return () => clearTimeout();
  }, [isError, closeModal]);

  switch (true) {
    case isProcessing:
      lottieAnimation = <LottieAnimation animationData={ProcessingAnimation} loop={true} />;
      itemsContainerContent = <StyledTypography variant="body2">Processing....</StyledTypography>;
      break;
    case isSuccess:
      lottieAnimation = <LottieAnimation animationData={successAnimation} loop={false} />;

      itemsContainerContent = (
        <StyledTypography color="primary.main" variant="body2">
          {successMessage}
        </StyledTypography>
      );
      break;
    case isError:
      lottieAnimation = <LottieAnimation animationData={FailedAnimation} loop={false} />;
      itemsContainerContent = (
        <Box
          display="flex"
          flexDirection="column"
          gap={1}
          justifyContent="center"
          alignItems="center"
        >
          <Typography color="error.main" variant="body2">
            {errorMessage}
          </Typography>
        </Box>
      );
      break;
    default:
      break;
  }

  return (
    <Modal open={showModal}>
      <StyledModalContent>
        {lottieAnimation}
        <ItemsContainer>{itemsContainerContent}</ItemsContainer>
      </StyledModalContent>
    </Modal>
  );
};

export default ActionStatusModal;
