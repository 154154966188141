import PropTypes from 'prop-types';
import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import FallbackImageLoader from 'core/fallbackLoader/FallbackImageLoader';

import { getLicensingPartner } from 'app/features/licensing-partners/licensingPartnerSaga';
import useSourceDetail from 'core/source-detail/useSourceDetail';
import DateUtils from 'utils/DateUtils';
import SourceDetails from 'core/source-detail/SourceDetails';

export default function LicensingPartner({ licensingPartnerId }) {
  const dispatch = useDispatch();

  const { isLoading, data: LicensingPartners } = useSelector(
    (state) => state.licensingPartners.show,
  );

  const viewLicensingPartnerDefinition = useSourceDetail(
    [
      {
        title: '',
        items: [
          {
            label: 'Licensing Partner ID',
            accessorKey: 'id',
          },
          {
            label: 'Name',
            accessorKey: 'name',
          },
          {
            label: 'Licensing Number',
            accessorKey: 'license_number',
          },
          {
            label: 'Logo',
            accessorKey: 'logo',
            cell: ({ logo }) => <FallbackImageLoader imageUrl={logo} />,
          },
          {
            label: 'Privacy Policy',
            accessorKey: 'privacy_policy_link',
            cell: ({ privacy_policy_link }) => (
              <a href={privacy_policy_link} target="_blank" rel="noopener noreferrer">
                {privacy_policy_link}
              </a>
            ),
          },
          {
            label: 'Terms and Conditions',
            accessorKey: 'terms_and_conditions_link',
            cell: ({ terms_and_conditions_link }) => (
              <a href={terms_and_conditions_link} target="_blank" rel="noopener noreferrer">
                {terms_and_conditions_link}
              </a>
            ),
          },
          {
            label: 'Created At',
            accessorKey: 'created_ts',
            cell: ({ created_ts }) => (created_ts ? DateUtils.isoToString(created_ts) : '-'),
          },
          {
            label: 'Updated At',
            accessorKey: 'updated_ts',
            cell: ({ updated_ts }) => (updated_ts ? DateUtils.isoToString(updated_ts) : '-'),
          },
        ],
      },
    ],
    [],
  );

  useEffect(() => {
    dispatch(getLicensingPartner(licensingPartnerId));
  }, [licensingPartnerId]);

  return (
    <>
      <SourceDetails
        viewMode="column"
        rowMode="row"
        definition={viewLicensingPartnerDefinition}
        data={LicensingPartners}
        isLoading={isLoading}
      />
    </>
  );
}

LicensingPartner.propTypes = {
  licensingPartnerId: PropTypes.string,
};
