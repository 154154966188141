import { useEffect } from 'react';
import { compose } from '@reduxjs/toolkit';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import withRole from 'hoc/withRole';
import Error from 'core/errors/Error';
import withAppState from 'hoc/withAppState';
import FinmoPayouts from 'components/finmo-payouts/FinmoPayouts';
// import FinmoPayoutFilterValues from 'components/finmo-payouts/FinmoPayoutFilterValues';
import { resetGetFinmoPayoutsFailure, toggleFilter } from 'app/features/finmo-payouts/finmoPayoutSlice';

import acl from 'app/config/acl';
import NewPageContent from 'layouts/NewPageContent';
import FinmoPayoutPreviewDetailsModal from 'components/finmo-payouts/FinmoPayoutPreviewDetailsModal';
import FinmoPayoutTableFilterValues from 'components/finmo-payouts/FinmoPayoutTableFilterValues';
import FilterButton from 'core/buttons/FilterButton';

function ListFinmoPayout({ isNetworkError }) {
  const finmoPayoutState = useSelector((state) => state.finmoPayouts.index);

  const { isError, errorCode, isFilterOpen } = finmoPayoutState;

  const dispatch = useDispatch();

  useEffect(() => {
    if (isError) dispatch(resetGetFinmoPayoutsFailure());
  }, [isNetworkError]);

  if (isError)
    return (
      <Error
        status={errorCode}
        withInDashboard={true}
        onRetry={() => dispatch(resetGetFinmoPayoutsFailure())}
      />
    );

  return (
    <NewPageContent
      breadcrumbs={[
        {
          label: 'Finmo Payouts',
          to: '',
        },
      ]}
      documentTitle="Finmo Payouts"
      rightTopEndContent={<FilterButton isOpen={isFilterOpen} onClick={() => dispatch(toggleFilter())} />}
    >
      {/* <FinmoPayoutFilterValues /> */}
      <FinmoPayoutTableFilterValues />
      <FinmoPayouts />
      <FinmoPayoutPreviewDetailsModal />
    </NewPageContent>
  );
}

export default compose(withAppState, withRole([acl.SUPERADMIN]))(ListFinmoPayout);
