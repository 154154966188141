import React from 'react';
import PropTypes from 'prop-types';
import styled from '@mui/material/styles/styled';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';

import Button from './Button';

const FilterBtn = styled(Button)(({ theme }) => ({
  border: 'none',
  backgroundColor: theme.palette.info.lighter,
  textTransform: 'uppercase',
  transition: 'background-color 0.3s ease-in-out, color 0.3s ease-in-out',
  borderRadius: 20,
  '&:hover': {
    border: 'none',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,

    '& .MuiSvgIcon-root': {
      color: `${theme.palette.common.white}`,
    },
  },
  '& .MuiButtonBase-root': {
    ripple: 'none',
  },
}));

export default function FilterButton({ isOpen, ...rest }) {
  return (
    <FilterBtn disableRipple variant="outlined" startIcon={isOpen ? <HighlightOffIcon /> : <FilterAltOutlinedIcon />} uppercase {...rest}>
      {isOpen ? 'Close' : 'Filter'}
    </FilterBtn>
  );
}

FilterButton.propTypes = {
  isOpen: PropTypes.bool,
};
