import { useEffect } from 'react';
import { compose } from '@reduxjs/toolkit';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import withRole from 'hoc/withRole';
import Error from 'core/errors/Error';
import withAppState from 'hoc/withAppState';
import ActionButton from 'core/buttons/ActionButton';
import Partners from 'components/partners/Partners';
import PartnerPreviewDetailsModal from 'components/partners/PartnerPreviewDetailsModal';

import acl from 'app/config/acl';
import { buildRoute } from 'routes';
import NewPageContent from 'layouts/NewPageContent';
import { resetGetPartnersFailure } from 'app/features/partners/partnerSlice';
import PartnerTableFilterValues from './PartnerTableFilterValues';

function ListPartner({ isNetworkError }) {
  const partnerState = useSelector((state) => state.partners.index);

  const { isError, errorCode } = partnerState;

  const dispatch = useDispatch();

  const navigate = useNavigate();

  useEffect(() => {
    if (isError) dispatch(resetGetPartnersFailure());
  }, [isNetworkError]);

  if (isError)
    return (
      <Error
        status={errorCode}
        withInDashboard={true}
        onRetry={() => dispatch(resetGetPartnersFailure())}
      />
    );

  return (
    <NewPageContent
      documentTitle="Partners"
      breadcrumbs={[
        {
          label: 'Partners',
          to: '',
        },
      ]}
      rightTopEndContent={
        <ActionButton color="primary" onClick={() => navigate(buildRoute('partners.create'))}>
          ADD PARTNER
        </ActionButton>
      }
    >
      <PartnerTableFilterValues />
      <Partners />
      <PartnerPreviewDetailsModal />
    </NewPageContent>
  );
}

export default compose(withAppState, withRole(acl.SUPERADMIN))(ListPartner);
