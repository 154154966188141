import * as Yup from 'yup';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { useRef, useEffect } from 'react';
import { compose } from '@reduxjs/toolkit';
import { useNavigate } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, FormProvider } from 'react-hook-form';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

import Button from 'core/buttons/Button';
import TextButton from 'core/buttons/TextButton';
import FormContainer from 'core/forms/FormContainer';
import FormSelectPartner from 'hook-form-controls/FormSelectPartner';

import app from 'app/config/app';
import YupPassword from 'utils/YupPassword';

import {
  createFundingSourceInfo,
  updateFundingSourceInfo,
} from 'app/features/funding-sources-info/fundingSourceInfoSaga';

import FormSelectFundingSource from 'hook-form-controls/FormSelectFundingSource';
import FormTextField from 'hook-form-controls/FormTextField';
import ActionButton from 'core/buttons/ActionButton';
import ButtonContainer from 'core/buttons/ButtonContainer';

YupPassword(Yup);

const partnerFundingSourceInfoSchema = Yup.object().shape({
  funding_source_id: Yup.string().required('Funding Source is Required'),
  funding_key: Yup.string().required('Funding Key is Required'),
  funding_key_value: Yup.string().required('Funding Key Value is Required'),
  partner_id: Yup.string().required('Partner is Required'),
});

function FundingSourceInfoForm({ isAddMode, fundingSourcesInfo, retries, isCreated }) {
  const submitButtonRef = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const methods = useForm({
    defaultValues: fundingSourcesInfo || {},
    resolver: yupResolver(partnerFundingSourceInfoSchema),
    mode: 'onChange',
  });

  const {
    formState: { errors },
    handleSubmit,
    reset,
    getValues,
    watch,
    setValue,
  } = methods;

  const onSubmit = async (data) => {
    const formattedData = {
      funding_source_id: data.funding_source_id,
      funding_key: data.funding_key,
      funding_key_value: data.funding_key_value,
      partner_id: data.partner_id,
    };

    dispatch(
      isAddMode
        ? createFundingSourceInfo(formattedData)
        : updateFundingSourceInfo({ id: fundingSourcesInfo.id, ...formattedData }),
    );
  };

  useEffect(() => {
    if (!isAddMode) {
      setValue('funding_source_id', fundingSourcesInfo?.funding_source_id);
      setValue('partner_id', fundingSourcesInfo?.partner_id);
      setValue('funding_key', fundingSourcesInfo?.funding_key);
      setValue('funding_key_value', fundingSourcesInfo?.funding_key_value);
    }
  }, [fundingSourcesInfo, setValue, isAddMode]);

  useEffect(() => {
    if (isCreated) {
      reset({
        partner_id: getValues('partner_id'),
        funding_source_id: getValues('funding_source_id'),
        funding_key: getValues('funding_key'),
        funding_key_value: getValues('funding_key_value'),
      });
    }
  }, [isCreated]);

  useEffect(() => {
    if (retries > 0) submitButtonRef.current.click();
  }, [retries]);

  return (
    <FormContainer>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container rowSpacing={3} columnSpacing={4}>
            <Grid item xs={12} md={6}>
              <FormSelectFundingSource
                id="select-funding-source"
                value={getValues('funding_source_id')}
                name="funding_source_id"
                label="Funding Source"
                valueKey="id"
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <FormSelectPartner
                id="select-partner"
                name="partner_id"
                label="Select Partner"
                error={!!errors?.partner_id}
                helperText={errors?.partner_id?.message ?? ''}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <FormTextField
                name="funding_key"
                label="Funding Key"
                error={!!errors?.funding_key}
                helperText={errors?.funding_key?.message ?? ''}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <FormTextField
                name="funding_key_value"
                label="Funding Key Value"
                error={!!errors?.funding_key_value}
                helperText={errors?.funding_key_value?.message ?? ''}
              />
            </Grid>

            <Grid item xs={12} md={12}>
              <ButtonContainer>
                <TextButton type="button" color="primary" onClick={() => navigate(-1)}>
                  Cancel
                </TextButton>
                <ActionButton
                  ref={submitButtonRef}
                  type="submit"
                  variant="contained"
                  endIcon={<ArrowForwardIcon />}
                >
                  {isAddMode ? 'Create' : 'Update'}
                </ActionButton>
              </ButtonContainer>
            </Grid>
          </Grid>
        </form>
      </FormProvider>
    </FormContainer>
  );
}

export default compose(connect(mapStateToProps))(FundingSourceInfoForm);

function mapStateToProps(state, ownProps) {
  return {
    ...ownProps,
    retries: ownProps.isAddMode
      ? state.fundingSourcesInfo.create.retries
      : state.fundingSourcesInfo.update.retries,
    isCreated: state.fundingSourcesInfo.create.isCreated,
  };
}

FundingSourceInfoForm.propTypes = {
  isAddMode: PropTypes.bool.isRequired,
  fundingSourcesInfo: PropTypes.object,
  retries: PropTypes.number,
  isCreated: PropTypes.bool,
};
