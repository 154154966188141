import { useEffect } from 'react';
import { compose } from '@reduxjs/toolkit';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import withRole from 'hoc/withRole';
import Error from 'core/errors/Error';
import Button from 'core/buttons/Button';
import withAppState from 'hoc/withAppState';
import PageContent from 'layouts/PageContent';
import FundingSources from 'components/funding-sources/FundingSources';
import FundingSourceFilterValues from 'components/funding-sources/FundingSourceFilterValues';
import { resetGetFundingSourcesFailure } from 'app/features/funding-sources/fundingSourceSlice';
import FundingSourceBriefDetailsModal from 'components/funding-sources/FundingSourceBriefDetailsModal';

import acl from 'app/config/acl';
import { buildRoute } from 'routes';
import NewPageContent from 'layouts/NewPageContent';
import FundingSourceBriefDetailsModal2 from 'components/funding-sources/FundingSourceBriefDetailsModal2';
import ActionButton from 'core/buttons/ActionButton';

function ListFundingSource({ isNetworkError }) {
  const fundingSourceState = useSelector((state) => state.fundingSources.index);

  const { isError, errorCode } = fundingSourceState;

  const dispatch = useDispatch();

  const navigate = useNavigate();

  useEffect(() => {
    if (isError) dispatch(resetGetFundingSourcesFailure());
  }, [isNetworkError]);

  if (isError)
    return (
      <Error
        status={errorCode}
        withInDashboard={true}
        onRetry={() => dispatch(resetGetFundingSourcesFailure())}
      />
    );

  return (
    <NewPageContent
      breadcrumbs={[
        {
          label: 'Funding Sources',
          to: '',
        },
      ]}
      documentTitle="Funding Sources"
      rightTopEndContent={
        <ActionButton onClick={() => navigate(buildRoute('funding-sources.create'))}>
          Add Funding Source
        </ActionButton>
      }
    >
      <FundingSourceFilterValues />
      <FundingSources />
      <FundingSourceBriefDetailsModal2 />
    </NewPageContent>
  );
}

export default compose(withAppState, withRole([acl.SUPERADMIN, acl.ADMIN]))(ListFundingSource);
