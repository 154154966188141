import styled from '@emotion/styled';
import { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import { useDispatch, useSelector } from 'react-redux';

import VerifiedBadge from 'core/badges/VerifiedBadge';
import FallbackImageLoader from 'core/fallbackLoader/FallbackImageLoader';

import { buildRoute } from 'routes';
import { getLicensingPartner } from 'app/features/licensing-partners/licensingPartnerSaga';
import { hideLicensingPartnerBriefDetails } from 'app/features/licensing-partners/licensingPartnerSlice';
import useSourceDetail from 'core/source-detail/useSourceDetail';
import Modal from 'app/components/Modal';
import SourceDetails from 'core/source-detail/SourceDetails';

const TextFlowWrapper = styled(Typography)(({ theme }) => ({
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  width: '25rem',
  fontWeight: '400',
  color: 'rgb(99, 115, 129)',
}));

export default function LicensingPartnerPreviewDetailsModal() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const viewData = useMemo(() => [
    {
      label: 'Licensing Partner Name',
      data: 'name',
    },
    {
      label: 'Logo',
      data: 'logo',
      get: ({ logo }) => <FallbackImageLoader imageUrl={logo} />,
    },
    {
      label: 'Licensing Number',
      data: 'license_number',
      get: ({ license_number }) => <TextFlowWrapper>{license_number}</TextFlowWrapper>,
    },
    {
      label: 'Website Link',
      data: 'website_link',
      get: ({ website_link }) => <TextFlowWrapper>{website_link}</TextFlowWrapper>,
    },
    {
      label: 'Privacy policy',
      data: 'privacy_policy_link',
      get: ({ privacy_policy_link }) => <TextFlowWrapper>{privacy_policy_link}</TextFlowWrapper>,
    },
    {
      label: 'Terms and Conditions',
      data: 'terms_and_conditions_link',
      get: ({ terms_and_conditions_link }) => (
        <TextFlowWrapper>{terms_and_conditions_link}</TextFlowWrapper>
      ),
    },
    {
      label: 'Is Deleted ?',
      data: 'is_deleted',
      cell: (info) => <VerifiedBadge isVerified={!!info.getValue()} />,
    },
  ]);

  const state = useSelector((state) => state.licensingPartners.show);

  const { showBriefDetailModal, licensingPartnerId, isLoading, data } = state;

  useEffect(() => {
    if (licensingPartnerId) {
      dispatch(getLicensingPartner(licensingPartnerId));
    }
  }, [licensingPartnerId]);

  if (!showBriefDetailModal && !licensingPartnerId) return <></>;

  const viewDetailColumn = useSourceDetail([
    {
      title: '',
      items: [
        { label: 'Licensing Partner Name', accessorKey: 'name' },
        {
          label: 'Logo',
          accessorKey: 'logo',
          cell: ({ logo }) => <FallbackImageLoader imageUrl={logo} />,
        },
        {
          label: 'Licensing Number',
          accessorKey: 'license_number',
        },
        {
          label: 'Website Link',
          accessorKey: 'website_link',
        },
        {
          label: 'Privacy Policy',
          accessorKey: 'privacy_policy_link',
        },
        {
          label: 'Terms and Conditions',
          accessorKey: 'terms_and_conditions_link',
        },
      ],
    },
  ]);

  return (
    <Modal
      open={showBriefDetailModal ?? false}
      onClose={() => dispatch(hideLicensingPartnerBriefDetails())}
      title="Transaction Details"
      handleFullDetails={() => (
        dispatch(hideLicensingPartnerBriefDetails()),
        navigate(buildRoute('licensing-partners.show', licensingPartnerId))
      )}
    >
      <SourceDetails
        showContainerDesign={false}
        definition={viewDetailColumn}
        data={data}
        isLoading={isLoading}
      />
    </Modal>
  );
}
