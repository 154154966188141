import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import NotStartedIcon from '@mui/icons-material/NotStarted';

import ucwords from 'helpers/ucwords';
import CreatedStatusIcon from 'core/status-icon/CreatedStatusIcon';
import PendingStatusIcon from 'core/status-icon/PendingStatusIcon';
import ExpiredStatusIcon from 'core/status-icon/ExpiredStatusIcon';
import RejectedStatusIcon from 'core/status-icon/RejectedStatusIcon';
import CompletedStatusIcon from 'core/status-icon/CompletedStatusIcon';
import ProcessingStatusIcon from 'core/status-icon/ProcessingStatusIcon';
import NotStartedStatusIcon from 'core/status-icon/StatusNotStartedIcon';

import { kycStatuses } from 'app/config/KYC';
import { styled } from '@mui/material';

const NotStartedStatus = styled(NotStartedIcon)(({ theme }) => ({
  color: theme.palette.grey[500],
}));

const KycStatusBadge = ({ status, width, height }) => {
  let icon;

  switch (status) {
    case kycStatuses.created:
      icon = <CreatedStatusIcon />;
      break;
    case kycStatuses.pending:
      icon = <PendingStatusIcon />;
      break;
    case kycStatuses.processing:
      icon = <ProcessingStatusIcon />;
      break;
    case kycStatuses.completed:
      icon = <CompletedStatusIcon />;
      break;
    case kycStatuses.rejected:
      icon = <RejectedStatusIcon />;
      break;
    case kycStatuses.expired:
      icon = <ExpiredStatusIcon />;
      break;
    case kycStatuses.not_started:
      icon = <NotStartedStatusIcon />;
      break;
    default:
      icon = null;
  }

  return (
    <>
      {
        <Tooltip title={ucwords(status)} placement="top" arrow>
          <IconButton
            sx={{
              padding: 0,
              margin: 0,
              '& svg': {
                width: width,
                height: height,
              },
            }}
          >
            {icon}
          </IconButton>
        </Tooltip>
      }
    </>
  );
};

KycStatusBadge.propTypes = {
  status: PropTypes.oneOf(Object.values(kycStatuses)),
  width: PropTypes.number,
  height: PropTypes.number,
};

KycStatusBadge.defaultProps = {
  width: 35,
  height: 35,
};

export default KycStatusBadge;
