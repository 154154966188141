import React from 'react';
import PropTypes from 'prop-types';
import Transactions from 'components/transactions/Transactions';
import TransactionFilterValues from 'components/transactions/TransactionFilterValues';
import TransactionBriefDetailsModal2 from 'components/transactions/TransactionPreviewDetailsModal';

export default function CustomerTransactionsTab({ customerId }) {
  return (
    <>
      <Transactions filterByCustomer={true} customerId={customerId} />
      <TransactionBriefDetailsModal2 />
    </>
  );
}

CustomerTransactionsTab.propTypes = {
  customerId: PropTypes.string.isRequired,
};
