import React, { useMemo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import PopoverButton from 'core/buttons/PopoverButton';
import ListItemButton from '@mui/material/ListItemButton';
import { useNavigate } from 'react-router-dom';
import ActiveBadge from 'core/badges/ActiveBadge';
import { useSelector, useDispatch } from 'react-redux';
import { useReactTable, getCoreRowModel } from '@tanstack/react-table';

import FormCheckbox from 'core/forms/FormCheckbox';
import VerifiedBadge from 'core/badges/VerifiedBadge';
import FundingSourcesActions from './FundingSourcesActions';
import TanstackReactTable from 'core/table/TanstackReactTable';
import FundingSourcePagination from './FundingSourcePagination';

import app from 'app/config/app';
import { buildRoute } from 'routes';
import isEmpty from 'helpers/isEmpty';
import constants from 'app/config/constants';
import useCountries from 'hooks/useCountries';
import { useConfirm } from 'core/mui-confirm';
import useQueryParams from 'hooks/useQueryParams';
import {
  getFundingSources,
  updateIsActiveStatus,
  deleteFundingSource,
} from 'app/features/funding-sources/fundingSourceSaga';
import { getFundingSourceBriefDetailsInit } from 'app/features/funding-sources/fundingSourceSlice';
import TableContainerWrapper from 'core/table/TableContainerWrapper';

export default function FundingSources() {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const { getCountryNameByIso3Code } = useCountries();

  const confirm = useConfirm();

  const fundingSourcesState = useSelector((state) => state.fundingSources.index);

  const { params, data, isLoading } = fundingSourcesState;

  const [queryParams] = useQueryParams(app.ignoreFilterKeys);

  const [columnVisibility, setColumnVisibility] = useState({});

  /**
   * handle toggle status
   *
   */
  const handleToggleStatus = (fundingSourceId) => {
    let fundingSource = data.filter((item) => item.id === fundingSourceId)?.[0];

    if (isEmpty(fundingSource)) return;

    const confirmationText = fundingSource.is_active
      ? 'Yes, set as Inactive'
      : 'Yes, set as Active';

    confirm({
      icon: 'warning',
      confirmationText,
      description: 'This will toggle the status of funding source.',
    })
      .then(() =>
        dispatch(
          updateIsActiveStatus({
            fundingSourceId: fundingSource.id,
            isActive: !fundingSource.is_active,
          }),
        ),
      )
      .catch(() => {});
  };

  /**
   * handle delete fundingSource
   *
   */
  const handleDelete = (fundingSourceId) => {
    confirm({
      icon: 'warning',
      confirmationText: 'Yes, Delete it.',
      description: 'This will permanently delete this funding source.',
    })
      .then(() => dispatch(deleteFundingSource(fundingSourceId)))
      .catch(() => {});
  };

  const columns = useMemo(
    () => [
      {
        id: constants.TABLE_CHECKBOX_SELECT_CHECKBOX_ID,
        header: ({ table }) => (
          <FormCheckbox
            forTableHead={true}
            {...{
              checked: table.getIsAllRowsSelected(),
              indeterminate: table.getIsSomeRowsSelected(),
              onChange: table.getToggleAllRowsSelectedHandler(),
            }}
          />
        ),
        cell: ({ row }) => (
          <div>
            <FormCheckbox
              {...{
                checked: row.getIsSelected(),
                indeterminate: row.getIsSomeSelected(),
                onChange: row.getToggleSelectedHandler(),
              }}
            />
          </div>
        ),
      },
      {
        id: constants.TABLE_SERIAL_NUMBER_COLUMN_ID,
        header: 'SN',
        accessorFn: (row) => row.sn,
      },
      {
        header: 'Country',
        accessorFn: (row) => getCountryNameByIso3Code(row.country),
      },
      {
        header: 'Payment Name',
        accessorFn: (row) => row.payment_name,
      },
      {
        header: 'Payment Value',
        accessorFn: (row) => row.payment_value,
      },
      {
        header: 'Is Active ?',
        accessorFn: (row) => row.is_active,
        cell: ({ row }) => (
          <ActiveBadge
            isActive={!!row.original.is_active}
            tooltipTitle="Click to toggle status"
            onClick={() => handleToggleStatus(row.original.id)}
          />
        ),
      },
      {
        header: 'Is Verified ?',
        accessorFn: (row) => row.is_verified,
        cell: ({ row }) => <VerifiedBadge isVerified={!!row.original.is_verified} />,
      },

      {
        id: constants.TABLE_ACTION_COLUMN_ID,
        header: 'Action',
        accessorFn: (row) => row.id,

        cell: ({ row }) => (
          <PopoverButton>
            {({ onClose }) => (
              <>
                <ListItemButton
                  onClick={() => {
                    dispatch(
                      getFundingSourceBriefDetailsInit({
                        showBriefDetailModal: true,
                        fundingSourceId: row.original.id,
                      }),
                    );
                    onClose();
                  }}
                >
                  Preview
                </ListItemButton>
                <ListItemButton
                  onClick={() => {
                    navigate(buildRoute('funding-sources.show', row.original.id));
                    onClose();
                  }}
                >
                  View Details
                </ListItemButton>
                <ListItemButton
                  onClick={() => {
                    handleDelete(row.original.id);
                    onClose();
                  }}
                  disabled={row.original?.isDeleting || false}
                >
                  Delete
                </ListItemButton>
              </>
            )}
          </PopoverButton>
        ),
      },
    ],
    [JSON.stringify(data)],
  );

  const table = useReactTable({
    data,
    columns,
    state: {
      columnVisibility,
    },
    onColumnVisibilityChange: setColumnVisibility,
    getCoreRowModel: getCoreRowModel(),
  });

  useEffect(() => {
    dispatch(getFundingSources({ ...queryParams, ...params }));
  }, [JSON.stringify(params)]);

  return (
    <TableContainerWrapper>
      <FundingSourcesActions table={table} />
      <TanstackReactTable
        table={table}
        data={data}
        isLoading={isLoading}
        columnsCount={
          columns.length - Object.entries(columnVisibility ?? {}).filter(([k, v]) => !v).length
        }
      />
      <FundingSourcePagination />
    </TableContainerWrapper>
  );
}

FundingSources.propTypes = {
  partnerId: PropTypes.string,
};
