import PropTypes from 'prop-types';
import styled from '@mui/material/styles/styled';

import DrawerLogoOpen from 'assets/logo/logo_white_horizontal.svg';
import DrawerLogoClose from 'assets/logo/passpay_logo_half_white.svg';

const DrawerWrapper = styled('div')(({ theme }) => ({
  color: theme.palette.background.paper,
  backgroundColor: 'inherit',
  display: 'flex',
  alignContent: 'left',
  justifyContent: 'left',
  alignItems: 'left',
  '& ."MuiTypography-root': {
    fontFamily: "'Public Sans', sans-serif",
    fontWeight: 600,
    fontSize: '2rem',
  },
}));

function DrawerContent({ open }) {
  return (
    <DrawerWrapper>
      <img src={open ? DrawerLogoOpen : DrawerLogoClose} height="40px" />
    </DrawerWrapper>
  );
}

DrawerContent.propTypes = {
  open: PropTypes.bool.isRequired,
};

export default DrawerContent;
