import { useEffect } from 'react';
import { compose } from '@reduxjs/toolkit';
import EditIcon from '@mui/icons-material/Edit';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import Error from 'core/errors/Error';
import Button from 'core/buttons/Button';
import withAppState from 'hoc/withAppState';
import PageContent from 'layouts/PageContent';
import ApiConfig from 'components/api-configs/ApiConfig';

import { resetGetApiConfigFailure } from 'app/features/api-configs/apiConfigSlice';
import { buildRoute } from 'routes';
import NewPageContent from 'layouts/NewPageContent';
import routePaths from 'app/config/routePaths';
import ActionButton from 'core/buttons/ActionButton';

function ViewApiConfig({ isNetworkError }) {
  const params = useParams();

  const navigate = useNavigate();

  const { isError, errorCode, errorMessage } = useSelector((state) => state.apiConfigs.show);

  const dispatch = useDispatch();

  useEffect(() => {
    if (isError) dispatch(resetGetApiConfigFailure());
  }, [isNetworkError]);

  if (isError)
    return (
      <Error
        status={errorCode || 0}
        message={errorMessage}
        onRetry={() => dispatch(resetGetApiConfigFailure())}
      />
    );

  return (
    <NewPageContent
      breadcrumbs={[
        {
          label: 'API Configs',
          to: routePaths.ListApiConfigs,
        },
        {
          label: 'API Config Details',
          to: '',
        },
      ]}
      documentTitle="API Config Details"
      rightTopEndContent={
        <ActionButton onClick={() => navigate(buildRoute('api-configs.edit', params.apiConfigId))}>
          Edit API Config
        </ActionButton>
      }
    >
      <ApiConfig apiConfigId={params.apiConfigId} />
    </NewPageContent>
  );
}

export default compose(withAppState)(ViewApiConfig);
