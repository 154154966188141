import React from 'react';

const ExpiredStatusIcon = (props) => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M0 20C0 8.95431 8.95431 0 20 0C31.0457 0 40 8.95431 40 20C40 31.0457 31.0457 40 20 40C8.95431 40 0 31.0457 0 20Z"
        fill="#BCC3E3"
      />
      <path
        d="M20 20C22.7614 20 25 17.7614 25 15C25 12.2386 22.7614 10 20 10C17.2386 10 15 12.2386 15 15C15 17.7614 17.2386 20 20 20Z"
        stroke="white"
      />
      <path
        opacity="0.4"
        d="M11.41 30C11.41 26.13 15.26 23 20 23C20.96 23 21.89 23.13 22.76 23.37"
        stroke="white"
      />
      <path
        d="M30 26C30 26.32 29.96 26.63 29.88 26.93C29.79 27.33 29.63 27.72 29.42 28.06C28.73 29.22 27.46 30 26 30C24.97 30 24.04 29.61 23.34 28.97C23.04 28.71 22.78 28.4 22.58 28.06C22.21 27.46 22 26.75 22 26C22 24.92 22.43 23.93 23.13 23.21C23.86 22.46 24.88 22 26 22C27.18 22 28.25 22.51 28.97 23.33C29.61 24.04 30 24.98 30 26Z"
        stroke="white"
      />
      <path d="M27.49 25.98H24.51" stroke="white" />
    </svg>
  );
};

export default ExpiredStatusIcon;
