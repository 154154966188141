import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';

import SettingItem from '../components/SettingItem';
import SectionTitle from 'core/section/SectionTitle';

import app from 'app/config/app';

function AboutSettingTab() {
  let [activeSetting, setActiveSetting] = useState(null);

  let _settingItems = [
    {
      key: 'about',
      label: 'App Version',
      content: app.version,
      contentSelected: '',
      actions: '',
    },
  ];

  return (
    <Box sx={{ px: 4 }}>
      <SectionTitle>About</SectionTitle>
      <Stack sx={{ mt: 2 }} divider={<Divider orientation="vertical" flexItem />}>
        {_settingItems.map((item) => (
          <SettingItem
            key={item.key}
            labelPrimary={item.label}
            content={activeSetting === item.key ? item.contentSelected : item.content}
            actions={item.actions}
            isActive={activeSetting === item.key}
          />
        ))}
      </Stack>
    </Box>
  );
}

export default AboutSettingTab;
