import React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Masonary from '@mui/lab/Masonry';
import Typography from '@mui/material/Typography';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import SourceDetails from 'core/source-detail/SourceDetails';
import useSourceDetail from 'core/source-detail/useSourceDetail';
import TableContainerWrapper from 'core/table/TableContainerWrapper';

const Role = ({ roleId }) => {
  const viewRolesDefinition = useSourceDetail([
    {
      title: '',
      items: [
        {
          label: 'Role Name',
          accessorKey: 'name',
        },
        {
          label: 'Role Type',
          accessorKey: 'roleType',
        },
        {
          label: 'Role Description',
          accessorKey: 'description',
        },
        {
          label: 'Role Status',
          accessorKey: 'status',
        },
      ],
    },
  ]);

  return (
    <Grid container spacing={2}>
      {/* <Grid item xs={12}>
        <SourceDetails data={finalData} definition={viewRolesDefinition} />
      </Grid>
      <Grid item xs={12}>
        <TableContainerWrapper>
          <Masonary columns={{ xs: 1, md: 2 }}>
            {finalData?.modules?.map((module) => {
              const allPermissionDisabled = module?.entities?.every((entities) => {
                return entities?.permissions?.every((permissions) => !permissions?.allowed);
              });
              if (allPermissionDisabled) return null;
              return (
                <Box
                  key={module.code}
                  sx={{
                    height: 'fit-content',
                    display: allPermissionDisabled ? 'none' : 'block',
                  }}
                >
                  <Typography variant="body2" title={module?.name}>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        flexWrap: 'wrap',
                        gap: '12px',
                        alignItems: 'stretch',
                      }}
                    >
                      {module?.entities?.map((entities) => {
                        const allPermissionDisabled = entities?.permissions?.every(
                          (permissions) => !permissions?.allowed,
                        );
                        const isSingleEntityAllFalse = entities?.permissions?.every(
                          (permission) => !permission?.allowed,
                        );
                        return (
                          <Box
                            key={entities?.code}
                            sx={(theme) => ({
                              flexBasis: isSingleEntityAllFalse ? '100%' : 'calc(50% - 8px)',
                              flexGrow: 1,
                              padding: '8px',
                              border: `1px solid ${theme.palette.divider}`,
                              borderRadius: '8px',
                              display: allPermissionDisabled ? 'none' : 'block',
                            })}
                          >
                            <Box display="flex" flexDirection="column" gap={1}>
                              <Typography fontSize={16} fontWeight={600}>
                                {entities?.name}
                              </Typography>
                              <Box
                                display="flex"
                                flexDirection="column"
                                sx={{
                                  flexWrap: 'wrap',
                                  gap: 2,
                                }}
                              >
                                {entities?.permissions?.map((permission) => {
                                  return (
                                    <Box
                                      display="flex"
                                      flexDirection="row"
                                      key={permission?.code}
                                      sx={(theme) => ({
                                        gap: '5px',
                                        alignItems: 'center',
                                        padding: '4px 6px',
                                        borderRadius: '20px',
                                        border: `1px solid ${theme.palette.divider}`,
                                        display: permission?.allowed ? 'flex' : 'none',
                                      })}
                                    >
                                      <CheckCircleIcon
                                        sx={(theme) => ({
                                          fontSize: 12,
                                          color: theme.palette.success.main,
                                        })}
                                      />
                                      <Typography fontSize={10} fontWeight={600}>
                                        {permission?.name}
                                      </Typography>
                                    </Box>
                                  );
                                })}
                              </Box>
                            </Box>
                          </Box>
                        );
                      })}
                    </Box>
                  </Typography>
                </Box>
              );
            })}
          </Masonary>
        </TableContainerWrapper>
      </Grid> */}
    </Grid>
  );
};

export default Role;
