import { useEffect } from 'react';
import { compose } from '@reduxjs/toolkit';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import withRole from 'hoc/withRole';
import Error from 'core/errors/Error';
import withAppState from 'hoc/withAppState';
import Webhooks from 'components/webhooks/Webhooks';
import WebhookFilterValues from 'components/webhooks/WebhookFilterValues';
import { resetGetWebhooksFailure } from 'app/features/webhooks/webhookSlice';

import acl from 'app/config/acl';
import { buildRoute } from 'routes';
import NewPageContent from 'layouts/NewPageContent';
import ActionButton from 'core/buttons/ActionButton';
import WebhookPreviewDetailsModal from 'components/webhooks/WebhookPreviewDetailsModal';

function ListWebhook({ isNetworkError }) {
  const webhookState = useSelector((state) => state.webhooks.index);

  const { isError, errorCode } = webhookState;

  const dispatch = useDispatch();

  const navigate = useNavigate();

  useEffect(() => {
    if (isError) dispatch(resetGetWebhooksFailure());
  }, [isNetworkError]);

  if (isError)
    return (
      <Error
        status={errorCode}
        withInDashboard={true}
        onRetry={() => dispatch(resetGetWebhooksFailure())}
      />
    );

  return (
    <NewPageContent
      breadcrumbs={[
        {
          label: 'Webhooks',
          to: '',
        },
      ]}
      documentTitle="Webhooks"
      rightTopEndContent={
        <ActionButton onClick={() => navigate(buildRoute('webhooks.create'))}>
          Add Webhook
        </ActionButton>
      }
    >
      <WebhookFilterValues />
      <Webhooks />
      <WebhookPreviewDetailsModal />
    </NewPageContent>
  );
}

export default compose(withAppState, withRole([acl.SUPERADMIN, acl.ADMIN]))(ListWebhook);
