import React from 'react';
import PropTypes from 'prop-types';
import PartnerCountries from 'components/partner-countries/PartnerCountries';
import PartnerCountryPreviewDetailsModal from 'components/partner-countries/PartnerCountryPreviewDetailsModal';

export default function PartnerCountriesTab({ partnerId }) {
  return (
    <>
      <PartnerCountries preFilterByPartner={true} partnerId={partnerId} />
      <PartnerCountryPreviewDetailsModal />
    </>
  );
}

PartnerCountriesTab.propTypes = {
  partnerId: PropTypes.string.isRequired,
};
