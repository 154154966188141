import { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import MuiDrawer from '@mui/material/Drawer';
import styled from '@mui/material/styles/styled';
import ListSubheader from '@mui/material/ListSubheader';

import DropdownList from 'core/list/DropdownList';
import ListItemLink from 'core/list/ListItemLink';
import SearchBox from 'core/forms/SearchBox';

import Auth from 'utils/Auth';
import app from 'app/config/app';
import isEmpty from 'helpers/isEmpty';
import useMediaQuery from '@mui/material/useMediaQuery';
import SearchIcon from '@mui/icons-material/Search';

import { setIsDrawerOpen } from 'app/features/app/appSlice';
import { localStorageSave } from 'helpers/localStorage';
import { useDispatch } from 'react-redux';
import constants from 'app/config/constants';
import debounce from 'helpers/debounce';

const openedMixin = (theme) => ({
  width: app.drawerWith,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',

  '& .MuiListItemIcon-root': {},
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  width: `calc(${theme.spacing(7)} + 1px)`,
  '& .MuiListItemIcon-root': {},
  '& .MuiListItemText-root': {
    display: 'none',
  },
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',

  '& .MuiPaper-root': {
    background: 'linear-gradient(176.5deg, #3387ED 0.12%, #0D4992 100%)',
    boxShadow: theme.shadows[2],
    borderRadius: 12,
    margin: 10,
    height: '98%',
  },

  '& div:first-of-type': {
    '&::-webkit-scrollbar': {
      width: '6px',
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: theme.palette.background.paper,
    },
    '&::-webkit-scrollbar-thumb': {
      background: theme.palette.grey[500],
      borderRadius: '6px',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: theme.palette.grey[600],
      cursor: 'pointer',
    },
  },
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': {
      ...openedMixin(theme),
    },
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': {
      ...closedMixin(theme),
    },
  }),
}));

function Sidebar({ open, sidebarItems, appBar }) {
  const [activeDropdownKey, setActiveDropdownKey] = useState(null);
  const [menuSearchQuery, setMenuSearchQuery] = useState('');
  const dispatch = useDispatch();

  const isScreenSmaller = useMediaQuery((theme) => theme.breakpoints.down('md'));

  const doesItemMatchSearch = (item) => {
    return (
      item.name.toLowerCase().includes(menuSearchQuery.toLowerCase()) ||
      item.children?.some((child) => doesItemMatchSearch(child))
    );
  };

  const handleSetActiveDropdownKey = (key) => {
    setActiveDropdownKey((prevKey) => (prevKey === key ? null : key));
  };

  const handleFilterItems = (e) => {
    setMenuSearchQuery(e.target.value);
  };

  const handleDrawerOpen = () => {
    localStorageSave(constants.IS_DRAWER_OPEN, true.toString());
    dispatch(setIsDrawerOpen(true));
  };

  const filterItems = sidebarItems
    ?.map((item) => {
      if (item.type === 'dropdown') {
        const filteredChildren = item.children.filter(
          (child) =>
            doesItemMatchSearch(child) ||
            child.name.toLowerCase().includes(menuSearchQuery.toLowerCase()),
        );

        if (
          item.name.toLowerCase().includes(menuSearchQuery.toLowerCase()) ||
          filteredChildren.length > 0
        ) {
          return { ...item, children: filteredChildren };
        }
        return null;
      } else if (item.type === 'link' || item.type === 'sub_header') {
        return item.name.toLowerCase().includes(menuSearchQuery.toLowerCase()) ? item : null;
      }
      return item;
    })
    .filter(Boolean);

  const items = filterItems.map((item, key) => {
    switch (item.type) {
      case 'link':
        if (!isEmpty(item?.roles) && !item?.roles?.includes(Auth.role()))
          return <Fragment key={key}></Fragment>;
        return (
          <Box
            sx={{
              ...(open && { marginX: 1 }),
            }}
            key={key}
          >
            <ListItemLink
              isDrawerOpen={open}
              button
              icon={item.icon}
              primary={item.name}
              to={item.path}
              isActive={item.isActive ?? false}
              sx={{
                ...(open && {
                  borderRadius: 3,
                }),
              }}
            />
          </Box>
        );
      case 'dropdown': {
        let roles = item.children.length
          ? item.children.map((i) => i.roles || []).reduce((a, i) => a.concat(i), [])
          : [];
        roles = [...new Set(roles)];

        if (!isEmpty(roles) && !roles.includes(Auth.role())) return <Fragment key={key}></Fragment>;

        const showDropdownOpen =
          menuSearchQuery.length > 0 &&
          item.children.some((child) =>
            child.name.toLowerCase().includes(menuSearchQuery.toLowerCase()),
          );

        const isOpen = activeDropdownKey === key || showDropdownOpen;

        return (
          <DropdownList
            isDrawerOpen={open}
            key={key}
            label={item.name}
            icon={item.icon}
            open={isOpen}
            onClick={() => handleSetActiveDropdownKey(key)}
            isActive={item.isActive ?? false}
            items={item.children}
          />
        );
      }
      case 'divider':
        return <Divider key={key} />;
      case 'sub_header':
        return (
          <ListSubheader component="div" key={key}>
            {item?.name}
          </ListSubheader>
        );
      default:
        return <Fragment key={key}></Fragment>;
    }
  });

  return (
    <Drawer id="sidebar" variant="permanent" open={open}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 1,
          position: 'sticky',
          top: 0,
          zIndex: 1,
          background: '#3184E8',
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: 1 }}>
          <Box sx={{ flex: 1 }}>{appBar}</Box>
        </Box>

        {!isScreenSmaller &&
          (open ? (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginX: 1,
              }}
            >
              <SearchBox
                sx={{
                  borderRadius: 2,
                  width: '100%',
                }}
                placeholder="Search Menu"
                value={menuSearchQuery}
                onChange={handleFilterItems}
                onClickClearSearch={() => setMenuSearchQuery('')}
              />
            </Box>
          ) : (
            <SearchIcon
              onClick={handleDrawerOpen}
              sx={{
                marginX: 1,
                marginTop: 1,
                backgroundColor: (theme) => theme.palette.common.white,

                border: (theme) => `1px solid ${theme.palette.grey[500]}`,
                padding: 1,
                fill: (theme) => theme.palette.grey[600],
                borderRadius: '8px',
                cursor: 'pointer',
                fontSize: 40,
                '&:hover': {
                  color: (theme) => theme.palette.primary.main,
                },
              }}
            />
          ))}
      </Box>

      {filterItems.length === 0 && (
        <Box alignItems="center" textAlign="center" justifyContent="center" color="common.white">
          No menu items found
        </Box>
      )}

      <List>{items}</List>
    </Drawer>
  );
}

export default Sidebar;

Sidebar.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.func,
  ]),
  open: PropTypes.bool,
  onDrawerClose: PropTypes.func,
  sidebarItems: PropTypes.arrayOf(PropTypes.object),
  appBar: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node, PropTypes.func]),
};

Sidebar.defaultProps = {
  open: false,
  onDrawerClose: () => {},
  drawerWidth: app.drawerWith,
  sidebarItems: [],
};
