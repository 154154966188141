import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import withRole from 'hoc/withRole';
import Error from 'core/errors/Error';
import PageContent from 'layouts/PageContent';
import AccountForm from 'components/accounts/AccountForm';
import ActionStatusModal from 'core/modal/ActionStatusModal';
import AccountFormSkeleton from 'components/accounts/AccountFormSkeleton';
import AccountProgressIndicatorModal from 'components/accounts/AccountProgressIndicatorModal';

import acl from 'app/config/acl';
import { buildRoute } from 'routes';
import ucwords from 'helpers/ucwords';
import { editAccount } from 'app/features/accounts/accountSaga';
import { setShowEditProcessingModal, updateAccountReset } from 'app/features/accounts/accountSlice';

function EditAccount() {
  const params = useParams();

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const {
    data: account,
    isError,
    errorMessage,
    isLoading,
    errorCode,
  } = useSelector((state) => state.accounts.edit);

  const { showProcessingModal, isUpdating, isUpdated, isFailed } = useSelector(
    (state) => state.accounts.update,
  );

  useEffect(() => {
    dispatch(editAccount(params.accountId));
  }, [params.accountId]);

  if (isError)
    return (
      <Error
        status={errorCode}
        message={errorMessage}
        onRetry={() => dispatch(editAccount(params.accountId))}
      />
    );

  return (
    <PageContent title="Edit Account">
      {isLoading ? <AccountFormSkeleton /> : <AccountForm isAddMode={false} account={account} />}
      {/* <AccountProgressIndicatorModal isAddMode={false} />
       */}

      <ActionStatusModal
        showModal={showProcessingModal}
        closeModal={() => dispatch(setShowEditProcessingModal(false))}
        data={account}
        isSuccess={isUpdated}
        isProcessing={isUpdating}
        isError={isFailed}
        errorMessage={ucwords(errorMessage) ?? 'Failed To Update Account'}
        successMessage="Account Updated Successfully"
        navigateToView={() =>
          navigate(buildRoute('accounts.show', account.user_id ?? params.accountId))
        }
        resetForm={() => dispatch(updateAccountReset())}
      />
    </PageContent>
  );
}

export default withRole([acl.SUPERADMIN, acl.ADMIN])(EditAccount);
