import { useMemo } from 'react';
import Grid from '@mui/material/Grid';
import { useSelector } from 'react-redux';

import FormContainer from 'core/forms/FormContainer';
import GroupedTableView from 'core/table/GroupedTableView';
import useSourceDetail from 'core/source-detail/useSourceDetail';
import SourceDetails from 'core/source-detail/SourceDetails';

export default function PartnerDetails() {
  const { isLoading, data: partner } = useSelector((state) => state.partners.show);

  const viewPartnerData = useMemo(
    () => [
      {
        groupName: 'Basic Information',
        groupData: [
          {
            label: 'Partner Name',
            data: 'name',
          },
          {
            label: 'Postal Code',
            data: 'post_code',
          },
          {
            label: 'Address',
            data: 'address',
          },
          {
            label: 'City',
            data: 'city',
          },
          {
            label: 'State',
            data: 'state',
          },
          {
            label: 'Country',
            data: 'country',
          },
          {
            label: 'Currency',
            data: 'currency',
          },
        ],
      },
      {
        groupName: 'Contact Person Details',
        groupData: [
          {
            label: 'Name',
            data: 'cnt_name',
          },
          {
            label: 'Email',
            data: 'cnt_email',
          },
          {
            label: 'Phone Number',
            data: 'cnt_phone_number',
          },
          {
            label: 'Mobile Number',
            data: 'cnt_mobile_number',
          },
        ],
      },
    ],
    [],
  );

  const viewPartnerDetails = useSourceDetail([
    {
      title: 'Basic Information',
      items: [
        { label: 'Partner Name', accessorKey: 'name' },
        { label: 'Postal Code', accessorKey: 'post_code' },
        { label: 'Address', accessorKey: 'address' },
        { label: 'City', accessorKey: 'city' },
        { label: 'State', accessorKey: 'state' },
        { label: 'Country', accessorKey: 'country' },
        { label: 'Currency', accessorKey: 'currency' },
      ],
    },
    {
      title: 'Contact Person Details',
      items: [
        { label: 'Name', accessorKey: 'cnt_name' },
        { label: 'Email', accessorKey: 'cnt_email' },
        { label: 'Phone Number', accessorKey: 'cnt_phone_number' },
        { label: 'Mobile Number', accessorKey: 'cnt_mobile_number' },
      ],
    },
  ]);

  return (
    <SourceDetails
      viewMode="column"
      rowMode="row"
      definition={viewPartnerDetails}
      data={partner}
      isLoading={isLoading}
    />
  );
}
