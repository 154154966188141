import PropTypes from 'prop-types';
import IconButton from '@mui/material/IconButton';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import { styled } from '@mui/material/styles';

export default function ReloadButton({ color, size, disabled, sx, ...rest }) {
  return (
    <IconButton
      disableRipple
      color={color}
      size={size}
      disabled={disabled}
      sx={{
        width: 40,
        backgroundColor: (theme) => theme.palette.info.lighter,
        transition: 'background-color 0.3s ease-in-out, color 0.3s ease-in-out',

        '&:hover': {
          backgroundColor: (theme) => theme.palette.primary.main,
          color: (theme) => theme.palette.common.white,
          border: 'none',
          outline: 'none',

          '& .MuiSvgIcon-root': {
            color: (theme) => theme.palette.common.white,
          },
        },
        '& .MuiButtonBase-root': {
          ripple: 'none',
        },
      }}
      {...rest}
    >
      <AutorenewIcon fontSize={size} />
    </IconButton>
  );
}

ReloadButton.propTypes = {
  color: PropTypes.oneOf([
    'inherit',
    'primary',
    'secondary',
    'success',
    'error',
    'info',
    'warning',
  ]),
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  disabled: PropTypes.bool,
  sx: PropTypes.object,
};

ReloadButton.defaultProps = {
  size: 'small',
  color: 'primary',
  disabled: false,
};
