import { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import { useDispatch, useSelector } from 'react-redux';

import ActiveBadge from 'core/badges/ActiveBadge';
import VerifiedBadge from 'core/badges/VerifiedBadge';
import GroupedTableView from 'core/table/GroupedTableView';
import PartnerClickAwayDetails from 'components/partners/PartnerClickAwayDetails';

import Auth from 'utils/Auth';
import isEmpty from 'helpers/isEmpty';
import DateUtils from 'utils/DateUtils';
import { getPartnerCountry } from 'app/features/partner-countries/partnerCountrySaga';
import useCountries from 'hooks/useCountries';
import useSourceDetail from 'core/source-detail/useSourceDetail';
import SourceDetails from 'core/source-detail/SourceDetails';

export default function PartnerCountry({ partnerCountryId }) {
  const dispatch = useDispatch();

  const { getCountryNameByIso3Code } = useCountries();

  const viewPartnerCountryData = useMemo(
    () => [
      {
        groupName: '',
        groupData: [
          ...(Auth.isSuperAdmin()
            ? [
                {
                  label: 'Partner',
                  data: 'partner_id',
                  get: ({ partner_id, partner_name }) => (
                    <PartnerClickAwayDetails partnerId={partner_id} label={partner_name} />
                  ),
                },
              ]
            : []),
          {
            label: 'Country',
            get: ({ iso3 }) => getCountryNameByIso3Code(iso3),
          },
          {
            label: 'Currency',
            data: 'currency',
          },
          {
            label: 'Is Active ?',
            data: 'is_active',
            get: ({ is_active }) => <ActiveBadge isActive={!!is_active} />,
          },
          {
            label: 'Created At',
            data: 'created_ts',
            get: ({ created_ts }) =>
              !isEmpty(created_ts) ? DateUtils.isoToString(created_ts) : '-',
          },
          {
            label: 'Created By',
            data: 'created_by',
          },
          {
            label: 'Updated At',
            data: 'updated_ts',
            get: ({ updated_ts }) =>
              !isEmpty(updated_ts) ? DateUtils.isoToString(updated_ts) : '-',
          },
          {
            label: 'Updated By',
            data: 'updated_by',
          },
        ],
      },
    ],
    [],
  );

  const viewPartnerCountryDataDetail = useSourceDetail([
    {
      title: 'Partner Country Details',
      items: [
        ...(Auth.isSuperAdmin()
          ? [
              {
                label: 'Partner',
                accessorKey: 'partner_id',
                cell: ({ partner_id, partner_name }) => (
                  <PartnerClickAwayDetails partnerId={partner_id} label={partner_name} />
                ),
              },
            ]
          : []),
        {
          label: 'Country',
          accessorKey: 'iso3',
          cell: ({ iso3 }) => getCountryNameByIso3Code(iso3),
        },
        { label: 'Currency', accessorKey: 'currency' },
        {
          label: 'Is Active?',
          accessorKey: 'is_active',
          cell: ({ is_active }) => <ActiveBadge isActive={!!is_active} />,
        },
        {
          label: 'Created At',
          accessorKey: 'created_ts',
          cell: ({ created_ts }) => (created_ts ? DateUtils.isoToString(created_ts) : '-'),
        },
        { label: 'Created By', accessorKey: 'created_by' },
        {
          label: 'Updated At',
          accessorKey: 'updated_ts',
          cell: ({ updated_ts }) => (updated_ts ? DateUtils.isoToString(updated_ts) : '-'),
        },
        { label: 'Updated By', accessorKey: 'updated_by' },
      ],
    },
  ]);

  const {
    isError,
    errorCode,
    isLoading,
    data: partnerCountry,
  } = useSelector((state) => state.partnerCountries.show);

  useEffect(() => {
    dispatch(getPartnerCountry(partnerCountryId));
  }, [partnerCountryId]);

  return (
    <SourceDetails
      rowMode="row"
      viewMode="column"
      definition={viewPartnerCountryDataDetail}
      data={partnerCountry}
      isLoading={isLoading}
    />
  );
}

PartnerCountry.propTypes = {
  partnerCountryId: PropTypes.string,
};
