import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import withRole from 'hoc/withRole';
import AccountForm from 'components/accounts/AccountForm';
import ActionStatusModal from 'core/modal/ActionStatusModal';

import acl from 'app/config/acl';
import { buildRoute } from 'routes';
import ucwords from 'helpers/ucwords';
import routePaths from 'app/config/routePaths';
import NewPageContent from 'layouts/NewPageContent';
import { setShowCreateProcessingModal, addAccountReset } from 'app/features/accounts/accountSlice';

function AddAccount() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();

  const webhookState = useSelector((state) => state.accounts.create);

  const {
    showProcessingModal,
    isCreating,
    isCreated,
    isFailed,
    errors,
    errorMessage,
    retries,
    data: account,
  } = webhookState;

  return (
    <NewPageContent
      breadcrumbs={[
        {
          label: 'Accounts',
          to: routePaths.ListAccounts,
        },
        {
          label: 'Add Account',
          to: '',
        },
      ]}
      documentTitle="Add Account"
    >
      <AccountForm isAddMode={true} />
      {/* <AccountProgressIndicatorModal isAddMode={true} /> */}
      <ActionStatusModal
        showModal={showProcessingModal}
        closeModal={() => dispatch(setShowCreateProcessingModal(false))}
        data={account}
        isSuccess={isCreated}
        isProcessing={isCreating}
        isError={isFailed}
        errorMessage={ucwords(errorMessage) ?? 'Failed To Update Account'}
        successMessage="Webhook Updated Successfully"
        navigateToView={() =>
          navigate(buildRoute('accounts.show', account.user_id ?? params.accountId))
        }
        resetForm={() => dispatch(addAccountReset())}
      />
    </NewPageContent>
  );
}

export default withRole([acl.SUPERADMIN, acl.ADMIN])(AddAccount);
