import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

import Webhooks from 'components/webhooks/Webhooks';
import WebhookPreviewDetailsModal from 'components/webhooks/WebhookPreviewDetailsModal';

export default function PartnerWebhooksTab({ partnerId }) {
  const navigate = useNavigate();

  return (
    <>
      <Webhooks preFilterByPartner={true} partnerId={partnerId} />
      <WebhookPreviewDetailsModal />
    </>
  );
}

PartnerWebhooksTab.propTypes = {
  partnerId: PropTypes.string.isRequired,
};
