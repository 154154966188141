import { useEffect } from 'react';
import { compose } from '@reduxjs/toolkit';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import Error from 'core/errors/Error';
import withAppState from 'hoc/withAppState';
import PageContent from 'layouts/PageContent';
import PartnerForm from 'components/partners/PartnerForm';
import PartnerFormSkeleton from 'components/partners/PartnerFormSkeleton';
import PartnerProgressIndicatorModal from 'components/partners/PartnerProgressIndicatorModal';

import acl from 'app/config/acl';
import withRole from 'hoc/withRole';
import { editPartner } from 'app/features/partners/partnerSaga';

import {
  retryToUpdate,
  setShowEditProcessingModal,
  updatePartnerReset,
} from 'app/features/partners/partnerSlice';
import ActionStatusModal from 'core/modal/ActionStatusModal';
import ucwords from 'helpers/ucwords';
import { buildRoute } from 'routes';

function EditPartner({ isNetworkError }) {
  const navigate = useNavigate();
  const params = useParams();

  const dispatch = useDispatch();

  const {
    data: partner,
    isError,
    errorMessage,
    isLoading,
    errorCode,
  } = useSelector((state) => state.partners.edit);

  const { showProcessingModal, isUpdating, isUpdated, isFailed, errors } = useSelector(
    (state) => state.partners.update,
  );

  useEffect(() => {
    dispatch(editPartner(params.partnerId));
  }, [params.partnerId, isNetworkError]);

  if (isError)
    return (
      <Error
        status={errorCode}
        message={errorMessage}
        onRetry={() => dispatch(editPartner(params.partnerId))}
      />
    );

  return (
    <PageContent title="Edit Partner">
      {isLoading ? <PartnerFormSkeleton /> : <PartnerForm isAddMode={false} partner={partner} />}
      <ActionStatusModal
        showModal={showProcessingModal}
        closeModal={() => dispatch(setShowEditProcessingModal(false))}
        data={partner}
        isSuccess={isUpdated}
        isProcessing={isUpdating}
        isError={isFailed}
        errorMessage={ucwords(errorMessage) ?? 'Failed To Update Partner'}
        successMessage="Partner Updated Successfully"
        retry={() => dispatch(retryToUpdate())}
        navigateToView={() => navigate(buildRoute('partners.show', partner?.id))}
        resetForm={() => dispatch(updatePartnerReset())}
      />
    </PageContent>
  );
}

export default compose(withAppState, withRole(acl.SUPERADMIN))(EditPartner);
