import { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import { useDispatch, useSelector } from 'react-redux';

import GroupedTableView from 'core/table/GroupedTableView';

import isEmpty from 'helpers/isEmpty';
import DateUtils from 'utils/DateUtils';
import { getApiConfig } from 'app/features/api-configs/apiConfigSaga';
import useSourceDetail from 'core/source-detail/useSourceDetail';
import SourceDetails from 'core/source-detail/SourceDetails';

export default function ApiConfig({ apiConfigId }) {
  const dispatch = useDispatch();

  const {
    isError,
    errorCode,
    isLoading,
    data: apiConfig,
  } = useSelector((state) => state.apiConfigs.show);

  useEffect(() => {
    dispatch(getApiConfig(apiConfigId));
  }, [apiConfigId]);

  const viewDetailColumn = useSourceDetail([
    {
      title: 'API Configuration Details',
      items: [
        { label: 'Partner Code', accessorKey: 'api_partner_code' },
        { label: 'Url', accessorKey: 'api_url' },
        { label: 'User ID', accessorKey: 'api_user_id' },
        { label: 'User Secret', accessorKey: 'api_user_secret' },
        { label: 'Ref1', accessorKey: 'ref1' },
        { label: 'Ref2', accessorKey: 'ref2' },
        { label: 'Ref3', accessorKey: 'ref3' },
        { label: 'Remarks', accessorKey: 'remarks' },
        {
          label: 'Created At',
          accessorKey: 'created_ts',
          cell: ({ data }) => (!isEmpty(data) ? DateUtils.isoToString(data) : '-'),
        },
      ],
    },
  ]);

  return (
    <SourceDetails
      viewMode="column"
      rowMode="row"
      definition={viewDetailColumn}
      data={apiConfig}
      isLoading={isLoading}
    />
  );
}

ApiConfig.propTypes = {
  apiConfigId: PropTypes.string,
};
