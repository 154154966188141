import { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import PartnerClickAwayDetails from 'components/partners/PartnerClickAwayDetails';

import Auth from 'utils/Auth';
import { buildRoute } from 'routes';
import { getCustomer } from 'app/features/customers/customerSaga';
import { hideCustomerBriefDetails } from 'app/features/customers/customerSlice';
import useSourceDetail from 'core/source-detail/useSourceDetail';
import SourceDetails from 'core/source-detail/SourceDetails';
import Modal from 'app/components/Modal';
import { Box, Typography } from '@mui/material';

function CustomerPreviewDetailsModal() {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const viewDetailColumn = useSourceDetail([
    {
      title: '',
      items: [
        ...(Auth.isSuperAdmin()
          ? [
              {
                label: 'Partner Id',
                accessorKey: 'partner_id',
                cell: ({ data }) => <PartnerClickAwayDetails partnerId={data} />,
              },
            ]
          : []),
        {
          label: 'First Name',
          accessorKey: 'first_name',
        },
        {
          label: 'Last Name',
          accessorKey: 'last_name',
        },
        {
          label: 'Email',
          accessorKey: 'email',
          cell: ({ email }) => (
            <Typography paddingRight={3} width="140px">
              {email}
            </Typography>
          ),
        },
        {
          label: 'Mobile Number',
          accessorKey: 'mobile_number',
        },
        {
          label: 'State',
          accessorKey: 'state',
        },
        {
          label: 'Country',
          accessorKey: 'country',
        },
      ],
    },
  ]);

  const state = useSelector((state) => state.customers.show);

  const { showBriefDetailModal, customerId, isLoading, data } = state;

  useEffect(() => {
    if (customerId) dispatch(getCustomer(customerId));
  }, [customerId]);

  if (!showBriefDetailModal && !customerId) return <></>;

  return (
    <Modal
      open={showBriefDetailModal ?? false}
      onClose={() => dispatch(hideCustomerBriefDetails())}
      title="Customer Details"
      handleFullDetails={() => (
        dispatch(hideCustomerBriefDetails()), navigate(buildRoute('customers.show', customerId))
      )}
    >
      <SourceDetails
        showContainerDesign={false}
        definition={viewDetailColumn}
        data={data}
        isLoading={isLoading}
        enableLabelColon={false}
      />
    </Modal>
  );
}

export default CustomerPreviewDetailsModal;
