import React, { useMemo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import PopoverButton from 'core/buttons/PopoverButton';
import ListItemButton from '@mui/material/ListItemButton';
import { useReactTable, getCoreRowModel } from '@tanstack/react-table';

import Clipboard from 'core/clipboard/Clipboard';
import FormCheckbox from 'core/forms/FormCheckbox';
import FinmoPayinsActions from './FinmoPayinsActions';
import FinmoPayinPagination from './FinmoPayinPagination';
import TanstackReactTable from 'core/table/TanstackReactTable';
import TableContainerWrapper from 'core/table/TableContainerWrapper';

import app from 'app/config/app';
import { buildRoute } from 'routes';
import constants from 'app/config/constants';
import { useConfirm } from 'core/mui-confirm';
import useQueryParams from 'hooks/useQueryParams';
import { getFinmoPayins } from 'app/features/finmo-payins/finmoPayinSaga';
import { getFinmoPayinBriefDetailsInit } from 'app/features/finmo-payins/finmoPayinSlice';

export default function FinmoPayins() {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const confirm = useConfirm();

  const finmoPayinsState = useSelector((state) => state.finmoPayins.index);

  const { params, data, isLoading } = finmoPayinsState;

  const [queryParams] = useQueryParams(app.ignoreFilterKeys);

  const [columnVisibility, setColumnVisibility] = useState({});

  const columns = useMemo(
    () => [
      {
        id: constants.TABLE_CHECKBOX_SELECT_CHECKBOX_ID,
        header: ({ table }) => (
          <FormCheckbox
            forTableHead={true}
            {...{
              checked: table.getIsAllRowsSelected(),
              indeterminate: table.getIsSomeRowsSelected(),
              onChange: table.getToggleAllRowsSelectedHandler(),
            }}
          />
        ),
        cell: ({ row }) => (
          <div>
            <FormCheckbox
              {...{
                checked: row.getIsSelected(),
                indeterminate: row.getIsSomeSelected(),
                onChange: row.getToggleSelectedHandler(),
              }}
            />
          </div>
        ),
      },
      {
        id: constants.TABLE_SERIAL_NUMBER_COLUMN_ID,
        header: 'SN',
        accessorFn: (row) => row.sn,
      },
      {
        header: 'Payin ID',
        cell: ({ row }) => <Clipboard content={row.original.payin_id} />,
      },
      {
        header: 'Method Name',
        accessorFn: (row) => row.payin_method_name,
      },
      {
        header: 'Category',
        accessorFn: (row) => row.payin_method_param?.payid_reference,
      },
      {
        header: 'Amount',
        accessorFn: (row) => row.amount + ' ' + row.currency,
      },
      {
        header: 'Reference',
        accessorFn: (row) => row.payin_method_category,
      },
      {
        header: 'Status',
        accessorFn: (row) => row.status,
      },
      {
        header: 'Paycode',
        accessorFn: (row) => row.pay_code?.text,
      },
      {
        header: 'Invoice ID',
        cell: ({ row }) => <Clipboard content={row.original.metadata?.invoice_id} />,
      },
      {
        id: constants.TABLE_ACTION_COLUMN_ID,
        header: 'Action',
        accessorFn: (row) => row.id,

        cell: (info) => {
          return (
            <PopoverButton>
              {({ onClose }) => (
                <>
                  <ListItemButton
                    onClick={() => {
                      dispatch(
                        getFinmoPayinBriefDetailsInit({
                          showBriefDetailModal: true,
                          finmoPayinId: info.row.original.payin_id,
                        }),
                      );
                      onClose();
                    }}
                  >
                    Preview
                  </ListItemButton>
                  <ListItemButton
                    onClick={() => {
                      navigate(buildRoute('finmo-payins.show', info.row.original.payin_id));
                      onClose();
                    }}
                  >
                    View Details
                  </ListItemButton>
                </>
              )}
            </PopoverButton>
          );
        },
      },
    ],
    [JSON.stringify(data)],
  );

  const table = useReactTable({
    data,
    columns,
    state: {
      columnVisibility,
    },
    onColumnVisibilityChange: setColumnVisibility,
    getCoreRowModel: getCoreRowModel(),
  });

  useEffect(() => {
    dispatch(getFinmoPayins({ ...queryParams, ...params }));
  }, [JSON.stringify(params)]);

  return (
    <TableContainerWrapper>
      <FinmoPayinsActions table={table} />
      <TanstackReactTable
        table={table}
        data={data}
        isLoading={isLoading}
        columnsCount={
          columns.length - Object.entries(columnVisibility ?? {}).filter(([k, v]) => !v).length
        }
      />
      <FinmoPayinPagination />
    </TableContainerWrapper>
  );
}

FinmoPayins.propTypes = {
  partnerId: PropTypes.string,
};
