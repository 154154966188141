import { useSelector, useDispatch } from 'react-redux';

import app from 'app/config/app';
import FilterValues from 'core/filter/FilterValues';

import {
  deleteFilterParams,
  resetParam,
} from 'app/features/funding-sources-info/fundingSourceInfoSlice';

export default function FundingSourcesInfoTableFilterValues() {
  const dispatch = useDispatch();

  const { params: appliedFilters } = useSelector((state) => state.fundingSourcesInfo.index);

  const handleOnRemoveFilter = (key) => {
    dispatch(deleteFilterParams(key));
  };

  const handleRemoveAllFilter = () => {
    dispatch(resetParam());
  };

  return (
    <FilterValues
      value={appliedFilters}
      onRemoveFilter={handleOnRemoveFilter}
      ignoreKeys={app.ignoreFilterKeys}
      RemoveAllFilter={handleRemoveAllFilter}
    />
  );
}
