import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import withRole from 'hoc/withRole';
import ActionStatusModal from 'core/modal/ActionStatusModal';
import LicensingPartnerForm from 'components/LicensingPartner/LicensingPartnerForm';

import acl from 'app/config/acl';
import { buildRoute } from 'routes';
import ucwords from 'helpers/ucwords';
import routePaths from 'app/config/routePaths';
import NewPageContent from 'layouts/NewPageContent';
import {
  retryToCreate,
  setShowCreateProcessingModal,
  addLicensingPartnerReset,
} from 'app/features/licensing-partners/licensingPartnerSlice';
import { getLicensingPartner } from 'app/features/licensing-partners/licensingPartnerSaga';

import { useEffect } from 'react';

function AddLicensingPartner() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();

  const {
    showProcessingModal,
    isCreating,
    isCreated,
    isFailed,
    errors,
    errorMessage,
    retries,
    data: licensingPartner,
  } = useSelector((state) => state.licensingPartners.create);

  return (
    <NewPageContent
      breadcrumbs={[
        { label: 'Licensing Partners', to: routePaths.ListLicensingPartners },
        { label: 'Add Licensing Partner', to: '' },
      ]}
      documentTitle="Add Licensing Partner"
    >
      <LicensingPartnerForm isAddMode={true} />

      <ActionStatusModal
        showModal={showProcessingModal}
        closeModal={() => dispatch(setShowCreateProcessingModal(false))}
        data={licensingPartner}
        isSuccess={isCreated}
        isProcessing={isCreating}
        isError={isFailed}
        retriesCount={retries}
        errorMessage={ucwords(errorMessage) ?? 'Failed To Create Licensing Partner'}
        successMessage="Licensing Partner Created Successfully"
        retry={() => dispatch(retryToCreate())}
        navigateToView={() =>
          navigate(
            buildRoute('licensing-partners.show', licensingPartner.id ?? params.licensingPartnerId),
          )
        }
        resetForm={() => dispatch(addLicensingPartnerReset())}
      />
    </NewPageContent>
  );
}

export default withRole([acl.SUPERADMIN, acl.ADMIN])(AddLicensingPartner);
