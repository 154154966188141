import { createSlice } from '@reduxjs/toolkit';

import app from 'app/config/app';
import isEmpty from 'helpers/isEmpty';
import Response from 'app/config/Response';

const initialState = {
  index: {
    data: [],
    params: {
      limit: app.perPage || 10,
      page: 1,
    },
    isLoading: false,
    totalCount: 0,
    totalPage: 0,
    isError: false,
    errorCode: null,
    errorMessage: null,
    isFilterOpen: false,
  },
  show: {
    showBriefDetailModal: false,
    finmoPayinId: null,
    isLoading: false,
    isError: false,
    errorCode: null,
    errorMessage: null,
    data: null,
  },
};

export const finmoPayinSlice = createSlice({
  name: 'finmoPayins',
  initialState,
  reducers: {
    getFinmoPayinsInit: (state) => {
      state.index = {
        ...state.index,
        isLoading: true,
        isError: false,
        errorCode: null,
        errorMessage: null,
      };
    },

    getFinmoPayinsSuccess: (state, { payload }) => {
      let data = payload?.data ?? [];

      // let { data, pagination } = payload;

      // const { totalCount, totalPage, currentPage, pageSize } = pagination;

      // let startSN = (currentPage - 1) * pageSize;

      let startSN = (state.index.params.page - 1) * state.index.params.limit;

      data = data?.map((item) => ({ sn: (startSN += 1), ...item }));

      state.index = {
        ...state.index,
        isLoading: false,
        isError: false,
        errorCode: null,
        errorMessage: null,
        data,
        // totalCount,
        // totalPage,
      };
    },

    getFinmoPayinsFailure: (state, { payload }) => {
      state.index = {
        ...state.index,
        isLoading: false,
        isError: true,
        errorCode: payload?.response?.status ?? 0,
        errorMessage: payload?.response?.message,
      };
    },

    resetGetFinmoPayinsFailure: (state) => {
      state.index = {
        ...state.index,
        isLoading: false,
        isError: false,
        errorCode: null,
        errorMessage: null,
      };
    },

    setParam: (state, { payload }) => {
      state.index.params = { ...state.index.params, ...payload };
    },

    resetParam: (state) => {
      state.index.params = {
        page_size: state.index.params.page_size,
        page_number: 1,
      };
    },

    deleteFilterParams: (state, { payload }) => {
      if (Array.isArray(payload)) payload.forEach((key) => delete state.index.params[key]);
      else delete state.index.params[payload];
    },

    getFinmoPayinInit: (state) => {
      state.show = {
        ...state.show,
        isLoading: true,
        isError: false,
        errorCode: null,
        errorMessage: null,
        data: null,
      };
    },

    getFinmoPayinSuccess: (state, { payload }) => {
      const data = payload?.data;
      if (isEmpty(data)) {
        state.show = {
          ...state.show,
          isLoading: false,
          isError: true,
          errorCode: Response.HTTP_NOT_FOUND,
          errorMessage: 'Finmo Payin not found',
        };
      } else {
        state.show = {
          ...state.show,
          isLoading: false,
          isError: false,
          errorCode: null,
          errorMessage: null,
          data,
        };
      }
    },

    getFinmoPayinFailure: (state, { payload }) => {
      state.show = {
        ...state.show,
        isLoading: false,
        isError: true,
        errorCode: payload?.response?.status ?? 0,
        errorMessage: payload?.response?.message,
        data: null,
      };
    },

    resetGetFinmoPayinFailure: (state) => {
      state.show = {
        ...state.show,
        isLoading: false,
        isError: false,
        errorCode: null,
        errorMessage: null,
        data: null,
      };
    },

    getFinmoPayinBriefDetailsInit: (state, { payload }) => {
      state.show = {
        ...state.show,
        showBriefDetailModal: payload.showBriefDetailModal,
        finmoPayinId: payload.finmoPayinId,
      };
    },

    hideFinmoPayinBriefDetails: (state) => {
      state.show = {
        showBriefDetailModal: false,
        finmoPayinId: null,
        isLoading: false,
        isError: false,
        errorCode: null,
        errorMessage: null,
        data: null,
      };
    },

    toggleFilter: (state) => {
      state.index.isFilterOpen = !state.index.isFilterOpen;
    },
  },
});

export const {
  getFinmoPayinsInit,
  getFinmoPayinsSuccess,
  getFinmoPayinsFailure,
  resetGetFinmoPayinsFailure,
  setParam,
  resetParam,
  deleteFilterParams,
  setShowCreateProcessingModal,
  setShowEditProcessingModal,
  getFinmoPayinInit,
  getFinmoPayinSuccess,
  getFinmoPayinFailure,
  resetGetFinmoPayinFailure,
  getFinmoPayinBriefDetailsInit,
  hideFinmoPayinBriefDetails,
  toggleFilter,
} = finmoPayinSlice.actions;

export default finmoPayinSlice.reducer;
