import { Fragment, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import List from '@mui/material/List';
import Popover from '@mui/material/Popover';
import Tooltip from '@mui/material/Tooltip';
import Collapse from '@mui/material/Collapse';
import CircleIcon from '@mui/icons-material/Circle';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { styled } from '@mui/material/styles';
import ListItem from './ListItem';
import ListItemLink from './ListItemLink';
import ListItemContexts from './ListItemContexts';
import { Divider } from '@mui/material';

const StyledDivider = styled(Divider)(({ theme, isActive }) => ({
  width: '1px',
  backgroundColor: isActive ? theme.palette.primary.main : theme.palette.grey[400],
  transition: 'background-color 0.3s ease',
}));

function DropdownList({
  isDrawerOpen,
  icon,
  label,
  children,
  open,
  onClick,
  isActive,
  items,
  ...rest
}) {
  const dropdownRef = useRef(null);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    console.log('ok');
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const openPopover = Boolean(anchorEl);
  const id = openPopover ? 'simple-popover' : undefined;

  if (isDrawerOpen)
    return (
      <Box paddingX={1} {...rest}>
        <ListItem
          sx={{
            borderRadius: 3,
            marginY: 1,
          }}
          button
          onClick={onClick}
          isActive={isActive}
          context={ListItemContexts.item}
        >
          {icon && <ListItemIcon sx={{ minWidth: '50px' }}>{icon}</ListItemIcon>}
          {label && <ListItemText primary={label} />}
          {open ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse ref={dropdownRef} in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {items.map((child, idx) => (
              <Box key={idx}>
                <ListItemLink
                  isDrawerOpen={true}
                  key={idx}
                  button
                  icon={<StyledDivider isActive={child.isActive ?? false} orientation="vertical" />}
                  primary={child.name}
                  to={child.path}
                  isActive={child.isActive ?? false}
                  context={ListItemContexts.dropdownItem}
                  sx={{
                    marginY: 0.4,
                  }}
                />
              </Box>
            ))}
          </List>
        </Collapse>
      </Box>
    );

  return (
    <Fragment {...rest}>
      <Tooltip title={label} placement="right">
        <Box
          sx={{
            marginY: 1,
            width: '45px',
            marginLeft: 0.6,
            borderRadius: 2,
          }}
        >
          <ListItem
            button
            aria-describedby={id}
            onClick={handleClick}
            isActive={isActive}
            context={ListItemContexts.item}
          >
            {icon && (
              <ListItemIcon
                sx={{
                  minWidth: '46px',
                }}
              >
                {icon}
              </ListItemIcon>
            )}
            {label && <ListItemText primary={label} />}
            {open ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
        </Box>
      </Tooltip>
      <Popover
        id={id}
        open={openPopover}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        sx={{ ml: '5px' }}
      >
        <Box
          sx={{
            paddingX: 1,
            paddingY: 1,
          }}
        >
          {items.map((child, idx) => (
            <ListItemLink
              isDrawerOpen={true}
              key={idx}
              button
              onClick={handleClose}
              // icon={<StyledDivider isActive={child.isActive ?? false} orientation="vertical" />}
              primary={child.name}
              to={child.path}
              isActive={child.isActive ?? false}
              context={ListItemContexts.dropdownItem}
              sx={{
                marginY: 0.5,

                '& .MuiListItemText-primary,': {
                  color: 'black',
                },

                '&:hover': {
                  '& .MuiListItemText-primary': {
                    color: 'black',
                  },
                },
              }}
            />
          ))}
        </Box>
      </Popover>
    </Fragment>
  );
}

export default DropdownList;

DropdownList.propTypes = {
  isDrawerOpen: PropTypes.bool,
  icon: PropTypes.node,
  label: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.func,
  ]),
  open: PropTypes.bool,
  onClick: PropTypes.func,
  isActive: PropTypes.bool,
  items: PropTypes.array,
};

DropdownList.defaultProps = {
  isDrawerOpen: false,
};
