import { createSlice } from '@reduxjs/toolkit';
import app from 'app/config/app';
import isEmpty from 'helpers/isEmpty';

const initialState = {
  index: {
    data: [],
    initialParams: {
      page_size: app.perPage || 10,
      page_number: 1,
      sort_by: 'created_ts',
      order_by: 'desc',
    },
    params: {
      page_size: app.perPage || 10,
      page_number: 1,
      sort_by: 'created_ts',
      order_by: 'desc',
    },
    isLoading: false,
    totalCount: 0,
    totalPage: 0,
    isError: false,
    errorCode: null,
    success: false,
    errorMessage: null,
  },
};

export const customersKycCountSlice = createSlice({
  name: 'customersKycCount',
  initialState,
  reducers: {
    getCustomersKycCountInit: (state) => {
      state.index.isLoading = true;
      state.index.isError = false;
      state.index.errorCode = null;
      state.index.errorMessage = null;
      state.index.data = [];
    },

    getCustomersKycCountSuccess: (state, { payload }) => {
      state.index.isLoading = false;
      state.index.isError = false;
      state.index.errorCode = null;
      state.index.errorMessage = null;
      state.index.data = payload;
    },

    getCustomersKycCountFailure: (state, { payload }) => {
      state.index.isLoading = false;
      state.index.isError = true;
      state.index.errorCode = payload.response?.status ?? 0;
      state.index.errorMessage = payload?.response?.message ?? 'Something went wrong';
    },

    resetCustomersKycCount: (state) => {
      state.index = initialState.index;
    },
  },
});

export const {
  getCustomersKycCountInit,
  getCustomersKycCountSuccess,
  getCustomersKycCountFailure,
  resetCustomersKycCount,
} = customersKycCountSlice.actions;

export default customersKycCountSlice.reducer;
