import PropTypes from 'prop-types';
import Stack from '@mui/material/Stack';
import styled from '@mui/material/styles/styled';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';

const Container = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100vh',
  width: '100vw',
}));

const LeftContainer = styled('div')(({ theme }) => ({
  marginRight: 40,
  backgroundColor: 'inherit',
  width: `calc(100vw/4.5)`,
  minWidth: '380px',
  alignItems: 'center',
  justifyContent: 'center',
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const RightContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flex: 1,
  justifyContent: 'center',
  alignItems: 'center',
}));

const BoxWrapper = styled(Box)(({ theme }) => ({
  margin: theme.spacing(2),
  padding: theme.spacing(5, 10),
  backgroundColor: theme.palette.primary.light,
  borderRadius: '32px',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
}));

export default function GuestContainer({ lTitle, lSubtitle, lImage, rChildren }) {
  const isMediumOrSmaller = useMediaQuery((theme) => theme.breakpoints.down('md'));

  return (
    <Container>
      <BoxWrapper>
        {!isMediumOrSmaller && (
          <LeftContainer>
            <Box gap={10}>
              {Object.prototype.toString.call(lImage) === '[object String]' ? (
                <img src={lImage} style={{ maxWidth: '350px' }} />
              ) : (
                lImage
              )}
            </Box>
          </LeftContainer>
        )}

        <RightContainer>{rChildren}</RightContainer>
      </BoxWrapper>
    </Container>
  );
}

GuestContainer.propTypes = {
  lTitle: PropTypes.string,
  lSubtitle: PropTypes.string,
  lImage: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  rChildren: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
};
