import { useSelector, useDispatch } from 'react-redux';

import app from 'app/config/app';
import FilterValues from 'core/filter/FilterValues';
import { deleteFilterParams, resetParam } from 'app/features/webhooks/webhookSlice';

export default function WebhookFilterValues() {
  const dispatch = useDispatch();

  const { params } = useSelector((state) => state.webhooks.index);

  const handleOnRemoveFilter = (key) => {
    dispatch(deleteFilterParams(key));
  };

  const handleRemoveAllFilter = () => {
    dispatch(resetParam());
  };

  return (
    <FilterValues
      value={params}
      onRemoveFilter={handleOnRemoveFilter}
      ignoreKeys={app.ignoreFilterKeys}
      RemoveAllFilter={handleRemoveAllFilter}
    />
  );
}
