import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import { useSelector, useDispatch } from 'react-redux';
import { useEffect, useState, useCallback } from 'react';
import FilterAltIcon from '@mui/icons-material/FilterAlt';

import Button from 'core/buttons/Button';
import ReloadButton from 'core/buttons/ReloadButton';
import ExportDocuments from 'core/lab/ExportDocuments';
import TableGridFilter from 'core/table/TableGridFilter';
import ColumnVisibility from 'core/table/ColumnVisibility';
import SelectPartner from 'components/partners/SelectPartner';
import TableActionsContainer from 'core/table/TableActionsContainer';

import Auth from 'utils/Auth';
import app from 'app/config/app';
import isEmpty from 'helpers/isEmpty';
import FilterType from 'app/config/FilterType';
import useQueryParams from 'hooks/useQueryParams';
import SelectCustomer from 'components/customers/SelectCustomer';
import {
  getBeneficiaries,
  getBeneficiariesDownloadReport,
} from 'app/features/beneficiaries/beneficiarySaga';
import {
  deleteFilterParams,
  setParam,
  resetBeneficiaryReportDownload,
} from 'app/features/beneficiaries/beneficiarySlice';
import FilterButton from 'core/buttons/FilterButton';

export default function BeneficiaryActions({ table }) {
  const dispatch = useDispatch();

  const [showFilter, setShowFilter] = useState(false);

  const beneficiaryState = useSelector((state) => state.beneficiaries.index);

  const [queryParams, updateQueryParams] = useQueryParams(app.ignoreFilterKeys);

  let availableFilters = [
    {
      label: 'country',
      value: 'country',
      type: FilterType.selectCountry,
    },
  ];

  const { params: appliedFilters, success, isFilterOpen } = beneficiaryState;

  const handleOnFilter = useCallback((data) => {
    dispatch(setParam({ ...data, page_number: 1 }));
  }, []);

  const handleOnReset = useCallback((e) => {
    dispatch(deleteFilterParams([...availableFilters.map((filter) => filter.value)]));
  });

  /**
   * If SUPERADMIN get partner id from query params
   * if not get from auth token
   */
  const partnerId = appliedFilters?.partner_id ?? Auth.getPartnerId();

  const handlePdfDownloadReport = () => {
    dispatch(
      getBeneficiariesDownloadReport({
        format: 'pdf',
      }),
    );
  };

  const handleExcelDownloadReport = () => {
    dispatch(
      getBeneficiariesDownloadReport({
        format: 'excel',
      }),
    );
  };

  useEffect(() => {
    if (success) {
      dispatch(resetBeneficiaryReportDownload());
    }
  }, [success, dispatch]);

  useEffect(() => {
    updateQueryParams(appliedFilters);
  }, [JSON.stringify(appliedFilters)]);

  useEffect(() => {
    dispatch(setParam(queryParams));
  }, [JSON.stringify(queryParams)]);

  return (
    <>
      <Box>
        <TableGridFilter
          showFilter={isFilterOpen}
          appliedFilters={appliedFilters}
          availableFilters={availableFilters}
          onFilter={handleOnFilter}
          onReset={handleOnReset}
        />
      </Box>
      <TableActionsContainer>
        <Box display="flex" alignItems="flex-start" gap={1}>
          {!isEmpty(partnerId) && (
            <SelectCustomer
              id="select-customer"
              onSelected={(value) => {
                if (!isEmpty(value)) {
                  dispatch(setParam({ user_id: value, page_number: 1 }));
                } else {
                  dispatch(deleteFilterParams('user_id'));
                }
              }}
              sx={{ width: '200px' }}
              partnerId={partnerId}
            />
          )}

          {Auth.isSuperAdmin() && (
            <SelectPartner
              id="select-partner"
              onSelected={(value) => {
                if (!isEmpty(value)) {
                  dispatch(setParam({ partner_id: value, page_number: 1 }));
                } else {
                  dispatch(deleteFilterParams(['partner_id', 'user_id']));
                }
              }}
              sx={{ width: '200px' }}
            />
          )}
        </Box>

        <Box display="flex" gap={1}></Box>

        <Box display="flex" gap={1}>
          {table && <ColumnVisibility table={table} />}
          <ReloadButton
            onClick={() => {
              dispatch(getBeneficiaries({ ...appliedFilters, page_number: 1 }));
            }}
          />

          <ExportDocuments
            onExcelDownload={handleExcelDownloadReport}
            onPdfDownload={handlePdfDownloadReport}
          />
        </Box>
      </TableActionsContainer>
    </>
  );
}

BeneficiaryActions.propTypes = {
  table: PropTypes.object,
};
