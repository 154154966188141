import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import { useSelector, useDispatch } from 'react-redux';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import React, { useEffect, useState, useCallback } from 'react';

import SearchBox from 'core/forms/SearchBox';
import ReloadButton from 'core/buttons/ReloadButton';
import ColumnVisibility from 'core/table/ColumnVisibility';
import TableActionsContainer from 'core/table/TableActionsContainer';

import debounce from 'helpers/debounce';
import FilterType from 'app/config/FilterType';
import useQueryParams from 'hooks/useQueryParams';
import TableGridFilter from 'core/table/TableGridFilter';
import ZaiWebhookIgnoreFilterKeys from './data/ZaiWebhookIgnoreFilterKeys';
import { getZaiWebhookLogs } from 'app/features/transactions/zaiTransactionSaga';
import {
  deleteWebhookLogsFilterParams,
  setWebhookLogsParam,
} from 'app/features/transactions/zaiTransactionSlice';
import { ZaiWebhookStatusOptions } from './data/ZaiWebhookStatus';
import FilterButton from 'core/buttons/FilterButton';

export default function ZaiWebhookLogsAction({ table }) {
  const dispatch = useDispatch();

  const [showFilter, setShowFilter] = useState(false);

  const ZaiTransctionState = useSelector((state) => state.zaiTransactions.webhookLogs);

  const [queryParams, updateQueryParams] = useQueryParams(ZaiWebhookIgnoreFilterKeys);

  let availableFilters = [
    {
      label: 'Transction ID',
      value: 'TransactionId',
      type: FilterType.input,
    },

    {
      label: 'User ID',
      value: 'UserId',
      type: FilterType.input,
    },
    {
      label: 'Status',
      value: 'Status',
      type: FilterType.select,
      options: ZaiWebhookStatusOptions,
    },
  ];

  const { params: appliedFilters, success, isFilterOpen } = ZaiTransctionState;

  const [search, setSearch] = useState(appliedFilters?.search ?? '');

  const handleOnFilter = useCallback((data) => {
    dispatch(setWebhookLogsParam({ ...data, page_number: 1 }));
  }, []);

  const handleOnSearch = useCallback((e) => {
    setSearch(e.target.value);
    debounce(() => {
      dispatch(setWebhookLogsParam({ search: e.target.value, page_number: 1 }));
    }, 500);
  }, []);

  const handleOnClearSearch = useCallback(() => {
    setSearch('');
    dispatch(deleteWebhookLogsFilterParams('search'));
  }, []);

  const handleOnReset = useCallback((e) => {
    dispatch(deleteWebhookLogsFilterParams([...availableFilters.map((filter) => filter.value)]));
  });

  useEffect(() => {
    updateQueryParams(appliedFilters);
    setSearch(appliedFilters?.search ?? '');
  }, [JSON.stringify(appliedFilters)]);

  useEffect(() => {
    dispatch(setWebhookLogsParam(queryParams));
  }, [JSON.stringify(queryParams)]);

  return (
    <>
      <Box>
        <TableGridFilter
          showFilter={isFilterOpen}
          appliedFilters={appliedFilters}
          availableFilters={availableFilters}
          onFilter={handleOnFilter}
          onReset={handleOnReset}
        />
      </Box>
      <TableActionsContainer>
        <SearchBox
          onChange={handleOnSearch}
          value={search || ''}
          onClickClearSearch={handleOnClearSearch}
          sx={{
            marginRight: 2,
          }}
        />

        <Box display="flex" gap={1}>
          {table && <ColumnVisibility table={table} />}
          {/* <DownloadButton onClick={() => {}} /> */}
          <ReloadButton
            onClick={() => {
              dispatch(getZaiWebhookLogs({ ...appliedFilters, page_number: 1 }));
            }}
          />
        </Box>
      </TableActionsContainer>
    </>
  );
}

ZaiWebhookLogsAction.propTypes = {
  table: PropTypes.object,
};
