import Box from '@mui/material/Box';
import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import { useCallback } from 'react';
import FilterAltIcon from '@mui/icons-material/FilterAlt';

import { getLicensingPartners } from 'app/features/licensing-partners/licensingPartnerSaga';
import TableActionsContainer from 'core/table/TableActionsContainer';
import TableGridFilter from 'core/table/TableGridFilter';
import ColumnVisibility from 'core/table/ColumnVisibility';

import FilterType from 'app/config/FilterType';
import { debounce } from 'redux-saga/effects';
import useQueryParams from 'hooks/useQueryParams';
import licensingPartnerIgnoreFilterKeys from 'pages/licensing-partners/data/licensingPartnerIgnoreFilterKeys';

import {
  deleteFilterParams,
  setParam,
} from 'app/features/licensing-partners/licensingPartnerSlice';
import SearchBox from 'core/forms/SearchBox';
import ReloadButton from 'core/buttons/ReloadButton';
import FilterButton from 'core/buttons/FilterButton';

export default function LicensingPartnerActions({ table }) {
  const dispatch = useDispatch();

  const [showFilter, setShowFilter] = useState(false);

  const licensingPartnerState = useSelector((state) => state.licensingPartners.index);

  const [queryParams, updateQueryParams] = useQueryParams(licensingPartnerIgnoreFilterKeys);

  let availableFilters = [
    // {
    //   label: '',
    //   value: 'search',
    //   type: FilterType.search,
    // },
    {
      label: 'Name',
      value: 'name',
      type: FilterType.input,
    },
    {
      label: 'Country',
      value: 'country',
      type: FilterType.selectCountry,
    },
    {
      label: 'Contact Email',
      value: 'cnt_email',
      type: FilterType.input,
    },
    {
      label: 'Contact Mobile No.',
      value: 'cnt_mobile_number',
      type: FilterType.input,
    },
  ];

  const { params: appliedFilters, success } = licensingPartnerState;

  const [search, setSearch] = useState(appliedFilters?.search ?? '');

  const handleOnFilter = useCallback((data) => {
    dispatch(setParam({ ...data, page_number: 1 }));
  }, []);

  const handleOnReset = useCallback((e) => {
    dispatch(deleteFilterParams([...availableFilters.map((filter) => filter.value)]));
  });
  const handleOnSearch = useCallback((e) => {
    setSearch(e.target.value);
    debounce(() => {
      dispatch(setParam({ search: e.target.value, page_number: 1 }));
    }, 500);
  }, []);

  const handleOnClearSearch = useCallback(() => {
    setSearch(' ');
    dispatch(deleteFilterParams('search'));
  });

  return (
    <>
      <Box>
        <TableGridFilter
          showFilter={showFilter}
          appliedFilters={appliedFilters}
          availableFilters={availableFilters}
          onFilter={handleOnFilter}
          onReset={handleOnReset}
        />
      </Box>

      <TableActionsContainer>
        <SearchBox
          onChange={handleOnSearch}
          value={search || ''}
          onClickClearSearch={handleOnClearSearch}
          sx={{
            marginRight: 2,
          }}
        />

        <Box display="flex" gap={1}>
          <FilterButton
            variant="outlined"
            onClick={() => setShowFilter(!showFilter)}
            uppercase
          />

          {table && <ColumnVisibility table={table} />}

          <ReloadButton
            onClick={() => {
              dispatch(
                getLicensingPartners({
                  ...appliedFilters,
                  page_number: 1,
                }),
              );
            }}
          />
        </Box>
      </TableActionsContainer>
    </>
  );
}
