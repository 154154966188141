import React from 'react';

const PendingStatusIcon = (props) => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M0 20C0 8.95431 8.95431 0 20 0C31.0457 0 40 8.95431 40 20C40 31.0457 31.0457 40 20 40C8.95431 40 0 31.0457 0 20Z"
        fill="#FFC14A"
      />
      <path
        d="M23.24 10H16.76C13 10 12.71 13.38 14.74 15.22L25.26 24.78C27.29 26.62 27 30 23.24 30H16.76C13 30 12.71 26.62 14.74 24.78L25.26 15.22C27.29 13.38 27 10 23.24 10Z"
        stroke="white"
      />
    </svg>
  );
};

export default PendingStatusIcon;
