import { useRef, useEffect } from 'react';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { compose } from '@reduxjs/toolkit';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, FormProvider } from 'react-hook-form';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

import Button from 'core/buttons/Button';
import TextButton from 'core/buttons/TextButton';
import FormContainer from 'core/forms/FormContainer';
import FormSelect from 'hook-form-controls/FormSelect';
import FormTextField from 'hook-form-controls/FormTextField';
import FormSelectPartner from 'hook-form-controls/FormSelectPartner';
import ValidationErrorMessages from 'core/errors/ValidationErrorMessages';

import Auth from 'utils/Auth';
import app from 'app/config/app';
import YupPassword from 'utils/YupPassword';
import WebhookObjectTypes from 'app/config/WebhookObjectTypes';
import { createWebhook, updateWebhook } from 'app/features/webhooks/webhookSaga';
import useQueryParams from 'hooks/useQueryParams';
import AuthUserIsSuperAdmin from 'components/auth/AuthUserIsSuperAdmin';
import ActionButton from 'core/buttons/ActionButton';
import ButtonContainer from 'core/buttons/ButtonContainer';

YupPassword(Yup);

const webhookSchema = Yup.object().shape({
  partner_id: Yup.string().when([], {
    is: () => Auth.isSuperAdmin(),
    then: Yup.string().required('Select a partner'),
  }),
  url: Yup.string().url().required('URL is required').max(100),
  object_type: Yup.string()
    .oneOf([...WebhookObjectTypes.map((option) => option.value)])
    .required('Object Type is required'),
  description: Yup.string().nullable().optional().max(50),
});

function WebhookForm({ isAddMode, webhook, retries, isCreated }) {
  const submitButtonRef = useRef(null);

  const [query] = useQueryParams();

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const methods = useForm({
    defaultValues: {
      ...(webhook ? webhook : {}),
      ...(isAddMode && query.partner_id
        ? {
            partner_id: query.partner_id,
          }
        : {}),
    },
    resolver: yupResolver(webhookSchema),
  });

  const {
    formState: { errors },
    handleSubmit,
    reset,
    getValues,
  } = methods;

  const onSubmit = async (data) => {
    dispatch(isAddMode ? createWebhook(data) : updateWebhook({ ...data, id: webhook.id }));
  };

  useEffect(() => {
    if (retries > 0 && submitButtonRef.current) submitButtonRef.current.click();
  }, [retries]);

  useEffect(() => {
    if (isCreated) reset({ partner_id: getValues('partner_id') });
  }, [isCreated]);

  return (
    <FormContainer>
      {app.isDevelopmentMode() && <ValidationErrorMessages errors={errors} />}
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container rowSpacing={3} columnSpacing={4}>
            <AuthUserIsSuperAdmin>
              <Grid item xs={12} sm={12}>
                <FormSelectPartner
                  id="select-partner"
                  name="partner_id"
                  label="Select Partner"
                  disabled={!isAddMode}
                />
              </Grid>
            </AuthUserIsSuperAdmin>
            <Grid item xs={12} sm={12} md={6}>
              <FormTextField name="url" label="URL" />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <FormSelect name="object_type" label="Object Type" options={WebhookObjectTypes} />
            </Grid>
            <Grid item sm={12}>
              <FormTextField name="description" label="Description" multiline />
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <ButtonContainer>
                <TextButton type="button" color="primary" onClick={() => navigate(-1)}>
                  Cancel
                </TextButton>
                <ActionButton
                  ref={submitButtonRef}
                  type="submit"
                  variant="contained"
                  endIcon={<ArrowForwardIcon />}
                >
                  {isAddMode ? 'Create' : 'Update'}
                </ActionButton>
              </ButtonContainer>
            </Grid>
          </Grid>
        </form>
      </FormProvider>
    </FormContainer>
  );
}

export default compose(connect(mapStateToProps))(WebhookForm);

function mapStateToProps(state, ownProps) {
  return {
    ...ownProps,
    retries: ownProps.isAddMode ? state.webhooks.create.retries : state.webhooks.update.retries,
    isCreated: state.webhooks.create.isCreated,
  };
}

WebhookForm.propTypes = {
  isAddMode: PropTypes.bool.isRequired,
  webhook: PropTypes.object,
  retries: PropTypes.number,
  isCreated: PropTypes.bool,
};
