import { useEffect } from 'react';
import { compose } from '@reduxjs/toolkit';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import withRole from 'hoc/withRole';
import Error from 'core/errors/Error';
import withAppState from 'hoc/withAppState';
import ActionButton from 'core/buttons/ActionButton';
import AuthUserIsSuperAdmin from 'components/auth/AuthUserIsSuperAdmin';
import PartnerCountries from 'components/partner-countries/PartnerCountries';
import PartnerCountryFilterValues from 'components/partner-countries/PartnerCountryFilterValues';
import PartnerCountryPreviewDetailsModal from 'components/partner-countries/PartnerCountryPreviewDetailsModal';

import Auth from 'utils/Auth';
import acl from 'app/config/acl';
import { buildRoute } from 'routes';
import NewPageContent from 'layouts/NewPageContent';
import { resetGetPartnerCountriesFailure } from 'app/features/partner-countries/partnerCountrySlice';
import PartnerCountryTableFilterValues from 'components/partner-countries/PartnerCountryTableFilterValues';

function ListPartnerCountry({ isNetworkError }) {
  const partnerCountryState = useSelector((state) => state.partnerCountries.index);

  const { isError, errorCode } = partnerCountryState;

  const dispatch = useDispatch();

  const navigate = useNavigate();

  useEffect(() => {
    if (isError) dispatch(resetGetPartnerCountriesFailure());
  }, [isNetworkError]);

  if (isError)
    return (
      <Error
        status={errorCode}
        withInDashboard={true}
        onRetry={() => dispatch(resetGetPartnerCountriesFailure())}
      />
    );

  return (
    <NewPageContent
      breadcrumbs={[
        {
          label: 'Partner Countries',
          to: '',
        },
      ]}
      documentTitle={Auth.isSuperAdmin() ? 'Partner Countries' : 'Countries'}
      rightTopEndContent={
        <AuthUserIsSuperAdmin>
          <ActionButton onClick={() => navigate(buildRoute('partner-countries.create'))}>
            Add Partner Country
          </ActionButton>
        </AuthUserIsSuperAdmin>
      }
    >
      <PartnerCountryFilterValues />
      <PartnerCountries />
      <PartnerCountryPreviewDetailsModal />
    </NewPageContent>
  );
}

export default compose(
  withAppState,
  withRole([acl.SUPERADMIN, acl.ADMIN, acl.USER]),
)(ListPartnerCountry);
