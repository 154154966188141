import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import { connect } from 'react-redux';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { compose } from '@reduxjs/toolkit';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import styled from '@mui/material/styles/styled';
import Typography from '@mui/material/Typography';
import RoomOutlinedIcon from '@mui/icons-material/RoomOutlined';
import MailOutlineOutlinedIcon from '@mui/icons-material/MailOutlineOutlined';

import Button from 'core/buttons/Button';
import Avatar from '@mui/material/Avatar';
import Skeleton from 'core/skeleton/Skeleton';

import { buildRoute } from 'routes';
import isEmpty from 'helpers/isEmpty';
import useCountries from 'hooks/useCountries';
import ucwords from 'helpers/ucwords';

const CustomerCardContainer = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  marginBottom: theme.spacing(2),
  display: 'flex',
  gap: theme.spacing(2),
  backgroundColor: 'inherit',
  // border: `1px solid ${theme.palette.grey[200]}`,
  borderRadius: 15,
  boxShadow: '1px 3px 6px 1px rgba(0,0,0,0.3)',
}));

const CustomerLogo = styled(Avatar)(({ theme }) => ({
  height: '80px',
  width: '80px',
  background: theme.palette.primary.soft,
  color: theme.palette.primary.main,
  fontWeight: theme.typography.fontWeightBold,
}));

const CustomerDetails = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
}));

const CustomerActions = styled(Box)(({ theme }) => ({
  display: 'flex',
  marginRight: 'auto',
  flexGrow: 1,
  alignSelf: 'baseline',
  justifyContent: 'end',
}));

function CustomerCard({ isLoading, customer }) {
  const { getCountryNameByIso3Code } = useCountries();

  const navigate = useNavigate();

  const params = useParams();

  if (isLoading)
    return (
      <CustomerCardContainer elevation={0}>
        <Skeleton variant="circular" sx={{ width: '80px', height: '80px' }} />

        <CustomerDetails sx={{ gap: 1 }}>
          <Skeleton sx={{ width: '250px', height: '32px', borderRadius: '4px' }} />

          <Stack
            flexDirection="row"
            alignItems="center"
            gap={1}
            sx={{ color: (theme) => theme.palette.primary.main }}
          >
            <Skeleton sx={{ width: '200px', height: '16px', borderRadius: '4px' }} />
          </Stack>
          <Stack
            flexDirection="row"
            alignItems="center"
            gap={1}
            sx={{ color: (theme) => theme.palette.primary.main }}
          >
            <Skeleton sx={{ width: '180px', height: '12px', borderRadius: '10px' }} />
          </Stack>
        </CustomerDetails>
        <CustomerActions>
          <Skeleton sx={{ width: '130px', height: '37px', borderRadius: '4px' }} />
        </CustomerActions>
      </CustomerCardContainer>
    );

  const customerName = [customer?.first_name, customer?.middle_name, customer?.last_name]
    .filter((v) => !isEmpty(v))
    .map((v) => ucwords(v))
    .join(' ');

  const customerShortName = customer?.first_name?.charAt(0) + customer?.last_name?.charAt(0);

  return (
    <CustomerCardContainer elevation={0}>
      <CustomerLogo variant="circular">{customerShortName}</CustomerLogo>
      <CustomerDetails sx={{ gap: 0.4 }}>
        <Typography variant="h5" fontWeight={800} letterSpacing={1.5}>
          {customerName}
        </Typography>
        <Box>
          <Stack
            flexDirection="row"
            alignItems="center"
            gap={1}
            sx={{ color: (theme) => theme.palette.grey[600] }}
          >
            <RoomOutlinedIcon sx={{ fontSize: 'inherit' }} />
            <Typography>
              {[customer.city, customer.state, getCountryNameByIso3Code(customer.country)]
                .filter((v) => !!v)
                .join(', ')}
            </Typography>
          </Stack>
          {customer?.post_code && (
            <Stack
              flexDirection="row"
              alignItems="center"
              gap={1}
              sx={{ color: (theme) => theme.palette.grey[600] }}
            >
              <MailOutlineOutlinedIcon sx={{ fontSize: 'inherit' }} />
              <Typography variant="subtitle2">Post Code - {customer.post_code}</Typography>
            </Stack>
          )}
        </Box>
      </CustomerDetails>
      {/* <CustomerActions>
        {isLoading ? (
          <Skeleton sx={{ width: '130px', height: '37px', borderRadius: '4px' }} />
        ) : (
          <Button onClick={() => navigate(buildRoute('customers.edit', params.customerId))}>
            Edit Customer
          </Button>
        )}
      </CustomerActions> */}
    </CustomerCardContainer>
  );
}

export default compose(connect(mapStateToProps))(CustomerCard);

CustomerCard.propTypes = {
  customer: PropTypes.object,
  isLoading: PropTypes.bool,
};

CustomerCard.defaultProps = {
  customer: {},
  isLoading: false,
};

function mapStateToProps(state, ownProps) {
  return {
    ...ownProps,
    ...(!Object.prototype.hasOwnProperty.call(ownProps, 'customer')
      ? {
          customer: state.customers.show.data || {},
        }
      : {}),
    ...(!Object.prototype.hasOwnProperty.call(ownProps, 'isLoading')
      ? {
          isLoading: state.customers.show.isLoading || isEmpty(state.customers.show.data),
        }
      : {}),
  };
}
