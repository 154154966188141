import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Badge from '@mui/material/Badge';
import Toolbar from '@mui/material/Toolbar';
import MuiAppBar from '@mui/material/AppBar';
import { styled } from '@mui/material/styles';
import MailIcon from '@mui/icons-material/Mail';
import MenuIcon from '@mui/icons-material/Menu';
import IconButton from '@mui/material/IconButton';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useSelector, useDispatch } from 'react-redux';

import AccountMenu from 'components/layouts/AccountMenu';

import app from 'app/config/app';
import { setIsDrawerOpen } from 'app/features/app/appSlice';
import { localStorageSave } from 'helpers/localStorage';
import constants from 'app/config/constants';
import { useEffect } from 'react';

const drawerWidth = 280;
const drawerOpenWidth = 280;
const drawerClosedWidth = 80;

function Topbar({ drawerWidth, children }) {
  const dispatch = useDispatch();

  const { isDrawerOpen: open } = useSelector((state) => state.app);

  const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
  })(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer,
    boxShadow: 'none',
    background: 'inherit',

    [theme.breakpoints.down('md')]: {
      marginLeft: `calc(${theme.spacing(8)} + 1px)`,
      width: `calc(100% - ${theme.spacing(8)})`,
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
      width: `86%`,
    },
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
    ...(!open && {
      [theme.breakpoints.up('sm')]: {
        marginLeft: `calc(${theme.spacing(8)} + 1px)`,
        width: `calc(100% - ${theme.spacing(8)})`,
      },
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
  }));

  const handleDrawerToggle = () => {
    localStorageSave(constants.IS_DRAWER_OPEN, (!open).toString());
    dispatch(setIsDrawerOpen(!open));
  };

  const handleDrawerClose = () => {
    localStorageSave(constants.IS_DRAWER_OPEN, false.toString());
    dispatch(setIsDrawerOpen(false));
  };

  const isScreenSmaller = useMediaQuery((theme) => theme.breakpoints.down('md'));

  const handleDrawerOnScreen = () => {
    if (isScreenSmaller && open) {
      handleDrawerClose();
    }
    handleDrawerToggle();
  };

  useEffect(() => {
    if (isScreenSmaller && open) {
      handleDrawerClose();
    }
  }, [isScreenSmaller, open]);

  return (
    <AppBar position="fixed" open={open} elevation={0}>
      <Toolbar
        sx={{
          marginY: 2,
          marginLeft: 3,
          marginRight: 2,
          borderRadius: 8,
          boxShadow: '10px 8px 10px 2px rgba(0, 0, 0, 0.06)',
          zIndex: 999,
          backgroundColor: (theme) => (isScreenSmaller ? 'none' : theme.palette.primary.light),
        }}
      >
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={handleDrawerOnScreen}
          edge="start"
          sx={{
            marginRight: '36px',
            ...(open && { display: 'none' }),
          }}
        >
          {!isScreenSmaller && (
            <MenuIcon
              sx={{
                color: 'black',
              }}
            />
          )}
        </IconButton>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          sx={{ width: '100%' }}
        >
          <Box>
            {open && (
              <MenuIcon
                sx={{ color: 'black', '&:hover': { cursor: 'pointer' } }}
                onClick={handleDrawerToggle}
              />
            )}
          </Box>

          <Box display="flex" gap="6">
            <AccountMenu />
          </Box>
        </Box>
      </Toolbar>
    </AppBar>
  );
}

export default Topbar;

Topbar.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.func,
  ]),
  open: PropTypes.bool,
  onDrawerOpen: PropTypes.func,
  drawerWidth: PropTypes.number,
};

Topbar.defaultProps = {
  children: null,
  open: false,
  onDrawerOpen: () => {},
  drawerWidth: app.drawerWith,
};
