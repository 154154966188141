import { buildRoute } from 'routes';
import { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import SourceDetails from 'core/source-detail/SourceDetails';
import useSourceDetail from 'core/source-detail/useSourceDetail';
import PartnerClickAwayDetails from 'components/partners/PartnerClickAwayDetails';

import Modal from 'app/components/Modal';
import useAuthUser from 'hooks/useAuthUser';
import useCountries from 'hooks/useCountries';
import { getPartnerCountry } from 'app/features/partner-countries/partnerCountrySaga';
import { hidePartnerCountryBriefDetails } from 'app/features/partner-countries/partnerCountrySlice';

function PartnerCountryPreviewDetailsModal() {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const user = useAuthUser();

  const { getCountryNameByIso3Code } = useCountries();

  const viewData = useMemo(
    () => [
      ...(user?.isSuperAdmin()
        ? [
            {
              label: 'Partner Id',
              data: 'partner_id',
              get: ({ partner_id, partner_name }) => (
                <PartnerClickAwayDetails partnerId={partner_id} label={partner_name} />
              ),
            },
          ]
        : []),
      {
        label: 'Country',
        get: ({ iso3 }) => getCountryNameByIso3Code(iso3),
      },
      {
        label: 'Currency',
        data: 'currency',
      },
      {
        label: 'Is Active ?',
        get: ({ is_active }) => (is_active ? 'Yes' : 'No'),
      },
    ],
    [],
  );

  const viewDetailColumn = useSourceDetail([
    {
      title: '',
      items: [
        ...(user?.isSuperAdmin()
          ? [
              {
                label: 'Partner',
                accessorKey: 'partner_name',
                cell: ({ partner_id, partner_name }) => (
                  <PartnerClickAwayDetails partnerId={partner_id} label={partner_name} />
                ),
              },
            ]
          : []),
        {
          label: 'Country',
          accessorKey: 'iso3',
          cell: ({ iso3 }) => getCountryNameByIso3Code(iso3),
        },
        {
          label: 'Currency',
          accessorKey: 'currency',
        },
        {
          label: 'Is Active?',
          accessorKey: 'is_active',
          cell: ({ is_active }) => (is_active ? 'Yes' : 'No'),
        },
      ],
    },
  ]);

  const state = useSelector((state) => state.partnerCountries.show);

  const { showBriefDetailModal, partnerCountryId, isLoading, data } = state;

  useEffect(() => {
    if (partnerCountryId) dispatch(getPartnerCountry(partnerCountryId));
  }, [partnerCountryId]);

  if (!showBriefDetailModal && !partnerCountryId) return <></>;

  return (
    <Modal
      open={showBriefDetailModal ?? false}
      onClose={() => dispatch(hidePartnerCountryBriefDetails())}
      title="Partner Country Details"
      handleFullDetails={() => (
        dispatch(hidePartnerCountryBriefDetails()),
        navigate(buildRoute('partner-countries.show', partnerCountryId))
      )}
    >
      <SourceDetails
        showContainerDesign={false}
        definition={viewDetailColumn}
        data={data}
        isLoading={isLoading}
      />
    </Modal>
  );
}

export default PartnerCountryPreviewDetailsModal;
