import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import withRole from 'hoc/withRole';
import Error from 'core/errors/Error';
import PartnerCountryForm from 'components/partner-countries/PartnerCountryForm';
import { editPartnerCountry } from 'app/features/partner-countries/partnerCountrySaga';
import PartnerCountryFormSkeleton from 'components/partner-countries/PartnerCountryFormSkeleton';

import acl from 'app/config/acl';
import { buildRoute } from 'routes';
import ucwords from 'helpers/ucwords';
import routePaths from 'app/config/routePaths';
import NewPageContent from 'layouts/NewPageContent';
import ActionStatusModal from 'core/modal/ActionStatusModal';
import {
  setShowEditProcessingModal,
  updatePartnerCountryReset,
} from 'app/features/partner-countries/partnerCountrySlice';
function EditPartnerCountry() {
  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    data: partnerCountry,
    isError,
    errorMessage,
    isLoading,
    errorCode,
  } = useSelector((state) => state.partnerCountries.edit);

  const { showProcessingModal, isUpdated, isUpdating, isFailed } = useSelector(
    (state) => state.partnerCountries.update,
  );

  useEffect(() => {
    dispatch(editPartnerCountry(params.partnerCountryId));
  }, [params.partnerCountryId]);

  if (isError)
    return (
      <Error
        status={errorCode}
        message={errorMessage}
        onRetry={() => dispatch(editPartnerCountry(params.partnerCountryId))}
      />
    );

  return (
    <NewPageContent
      breadcrumbs={[
        { label: 'Partner Countries', to: routePaths.ListPartnerCountries },
        { label: 'Edit Partner Country', to: '' },
      ]}
      documentTitle="Edit Partner Country"
    >
      {isLoading ? (
        <PartnerCountryFormSkeleton />
      ) : (
        <PartnerCountryForm isAddMode={false} partnerCountry={partnerCountry} />
      )}
      {/* <PartnerCountryProgressIndicatorModal isAddMode={false} /> */}
      <ActionStatusModal
        showModal={showProcessingModal}
        closeModal={() => dispatch(setShowEditProcessingModal(false))}
        data={partnerCountry}
        isSuccess={isUpdated}
        isProcessing={isUpdating}
        isError={isFailed}
        errorMessage={ucwords(errorMessage) ?? 'Failed To Update Partner Country'}
        successMessage="Partner Country Updated Successfully"
        navigateToView={() =>
          navigate(
            buildRoute('partner-countries.show', partnerCountry?.id ?? params.partnerCountryId),
          )
        }
        resetForm={() => dispatch(updatePartnerCountryReset())}
      />
    </NewPageContent>
  );
}

export default withRole([acl.SUPERADMIN])(EditPartnerCountry);
