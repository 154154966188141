import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import withRole from 'hoc/withRole';
import Error from 'core/errors/Error';
import PageContent from 'layouts/PageContent';
import WebhookForm from 'components/webhooks/WebhookForm';
import WebhookFormSkeleton from 'components/webhooks/WebhookFormSkeleton';
import WebhookProgressIndicatorModal from 'components/webhooks/WebhookProgressIndicatorModal';

import acl from 'app/config/acl';
import { editWebhook } from 'app/features/webhooks/webhookSaga';
import NewPageContent from 'layouts/NewPageContent';
import routePaths from 'app/config/routePaths';
import ModalLoader from 'core/modal/ActionStatusModal';

import ActionStatusModal from 'core/modal/ActionStatusModal';

import {
  retryToUpdate,
  setShowEditProcessingModal,
  updateWebhookReset,
} from 'app/features/webhooks/webhookSlice';
import ucwords from 'helpers/ucwords';
import { buildRoute } from 'routes';

function EditWebhook() {
  const params = useParams();
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const {
    isError,
    isLoading,
    errorCode,
    showProcessingModal,
    isUpdating,
    isUpdated,
    isFailed,
    errors,
    errorMessage,
  } = useSelector((state) => state.webhooks.update);

  const { data: webhook } = useSelector((state) => state.webhooks.edit);

  useEffect(() => {
    dispatch(editWebhook(params.webhookId));
  }, [params.webhookId]);

  if (isError)
    return (
      <Error
        status={errorCode}
        message={errorMessage}
        onRetry={() => dispatch(editWebhook(params.webhookId))}
      />
    );

  return (
    <NewPageContent
      breadcrumbs={[
        {
          label: 'Webhooks',
          to: routePaths.ListWebhooks,
        },
        {
          label: 'Edit Webhook',
          to: '',
        },
      ]}
      documentTitle="Edit Webhook"
    >
      {isLoading ? <WebhookFormSkeleton /> : <WebhookForm isAddMode={false} webhook={webhook} />}
      {/* <WebhookProgressIndicatorModal isAddMode={false} /> */}
      <ActionStatusModal
        showModal={showProcessingModal}
        closeModal={() => dispatch(setShowEditProcessingModal(false))}
        data={webhook}
        isSuccess={isUpdated}
        isProcessing={isUpdating}
        isError={isFailed}
        errorMessage={ucwords(errorMessage) ?? 'Failed To Update Webhook'}
        successMessage="Webhook Updated Successfully"
        retry={() => dispatch(retryToUpdate())}
        navigateToView={() =>
          navigate(buildRoute('webhooks.show', webhook?.id ?? params.webhookId))
        }
        resetForm={() => dispatch(updateWebhookReset())}
      />
    </NewPageContent>
  );
}

export default withRole([acl.SUPERADMIN, acl.ADMIN])(EditWebhook);
