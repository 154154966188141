import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import ListItemButton from '@mui/material/ListItemButton';
import React, { useMemo, useEffect, useState } from 'react';
import { useReactTable, getCoreRowModel } from '@tanstack/react-table';

import constants from 'app/config/constants';
import { useConfirm } from 'core/mui-confirm';
import ActiveBadge from 'core/badges/ActiveBadge';
import FormCheckbox from 'core/forms/FormCheckbox';
import PopoverButton from 'core/buttons/PopoverButton';
import TanstackReactTable from 'core/table/TanstackReactTable';
import TableContainerWrapper from 'core/table/TableContainerWrapper';

import Auth from 'utils/Auth';
import app from 'app/config/app';
import { buildRoute } from 'routes';
import isEmpty from 'helpers/isEmpty';
import ucwords from 'helpers/ucwords';
import AccountsActions from './AccountsActions';
import useQueryParams from 'hooks/useQueryParams';
import AccountPagination from './AccountPagination';
import { getAccounts } from 'app/features/accounts/accountSaga';
import { getAccountBriefDetailsInit } from 'app/features/accounts/accountSlice';
import { deleteAccount, updateIsActiveStatus } from 'app/features/accounts/accountSaga';

export default function Accounts() {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const confirm = useConfirm();

  const accountsState = useSelector((state) => state.accounts.index);

  const { params, data, isLoading } = accountsState;

  const [queryParams] = useQueryParams(app.ignoreFilterKeys);

  const [columnVisibility, setColumnVisibility] = useState({});

  /**
   * handle toggle status
   *
   */
  const handleToggleStatus = (accountId) => {
    let account = data.filter((item) => item.id === accountId)?.[0];

    if (isEmpty(account)) return;

    const confirmationText = account.is_active ? 'Yes, set as Inactive' : 'Yes, set as Active';

    confirm({
      icon: 'warning',
      confirmationText,
      description: 'This will toggle the status of account.',
    })
      .then(() =>
        dispatch(updateIsActiveStatus({ accountId: account.id, isActive: !account.is_active })),
      )
      .catch(() => {});
  };

  /**
   * handle delete account
   *
   */
  const handleDelete = (accountId) => {
    confirm({
      icon: 'warning',
      confirmationText: 'Yes, Delete it.',
      description: 'This will permanently delete this account.',
    })
      .then(() => dispatch(deleteAccount(accountId)))
      .catch(() => {});
  };

  const columns = useMemo(
    () => [
      {
        id: constants.TABLE_CHECKBOX_SELECT_CHECKBOX_ID,
        header: ({ table }) => (
          <FormCheckbox
            forTableHead={true}
            {...{
              checked: table.getIsAllRowsSelected(),
              indeterminate: table.getIsSomeRowsSelected(),
              onChange: table.getToggleAllRowsSelectedHandler(),
            }}
          />
        ),
        cell: ({ row }) => (
          <div>
            <FormCheckbox
              {...{
                checked: row.getIsSelected(),
                indeterminate: row.getIsSomeSelected(),
                onChange: row.getToggleSelectedHandler(),
              }}
            />
          </div>
        ),
      },
      {
        id: constants.TABLE_SERIAL_NUMBER_COLUMN_ID,
        header: 'SN',
        accessorFn: (row) => row.sn,
      },
      {
        header: 'Name',
        accessorFn: (row) => row.name,
      },
      {
        id: 'user_type',
        header: 'Role',
        accessorFn: (row) => ucwords(row.user_type),
      },
      ...(Auth.isSuperAdmin()
        ? [
            {
              header: 'Partner Name',
              accessorFn: (row) => row.partner_name,
            },
          ]
        : []),
      {
        header: 'Phone Number',
        accessorFn: (row) => row.phone_number,
      },
      {
        id: 'email',
        header: 'Email',
        accessorFn: (row) => row.email,
      },
      {
        header: 'Is Active ?',
        accessorFn: (row) => row.is_active,
        cell: ({ row }) => (
          <ActiveBadge
            isActive={!!row.original.is_active}
            tooltipTitle="Click to toggle status"
            onClick={() => handleToggleStatus(row.original.id)}
          />
        ),
      },
      {
        id: constants.TABLE_ACTION_COLUMN_ID,
        header: 'Action',
        accessorFn: (row) => row.id,
        cell: ({ row }) => (
          <PopoverButton>
            {({ onClose }) => (
              <>
                <ListItemButton
                  onClick={() => {
                    dispatch(
                      getAccountBriefDetailsInit({
                        showBriefDetailModal: true,
                        accountId: row.original.id,
                      }),
                    );
                    onClose();
                  }}
                >
                  Preview
                </ListItemButton>
                <ListItemButton
                  onClick={() => {
                    navigate(buildRoute('accounts.show', row.original.id));
                    onClose();
                  }}
                >
                  View Details
                </ListItemButton>

                <ListItemButton
                  onClick={() => navigate(buildRoute('accounts.edit', row.original.id))}
                >
                  Edit
                </ListItemButton>

                {Auth.id() !== row.original.id && (
                  <ListItemButton
                    onClick={() => handleDelete(row.original.id)}
                    disabled={row.original?.isDeleting || false}
                    color="error"
                  >
                    Delete
                  </ListItemButton>
                )}
              </>
            )}
          </PopoverButton>
        ),
      },
    ],
    [JSON.stringify(data)],
  );

  const table = useReactTable({
    data,
    columns,
    state: {
      columnVisibility,
    },
    onColumnVisibilityChange: setColumnVisibility,
    getCoreRowModel: getCoreRowModel(),
  });

  useEffect(() => {
    dispatch(getAccounts({ ...queryParams, ...params }));
  }, [JSON.stringify(params)]);

  return (
    <TableContainerWrapper>
      <AccountsActions table={table} />
      <TanstackReactTable
        table={table}
        data={data}
        isLoading={isLoading}
        columnsCount={
          columns.length - Object.entries(columnVisibility ?? {}).filter(([k, v]) => !v).length
        }
      />
      <AccountPagination />
    </TableContainerWrapper>
  );
}

Accounts.propTypes = {
  partnerId: PropTypes.string,
};
