import { useEffect } from 'react';
import { compose } from '@reduxjs/toolkit';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import Error from 'core/errors/Error';
import withAppState from 'hoc/withAppState';
import PageContent from 'layouts/PageContent';
import FinmoPayout from 'components/finmo-payouts/FinmoPayout';

import acl from 'app/config/acl';
import withRole from 'hoc/withRole';
import { resetGetFinmoPayoutFailure } from 'app/features/finmo-payouts/finmoPayoutSlice';
import NewPageContent from 'layouts/NewPageContent';
import routePaths from 'app/config/routePaths';

function ViewFinmoPayout({ isNetworkError }) {
  const params = useParams();

  const { isError, errorCode } = useSelector((state) => state.finmoPayouts.show);

  const dispatch = useDispatch();

  useEffect(() => {
    if (isError) dispatch(resetGetFinmoPayoutFailure());
  }, [isNetworkError]);

  if (isError)
    return <Error status={errorCode || 0} onRetry={() => dispatch(resetGetFinmoPayoutFailure())} />;

  return (
    <NewPageContent
      breadcrumbs={[
        {
          label: 'Finmo Payouts',
          to: routePaths.ListFinmoPayouts,
        },
        { label: 'Finmo Payout Details', to: '' },
      ]}
      documentTitle="Finmo Payout Details"
    >
      <FinmoPayout finmoPayoutId={params.finmoPayoutId} />
    </NewPageContent>
  );
}

export default compose(withAppState, withRole([acl.SUPERADMIN]))(ViewFinmoPayout);
