import React from 'react';
import PropTypes from 'prop-types';
import PartnerFundingSources from 'components/partner-funding-sources/PartnerFundingSources';
import PartnerFundingSourcePreviewDetailsModal from 'components/partner-funding-sources/PartnerFundingSourcePreviewDetailsModal';

export default function PartnerFundingSourcesTab({ partnerId }) {
  return (
    <>
      <PartnerFundingSources preFilterByPartner={true} partnerId={partnerId} />
      <PartnerFundingSourcePreviewDetailsModal />
    </>
  );
}

PartnerFundingSourcesTab.propTypes = {
  partnerId: PropTypes.string.isRequired,
};
