import React from 'react';

const TotalCustomerIcon = (props) => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M0 20C0 8.95431 8.95431 0 20 0C31.0457 0 40 8.95431 40 20C40 31.0457 31.0457 40 20 40C8.95431 40 0 31.0457 0 20Z"
        fill="#4EBAEA"
      />
      <path
        opacity="0.4"
        d="M25.9976 15.16C25.9376 15.15 25.8676 15.15 25.8076 15.16C24.4276 15.11 23.3276 13.98 23.3276 12.58C23.3276 11.15 24.4776 10 25.9076 10C27.3376 10 28.4876 11.16 28.4876 12.58C28.4776 13.98 27.3776 15.11 25.9976 15.16Z"
        stroke="white"
      />
      <path
        opacity="0.4"
        d="M24.9675 22.44C26.3375 22.67 27.8475 22.43 28.9075 21.72C30.3175 20.78 30.3175 19.24 28.9075 18.3C27.8375 17.59 26.3075 17.35 24.9375 17.59"
        stroke="white"
      />
      <path
        opacity="0.4"
        d="M13.9675 15.16C14.0275 15.15 14.0975 15.15 14.1575 15.16C15.5375 15.11 16.6375 13.98 16.6375 12.58C16.6375 11.15 15.4875 10 14.0575 10C12.6275 10 11.4775 11.16 11.4775 12.58C11.4875 13.98 12.5875 15.11 13.9675 15.16Z"
        stroke="white"
      />
      <path
        opacity="0.4"
        d="M14.9975 22.44C13.6275 22.67 12.1175 22.43 11.0575 21.72C9.6475 20.78 9.6475 19.24 11.0575 18.3C12.1275 17.59 13.6575 17.35 15.0275 17.59"
        stroke="white"
      />
      <path
        d="M20 22.63C19.94 22.62 19.87 22.62 19.81 22.63C18.43 22.58 17.33 21.45 17.33 20.05C17.33 18.62 18.48 17.47 19.91 17.47C21.34 17.47 22.49 18.63 22.49 20.05C22.48 21.45 21.38 22.59 20 22.63Z"
        stroke="white"
      />
      <path
        d="M17.09 25.78C15.68 26.72 15.68 28.26 17.09 29.2C18.69 30.27 21.31 30.27 22.91 29.2C24.32 28.26 24.32 26.72 22.91 25.78C21.32 24.72 18.69 24.72 17.09 25.78Z"
        stroke="white"
      />
    </svg>
  );
};

export default TotalCustomerIcon;
