import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getBeneficiaries } from 'app/features/beneficiaries/beneficiarySaga';
import { useEffect } from 'react';
import Error from 'core/errors/Error';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { BeneficiaryCard } from './BeneficiaryCard';
import { getBeneficiaryBriefDetailsInit } from 'app/features/beneficiaries/beneficiarySlice';
import { buildRoute } from 'routes';
import Skeleton from 'core/skeleton/Skeleton';

const BeneficiariesListCard = ({ filterByCustomer, customerId }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { data, params, isLoading, isError, errorCode } = useSelector(
    (state) => state.beneficiaries.index,
  );

  useEffect(() => {
    dispatch(getBeneficiaries({ ...params, ...(filterByCustomer ? { user_id: customerId } : {}) }));
  }, [dispatch, filterByCustomer, customerId, params]);

  if (isError) return <Error status={errorCode} />;

  const dataLength = Array.isArray(data) ? data.length : 5;

  return (
    <Grid container spacing={2} justifyContent="flex-start">
      {data?.map((beneficiary) =>
        isLoading ? (
          Array.from({ length: dataLength }).map((_, index) => (
            <Grid key={index} item xs={12} md={4} lg={3} gap={4}>
              <Skeleton key={index} variant="rect" width={300} height={300} />
            </Grid>
          ))
        ) : (
          <BeneficiaryCard
            key={beneficiary.id}
            handleBeneficiaryBriefView={() => {
              dispatch(
                getBeneficiaryBriefDetailsInit({
                  showBriefDetailModal: true,
                  beneficiaryId: beneficiary?.id,
                }),
              );
            }}
            beneficiary={beneficiary}
            handleBeneficiaryFullView={() =>
              navigate(buildRoute('beneficiaries.show', beneficiary?.id))
            }
          />
        ),
      )}
    </Grid>
  );
};

export default BeneficiariesListCard;
