import React from 'react';

const CompletedStatusIcon = (props) => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M0 20C0 8.95431 8.95431 0 20 0C31.0457 0 40 8.95431 40 20C40 31.0457 31.0457 40 20 40C8.95431 40 0 31.0457 0 20Z"
        fill="#F4FCF7"
      />
      <path
        d="M25 29.25H15C11.35 29.25 9.25 27.15 9.25 23.5V16.5C9.25 12.85 11.35 10.75 15 10.75H25C28.65 10.75 30.75 12.85 30.75 16.5V23.5C30.75 27.15 28.65 29.25 25 29.25ZM15 12.25C12.14 12.25 10.75 13.64 10.75 16.5V23.5C10.75 26.36 12.14 27.75 15 27.75H25C27.86 27.75 29.25 26.36 29.25 23.5V16.5C29.25 13.64 27.86 12.25 25 12.25H15Z"
        fill="#23C965"
      />
      <path
        d="M20 23.75C17.93 23.75 16.25 22.07 16.25 20C16.25 17.93 17.93 16.25 20 16.25C22.07 16.25 23.75 17.93 23.75 20C23.75 22.07 22.07 23.75 20 23.75ZM20 17.75C18.76 17.75 17.75 18.76 17.75 20C17.75 21.24 18.76 22.25 20 22.25C21.24 22.25 22.25 21.24 22.25 20C22.25 18.76 21.24 17.75 20 17.75Z"
        fill="#23C965"
      />
      <path
        d="M11 17.75H10C9.59 17.75 9.25 17.41 9.25 17C9.25 16.59 9.59 16.25 10 16.25H11C13.58 16.25 14.25 15.58 14.25 13V12C14.25 11.59 14.59 11.25 15 11.25C15.41 11.25 15.75 11.59 15.75 12V13C15.75 16.42 14.42 17.75 11 17.75Z"
        fill="#23C965"
      />
      <path
        d="M30 17.75H29C25.58 17.75 24.25 16.42 24.25 13V12C24.25 11.59 24.59 11.25 25 11.25C25.41 11.25 25.75 11.59 25.75 12V13C25.75 15.58 26.42 16.25 29 16.25H30C30.41 16.25 30.75 16.59 30.75 17C30.75 17.41 30.41 17.75 30 17.75Z"
        fill="#23C965"
      />
      <path
        d="M15 28.75C14.59 28.75 14.25 28.41 14.25 28V27C14.25 24.42 13.58 23.75 11 23.75H10C9.59 23.75 9.25 23.41 9.25 23C9.25 22.59 9.59 22.25 10 22.25H11C14.42 22.25 15.75 23.58 15.75 27V28C15.75 28.41 15.41 28.75 15 28.75Z"
        fill="#23C965"
      />
      <path
        d="M25 28.75C24.59 28.75 24.25 28.41 24.25 28V27C24.25 23.58 25.58 22.25 29 22.25H30C30.41 22.25 30.75 22.59 30.75 23C30.75 23.41 30.41 23.75 30 23.75H29C26.42 23.75 25.75 24.42 25.75 27V28C25.75 28.41 25.41 28.75 25 28.75Z"
        fill="#23C965"
      />
    </svg>
  );
};

export default CompletedStatusIcon;
