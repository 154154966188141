import { useMemo } from 'react';
import Grid from '@mui/material/Grid';
import { useSelector } from 'react-redux';

import ActiveBadge from 'core/badges/ActiveBadge';
import FormContainer from 'core/forms/FormContainer';
import GroupedTableView from 'core/table/GroupedTableView';

import isEmpty from 'helpers/isEmpty';
import DateUtils from 'utils/DateUtils';
import useAuthUser from 'hooks/useAuthUser';
import PartnerClickAwayDetails from 'components/partners/PartnerClickAwayDetails';
import useSourceDetail from 'core/source-detail/useSourceDetail';
import SourceDetails from 'core/source-detail/SourceDetails';

export default function AccountDetailsTab() {
  const { isLoading, data: account } = useSelector((state) => state.accounts.show);

  const user = useAuthUser();

  const viewData = useMemo(
    () => [
      {
        groupName: 'Basic Information',
        groupData: [
          {
            label: 'Name',
            data: 'name',
          },
          {
            label: 'Email',
            data: 'email',
          },
          {
            label: 'Phone Number',
            data: 'phone_number',
          },
          {
            label: 'Role',
            data: 'user_type',
          },
          {
            label: 'Is Active ?',
            data: 'is_active',
            get: ({ is_active }) => <ActiveBadge isActive={!!is_active} />,
          },
        ],
      },
      {
        groupName: 'Others Information',
        groupData: [
          ...(user?.isSuperAdmin()
            ? [
                {
                  label: 'Partner Id',
                  data: 'partner_id',
                  get: ({ partner_id, partner_name }) => (
                    <PartnerClickAwayDetails partnerId={partner_id} label={partner_name} />
                  ),
                },
              ]
            : []),
          {
            label: 'Last Login at',
            data: 'last_login_ts',
            get: ({ last_login_ts }) =>
              !isEmpty(last_login_ts) ? DateUtils.isoToString(last_login_ts) : '-',
          },
          {
            label: 'Created At',
            data: 'created_ts',
            get: ({ created_ts }) =>
              !isEmpty(created_ts) ? DateUtils.isoToString(created_ts) : '',
          },
          {
            label: 'Created By',
            data: 'created_by',
          },
          {
            label: 'Updated At',
            data: 'updated_ts',
            get: ({ updated_ts }) =>
              !isEmpty(updated_ts) ? DateUtils.isoToString(updated_ts) : '',
          },
          {
            label: 'Updated By',
            data: 'updated_by',
          },
        ],
      },
    ],
    [],
  );

  const viewDataDetail = useSourceDetail([
    {
      title: 'Basic Information',
      items: [
        { label: 'Name', accessorKey: 'name' },
        { label: 'Email', accessorKey: 'email' },
        { label: 'Phone Number', accessorKey: 'phone_number' },
        { label: 'Role', accessorKey: 'user_type' },
        {
          label: 'Is Active?',
          accessorKey: 'is_active',
          cell: ({ is_active }) => <ActiveBadge isActive={!!is_active} />,
        },
      ],
    },
    {
      title: 'Other Information',
      items: [
        ...(user?.isSuperAdmin()
          ? [
              {
                label: 'Partner Id',
                accessorKey: 'partner_id',
                cell: ({ partner_id, partner_name }) => (
                  <PartnerClickAwayDetails partnerId={partner_id} label={partner_name} />
                ),
              },
            ]
          : []),
        {
          label: 'Last Login at',
          accessorKey: 'last_login_ts',
          cell: ({ last_login_ts }) => (last_login_ts ? DateUtils.isoToString(last_login_ts) : '-'),
        },
        {
          label: 'Created At',
          accessorKey: 'created_ts',
          cell: ({ created_ts }) => (created_ts ? DateUtils.isoToString(created_ts) : ''),
        },
        { label: 'Created By', accessorKey: 'created_by' },
        {
          label: 'Updated At',
          accessorKey: 'updated_ts',
          cell: ({ updated_ts }) => (updated_ts ? DateUtils.isoToString(updated_ts) : ''),
        },
        { label: 'Updated By', accessorKey: 'updated_by' },
      ],
    },
  ]);

  return (
    <FormContainer>
      <SourceDetails
        viewMode="column"
        rowMode="row"
        definition={viewDataDetail}
        data={account}
        isLoading={isLoading}
      />
    </FormContainer>
  );
}
