import React from 'react';
import withRole from 'hoc/withRole';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { buildRoute } from 'routes';
import NewPageContent from 'layouts/NewPageContent';
import ApiConfigForm from 'components/api-configs/ApiConfigForm';

import acl from 'app/config/acl';
import routePaths from 'app/config/routePaths';
import {
  setShowCreateProcessingModal,
  addApiConfigReset,
} from 'app/features/api-configs/apiConfigSlice';

import ActionStatusModal from 'core/modal/ActionStatusModal';

import ucwords from 'helpers/ucwords';

function AddApiConfig() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();

  const {
    showProcessingModal,
    isCreating,
    isCreated,
    isFailed,
    errors,
    errorMessage,
    retries,
    data: apiConfig,
  } = useSelector((state) => state.apiConfigs.create);

  const configData = useSelector((state) => state.apiConfigs.create);

  return (
    <NewPageContent
      breadcrumbs={[
        {
          label: 'Api Configs',
          to: routePaths.ListApiConfigs,
        },
        {
          label: 'Add API Config',
          to: '',
        },
      ]}
      documentTitle="Add API Config"
    >
      <ApiConfigForm isAddMode={true} />

      <ActionStatusModal
        showModal={showProcessingModal}
        closeModal={() => dispatch(setShowCreateProcessingModal(false))}
        data={apiConfig}
        isSuccess={isCreated}
        isProcessing={isCreating}
        isError={isFailed}
        retriesCount={retries}
        errorMessage={ucwords(errorMessage) ?? 'Failed To Api Config'}
        successMessage="Api Config Created Successfully"
        navigateToView={() =>
          navigate(buildRoute('api-configs.show', apiConfig.config_id ?? params.apiConfigId))
        }
        resetForm={() => dispatch(addApiConfigReset())}
      />
    </NewPageContent>
  );
}

export default withRole([acl.SUPERADMIN, acl.ADMIN])(AddApiConfig);
