import { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import { useDispatch, useSelector } from 'react-redux';

import ActiveBadge from 'core/badges/ActiveBadge';
import VerifiedBadge from 'core/badges/VerifiedBadge';
import GroupedTableView from 'core/table/GroupedTableView';
import PartnerClickAwayDetails from 'components/partners/PartnerClickAwayDetails';
import Error from 'core/errors/Error';

import Auth from 'utils/Auth';
import isEmpty from 'helpers/isEmpty';
import DateUtils from 'utils/DateUtils';
import useCountries from 'hooks/useCountries';
import { getPartnerFundingSource } from 'app/features/partner-funding-sources/partnerFundingSourceSaga';
import SourceDetails from 'core/source-detail/SourceDetails';
import useSourceDetail from 'core/source-detail/useSourceDetail';

export default function PartnerFundingSource({ partnerFundingSourceId }) {
  const dispatch = useDispatch();

  const { getCountryNameByIso3Code } = useCountries();

  const viewPartnerFundingSourceDefinition = useSourceDetail(
    [
      {
        title: '',
        items: [
          ...(Auth.isSuperAdmin()
            ? [
                {
                  label: 'Partner',
                  accessorKey: 'partner_id',
                  cell: ({ partner_id, partner_name }) => (
                    <PartnerClickAwayDetails partnerId={partner_id} label={partner_name} />
                  ),
                },
              ]
            : []),
          {
            label: 'Country',
            accessorKey: 'country',
            cell: ({ country }) => getCountryNameByIso3Code(country),
          },
          {
            label: 'Funding Source Name',
            accessorKey: 'funding_source_name',
          },
          {
            label: 'Funding Source Value',
            accessorKey: 'funding_source',
          },
          {
            label: 'Is Active?',
            accessorKey: 'is_active',
            cell: ({ is_active }) => <ActiveBadge isActive={is_active} />,
          },
          {
            label: 'Created At',
            accessorKey: 'created_ts',
            cell: ({ created_ts }) =>
              !isEmpty(created_ts) ? DateUtils.isoToString(created_ts) : '-',
          },
          {
            label: 'Created By',
            accessorKey: 'created_by',
          },
          {
            label: 'Updated At',
            accessorKey: 'updated_ts',
            cell: ({ updated_ts }) =>
              !isEmpty(updated_ts) ? DateUtils.isoToString(updated_ts) : '-',
          },
          {
            label: 'Updated By',
            accessorKey: 'updated_by',
          },
        ],
      },
    ],
    [],
  );

  const {
    isError,
    errorCode,
    isLoading,
    data: partnerFundingSource,
  } = useSelector((state) => state.partnerFundingSources.show);

  useEffect(() => {
    dispatch(getPartnerFundingSource(partnerFundingSourceId));
  }, [partnerFundingSourceId]);

  if (isError) return <Error status={errorCode} />;

  return (
    <>
      <SourceDetails
        viewMode="column"
        rowMode="row"
        definition={viewPartnerFundingSourceDefinition}
        data={partnerFundingSource}
        isLoading={isLoading}
      />
    </>
  );
}

PartnerFundingSource.propTypes = {
  partnerFundingSourceId: PropTypes.string,
};
