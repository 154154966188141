import ZaiRefundLogs from 'components/zai-logs/ZaiRefundLogs';
import FilterButton from 'core/buttons/FilterButton';
import NewPageContent from 'layouts/NewPageContent';
import PageContent from 'layouts/PageContent';
import { useDispatch, useSelector } from 'react-redux';

import {
  toggleFilterZaiRefund
} from 'app/features/transactions/zaiTransactionSlice';

function ListZaiRefundLogs() {
  const dispatch = useDispatch()

  const transactionState = useSelector((state) => state.zaiTransactions.refundLogs);

  const { isFilterOpen } = transactionState;

  return (
    <NewPageContent
      breadcrumbs={[{ label: 'Zai Refund logs', to: '' }]}
      documentTitle="Zai Refund Logs"
      rightTopEndContent={
        <FilterButton
          isOpen={isFilterOpen}
          onClick={() => dispatch(toggleFilterZaiRefund())}
        />
      }
    >
      <ZaiRefundLogs />
    </NewPageContent>
  );
}

export default ListZaiRefundLogs;
