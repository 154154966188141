import React, { useEffect } from 'react';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import PropTypes from 'prop-types';
import NotStartedIcon from '@mui/icons-material/NotStarted';

import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { useDispatch, useSelector } from 'react-redux';
import TotalCustomerIcon from 'core/status-icon/TotalCustomerIcon';
import CreatedStatusIcon from 'core/status-icon/CreatedStatusIcon';
import PendingStatusIcon from 'core/status-icon/PendingStatusIcon';
import ProcessingStatusIcon from 'core/status-icon/ProcessingStatusIcon';
import CompletedStatusIcon from 'core/status-icon/CompletedStatusIcon';
import RejectedStatusIcon from 'core/status-icon/RejectedStatusIcon';
import ExpiredStatusIcon from 'core/status-icon/ExpiredStatusIcon';
import StatusNotStartedIcon from 'core/status-icon/StatusNotStartedIcon';
import { getCustomersKycCount } from 'app/features/customers/customersKycCountSaga';
import { Button } from '@mui/material';

const Wrapper = styled(Box)(({ theme }) => ({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  //background: 'red',
  //background: theme.palette.common.white,
  padding: '16px',
  gap: '8px',
  minWidth: '203px',
  flexWrap: 'wrap',
}));

const MainWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: '16px',
  //background: theme.palette.common.white,
  // background: 'red',
  borderRadius: 15,
  boxShadow: '10px 8px 10px 2px rgba(0, 0, 0, 0.06)',
  // overflowX: 'scroll',
  width: '100%',

  flexWrap: 'nowrap',
  overflowX: 'scroll',
  // [theme.breakpoints.down('md')]: {
  //   flexWrap: 'nowrap',
  //   overflowX: 'scroll',
  // },
  // [theme.breakpoints.down('sm')]: {
  //   flexWrap: 'nowrap',
  //   overflowX: 'scroll',
  // },
}));

export default function CustomerStat({ fromDate, toDate, pageName }) {
  const dispatch = useDispatch();

  const { data: customerKycResponse, isLoading } = useSelector(
    (state) => state.customersKycCount.index,
  );

  const customerKycData = customerKycResponse?.data;

  useEffect(() => {
    dispatch(getCustomersKycCount());
  }, [dispatch]);

  const statsData = [
    {
      label: 'Total Customers',
      icon: <TotalCustomerIcon />,
      count: customerKycData?.totalUsersCount ?? 0,
    },
    {
      label: 'KYC Created',
      icon: <CreatedStatusIcon />,
      count: customerKycData?.kycCreatedCount ?? 0,
    },
    {
      label: 'KYC Pending',
      icon: <PendingStatusIcon />,
      count: customerKycData?.kycPendingCount ?? 0,
    },
    {
      label: 'KYC Processing',
      icon: <ProcessingStatusIcon />,
      count: customerKycData?.kycProcessingCount ?? 0,
    },
    {
      label: 'KYC Completed',
      icon: <CompletedStatusIcon />,
      count: customerKycData?.kycCompletedCount ?? 0,
    },
    {
      label: 'KYC Rejected',
      icon: <RejectedStatusIcon />,
      count: customerKycData?.kycRejectedCount ?? 0,
    },
    {
      label: 'KYC Expired',
      icon: <ExpiredStatusIcon />,
      count: customerKycData?.kycExpiredCount ?? 0,
    },
    {
      label: 'KYC Not Started',
      icon: (
        <StatusNotStartedIcon
          sx={{
            height: '40px',
            width: '40px',
          }}
        />
      ),
      count: customerKycData?.kycNotStartedCount ?? 0,
    },
  ];

  return (
    <MainWrapper
      sx={{
        background: (theme) => theme.palette.common.white,
      }}
    >
      {statsData.map((stat, index) => (
        <Wrapper key={index}>
          {stat.icon}
          <Box display="flex" flexDirection="column">
            <Typography
              fontSize="0.857rem"
              marginBottom="0.5rem"
              lineHeight="1rem"
              color="text.secondary"
            >
              {stat.label}
            </Typography>
            {isLoading ? (
              <Skeleton width="100px" />
            ) : (
              <Typography fontSize="1.429rem" lineHeight="2rem" fontWeight={600}>
                {stat.count}
              </Typography>
            )}
          </Box>
        </Wrapper>
      ))}
    </MainWrapper>
  );
}

CustomerStat.PropTypes = {
  fromDate: PropTypes.string,
  toDate: PropTypes.string,
  pageName: PropTypes.string,
};
