import React from 'react';
import { useParams } from 'react-router-dom';

import Role from 'components/Roles/Role';

import routePaths from 'app/config/routePaths';
import NewPageContent from 'layouts/NewPageContent';

const ViewRoles = () => {
  const params = useParams();

  return (
    <NewPageContent
      breadcrumbs={[
        {
          label: 'Roles',
          to: routePaths.ListRoles,
        },
        {
          label: 'Role Details',
          to: '',
        },
      ]}
      documentTitle="Role Details"
    >
      <Role roleId={params.roleId} />
    </NewPageContent>
  );
};

export default ViewRoles;
