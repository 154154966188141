import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import withRole from 'hoc/withRole';
import Button from 'core/buttons/Button';
import PageContent from 'layouts/PageContent';

import acl from 'app/config/acl';
import { buildRoute } from 'routes';
import WebhookForm from 'components/webhooks/WebhookForm';
import WebhookProgressIndicatorModal from 'components/webhooks/WebhookProgressIndicatorModal';
import NewPageContent from 'layouts/NewPageContent';
import routePaths from 'app/config/routePaths';
import { useDispatch, useSelector } from 'react-redux';

import {
  retryToCreate,
  setShowCreateProcessingModal,
  addWebhookReset,
} from 'app/features/webhooks/webhookSlice';

import ucwords from 'helpers/ucwords';

import ActionStatusModal from 'core/modal/ActionStatusModal';

function AddWebhook() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();

  const webhookState = useSelector((state) => state.webhooks.create);

  const {
    showProcessingModal,
    isCreating,
    isCreated,
    isFailed,
    errors,
    errorMessage,
    retries,
    data: webhook,
  } = webhookState;

  return (
    <NewPageContent
      breadcrumbs={[
        {
          label: 'Webhooks',

          to: routePaths.ListWebhooks,
        },
        {
          label: 'Add Webhook',
          to: '',
        },
      ]}
      documentTitle="Add Webhook"
      // rightTopEndContent={
      //   <Button onClick={() => navigate(buildRoute('webhooks.index'))}>List Webhook</Button>
      // }
    >
      <WebhookForm isAddMode={true} />

      {/* <WebhookProgressIndicatorModal isAddMode={true} /> */}

      <ActionStatusModal
        showModal={showProcessingModal}
        closeModal={() => dispatch(setShowCreateProcessingModal(false))}
        data={webhook}
        isSuccess={isCreated}
        isProcessing={isCreating}
        isError={isFailed}
        retriesCount={retries}
        errorMessage={ucwords(errorMessage) ?? 'Failed To Create Webhook'}
        successMessage="Webhook Created Successfully"
        retry={() => dispatch(retryToCreate())}
        navigateToView={() =>
          navigate(buildRoute('webhooks.show', webhook?.id ?? params.webhookId))
        }
        resetForm={() => dispatch(addWebhookReset())}
      />
    </NewPageContent>
  );
}

export default withRole([acl.SUPERADMIN, acl.ADMIN])(AddWebhook);
