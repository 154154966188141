import React from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import MuiCheckbox, { CheckboxProps as MuiCheckboxProps } from '@mui/material/Checkbox';
import styled from '@mui/material/styles/styled';

const HelperTextWrapper = styled('div')(({ theme, disabled, error }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
  fontSize: '0.875rem',
  paddingLeft: '2px',
  paddingRight: '2px',
  color: error
    ? theme.palette.critical.main
    : disabled
    ? theme.palette.disabled
    : theme.palette.text.primary,
  svg: {
    stroke: error
      ? theme.palette.critical.main
      : disabled
      ? theme.palette.disabled
      : theme.palette.text.primary,
  },
}));

const HelperText = (props) => {
  if (!props.error) return <></>;

  return (
    <HelperTextWrapper {...props}>
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7.99992 14.6668C11.6666 14.6668 14.6666 11.6668 14.6666 8.00016C14.6666 4.3335 11.6666 1.3335 7.99992 1.3335C4.33325 1.3335 1.33325 4.3335 1.33325 8.00016C1.33325 11.6668 4.33325 14.6668 7.99992 14.6668Z"
          strokeWidth="1.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8 5.3335V8.66683"
          strokeWidth="1.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.99634 10.6665H8.00233"
          strokeWidth="1.75"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
      {props.children}
    </HelperTextWrapper>
  );
};

const CheckBox = (props) => {
  const { label, error, helperText, disabled, ...rest } = props;

  return (
    <>
      <FormControlLabel
        control={<MuiCheckbox {...rest} />}
        label={label}
        disabled={disabled}
        sx={{
          '& .MuiFormControlLabel-label': {
            whiteSpace: 'normal',
            wordWrap: 'break-word',
            width: '100%',
            fontSize: '12px',
          },
        }}
      />
      <HelperText error={error} disabled={disabled}>
        {helperText}
      </HelperText>
    </>
  );
};

export default CheckBox;
