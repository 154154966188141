import { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import { useDispatch, useSelector } from 'react-redux';

import ActiveBadge from 'core/badges/ActiveBadge';
import VerifiedBadge from 'core/badges/VerifiedBadge';
import GroupedTableView from 'core/table/GroupedTableView';
import PartnerClickAwayDetails from 'components/partners/PartnerClickAwayDetails';
import CustomerClickAwayDetails from 'components/customers/CustomerClickAwayDetails';

import Auth from 'utils/Auth';
import isEmpty from 'helpers/isEmpty';
import ucwords from 'helpers/ucwords';
import DateUtils from 'utils/DateUtils';
import useCountries from 'hooks/useCountries';
import { getBeneficiary } from 'app/features/beneficiaries/beneficiarySaga';
import useSourceDetail from 'core/source-detail/useSourceDetail';
import SourceDetails from 'core/source-detail/SourceDetails';

export default function Beneficiary({ beneficiaryId }) {
  const dispatch = useDispatch();

  const { getCountryNameByIso3Code } = useCountries();

  const viewBeneficiaryDetails = useSourceDetail([
    {
      title: 'Basic Information',
      items: [
        {
          label: 'Beneficiary Name',
          accessorKey: 'full_name',
          cell: ({ first_name, middle_name, last_name }) =>
            [first_name, middle_name, last_name]
              .filter((v) => !isEmpty(v))
              .map((v) => ucwords(v))
              .join(' '),
        },
        { label: 'Beneficiary Type', accessorKey: 'beneficiary_type' },
        { label: 'Customer ID', accessorKey: 'user_id' },
        ...(Auth.isSuperAdmin() && [
          {
            label: 'Partner Beneficiary Id',
            accessorKey: 'foxfire_bene_id'
          },
        ]),
        {
          label: 'Customer',
          accessorKey: 'user_name',
          cell: ({ user_id, user_name }) => (
            <CustomerClickAwayDetails customerId={user_id} label={user_name} />
          ),
        },
        { label: 'Relation', accessorKey: 'relation' },
        {
          label: 'Is Active ?',
          accessorKey: 'is_active',
          cell: ({ is_active }) => <ActiveBadge isActive={!!is_active} />,
        },
        {
          label: 'Is Verified ?',
          accessorKey: 'is_verified',
          cell: ({ is_verified }) => <VerifiedBadge isActive={!!is_verified} />,
        },
      ],
    },
    {
      title: 'Partner Information',
      items: [
        ...(Auth.isSuperAdmin()
          ? [
              {
                label: 'Partner',
                accessorKey: 'partner_name',
                cell: ({ partner_id, partner_name }) => (
                  <PartnerClickAwayDetails partnerId={partner_id} label={partner_name} />
                ),
              },
            ]
          : []),
        { label: 'Partner ID', accessorKey: 'partner_id' },
      ],
    },
    {
      title: 'Contact Information',
      items: [
        { label: 'Phone Country Code', accessorKey: 'phone_country_code' },
        { label: 'Mobile Number', accessorKey: 'mobile_number' },
        {
          label: 'Country',
          accessorKey: 'country',
          cell: ({ country }) => getCountryNameByIso3Code(country),
        },
        { label: 'Post Code', accessorKey: 'postcode' },
        { label: 'City', accessorKey: 'city' },
        { label: 'State', accessorKey: 'state' },
        { label: 'Address', accessorKey: 'address' },
      ],
    },
    {
      title: 'Banking Information',
      items: [
        { label: 'Payment Type', accessorKey: 'payment_type' },
        { label: 'Currency', accessorKey: 'currency' },
        { label: 'Account Number', accessorKey: 'account_number' },
        { label: 'Bank Identifier Type', accessorKey: 'bank_identifier_type' },
        { label: 'Bank Identifier Value', accessorKey: 'bank_identifier_value' },
        { label: 'Branch Identifier Type', accessorKey: 'branch_identifier_type' },
        { label: 'Branch Identifier Value', accessorKey: 'branch_identifier_value' },
      ],
    },
    {
      title: 'Identification Details',
      items: [
        { label: 'ID Type', accessorKey: 'id_type' },
        { label: 'ID Number', accessorKey: 'id_number' },
        { label: 'ID Issued Date', accessorKey: 'id_issue_date' },
        { label: 'ID Expiry Date', accessorKey: 'id_expiry_date' },
        { label: 'ID Issued Country', accessorKey: 'id_issued_country' },
      ],
    },
    {
      title: 'Additional Information',
      items: [
        { label: 'Source ID', accessorKey: 'source_id' },
        { label: 'Account ID', accessorKey: 'account_id' },
        {
          label: 'Created At',
          accessorKey: 'created_ts',
          cell: ({ created_ts }) =>
            !isEmpty(created_ts) ? DateUtils.isoToString(created_ts) : '-',
        },
        { label: 'Created By', accessorKey: 'created_by' },
        {
          label: 'Updated At',
          accessorKey: 'updated_ts',
          cell: ({ updated_ts }) =>
            !isEmpty(updated_ts) ? DateUtils.isoToString(updated_ts) : '-',
        },
        { label: 'Updated By', accessorKey: 'updated_by' },
      ],
    },
  ]);

  const {
    isError,
    errorCode,
    isLoading,
    data: beneficiary,
  } = useSelector((state) => state.beneficiaries.show);

  useEffect(() => {
    dispatch(getBeneficiary(beneficiaryId));
  }, [beneficiaryId]);

  return (
    <SourceDetails
      viewMode="row"
      rowMode="row"
      definition={viewBeneficiaryDetails}
      data={beneficiary}
      isLoading={isLoading}
    />
  );
}

Beneficiary.propTypes = {
  beneficiaryId: PropTypes.string,
};
