import React, { useMemo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import { useNavigate } from 'react-router-dom';
import ActiveBadge from 'core/badges/ActiveBadge';
import PopoverButton from 'core/buttons/PopoverButton';
import ListItemButton from '@mui/material/ListItemButton';
import { useSelector, useDispatch } from 'react-redux';
import { useReactTable, getCoreRowModel } from '@tanstack/react-table';

import FormCheckbox from 'core/forms/FormCheckbox';
import TableContainer from 'core/table/TableContainer';
import TanstackReactTable from 'core/table/TanstackReactTable';
import PartnerCountriesActions from './PartnerCountriesActions';
import PartnerCountryPagination from './PartnerCountryPagination';
import AuthUserIsSuperAdmin from 'components/auth/AuthUserIsSuperAdmin';

import Auth from 'utils/Auth';
import app from 'app/config/app';
import { buildRoute } from 'routes';
import isEmpty from 'helpers/isEmpty';
import constants from 'app/config/constants';
import useCountries from 'hooks/useCountries';
import { useConfirm } from 'core/mui-confirm';
import Dimensions from 'app/config/Dimensions';
import useQueryParams from 'hooks/useQueryParams';
import { getPartnerCountryBriefDetailsInit } from 'app/features/partner-countries/partnerCountrySlice';
import {
  getPartnerCountries,
  updateIsActiveStatus,
  deletePartnerCountry,
} from 'app/features/partner-countries/partnerCountrySaga';
import TableContainerWrapper from 'core/table/TableContainerWrapper';

export default function PartnerCountries({ preFilterByPartner, partnerId }) {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const { getCountryNameByIso3Code, countries } = useCountries();

  const confirm = useConfirm();

  const partnerCountriesState = useSelector((state) => state.partnerCountries.index);

  const { params, data, isLoading } = partnerCountriesState;

  const [queryParams] = useQueryParams(app.ignoreFilterKeys);

  const [columnVisibility, setColumnVisibility] = useState({});

  /**
   * handle toggle status
   *
   */
  const handleToggleStatus = (partnerCountryId) => {
    let partnerCountry = data.filter((item) => item.id === partnerCountryId)?.[0];

    if (isEmpty(partnerCountry)) return;

    const confirmationText = partnerCountry.is_active
      ? 'Yes, set as Inactive'
      : 'Yes, set as Active';

    confirm({
      icon: 'warning',
      confirmationText,
      description: 'This will toggle the status of partner country.',
    })
      .then(() =>
        dispatch(
          updateIsActiveStatus({
            partnerCountryId: partnerCountry.id,
            isActive: !partnerCountry.is_active,
          }),
        ),
      )
      .catch(() => {});
  };

  /**
   * handle delete partnerCountry
   *
   */
  const handleDelete = (partnerCountryId) => {
    confirm({
      icon: 'warning',
      confirmationText: 'Yes, Delete it.',
      description: 'This will permanently delete this partner country.',
    })
      .then(() => dispatch(deletePartnerCountry(partnerCountryId)))
      .catch(() => {});
  };

  const columns = useMemo(
    () => [
      {
        id: constants.TABLE_CHECKBOX_SELECT_CHECKBOX_ID,
        header: ({ table }) => (
          <FormCheckbox
            forTableHead={true}
            {...{
              checked: table.getIsAllRowsSelected(),
              indeterminate: table.getIsSomeRowsSelected(),
              onChange: table.getToggleAllRowsSelectedHandler(),
            }}
          />
        ),
        cell: ({ row }) => (
          <div>
            <FormCheckbox
              {...{
                checked: row.getIsSelected(),
                indeterminate: row.getIsSomeSelected(),
                onChange: row.getToggleSelectedHandler(),
              }}
            />
          </div>
        ),
      },
      {
        id: constants.TABLE_SERIAL_NUMBER_COLUMN_ID,
        header: 'SN',
        accessorFn: (row) => row.sn,
      },
      ...(Auth.isSuperAdmin()
        ? [
            {
              header: 'Partner',
              accessorFn: (row) => row.partner_name,
            },
          ]
        : []),
      {
        header: 'Country',
        accessorFn: (row) => getCountryNameByIso3Code(row.iso3),
      },
      {
        header: 'Currency',
        accessorFn: (row) => row.currency,
      },
      {
        header: 'Is Active ?',
        accessorFn: (row) => row.is_active,
        cell: ({ row }) => (
          <ActiveBadge
            isActive={!!row.original.is_active}
            tooltipTitle="Click to toggle status"
            onClick={() => handleToggleStatus(row.original.id)}
          />
        ),
      },
      {
        id: constants.TABLE_ACTION_COLUMN_ID,
        header: 'Action',
        accessorFn: (row) => row.id,
        cell: ({ row }) => (
          <PopoverButton>
            {({ onClose }) => (
              <>
                <ListItemButton
                  onClick={() => {
                    dispatch(
                      getPartnerCountryBriefDetailsInit({
                        showBriefDetailModal: true,
                        partnerCountryId: row.original.id,
                      }),
                    );
                    onClose();
                  }}
                >
                  Preview
                </ListItemButton>
                <ListItemButton
                  onClick={() => {
                    navigate(buildRoute('partner-countries.show', row.original.id));
                    onClose();
                  }}
                >
                  View Details
                </ListItemButton>
                <ListItemButton
                  onClick={() => {
                    navigate(buildRoute('partner-countries.edit', row.original.id));
                    onClose();
                  }}
                >
                  Edit
                </ListItemButton>
                <AuthUserIsSuperAdmin>
                  <ListItemButton
                    onClick={() => handleDelete(row.original.id)}
                    disabled={row.original?.isDeleting || false}
                    color="error"
                  >
                    Delete
                  </ListItemButton>
                </AuthUserIsSuperAdmin>
              </>
            )}
          </PopoverButton>
        ),
      },
    ],
    [JSON.stringify(data), JSON.stringify(countries)],
  );

  const table = useReactTable({
    data,
    columns,
    state: {
      columnVisibility,
    },
    onColumnVisibilityChange: setColumnVisibility,
    getCoreRowModel: getCoreRowModel(),
  });

  useEffect(() => {
    dispatch(
      getPartnerCountries({
        ...queryParams,
        ...params,
        ...(preFilterByPartner
          ? {
              partner_id: partnerId,
            }
          : {}),
      }),
    );
  }, [JSON.stringify(params)]);

  return (
    <TableContainerWrapper>
      {!preFilterByPartner && <PartnerCountriesActions table={table} />}
      <TanstackReactTable
        table={table}
        data={data}
        isLoading={isLoading}
        columnsCount={
          columns.length - Object.entries(columnVisibility ?? {}).filter(([k, v]) => !v).length
        }
      />
      <PartnerCountryPagination />
    </TableContainerWrapper>
  );
}

PartnerCountries.propTypes = {
  preFilterByPartner: PropTypes.bool,
  partnerId: (props, propName, componentName) => {
    if (props['preFilterByPartner'] === true && isEmpty(props[propName])) {
      return new Error(
        `${propName} is required when 'preFilterByPartner' props is 'true' in ${componentName} component`,
      );
    } else if (props['preFilterByPartner'] === true && typeof props[propName] !== 'string') {
      return new Error(`${propName} value must be string ${componentName} component`);
    }
  },
};
