import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import Modal from 'app/components/Modal';
import SourceDetails from 'core/source-detail/SourceDetails';
import useSourceDetail from 'core/source-detail/useSourceDetail';

import { buildRoute } from 'routes';
import DateUtils from 'utils/DateUtils';
import { getTransaction } from 'app/features/transactions/transactionSaga';
import { hideTransactionBriefDetails } from 'app/features/transactions/transactionSlice';

function TransactionPreviewDetailsModal() {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const state = useSelector((state) => state.transactions.show);

  const { showBriefDetailModal, transactionId, isLoading, data } = state;

  const viewDetailColumn = useSourceDetail([
    {
      title: '',
      items: [
        { label: 'Transaction Id', accessorKey: 'id' },
        { label: 'Order Id', accessorKey: 'order_id' },
        { label: 'Customer Name', accessorKey: 'user_name' },
        { label: 'Purpose', accessorKey: 'txn_purpose' },
        { label: 'Sending Amount', accessorKey: 'amount' },
        { label: 'Payout Amount', accessorKey: 'payout_amount' },
        { label: 'Status', accessorKey: 'status' },
        {
          label: 'Created Date',
          accessorKey: 'created_at',
          cell: ({ data }) => <>{DateUtils.isoToString(data)}</>,
        },
        {
          label: 'Updated Date',
          accessorKey: 'updated_at',
          cell: ({ data }) => <>{DateUtils.isoToString(data)}</>,
        },
      ],
    },
  ]);

  useEffect(() => {
    if (transactionId) dispatch(getTransaction(transactionId));
  }, [transactionId]);

  if (!showBriefDetailModal && !transactionId) return <></>;

  return (
    <Modal
      open={showBriefDetailModal ?? false}
      onClose={() => dispatch(hideTransactionBriefDetails())}
      title="Transaction Details"
      handleFullDetails={() => (
        dispatch(hideTransactionBriefDetails()),
        navigate(buildRoute('transactions.show', transactionId))
      )}
    >
      <SourceDetails
        showContainerDesign={false}
        definition={viewDetailColumn}
        data={data}
        isLoading={isLoading}
      />
    </Modal>
  );
}

export default TransactionPreviewDetailsModal;
