import { useEffect } from 'react';
import { compose } from '@reduxjs/toolkit';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import Error from 'core/errors/Error';
import withAppState from 'hoc/withAppState';
import Beneficiaries from 'components/beneficiaries/Beneficiaries';
import { resetGetBeneficiariesFailure, toggleFilter } from 'app/features/beneficiaries/beneficiarySlice';
import NewPageContent from 'layouts/NewPageContent';
import BeneficiaryPreviewDetailsModal from 'components/beneficiaries/BeneficiaryPreviewDetailsModal';
import BeneficiaryTableFilterValues from 'components/beneficiaries/BeneficiaryTableFilterValues';
import FilterButton from 'core/buttons/FilterButton';

function ListBeneficiary({ isNetworkError }) {
  const beneficiaryState = useSelector((state) => state.beneficiaries.index);

  const { isError, errorCode, isFilterOpen } = beneficiaryState;

  const dispatch = useDispatch();

  const navigate = useNavigate();

  useEffect(() => {
    if (isError) dispatch(resetGetBeneficiariesFailure());
  }, [isNetworkError]);

  if (isError)
    return (
      <Error
        status={errorCode}
        withInDashboard={true}
        onRetry={() => dispatch(resetGetBeneficiariesFailure())}
      />
    );

  return (
    <NewPageContent
      breadcrumbs={[
        {
          label: 'List Beneficiaries',
          to: '',
        },
      ]}
      documentTitle="Beneficiaries"
      rightTopEndContent={
        <FilterButton
          variant="outlined"
          isOpen={isFilterOpen}
          onClick={() => dispatch(toggleFilter())}
          uppercase

        />
      }
    >
      {/* <BeneficiaryFilterValues /> */}
      <BeneficiaryTableFilterValues />
      <Beneficiaries />
      <BeneficiaryPreviewDetailsModal />
    </NewPageContent>
  );
}

export default compose(withAppState)(ListBeneficiary);
