import qs from 'qs';
import { createAction } from '@reduxjs/toolkit';
import { takeLatest, put } from 'redux-saga/effects';

import http from 'app/services/httpService';
import { callWrapperSaga } from 'app/services/sagaService';
import apiEndpoints, { buildApiRoute } from 'routes/apiEndpoints';

import {
  getCustomersKycCountInit,
  getCustomersKycCountSuccess,
  getCustomersKycCountFailure,
} from './customersKycCountSlice';

const getCustomersKycCount = createAction('customersKycCount/list');

/**
 * get a single customer
 */

/**
 * get a customers list
 *
 */

function* getCustomersKycCountSaga({ payload }) {
  yield put(getCustomersKycCountInit());
  try {
    let endpoint = `${apiEndpoints.getCustomerKycCount}?${qs.stringify(payload)}`;
    let response = yield callWrapperSaga(() => http.get(endpoint));

    yield put(getCustomersKycCountSuccess(response.data));
  } catch (err) {
    yield put(getCustomersKycCountFailure(err));
  }
}

function* customersKycCountSaga() {
  yield takeLatest(getCustomersKycCount, getCustomersKycCountSaga);
}

export default customersKycCountSaga;
export { getCustomersKycCount };
