import React from 'react';
import { buildRoute } from 'routes';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import Roles from 'components/Roles/Roles';
import ActionButton from 'core/buttons/ActionButton';

import NewPageContent from 'layouts/NewPageContent';

const ListRoles = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return (
    <NewPageContent
      breadcrumbs={[
        {
          label: 'Roles',
          to: '',
        },
      ]}
      documentTitle="Roles"
      rightTopEndContent={
        <ActionButton onClick={() => navigate(buildRoute('roles.create'))}>ADD Roles</ActionButton>
      }
    >
      <Roles />
    </NewPageContent>
  );
};

export default ListRoles;
