import { useEffect, memo } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import Collapse from '@mui/material/Collapse';
import Typography from '@mui/material/Typography';

import { FormProvider, useForm } from 'react-hook-form';

import Button from 'core/buttons/Button';

import isEmpty from 'helpers/isEmpty';
import Spacer from 'core/spacer/Spacer';
import cleanObject from 'helpers/cleanObject';
import BuildFilterInputValue from 'core/filter/BuildFilterInputValue';
import { yupResolver } from '@hookform/resolvers/yup';
import styled from '@emotion/styled';

const ButtonBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  gap: theme.spacing(2),

  [theme.breakpoints.down('sm')]: {
    display: 'flex',
    flexDirection: 'column',
  },
}));

const InputLabel = styled(Typography)(({ theme }) => ({
  // marginBottom={0.5} color="#1B2037" fontWeight={500}

  marginBottom: 0.5,
  color: '#1B2037',
  fontWeight: 500,

  [theme.breakpoints.down('sm')]: {
    fontSize: '0.875rem',
  },
}));

const FilterButton = styled(Box)(({ theme }) => ({
  paddingX: 6,
  borderRadius: 2,
}));

function TableGridFilter({
  showFilter,
  availableFilters,
  appliedFilters,
  onReset,
  onFilter,
  formResolver,
}) {
  const methods = useForm({
    ...(formResolver
      ? {
          resolver: yupResolver(formResolver),
        }
      : undefined),
  });

  const { handleSubmit, setValue } = methods;

  const handleReset = () => {
    // reset({});
    availableFilters.forEach((filter) => {
      setValue(filter.value, '');
    });

    if (Object.prototype.toString.call(onReset) === '[object Function]') onReset();
  };

  const onSubmit = async (data) => {
    if (Object.prototype.toString.call(onFilter) === '[object Function]')
      onFilter(cleanObject(data));
  };

  useEffect(() => {
    let availableFilterKeys = availableFilters.map((filter) => filter.value);
    for (let [key, value] of Object.entries(appliedFilters)) {
      if (availableFilterKeys.includes(key) && !isEmpty(value)) {
        setValue(key, value);
      }
    }
  }, [JSON.stringify(appliedFilters)]);

  return (
    <Collapse
      in={showFilter}
      style={{ transformOrigin: '0 0 0' }}
      {...(showFilter ? { timeout: 300 } : {})}
    >
      <Box sx={{ p: 2 }}>
        <Box
          sx={{
            p: 2,
            border: (theme) => `1px solid ${theme.palette.grey[400]}`,
            borderRadius: '0.5rem',
          }}
        >
          <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid container spacing={3}>
                {availableFilters.map((filter, i) => {
                  return (
                    <Grid key={i} item xs={12} mg={6} lg={3}>
                      {/* <InputLabel>{filter.label}</InputLabel> */}
                      <BuildFilterInputValue filter={filter} filterByKey={filter.value} />
                    </Grid>
                  );
                })}
                <Grid item xs={12}>
                  <ButtonBox>
                    <Button
                      type="button"
                      onClick={handleReset}
                      size="large"
                      variant="outlined"
                      sx={{
                        px: 6,
                        borderRadius: '2rem',
                      }}
                    >
                      Reset
                    </Button>
                    <Button
                      type="submit"
                      size="large"
                      variant="contained"
                      sx={{ px: 6, borderRadius: '2rem' }}
                    >
                      Filter
                    </Button>
                  </ButtonBox>
                </Grid>
              </Grid>
            </form>
          </FormProvider>
        </Box>
      </Box>

      <Spacer />
    </Collapse>
  );
}

export default memo(TableGridFilter);

TableGridFilter.propTypes = {
  id: PropTypes.string,
  showFilter: PropTypes.bool,
  availableFilters: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.any,
    }),
  ),
  appliedFilters: PropTypes.object,
  onReset: PropTypes.func,
  onFilter: PropTypes.func,
};

TableGridFilter.defaultValues = {
  showFilter: true,
  availableFilters: [],
  appliedFilters: {},
};
