import { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import Modal from 'app/components/Modal';
import KycStatusBadge from 'core/badges/KycStatusBadge';
import SourceDetails from 'core/source-detail/SourceDetails';
import useSourceDetail from 'core/source-detail/useSourceDetail';
import PartnerClickAwayDetails from 'components/partners/PartnerClickAwayDetails';
import CustomerClickAwayDetails from 'components/customers/CustomerClickAwayDetails';

import Auth from 'utils/Auth';
import { buildRoute } from 'routes';
import { getCustomerKyc } from 'app/features/customer-kycs/customerKycSaga';
import { hideCustomerKycBriefDetails } from 'app/features/customer-kycs/customerKycSlice';

function CustomerKycPreviewDetailsModal() {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const viewDetailColumn = useSourceDetail([
    {
      title: '',
      items: [
        {
          label: 'Customer Name',
          accessorKey: 'user_name',
        },
        {
          label: 'Customer ID',
          accessorKey: 'user_id',
          cell: ({ user_id }) => <CustomerClickAwayDetails customerId={user_id} />,
        },
        {
          label: 'KYC Status',
          accessorKey: 'kyc_status',
          cell: ({ kyc_status }) => <KycStatusBadge status={kyc_status} />,
        },
        {
          label: 'KYC Method',
          accessorKey: 'kyc_method',
        },
        {
          label: 'KYC Type',
          accessorKey: 'kyc_type',
        },
        ...(Auth.isSuperAdmin()
          ? [
              {
                label: 'Partner',
                accessorKey: 'partner_id',
                cell: ({ partner_id, partner_name }) => (
                  <PartnerClickAwayDetails label={partner_name} partnerId={partner_id} />
                ),
              },
            ]
          : []),
      ],
    },
  ]);

  const state = useSelector((state) => state.customerKycs.show);

  const { showBriefDetailModal, customerKycId, isLoading, data } = state;

  useEffect(() => {
    if (customerKycId) dispatch(getCustomerKyc(customerKycId));
  }, [customerKycId]);

  if (!showBriefDetailModal && !customerKycId) return <></>;

  return (
    <Modal
      open={showBriefDetailModal ?? false}
      onClose={() => dispatch(hideCustomerKycBriefDetails())}
      title="Transaction Details"
      handleFullDetails={() => (
        dispatch(hideCustomerKycBriefDetails()),
        navigate(buildRoute('customer-kycs.show', customerKycId))
      )}
    >
      <SourceDetails
        showContainerDesign={false}
        definition={viewDetailColumn}
        data={data}
        isLoading={isLoading}
      />
    </Modal>
  );
}

export default CustomerKycPreviewDetailsModal;
