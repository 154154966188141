const routePaths = {
  ListTransactions: '/transactions',
  ZaiTransactions: '/transactions/zai',
  ListFundingSources: '/partner-funding-sources',
  ListFundingSourceInfo: '/funding-source-info',
  ListPartners: '/partners',
  ListPartnerCountries: '/partner-countries',
  ListPartnerFundingSource: '/partner-funding-sources',
  ListAccounts: '/accounts',
  ListCustomers: '/customers',
  ListCUstomerKyc: '/customer-kycs',
  ListBeneficiary: '/beneficiaries',
  ListWebhooks: '/webhooks',
  ListWebhookHeaders: '/webhook-headers',
  ListFinmoPayins: '/finmo/payins',
  ListFinmoPayouts: '/finmo/payouts',
  AddFundingSource: '/funding-sources/add',
  AddWebhookHeader: '/webhook-headers/add',
  ListZaiWebhookLogs: '/logs/webhook-logs',
  ListZaiRefundLogs: '/logs/refund-logs',
  ListLicensingPartners: '/licensing-partners',
  ListFundingSource: '/funding-sources',
  ListPartnerFundingSourceInfo: '/funding-source-info',
  ListApiConfigs: '/api-configs',
  ListRoles: '/user/roles',
  ListAbout: '/about',
};

export default routePaths;
