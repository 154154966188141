import { useEffect } from 'react';
import { compose } from '@reduxjs/toolkit';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import withRole from 'hoc/withRole';
import Error from 'core/errors/Error';
import withAppState from 'hoc/withAppState';
import ActionButton from 'core/buttons/ActionButton';
import AuthUserIsSuperAdmin from 'components/auth/AuthUserIsSuperAdmin';
import PartnerFundingSources from 'components/partner-funding-sources/PartnerFundingSources';
import PartnerFundingSourceFilterValues from 'components/partner-funding-sources/PartnerFundingSourceFilterValues';

import Auth from 'utils/Auth';
import acl from 'app/config/acl';
import { buildRoute } from 'routes';
import routePaths from 'app/config/routePaths';
import NewPageContent from 'layouts/NewPageContent';
import { resetGetPartnerFundingSourceFailure } from 'app/features/partner-funding-sources/partnerFundingSourceSlice';
import PartnerFundingSourcePreviewDetailsModal from 'components/partner-funding-sources/PartnerFundingSourcePreviewDetailsModal';

function ListPartnerFundingSource({ isNetworkError }) {
  const partnerFundingSourceState = useSelector((state) => state.partnerFundingSources.index);

  const { isError, errorCode } = partnerFundingSourceState;

  const dispatch = useDispatch();

  const navigate = useNavigate();

  useEffect(() => {
    if (isError) dispatch(resetGetPartnerFundingSourceFailure());
  }, [isNetworkError]);

  if (isError)
    return (
      <Error
        status={errorCode}
        withInDashboard={true}
        onRetry={() => dispatch(resetGetPartnerFundingSourceFailure())}
      />
    );

  return (
    <NewPageContent
      breadcrumbs={[
        {
          label: 'Funding Source',
          to: routePaths.ListFundingSources,
        },
      ]}
      documentTitle={Auth.isSuperAdmin() ? 'Partner Funding Sources' : 'Funding Sources'}
      rightTopEndContent={
        <AuthUserIsSuperAdmin>
          <ActionButton onClick={() => navigate(buildRoute('partner-funding-sources.create'))}>
            Add Partner Funding Source
          </ActionButton>
        </AuthUserIsSuperAdmin>
      }
    >
      <PartnerFundingSourceFilterValues />
      <PartnerFundingSources />
      <PartnerFundingSourcePreviewDetailsModal />
    </NewPageContent>
  );
}

export default compose(
  withAppState,
  withRole([acl.SUPERADMIN, acl.ADMIN, acl.USER]),
)(ListPartnerFundingSource);
