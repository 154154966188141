import PropTypes from 'prop-types';

import React from 'react';
import styled from '@emotion/styled';
import Box from '@mui/material/Box';

const ButtonWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column-reverse',
    gap: '1rem',
  },
}));

const ButtonContainer = ({ children, sx }) => {
  return <ButtonWrapper sx={{ sx }}>{children}</ButtonWrapper>;
};

export default ButtonContainer;

ButtonContainer.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.func,
    PropTypes.string,
  ]),
};
