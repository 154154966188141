import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import PopoverButton from 'core/buttons/PopoverButton';
import ListItemButton from '@mui/material/ListItemButton';
import { useEffect, useMemo, useState, useContext } from 'react';
import { getCoreRowModel, useReactTable } from '@tanstack/react-table';

import Error from 'core/errors/Error';
import ActiveBadge from 'core/badges/ActiveBadge';
import FormCheckbox from 'core/forms/FormCheckbox';
import VerifiedBadge from 'core/badges/VerifiedBadge';
import TransactionPagination from './TransactionPagination';
import TanstackReactTable from 'core/table/TanstackReactTable';

import { buildRoute } from 'routes';
import isEmpty from 'helpers/isEmpty';
import DateUtils from 'utils/DateUtils';
import useAuthUser from 'hooks/useAuthUser';
import constants from 'app/config/constants';
import { fundingSources } from 'app/config/FundingSource';
import { transactionStatuses } from 'app/config/TransactionStatus';
import TableContainerWrapper from 'core/table/TableContainerWrapper';
import TransactionStatusBadge from 'core/badges/TransactionStatusBadge';

import {
  resetParam,
  getTransactionBriefDetailsInit,
} from 'app/features/transactions/transactionSlice';
import TransactionActions from './TransactionsActions';
import { getTransactions } from 'app/features/transactions/transactionSaga';
import TransactionPreviewDetailsModal from './TransactionPreviewDetailsModal';
import PendingTransactionsContext from 'app/context/PendingTransactionsContext';
export default function Transactions({ filterByCustomer, customerId }) {
  const dispatch = useDispatch();
  const [showFilter, setShowFilter] = useState(false);
  const [open, setIsOpen] = useState(true);

  const handleToggleFilter = () => {
    setShowFilter(!showFilter);
  };

  const isPendingTransactionsContext = useContext(PendingTransactionsContext);

  const navigate = useNavigate();

  const user = useAuthUser();

  const [columnVisibility, setColumnVisibility] = useState({});

  const transactionState = useSelector((state) => state.transactions.index);

  const { data, params, isLoading, isError, errorCode } = transactionState;

  const columns = useMemo(
    () => [
      {
        id: constants.TABLE_CHECKBOX_SELECT_CHECKBOX_ID,
        header: ({ table }) => (
          <FormCheckbox
            forTableHead={true}
            {...{
              checked: table.getIsAllRowsSelected(),
              indeterminate: table.getIsSomeRowsSelected(),
              onChange: table.getToggleAllRowsSelectedHandler(),
            }}
          />
        ),
        cell: ({ row }) => (
          <div>
            <FormCheckbox
              {...{
                checked: row.getIsSelected(),
                indeterminate: row.getIsSomeSelected(),
                onChange: row.getToggleSelectedHandler(),
              }}
            />
          </div>
        ),
      },
      {
        id: constants.TABLE_SERIAL_NUMBER_COLUMN_ID,
        header: 'SN',
        accessorFn: (row) => row.sn,
      },
      {
        header: 'Customer',
        accessorFn: (row) => row.user_name,
      },
      ...(user?.isSuperAdmin()
        ? [
            {
              header: 'Partner Name',
              accessorFn: (row) => row.partner_name,
            },
          ]
        : []),
      {
        header: 'Funding Type',
        accessorFn: (row) => row.funding_type,
      },

      {
        header: 'Purpose',
        accessorFn: (row) => row.txn_purpose,
      },
      {
        header: 'Amount',
        accessorFn: (row) => row.amount,
      },
      {
        header: 'Currency',
        accessorFn: (row) => row.currency,
      },
      ...(!isPendingTransactionsContext
        ? [
            {
              header: 'Status',
              accessorFn: (row) => row.status,
              cell: (info) => {
                return (
                  <>
                    <TransactionStatusBadge status={info.getValue()} />
                  </>
                );
              },
            },
          ]
        : []),
      {
        header: 'Created Date',
        accessorFn: (row) => DateUtils.isoToString(row.created_ts),
      },
      {
        header: 'Updated Date',
        accessorFn: (row) => (row.updated_ts ? DateUtils.isoToString(row.updated_ts) : '━━'),
      },

      {
        header: 'Is Verified ?',
        accessorFn: (row) => row.is_verified,
        cell: (info) => {
          return <VerifiedBadge isVerified={!!info.getValue()} />;
        },
      },
      {
        header: 'Is Active ?',
        accessorFn: (row) => row.is_active,
        cell: (info) => <ActiveBadge isActive={!!info.getValue()} />,
      },
      {
        id: constants.TABLE_ACTION_COLUMN_ID,
        header: 'Action',
        accessorFn: (row) => row.id,

        cell: (info) => {
          return (
            <PopoverButton>
              {({ onClose }) => (
                <>
                  <ListItemButton
                    onClick={() => {
                      dispatch(
                        getTransactionBriefDetailsInit({
                          showBriefDetailModal: true,
                          transactionId: info.getValue(),
                        }),
                      );
                      onClose();
                    }}
                  >
                    Preview
                  </ListItemButton>
                  <ListItemButton
                    onClick={() =>
                      navigate(buildRoute('transactions.show', info.getValue()), onClose())
                    }
                  >
                    View Details
                  </ListItemButton>
                </>
              )}
            </PopoverButton>
          );
        },
      },
    ],
    [isPendingTransactionsContext],
  );

  const table = useReactTable({
    data,
    columns,
    state: {
      columnVisibility,
    },
    onColumnVisibilityChange: setColumnVisibility,
    getCoreRowModel: getCoreRowModel(),
  });

  useEffect(() => {
    dispatch(
      getTransactions({
        ...params,
        ...(isPendingTransactionsContext
          ? {
              status: transactionStatuses.created,
              funding_type: isEmpty(params.funding_type)
                ? [fundingSources.payID, fundingSources.bankTransfer, fundingSources.payNowQR]
                : params.funding_type,
            }
          : {}),
        ...(filterByCustomer
          ? {
              user_id: customerId,
            }
          : {}),
      }),
    );
  }, [JSON.stringify(params), isPendingTransactionsContext]);

  useEffect(() => {
    dispatch(resetParam());
  }, [isPendingTransactionsContext]);

  if (isError) return <Error status={errorCode} />;

  return (
    <>
      <TableContainerWrapper>
        <TransactionActions
          table={table}
          showFilter={showFilter}
          onToggleFilter={handleToggleFilter}
          enableFilterByPartner={!filterByCustomer}
          defaultFilterQueries={{
            ...(filterByCustomer
              ? {
                  user_id: customerId,
                }
              : {}),
          }}
        />
        <TanstackReactTable
          table={table}
          isLoading={isLoading}
          data={data}
          columnsCount={
            columns.length - Object.entries(columnVisibility ?? {}).filter(([k, v]) => !v).length
          }
        />
        <TransactionPagination />
      </TableContainerWrapper>

      <TransactionPreviewDetailsModal />
    </>
  );
}
