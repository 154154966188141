import { buildRoute } from 'routes';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import Modal from 'app/components/Modal';
import SourceDetails from 'core/source-detail/SourceDetails';
import { getAccount } from 'app/features/accounts/accountSaga';
import useSourceDetail from 'core/source-detail/useSourceDetail';
import { hideAccountBriefDetails } from 'app/features/accounts/accountSlice';
import PartnerClickAwayDetails from 'components/partners/PartnerClickAwayDetails';

import useAuthUser from 'hooks/useAuthUser';

function AccountPreviewDetailsModal() {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const user = useAuthUser();

  const state = useSelector((state) => state.accounts.show);

  const { showBriefDetailModal, accountId, isLoading, data } = state;

  useEffect(() => {
    if (accountId) dispatch(getAccount(accountId));
  }, [accountId]);

  if (!showBriefDetailModal && !accountId) return <></>;

  const viewDetailColumn = useSourceDetail([
    {
      title: '',
      items: [
        { label: 'Name', accessorKey: 'name' },
        { label: 'Email', accessorKey: 'email' },
        { label: 'Phone Number', accessorKey: 'phone_number' },
        { label: 'Role', accessorKey: 'user_type' },
        ...(user?.isSuperAdmin()
          ? [
              {
                label: 'Partner ID',
                accessorKey: 'partner_id',
                cell: ({ partner_id, partner_name }) => (
                  <PartnerClickAwayDetails partnerId={partner_id} label={partner_name} />
                ),
              },
            ]
          : []),
      ],
    },
  ]);

  return (
    <Modal
      open={showBriefDetailModal ?? false}
      onClose={() => dispatch(hideAccountBriefDetails())}
      title="Account Details"
      handleFullDetails={() => (
        dispatch(hideAccountBriefDetails()), navigate(buildRoute('accounts.show', accountId))
      )}
    >
      <SourceDetails
        showContainerDesign={false}
        definition={viewDetailColumn}
        data={data}
        isLoading={isLoading}
      />
    </Modal>
  );
}

export default AccountPreviewDetailsModal;
