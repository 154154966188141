import { useEffect } from 'react';
import Grid from '@mui/material/Grid';
import { compose } from '@reduxjs/toolkit';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';

import withRole from 'hoc/withRole';
import Error from 'core/errors/Error';
import withAppState from 'hoc/withAppState';
import Transaction from 'components/transactions/Transaction';

import acl from 'app/config/acl';
import routePaths from 'app/config/routePaths';
import NewPageContent from 'layouts/NewPageContent';
import ViewTransactionDetailDocument from './ViewTransactionDetailDocument';
import { resetGetTransactionFailure } from 'app/features/transactions/transactionSlice';

function ViewTransaction({ isNetworkError }) {
  const params = useParams();

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const { isError, errorCode, errorMessage } = useSelector((state) => state.transactions.show);

  useEffect(() => {
    if (isError) dispatch(resetGetTransactionFailure());
  }, [isNetworkError]);

  if (isError)
    return (
      <Error
        status={errorCode}
        message={errorMessage}
        onRetry={() => dispatch(resetGetTransactionFailure())}
      />
    );

  return (
    <NewPageContent
      title="Transaction Details"
      breadcrumbs={[
        {
          label: 'Transactions',
          to: routePaths.ListTransactions,
        },
        {
          label: 'Transaction Details',
          to: '',
        },
      ]}
      documentTitle="Transactions"
    >
      <Grid container spacing={1}>
        <Grid item xs={12} md={9}>
          <Transaction transactionId={params.transactionId} />
        </Grid>
        <Grid
          xs={12}
          md={2}
          item
          marginTop={1}
          sx={{
            backgroundColor: (theme) => theme.palette.primary.light,

            padding: '1rem',
            borderRadius: 4,
            boxShadow: '10px 8px 10px 2px rgba(0, 0, 0, 0.06)',
          }}
        >
          <ViewTransactionDetailDocument transactionId={params.transactionId} />
        </Grid>
      </Grid>
    </NewPageContent>
  );
}

export default compose(
  withAppState,
  withRole([acl.SUPERADMIN, acl.ADMIN, acl.USER]),
)(ViewTransaction);
