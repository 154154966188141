const ColorPalette = {
  primary: {
    // light: '#4f5789',
    light: '#ffffff',
    soft: '#D6E7FB',
    main: '#105BB7',
    // main: "#ffffff",
    dark: '#181f4b',
  },
  success: {
    lighter: '#E9FCD4',
    light: '#AAF27F',
    main: '#54D62C',
    dark: '#229A16',
    darker: '#08660D',
    contrastText: '#fff',
  },
  error: {
    lighter: '#FFE7D9',
    light: '#ff7975',
    main: '#FF4842',
    dark: '#B72136',
    darker: '#B72136',
    contrastText: '#fff',
  },
  info: {
    lighter: '#D6E7FB',
    light: '#74CAFF',
    main: '#1890FF',
    dark: '#0C53B7',
    darker: '#04297A',
    contrastText: '#fff',
  },
  warning: {
    lighter: '#FFF7CD',
    light: '#FFE16A',
    main: '#FFC107',
    dark: '#B78103',
    darker: '#7A4F01',
    contrastText: '#fff',
  },
  grey: {
    50: '#fafafa',
    100: '#F9FAFB',
    200: '#F4F6F8',
    300: '#DFE3E8',
    400: '#C4CDD5',
    500: '#919EAB',
    600: '#637381',
    700: '#454F5B',
    800: '#212B36',
    900: '#161C24',
  },
  menuActive: '#BA2C73',
  subMenuActive: '#6B275D',
};

export default ColorPalette;
