import { Box, Grid, Typography } from '@mui/material';
import CheckBox from 'core/forms/CheckBox';
import { RoleSwitch } from 'core/Role/RoleSwitch';
import isEmpty from 'helpers/isEmpty';
import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';

const RolesTabs = ({ modules }) => {
  const [selectedModule, setSelectedModule] = useState(null);
  const [selectedModuleSubPermissions, setSelectedModuleSubPermissions] = useState({});

  const { setValue } = useFormContext();

  useEffect(() => {
    if (modules?.length > 0) {
      setSelectedModule(modules[0]);
    }
  }, [modules]);

  useEffect(() => {
    if (!selectedModule) return;

    const subPermissionModule = selectedModule.entities?.reduce((acc, entity) => {
      entity?.permissions?.forEach((permission) => {
        if (!isEmpty(permission?.subPermissions)) {
          acc[`${entity.name} ${permission.name}`] = permission.subPermissions;
        }
      });
      return acc;
    }, {});

    setSelectedModuleSubPermissions((prev) =>
      JSON.stringify(prev !== JSON.stringify(subPermissionModule) ? subPermissionModule : prev),
    );
  }, [selectedModule]);

  const handleSubPermissionChange = (e, entityName, permissionName, subPermissionName) => {
    const moduleIdx = modules.findIndex((module) => module.code === selectedModule?.code);
    const entity = selectedModule?.entities?.find((entity) => entity.name === entityName);
    const permission = entity?.permissions?.find(
      (permission) => permission.name === permissionName,
    );

    const eIdx = selectedModule?.entities?.findIndex((entity) => entity.name === entityName);
    const pIdx =
      eIdx !== undefined
        ? selectedModule?.entities?.[eIdx]?.permissions?.findIndex((p) => p.name === permissionName)
        : -1;

    if (permission?.subPermissions) {
      if (!e.target.checked) {
        permission.subPermissions = permission.subPermissions.filter(
          (subPermission) => subPermission !== subPermissionName,
        );
      }
    }

    setValue(`modules.${moduleIdx}.entities.${eIdx}.permissions.${pIdx}`, permission);
  };

  const handleAllSubPermissionChange = (e, entityName, permissionName) => {
    const moduleIdx = modules.findIndex((module) => module.code === selectedModule?.code);
    const entity = selectedModule?.entities?.find((entity) => entity.name === entityName);
    const permission = entity?.permissions?.find(
      (permission) => permission.name === permissionName,
    );

    const eIdx = selectedModule?.entities?.findIndex((entity) => entity.name === entityName);
    const pIdx =
      eIdx !== undefined
        ? selectedModule?.entities?.[eIdx]?.permissions?.findIndex((p) => p.name === permissionName)
        : -1;

    if (permission?.subPermissions) {
      permission.subPermissions = e.target.checked
        ? selectedModuleSubPermissions[`${entityName} ${permissionName}`]
        : [];
    }

    setValue(`modules.${moduleIdx}.entities.${eIdx}.permissions.${pIdx}`, permission);
  };

  const handlePermissionByModule = (e, mIdx) => {
    const recentSelectedModule = modules[mIdx];

    recentSelectedModule?.entities?.forEach((entity) => {
      entity?.permissions?.forEach((permission) => {
        if (permission) {
          permission.allowed = e.target.checked;
        }
      });
    });

    setSelectedModule(recentSelectedModule);
    setValue(`modules.${mIdx}`, recentSelectedModule);
  };

  const handlePermissionByEntityName = (e, entityIdx) => {
    const recentSelectedModuleIndex = modules.findIndex(
      (module) => module.code === selectedModule?.code,
    );
    const recentSelectedModule = modules[recentSelectedModuleIndex];

    recentSelectedModule?.entities?.[entityIdx]?.permissions?.forEach((permission) => {
      if (permission) {
        permission.allowed = e.target.checked;
      }
    });

    setSelectedModule(recentSelectedModule);
    setValue(`modules.${recentSelectedModuleIndex}`, recentSelectedModule);
  };

  const handleIndividualPermissionsChange = (e, eIdx, pIdx) => {
    const mIdx = modules.findIndex((module) => module.code === selectedModule?.code);
    const permission = modules[mIdx]?.entities?.[eIdx]?.permissions?.[pIdx];

    if (permission) {
      permission.allowed = e.target.checked;
    }

    const updatedSelectedModule = {
      ...selectedModule,
      entities: selectedModule?.entities?.map((entity, index) =>
        index === eIdx
          ? {
              ...entity,
              permissions: entity.permissions?.map((p, index) =>
                index === pIdx ? { ...p, allowed: e.target.checked } : p,
              ),
            }
          : entity,
      ),
    };

    setSelectedModule(updatedSelectedModule);
    setValue(`modules.${mIdx}.entities.${eIdx}.permissions.${pIdx}`, permission);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={3}>
        <Box
          display="flex"
          flexDirection="column"
          sx={(theme) => ({
            borderRight: `1px solid ${theme.palette.divider}`,
            padding: '8px',
            cursor: 'pointer',
          })}
        >
          {modules?.map((module, moduleIndex) => {
            const isCurrentModule = selectedModule?.code === module?.code;
            const isAllModuleChecked = module?.entities?.some((entity) => {
              return entity?.permissions?.some((permission) => permission?.allowed);
            });
            return (
              <Box
                display="flex"
                flexDirection="column"
                key={module?.code}
                p={1}
                gap={1}
                onClick={() => setSelectedModule(module)}
                sx={(theme) => ({
                  // background: isCurrentModule ? theme.palette.primary.lighter : '',
                  backgroundColor: (theme) => (isCurrentModule ? theme.palette.primary.soft : ''),
                  borderRadius: '5px',
                })}
              >
                <Typography
                  fontSize={14}
                  fontWeight={isCurrentModule ? 500 : 400}
                  sx={(theme) => ({
                    color: isCurrentModule
                      ? theme.palette.primary.main
                      : theme.palette.text.secondary,
                  })}
                >
                  {module?.name}
                </Typography>
                {isCurrentModule && (
                  <Box display="flex" flexDirection="row" gap={'5px'}>
                    <RoleSwitch
                      onChange={(e) => {
                        handlePermissionByModule(e, moduleIndex);
                      }}
                      checked={isAllModuleChecked}
                    />
                    <Typography fontSize={12} color="black">
                      Enable All
                    </Typography>
                  </Box>
                )}
              </Box>
            );
          })}
        </Box>
      </Grid>
      <Grid
        item
        xs={9}
        sx={{
          maxHeight: '500px',
          overflowY: 'auto',
        }}
      >
        <Grid container spacing={2}>
          {selectedModule?.entities?.map((entity, entityIndex) => {
            const isSubPermissionModuleEmpty = isEmpty(selectedModuleSubPermissions);
            const isAllEntityPermissionChecked = entity?.permissions?.some(
              (permission) => permission?.allowed,
            );
            return (
              <>
                <Grid item xs={12} lg={isSubPermissionModuleEmpty ? 5 : 6} key={entity?.code}>
                  <Box
                    display="flex"
                    flexDirection="column"
                    gap={1}
                    p={1}
                    sx={(theme) => ({
                      background: theme.palette.grey[50],
                      borderRadius: '5px',
                      border: `1px solid ${theme.palette.divider}`,
                      height: '100%',
                    })}
                  >
                    <Box
                      display="flex"
                      flexDirection="row"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Typography fontSize={16} fontWeight={600}>
                        {entity?.name}
                      </Typography>
                      <RoleSwitch
                        checked={isAllEntityPermissionChecked}
                        onChange={(e) => {
                          handlePermissionByEntityName(e, entityIndex);
                        }}
                      />
                    </Box>
                    <Grid container>
                      {entity?.permissions?.map((permission, permissionIndex) => {
                        return (
                          <>
                            {permission ? (
                              <Grid item xs={6} key={permission?.code} p={'2px'}>
                                <CheckBox
                                  label={permission?.name}
                                  checked={permission?.allowed}
                                  onChange={(e) => {
                                    handleIndividualPermissionsChange(
                                      e,
                                      entityIndex,
                                      permissionIndex,
                                    );
                                  }}
                                />
                              </Grid>
                            ) : null}
                          </>
                        );
                      })}
                    </Grid>
                  </Box>
                </Grid>
              </>
            );
          })}
          {Object.values(selectedModuleSubPermissions).map((subPermissions, index) => {
            const name = Object.keys(selectedModuleSubPermissions)[index]?.split(' ');

            const allSubPermissionModule = selectedModule?.entities
              ?.find((entity) => entity?.name === name[0])
              ?.permissions?.find((permission) => permission?.name === name[1])?.subPermissions;

            const isAllSubPermissionChecked =
              allSubPermissionModule?.length === subPermissions?.length;

            return (
              <>
                <Grid item xs={12} lg={7} key={index}>
                  <Box
                    display="flex"
                    flexDirection="column"
                    gap={1}
                    p={1}
                    sx={(theme) => ({
                      background: theme.palette.grey[50],
                      borderRadius: '5px',
                      border: `1px solid ${theme.palette.divider}`,
                      overflowX: 'auto',
                      height: '100%',
                    })}
                  >
                    <Box
                      display="flex"
                      flexDirection="row"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Box display="flex" flexDirection="column">
                        <Typography fontSize={16} fontWeight={600}>
                          {Object.keys(selectedModuleSubPermissions)[index]}
                        </Typography>
                        <Typography fontSize={12}>Sub Permissions</Typography>
                      </Box>
                      <RoleSwitch
                        checked={isAllSubPermissionChecked}
                        onChange={(e) => {
                          handleAllSubPermissionChange(e, name[0], name[1]);
                        }}
                      />
                    </Box>
                    <Grid container>
                      {subPermissions && Array.isArray(subPermissions)
                        ? subPermissions.map((subPermission, subPermissionIndex) => {
                            const isSubPermissionChecked =
                              allSubPermissionModule?.includes(subPermission);
                            return (
                              <Grid item xs={6} key={subPermission} p={'2px'}>
                                <CheckBox
                                  label={subPermission}
                                  onChange={(e) => {
                                    handleSubPermissionChange(e, name[0], name[1], subPermission);
                                  }}
                                  checked={isSubPermissionChecked}
                                />
                              </Grid>
                            );
                          })
                        : null}
                    </Grid>
                  </Box>
                </Grid>
              </>
            );
          })}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default RolesTabs;
