import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import cleanObject from 'helpers/cleanObject';
import styled from '@emotion/styled';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import Button from 'core/buttons/Button';

const FilterChip = styled(Chip)(({ theme }) => ({
  color: theme.palette.primary.main,
  backgroundColor: theme.palette.primary.soft,

  '& .MuiChip-icon': {
    backgroundColor: 'red',
  },
}));

const DeleteIcon = styled(HighlightOffOutlinedIcon)(({ theme }) => ({
  fill: theme.palette.primary.main,
}));

const ClearAllButton = styled(Button)(({ theme }) => ({
  background: 'transparent',
  color: theme.palette.primary.main,
  '&:hover': {
    backgroundColor: 'transparent',
  },
}));

const formattedLabel = (str) => {
  return str.replace(/_/g, ' ').replace(/\b\w/g, (char) => char.toUpperCase());
};

function FilterValues({ value, onRemoveFilter, ignoreKeys, RemoveAllFilter }) {
  const [filter, setFilter] = useState({});

  useEffect(() => {
    let filterWithIgnoreKeys = cleanObject({ ...value });
    ignoreKeys.forEach((element) => delete filterWithIgnoreKeys[element]);
    setFilter(filterWithIgnoreKeys);
  }, [JSON.stringify(value)]);

  if (Object.entries(filter).length > 0)
    return (
      <Stack direction="row" spacing={1} sx={{ mb: 3, alignItems: 'center' }}>
        {Object.keys(filter).map(
          (filterKey, i) =>
            !ignoreKeys.includes(filterKey) && (
              <FilterChip
                key={filterKey}
                label={`${formattedLabel(filterKey)}  : ${filter[filterKey]}`}
                size="medium"
                deleteIcon={<DeleteIcon />}
                onDelete={() => onRemoveFilter(filterKey)}
              />
            ),
        )}

        <ClearAllButton disableRipple onClick={RemoveAllFilter}>
          Clear All
        </ClearAllButton>
      </Stack>
    );

  return <></>;
}

export default FilterValues;

FilterValues.propTypes = {
  value: PropTypes.object,
  onRemoveFilter: PropTypes.func,
  ignoreKeys: PropTypes.array,
  RemoveAllFilter: PropTypes.func,
};

FilterValues.defaultValues = {
  value: {},
  onRemoveFilter: () => {},
  ignoreKeys: [],
};
