import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import Modal from 'app/components/Modal';
import Clipboard from 'core/clipboard/Clipboard';
import SourceDetails from 'core/source-detail/SourceDetails';
import useSourceDetail from 'core/source-detail/useSourceDetail';
import { getFinmoPayin } from 'app/features/finmo-payins/finmoPayinSaga';
import { hideFinmoPayinBriefDetails } from 'app/features/finmo-payins/finmoPayinSlice';
import { buildRoute } from 'routes';
import ucwords from 'helpers/ucwords';

function FinmoPayinBriefDetailsModal2() {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const viewData = useMemo(
    () => [
      {
        label: 'Payin ID',
        get: ({ payin_id }) => <Clipboard content={payin_id} />,
      },
      {
        label: 'Payin Method Name',
        data: 'payin_method_name',
      },
      {
        label: 'Payin Method Description',
        data: 'payin_method_description',
      },
      {
        label: 'Payin Method Category',
        get: ({ payin_method_category }) => ucwords(payin_method_category),
      },
      {
        label: 'PayID Reference',
        get: ({ payin_method_param }) => payin_method_param?.payid_reference,
      },
      {
        label: 'Amount',
        data: 'amount',
      },
      {
        label: 'Currency',
        data: 'currency',
      },
      {
        label: 'Status',
        data: 'status',
      },
      {
        label: 'Paycode',
        get: ({ pay_code }) => <Clipboard content={pay_code?.text} />,
      },
      {
        label: 'Invoice ID',
        get: ({ metadata }) => <Clipboard content={metadata?.invoice_id} />,
      },
    ],
    [],
  );

  const state = useSelector((state) => state.finmoPayins.show);

  const { showBriefDetailModal, finmoPayinId, isLoading, data } = state;

  useEffect(() => {
    if (finmoPayinId) dispatch(getFinmoPayin(finmoPayinId));
  }, [finmoPayinId]);

  if (!showBriefDetailModal && !finmoPayinId) return <></>;

  const viewDetailColumn = useSourceDetail([
    {
      title: '',
      items: [
        {
          label: 'Payin ID',
          accessorKey: 'payin_id',
          cell: ({ payin_id }) => <Clipboard content={payin_id} />,
        },
        {
          label: 'Payin Method Name',
          accessorKey: 'payin_method_name',
        },
        {
          label: 'Payin Method Description',
          accessorKey: 'payin_method_description',
        },
        {
          label: 'Payin Method Category',
          accessorKey: 'payin_method_category',
          cell: ({ payin_method_category }) => ucwords(payin_method_category),
        },
        {
          label: 'PayID Reference',
          accessorKey: 'payin_method_param.payid_reference',
          cell: ({ payin_method_param }) => payin_method_param?.payid_reference || '-',
        },
        {
          label: 'Amount',
          accessorKey: 'amount',
        },
        {
          label: 'Currency',
          accessorKey: 'currency',
        },
        {
          label: 'Status',
          accessorKey: 'status',
        },
        {
          label: 'Paycode',
          accessorKey: 'pay_code.text',
          cell: ({ pay_code }) => (pay_code?.text ? <Clipboard content={pay_code?.text} /> : '-'),
        },
        {
          label: 'Invoice ID',
          accessorKey: 'metadata.invoice_id',
          cell: ({ metadata }) =>
            metadata?.invoice_id ? <Clipboard content={metadata?.invoice_id} /> : '-',
        },
      ],
    },
  ]);

  return (
    // <SidebarBriefModal
    //   open={showBriefDetailModal ?? false}
    //   onClose={() => dispatch(hideFinmoPayinBriefDetails())}
    //   title="Finmo Payin Details"
    //   onClickView={() => (
    //     dispatch(hideFinmoPayinBriefDetails()),
    //     navigate(buildRoute('finmo-payins.show', finmoPayinId))
    //   )}
    //   viewData={viewData}
    //   item={data}
    //   isLoading={isLoading}
    // />

    <Modal
      open={showBriefDetailModal ?? false}
      onClose={() => dispatch(hideFinmoPayinBriefDetails())}
      title="Finmo Payin Details"
      handleFullDetails={() => (
        dispatch(hideFinmoPayinBriefDetails()),
        navigate(buildRoute('finmo-payins.show', finmoPayinId))
      )}
    >
      <SourceDetails
        showContainerDesign={false}
        definition={viewDetailColumn}
        data={data}
        isLoading={isLoading}
      />
    </Modal>
  );
}

export default FinmoPayinBriefDetailsModal2;
