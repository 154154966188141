import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import withRole from 'hoc/withRole';
import Button from 'core/buttons/Button';

import acl from 'app/config/acl';
import { buildRoute } from 'routes';
import FundingSourceForm from 'components/funding-sources/FundingSourceForm';
import FundingSourceProgressIndicatorModal from 'components/funding-sources/FundingSourceProgressIndicatorModal';
import NewPageContent from 'layouts/NewPageContent';
import routePaths from 'app/config/routePaths';

import {
  retryToCreate,
  retryToUpdate,
  setShowEditProcessingModal,
  setShowCreateProcessingModal,
  addFundingSourceReset,
} from 'app/features/funding-sources/fundingSourceSlice';
import { useDispatch, useSelector } from 'react-redux';
import ActionStatusModal from 'core/modal/ActionStatusModal';
import ucwords from 'helpers/ucwords';

function AddFundingSource() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();

  const {
    showProcessingModal,
    isCreating,
    isCreated,
    isFailed,
    errors,
    errorMessage,
    retries,
    data: fundingSource,
  } = useSelector((state) => state.fundingSources.create);

  return (
    <NewPageContent
      breadcrumbs={[
        { label: 'Funding Sources', to: routePaths.ListFundingSource },
        { label: 'Add Funding Source', to: '' },
      ]}
      documentTitle="Add Funding Source"
    >
      <FundingSourceForm isAddMode={true} />
      {/* <FundingSourceProgressIndicatorModal isAddMode={true} /> */}
      <ActionStatusModal
        showModal={showProcessingModal}
        closeModal={() => dispatch(setShowCreateProcessingModal(false))}
        data={fundingSource}
        isSuccess={isCreated}
        isProcessing={isCreating}
        isError={isFailed}
        retriesCount={retries}
        errorMessage={ucwords(errorMessage) ?? 'Failed To Create Funding Source'}
        successMessage="Webhook Created Successfully"
        retry={() => dispatch(retryToCreate())}
        navigateToView={() =>
          navigate(buildRoute('funding-sources.show', fundingSource.id ?? params.fundingSourceId))
        }
        resetForm={() => dispatch(addFundingSourceReset())}
      />
    </NewPageContent>
  );
}

export default withRole([acl.SUPERADMIN, acl.ADMIN])(AddFundingSource);
