import { useNavigate } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import { useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ListItemButton from '@mui/material/ListItemButton';
import { getCoreRowModel, useReactTable } from '@tanstack/react-table';

import Error from 'core/errors/Error';
import FormCheckbox from 'core/forms/FormCheckbox';
import PopoverButton from 'core/buttons/PopoverButton';
import TanstackReactTable from 'core/table/TanstackReactTable';
import TableContainerWrapper from 'core/table/TableContainerWrapper';

import { buildRoute } from 'routes';
import DateUtils from 'utils/DateUtils';
import useAuthUser from 'hooks/useAuthUser';

import constants from 'app/config/constants';
import TransactionsActions from './ZaiTransactionsActions';
import ZaiTransactionPagination from './ZaiTransactionPagination';
import { getZaiTransactions } from 'app/features/transactions/zaiTransactionSaga';
import ZaiTransactionPreviewDetailsModal from './ZaiTransactionPreviewDetailsModal';
import { getTransactionBriefDetailsInit } from 'app/features/transactions/zaiTransactionSlice';
import {
  openCheckBalanceModal,
  openMakePaymentModal,
  openRefundPaymentModal,
} from 'app/features/transactions/zaiTransactionSlice';

export default function ZaiTransactions({ filterByCustomer, customerId }) {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const user = useAuthUser();

  const [columnVisibility, setColumnVisibility] = useState({});

  const transactionState = useSelector((state) => state.zaiTransactions.index);

  const { data, params, isLoading, isError, errorCode } = transactionState;

  const columns = useMemo(
    () => [
      {
        id: constants.TABLE_CHECKBOX_SELECT_CHECKBOX_ID,
        header: ({ table }) => (
          <FormCheckbox
            forTableHead={true}
            {...{
              checked: table.getIsAllRowsSelected(),
              indeterminate: table.getIsSomeRowsSelected(),
              onChange: table.getToggleAllRowsSelectedHandler(),
            }}
          />
        ),
        cell: ({ row }) => (
          <div>
            <FormCheckbox
              {...{
                checked: row.getIsSelected(),
                indeterminate: row.getIsSomeSelected(),
                onChange: row.getToggleSelectedHandler(),
              }}
            />
          </div>
        ),
      },
      {
        id: constants.TABLE_SERIAL_NUMBER_COLUMN_ID,
        header: 'SN',
        accessorFn: (row) => row.sn,
      },
      {
        header: 'Customer',
        accessorFn: (row) => row.user_name,
      },
      ...(user?.isSuperAdmin()
        ? [
            {
              header: 'Partner Name',
              accessorFn: (row) => row.partner_name,
            },
          ]
        : []),
      {
        header: 'Funding Type',
        accessorFn: (row) => row.funding_type,
      },

      {
        header: 'Purpose',
        accessorFn: (row) => row.txn_purpose,
      },
      {
        header: 'Amount',
        accessorFn: (row) => row.amount,
        cell: ({ getValue }) => <Typography align="right">{getValue()}</Typography>,
      },
      {
        header: 'Currency',
        accessorFn: (row) => row.currency,
      },
      {
        header: 'Created Date',
        accessorFn: (row) => DateUtils.isoToString(row.created_ts),
      },
      {
        header: 'Updated Date',
        accessorFn: (row) => (row.updated_ts ? DateUtils.isoToString(row.updated_ts) : '-'),
      },

      {
        id: constants.TABLE_ACTION_COLUMN_ID,
        header: 'Action',
        accessorFn: (row) => row.id,
        cell: (info) => {
          return (
            <PopoverButton>
              {({ onClose }) => (
                <>
                  <ListItemButton
                    onClick={() => {
                      dispatch(
                        getTransactionBriefDetailsInit({
                          showBriefDetailModal: true,
                          transactionId: info.getValue(),
                        }),
                      );
                      onClose();
                    }}
                  >
                    Preview
                  </ListItemButton>

                  <ListItemButton
                    onClick={() => {
                      navigate(buildRoute('zai-transactions.show', info.getValue()));
                      onClose();
                    }}
                  >
                    View Details
                  </ListItemButton>

                  <ListItemButton
                    onClick={() => {
                      dispatch(openCheckBalanceModal({ customerId: info.row.original.user_id }));
                      onClose();
                    }}
                  >
                    Check Balance
                  </ListItemButton>

                  <ListItemButton
                    onClick={() => {
                      dispatch(
                        openMakePaymentModal({
                          transactionId: info.row.original.id,
                          customerName: info.row.original.user_name,
                          customerId: info.row.original.user_id,
                        }),
                      );
                      onClose();
                    }}
                  >
                    Make Payment
                  </ListItemButton>

                  <ListItemButton
                    onClick={() => {
                      dispatch(
                        openRefundPaymentModal({
                          customerName: info.row.original.user_name,
                          customerId: info.row.original.user_id,
                        }),
                      );
                      onClose();
                    }}
                  >
                    Refund Payment
                  </ListItemButton>
                </>
              )}
            </PopoverButton>
          );
        },
      },
    ],
    [],
  );

  const table = useReactTable({
    data,
    columns,
    state: {
      columnVisibility,
    },
    onColumnVisibilityChange: setColumnVisibility,
    getCoreRowModel: getCoreRowModel(),
  });

  useEffect(() => {
    dispatch(
      getZaiTransactions({
        ...params,
        ...(filterByCustomer
          ? {
              user_id: customerId,
            }
          : {}),
      }),
    );
  }, [JSON.stringify(params)]);

  if (isError) return <Error status={errorCode} />;

  return (
    <TableContainerWrapper>
      <TransactionsActions
        table={table}
        enableFilterByPartner={!filterByCustomer}
        defaultFilterQueries={{
          ...(filterByCustomer
            ? {
                user_id: customerId,
              }
            : {}),
        }}
      />
      <TanstackReactTable
        table={table}
        isLoading={isLoading}
        data={data}
        columnsCount={
          columns.length - Object.entries(columnVisibility ?? {}).filter(([k, v]) => !v).length
        }
      />
      <ZaiTransactionPagination />
      <ZaiTransactionPreviewDetailsModal />
    </TableContainerWrapper>
  );
}
