import { createBrowserHistory } from 'history';

import Login from 'pages/auth/Login';
import Profile from 'pages/auth/Profile';
import Dashboard from 'pages/dashboard/Dashboard';
import AddAccount from 'pages/accounts/AddAccount';
import AddPartner from 'pages/partners/AddPartner';
import AddWebhook from 'pages/webhooks/AddWebhook';
import ViewWebhook from 'pages/webhooks/ViewWebhook';
import ListSetting from 'pages/settings/ListSetting';
import ResetPassword from 'pages/auth/ResetPassword';
import EditAccount from 'pages/accounts/EditAccount';
import ListAccount from 'pages/accounts/ListAccount';
import ViewAccount from 'pages/accounts/ViewAccount';
import ListPartner from 'pages/partners/ListPartner';
import ViewPartner from 'pages/partners/ViewPartner';
import EditPartner from 'pages/partners/EditPartner';
import ListWebhook from 'pages/webhooks/ListWebhook';
import EditWebhook from 'pages/webhooks/EditWebhook';
import ForgotPassword from 'pages/auth/ForgotPassword';
import ListCustomer from 'pages/customers/ListCustomer';
import ViewCustomer from 'pages/customers/ViewCustomer';
import VerifyIpAddress from 'pages/auth/VerifyIpAddress';
import AddApiConfig from 'pages/api-configs/AddApiConfig';
import ListApiConfig from 'pages/api-configs/ListApiConfig';
import EditApiConfig from 'pages/api-configs/EditApiConfig';
import AusTrackReports from 'pages/reports/AusTrackReports';
import ViewApiConfig from 'pages/api-configs/ViewApiConfig';
import ListFinmoPayin from 'pages/finmo-payins/ListFinmoPayin';
import ViewFinmoPayin from 'pages/finmo-payins/ViewFinmoPayin';
import ListTransaction from 'pages/transactions/ListTransaction';
import ViewTransaction from 'pages/transactions/ViewTransaction';
import ViewFinmoPayout from 'pages/finmo-payouts/ViewFinmoPayout';
import ListCustomerKyc from 'pages/customer-kycs/ListCustomerKyc';

import ViewCustomerKyc from 'pages/customer-kycs/ViewCustomerKyc';
import ListBeneficiary from 'pages/beneficiaries/ListBeneficiary';
import ViewBeneficiary from 'pages/beneficiaries/ViewBeneficiary';
import ListFinmoPayout from 'pages/finmo-payouts/ListFinmoPayout';
import AddWebhookHeader from 'pages/webhook-headers/AddWebhookHeader';
import AddFundingSource from 'pages/funding-sources/AddFundingSource';
import ListWebhookHeader from 'pages/webhook-headers/ListWebhookHeader';
import ViewZaiTransaction from 'pages/transactions/ViewZaiTransaction';
import ListZaiTransaction from 'pages/transactions/ListZaiTransaction';
import EditWebhookHeader from 'pages/webhook-headers/EditWebhookHeader';
import ViewWebhookHeader from 'pages/webhook-headers/ViewWebhookHeader';
import ListFundingSource from 'pages/funding-sources/ListFundingSource';
import ViewFundingSource from 'pages/funding-sources/ViewFundingSource';
import AddPartnerCountry from 'pages/partner-countries/AddPartnerCountry';
import ListPartnerCountry from 'pages/partner-countries/ListPartnerCountry';
import ViewPartnerCountry from 'pages/partner-countries/ViewPartnerCountry';
import PartnerSelfBusinessProfile from 'pages/partners/PartnerSelfBusinessProfile';
import ListPartnerCredentials from 'pages/partner-credentials/ListPartnerCredentials';
import AddPartnerFundingSource from 'pages/partner-funding-sources/AddPartnerFundingSource';
import ListPartnerFundingSource from 'pages/partner-funding-sources/ListPartnerFundingSource';
import ViewPartnerFundingSource from 'pages/partner-funding-sources/ViewPartnerFundingSource';

import ListLicensingPartner from 'pages/licensing-partners/ListLicensingPartner';

import acl from 'app/config/acl';
import isEmpty from 'helpers/isEmpty';
import ListZaiWebhookLogs from 'pages/transactions/ListZaiWebhookLogs';
import ListZaiRefundLogs from 'pages/transactions/ListZaiRefundLogs';
import AddLicensingPartner from 'pages/licensing-partners/AddLicensingPartner';
import EditLicensingPartner from 'pages/licensing-partners/EditLicensingPartner';
import ViewLicensingPartner from 'pages/licensing-partners/ViewLicensingPartner';
import AddLicensePartnerMapper from 'components/license-partners-mapper/AddLicensePartnerMapper';
import ViewLicensePartnerMapper from 'components/license-partners-mapper/ViewLicensePartnerMapper';
import ListFundingSourceInfo from 'pages/funding-source-info/ListFundingSourceInfo';
import AddFundingSourceInfo from 'pages/funding-source-info/AddFundingSourceInfo';
import ViewFundingSourceInfo from 'pages/funding-source-info/ViewFundingSourceInfo';
import EditFundingSourceInfo from 'pages/funding-source-info/EditFundingSourceInfo';
import ListRoles from 'pages/master-settings/roles/ListRoles';
import AddRoles from 'pages/master-settings/roles/AddRoles';
import ViewRoles from 'pages/master-settings/roles/ViewRoles';
import EditPartnerCountry from 'pages/partner-countries/EditPartnerCountry';

import About from 'components/settings/About';
import ChangePassword from 'components/settings/ChangePassword';

const GUEST_ROUTES = {
  Login: {
    path: '/login',
    component: <Login />,
    index: true,
    name: 'login',
  },
  ForgotPassword: {
    path: '/forgot-password',
    component: <ForgotPassword />,
    index: true,
    name: 'forgot_password',
  },
  VerifyIpAddress: {
    path: '/click/:token/:message',
    component: <VerifyIpAddress />,
    index: true,
    name: 'verify-ip-address',
  },
  ResetPassword: {
    path: '/reset/:userId/:code/:random',
    component: <ResetPassword />,
    index: true,
    name: 'reset-password',
  },
};

const PROTECTED_ROUTES = {
  Home: {
    path: '/',
    component: <Dashboard />,
    index: true,
    name: 'home',
  },
  Dashboard: {
    path: '/',
    component: <Dashboard />,
    index: true,
    name: 'dashboard',
  },
  Profile: {
    path: '/profile',
    component: <Profile />,
    index: true,
    name: 'profile',
  },
  // Settings: {
  //   path: '/my-settings',
  //   component: <ListSetting />,
  //   index: true,
  //   name: 'settings',
  // },
  About: {
    path: '/about',
    component: <About />,
    index: true,
    name: 'about',
  },

  ChangePassword: {
    path: '/change-password',
    component: <ChangePassword />,
    index: true,
    name: 'change_password',
  },

  // Roles

  ListRoles: {
    path: '/user/roles',
    component: <ListRoles />,
    index: true,
    name: 'roles.index',
    roles: [acl.SUPERADMIN],
  },
  AddRoles: {
    path: '/user/roles/create',
    component: <AddRoles />,
    index: true,
    name: 'roles.create',
    roles: [acl.SUPERADMIN],
  },
  ViewRoles: {
    path: '/user/roles/:roleId',
    component: <ViewRoles />,
    index: true,
    name: 'roles.show',
  },

  // Partners
  ListPartner: {
    path: '/partners',
    component: <ListPartner />,
    index: true,
    name: 'partners.index',
    roles: [acl.SUPERADMIN],
  },
  AddPartner: {
    path: '/partners/create',
    component: <AddPartner />,
    index: true,
    name: 'partners.create',
    roles: [acl.SUPERADMIN],
  },
  ViewPartner: {
    path: '/partners/:partnerId',
    component: <ViewPartner />,
    index: true,
    name: 'partners.show',
    roles: [acl.SUPERADMIN, acl.ADMIN],
  },
  EditPartner: {
    path: '/partners/:partnerId/edit',
    component: <EditPartner />,
    index: true,
    name: 'partners.edit',
    roles: [acl.SUPERADMIN],
  },

  AddLicensingPartner: {
    path: '/licensing-partners/create',
    component: <AddLicensingPartner />,
    index: true,
    name: 'licensing-partners.create',
    roles: [acl.SUPERADMIN],
  },

  ViewLicensingPartner: {
    path: '/licensing-partners/:licensingPartnerId',
    component: <ViewLicensingPartner />,
    index: true,
    name: 'licensing-partners.show',
    roles: [acl.SUPERADMIN, acl.ADMIN],
  },

  EditLicensingPartner: {
    path: '/licensing-partners/:licensingPartnerId/edit',
    component: <EditLicensingPartner />,
    index: true,
    name: 'licensing-partners.update',
    roles: [acl.SUPERADMIN],
  },

  ListLicensingPartners: {
    path: '/licensing-partners',
    component: <ListLicensingPartner />,
    index: true,
    name: 'licensing-partners.index',
    roles: [acl.SUPERADMIN],
  },

  AddLicensePartnerMap: {
    path: '/licensePartnerMapper/create',
    component: <AddLicensePartnerMapper />,
    index: true,
    name: 'license-partner-mapper.create',
    roles: [acl.SUPERADMIN],
  },

  ViewLicensePartnerMapper: {
    path: '/licensePartnerMapper/:licensingPartnerId',
    component: <ViewLicensePartnerMapper />,
    index: true,
    name: 'license-partner-mapper.index',
    roles: [acl.SUPERADMIN, acl.ADMIN],
  },

  PartnerSelfBusinessProfile: {
    path: '/business-profile',
    component: <PartnerSelfBusinessProfile />,
    index: true,
    name: 'business-profile',
    roles: [acl.ADMIN],
  },

  // Accounts
  ListAccount: {
    path: '/accounts',
    component: <ListAccount />,
    index: true,
    name: 'accounts.index',
    roles: [acl.SUPERADMIN, acl.ADMIN],
  },
  AddAccount: {
    path: '/accounts/create',
    component: <AddAccount />,
    index: true,
    name: 'accounts.create',
    roles: [acl.SUPERADMIN, acl.ADMIN],
  },
  EditAccount: {
    path: '/accounts/:accountId/edit',
    component: <EditAccount />,
    index: true,
    name: 'accounts.edit',
    roles: [acl.SUPERADMIN, acl.ADMIN],
  },
  ViewAccount: {
    path: '/accounts/:accountId',
    component: <ViewAccount />,
    index: true,
    name: 'accounts.show',
    roles: [acl.SUPERADMIN, acl.ADMIN],
  },

  // Customers
  ListCustomer: {
    path: '/customers',
    component: <ListCustomer />,
    index: true,
    name: 'customers.index',
    roles: [acl.SUPERADMIN, acl.ADMIN, acl.USER],
  },
  ViewCustomer: {
    path: '/customers/:customerId',
    component: <ViewCustomer />,
    index: true,
    name: 'customers.show',
    roles: [acl.SUPERADMIN, acl.ADMIN, acl.USER],
  },

  // Transactions
  ListPendingBankTransaction: {
    path: '/transactions/pending-bank-transactions',
    component: <ListTransaction />,
    index: true,
    name: 'pending-bank-transactions.index',
  },
  ListTransaction: {
    path: '/transactions',
    component: <ListTransaction />,
    index: true,
    name: 'transactions.index',
  },
  ViewTransaction: {
    path: '/transactions/:transactionId',
    component: <ViewTransaction />,
    index: true,
    name: 'transactions.show',
  },
  ListZaiTransaction: {
    path: '/transactions/zai',
    component: <ListZaiTransaction />,
    index: true,
    name: 'zai-transactions.index',
  },
  ViewZaiTransaction: {
    path: '/transactions/zai/:transactionId',
    component: <ViewZaiTransaction />,
    index: true,
    name: 'zai-transactions.show',
  },

  // CustomerKYCs
  ListCustomerKyc: {
    path: '/customer-kycs',
    component: <ListCustomerKyc />,
    index: true,
    name: 'customer-kycs.index',
    roles: [acl.SUPERADMIN, acl.ADMIN, acl.USER],
  },
  ViewCustomerKyc: {
    path: '/customer-kycs/:customerKycId',
    component: <ViewCustomerKyc />,
    index: true,
    name: 'customer-kycs.show',
    roles: [acl.SUPERADMIN, acl.ADMIN, acl.USER],
  },

  // PartnerCredentials
  ListPartnerCredentials: {
    path: '/partner-credentials',
    component: <ListPartnerCredentials />,
    index: true,
    name: 'partner-credentials.index',
  },

  // ApiConfigs
  ListApiConfig: {
    path: '/api-configs',
    component: <ListApiConfig />,
    index: true,
    name: 'api-configs.index',
  },
  AddApiConfig: {
    path: '/api-configs/create',
    component: <AddApiConfig />,
    index: true,
    name: 'api-configs.create',
  },
  ViewApiConfig: {
    path: '/api-configs/:apiConfigId',
    component: <ViewApiConfig />,
    index: true,
    name: 'api-configs.show',
  },
  EditApiConfig: {
    path: '/api-configs/:apiConfigId/edit',
    component: <EditApiConfig />,
    index: true,
    name: 'api-configs.edit',
  },

  // Webhooks
  ListWebhook: {
    path: '/webhooks',
    component: <ListWebhook />,
    index: true,
    name: 'webhooks.index',
  },
  AddWebhook: {
    path: '/webhooks/create',
    component: <AddWebhook />,
    index: true,
    name: 'webhooks.create',
  },
  EditWebhook: {
    path: '/webhooks/:webhookId/edit',
    component: <EditWebhook />,
    index: true,
    name: 'webhooks.edit',
  },
  ViewWebhook: {
    path: '/webhooks/:webhookId',
    component: <ViewWebhook />,
    index: true,
    name: 'webhooks.show',
  },

  // WebhookHeaders
  ListWebhookHeader: {
    path: '/webhook-headers',
    component: <ListWebhookHeader />,
    index: true,
    name: 'webhook-headers.index',
    roles: [],
  },
  AddWebhookHeader: {
    path: '/webhook-headers/create',
    component: <AddWebhookHeader />,
    index: true,
    name: 'webhook-headers.create',
  },
  EditWebhookHeader: {
    path: '/webhook-headers/:webhookHeaderId/edit',
    component: <EditWebhookHeader />,
    index: true,
    name: 'webhook-headers.edit',
  },
  ViewWebhookHeader: {
    path: '/webhook-headers/:webhookHeaderId',
    component: <ViewWebhookHeader />,
    index: true,
    name: 'webhook-headers.show',
  },

  ListZaiWebhookLogs: {
    path: '/logs/webhook-logs',
    component: <ListZaiWebhookLogs />,
    index: true,
    name: 'zai-transactions.webhookLogs',
  },

  ListZaiRefundLogs: {
    path: '/logs/refund-logs',
    component: <ListZaiRefundLogs />,
    index: true,
    name: 'zai-transactions.refundLogs',
  },

  // FundingSources
  ListFundingSource: {
    path: '/funding-sources',
    component: <ListFundingSource />,
    index: true,
    name: 'funding-sources.index',
  },
  AddFundingSource: {
    path: '/funding-sources/create',
    component: <AddFundingSource />,
    index: true,
    name: 'funding-sources.create',
  },
  ViewFundingSource: {
    path: '/funding-sources/:fundingSourceId',
    component: <ViewFundingSource />,
    index: true,
    name: 'funding-sources.show',
  },

  // Funding Source Info
  ListFundingSourceInfo: {
    path: '/funding-source-info',
    component: <ListFundingSourceInfo />,
    index: true,
    name: 'funding-sources-info.index',
  },

  AddFundingSourceInfo: {
    path: '/funding-source-info/create',
    component: <AddFundingSourceInfo />,
    index: true,
    name: 'funding-sources-info.create',
  },

  ViewFundingSourceInfo: {
    path: '/funding-source-info/:fundingSourceInfoId',
    component: <ViewFundingSourceInfo />,
    index: true,
    name: 'funding-sources-info.show',
  },

  EditFundingSourceInfo: {
    path: '/funding-source-info/:fundingSourceInfoId/edit',
    component: <EditFundingSourceInfo />,
    index: true,
    name: 'funding-sources-info.edit',
    roles: [acl.SUPERADMIN],
  },

  // Beneficiaries
  ListBeneficiary: {
    path: '/beneficiaries',
    component: <ListBeneficiary />,
    index: true,
    name: 'beneficiaries.index',
  },
  ViewBeneficiary: {
    path: '/beneficiaries/:beneficiaryId',
    component: <ViewBeneficiary />,
    index: true,
    name: 'beneficiaries.show',
  },

  // FinmoPayins
  ListFinmoPayin: {
    path: '/finmo/payins',
    component: <ListFinmoPayin />,
    index: true,
    name: 'finmo-payins.index',
  },
  ViewFinmoPayin: {
    path: '/finmo/payins/:finmoPayinId',
    component: <ViewFinmoPayin />,
    index: true,
    name: 'finmo-payins.show',
  },

  // FinmoPayouts
  ListFinmoPayout: {
    path: '/finmo/payouts',
    component: <ListFinmoPayout />,
    index: true,
    name: 'finmo-payouts.index',
  },
  ViewFinmoPayout: {
    path: '/finmo/payouts/:finmoPayoutId',
    component: <ViewFinmoPayout />,
    index: true,
    name: 'finmo-payouts.show',
  },

  // PartnerCountries
  ListPartnerCountry: {
    path: '/partner-countries',
    component: <ListPartnerCountry />,
    index: true,
    name: 'partner-countries.index',
  },
  AddPartnerCountry: {
    path: '/partner-countries/create',
    component: <AddPartnerCountry />,
    index: true,
    name: 'partner-countries.create',
  },
  ViewPartnerCountry: {
    path: '/partner-countries/:partnerCountryId',
    component: <ViewPartnerCountry />,
    index: true,
    name: 'partner-countries.show',
  },
  EditPartnerCountry: {
    path: '/partner-countries/:partnerCountryId/edit',
    component: <EditPartnerCountry />,
    index: true,
    name: 'partner-countries.edit',
  },

  // PartnerFundingSources
  ListPartnerFundingSource: {
    path: '/partner-funding-sources',
    component: <ListPartnerFundingSource />,
    index: true,
    name: 'partner-funding-sources.index',
  },
  AddPartnerFundingSource: {
    path: '/partner-funding-sources/create',
    component: <AddPartnerFundingSource />,
    index: true,
    name: 'partner-funding-sources.create',
  },
  ViewPartnerFundingSource: {
    path: '/partner-funding-sources/:partnerFundingSourceId',
    component: <ViewPartnerFundingSource />,
    index: true,
    name: 'partner-funding-sources.show',
  },

  ListAusTrackReport: {
    path: '/aus-track-reports',
    component: <AusTrackReports />,
    index: true,
    name: 'aus-track-reports',
  },
};

/**
 * build a route path from a route name
 *
 * @param {string} name
 * @param {mixed} params
 * @returns {string}
 */
const buildRoute = (routeName, ...params) => {
  let routePath = Object.values({ ...GUEST_ROUTES, ...PROTECTED_ROUTES }).find(
    (route) => route.name === routeName,
  )?.path;

  if (!routePath) throw new Error(`Route name ${routeName} not found`);

  let arrayContainsObject = params.some(
    (e) => Object.prototype.toString.call(e) === '[object Object]',
  );

  if (arrayContainsObject && params.length > 1)
    throw new Error(
      'Expected only single parameter while route parameter has an object as a parameter',
    );

  if (arrayContainsObject) {
    routePath = routePath.split('/');

    let routeParams = params[0];

    Object.keys(routeParams).forEach((key) => {
      routePath = routePath.map((path) => {
        if (`:${key}` === path) {
          let value = routeParams[key];
          delete routeParams[key];
          return value;
        }
        return path;
      });
    });

    routePath = routePath.join('/');

    if (Object.keys(routeParams).length > 0) {
      routePath = routePath + '?' + new URLSearchParams(routeParams);
    }
  } else {
    routePath = routePath.replace(/:([^/]+)/, params[0]);

    params.shift();

    if (params.length > 0) {
      routePath = routePath + '?' + params.join('&');
    }
  }
  return routePath;
};

/**
 * get a current route name from a path
 *
 * @param {string|null} pathname
 * @returns {string}
 */
const currentRouteName = (pathname = null) => {
  const activePath = pathname || window.location.pathname;
  const route = Object.values({ ...GUEST_ROUTES, ...PROTECTED_ROUTES }).find((route) => {
    let routePath = route.path;
    let routePattern = routePath.replace(/:([^/]+)/g, '[^/]*[a-z0-9][^/]*');
    return new RegExp(routePattern + '$', 'g').test(activePath);
  });
  if (isEmpty(route)) throw new Error(`Route path ${activePath} not found`);
  return route.name;
};

const history = createBrowserHistory();

export { GUEST_ROUTES, PROTECTED_ROUTES, history, buildRoute, currentRouteName };
