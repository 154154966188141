import withRole from 'hoc/withRole';
import PartnerForm from 'components/partners/PartnerForm';

import acl from 'app/config/acl';
import NewPageContent from 'layouts/NewPageContent';
import routePaths from 'app/config/routePaths';
import { useDispatch, useSelector } from 'react-redux';

import {
  retryToCreate,
  setShowCreateProcessingModal,
  addPartnerReset,
} from 'app/features/partners/partnerSlice';
import ActionStatusModal from 'core/modal/ActionStatusModal';
import { buildRoute } from 'routes';
import ucwords from 'helpers/ucwords';
import { useNavigate } from 'react-router-dom';
import { usePagination } from '@mui/lab';
import { useEffect } from 'react';

function AddPartner() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = usePagination();

  const partnerState = useSelector((state) => state.partners.create);

  const {
    showProcessingModal,
    isCreated,
    isCreating,
    isFailed,
    errorMessage,
    errors,
    data: partner,
  } = partnerState;

  return (
    <NewPageContent
      breadcrumbs={[
        {
          label: 'Partners',
          to: routePaths.ListPartners,
        },
        {
          label: 'Add Partner',
          to: '',
        },
      ]}
      documentTitle="Add Partner"
    >
      <PartnerForm isAddMode={true} />
      <ActionStatusModal
        showModal={showProcessingModal}
        closeModal={() => dispatch(setShowCreateProcessingModal(false))}
        isSuccess={isCreated}
        isProcessing={isCreating}
        isError={isFailed}
        data={partner}
        errorMessage={ucwords(errorMessage) ?? 'Failed To Create Partner'}
        successMessage="Partner Created successfully"
        retry={() => dispatch(retryToCreate())}
        navigateToView={() => navigate(buildRoute('partners.show', partner?.id))}
        resetForm={() => dispatch(addPartnerReset())}
      />
    </NewPageContent>
  );
}

export default withRole(acl.SUPERADMIN)(AddPartner);
