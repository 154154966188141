import { useMemo, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import { useSelector, useDispatch } from 'react-redux';

import GroupedTableView from 'core/table/GroupedTableView';

import isEmpty from 'helpers/isEmpty';
import DateUtils from 'utils/DateUtils';
import { getLoggedInUser } from 'app/features/auth/authSaga';
import useSourceDetail from 'core/source-detail/useSourceDetail';
import SourceDetails from 'core/source-detail/SourceDetails';

export default function MyProfile() {
  const dispatch = useDispatch();

  const account = useSelector((state) => state.auth.user);

  const ProfileDefination = useSourceDetail([
    {
      title: '',
      items: [
        {
          label: 'First Name',
          accessorKey: 'first_name',
        },
        {
          label: 'Last Name',
          accessorKey: 'last_name',
        },
        {
          label: 'Email',
          accessorKey: 'email',
        },
        {
          label: 'Phone Number',
          accessorKey: 'phone_number',
        },
        {
          label: 'Last Login at',
          accessorKey: 'last_login_ts',
          cell: ({ last_login_ts }) =>
            !isEmpty(last_login_ts) ? DateUtils.isoToString(last_login_ts) : '-',
        },
      ],
    },
  ]);

  useEffect(() => {
    if (isEmpty(account)) dispatch(getLoggedInUser());
  }, []);

  return (
    <>
      <SourceDetails
        viewMode="column"
        rowMode="row"
        definition={ProfileDefination}
        data={account}
      />
    </>
  );
}
