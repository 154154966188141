import React, { useState } from 'react';
import Box from '@mui/material/Box';

import TextButton from 'core/buttons/TextButton';
import ChangePasswordForm from 'components/auth/ChangePasswordForm';
import NewPageContent from 'layouts/NewPageContent';
import { styled } from '@mui/material/styles';

import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import { Typography } from '@mui/material';

const WrapperBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.primary.light,
  padding: 15,
  borderRadius: 15,
  // boxShadow: '1px 3px 6px 1px rgba(0,0,0,0.3)',
  // boxShadow: '0px 8px 0px 0px rgba(0, 0, 0, 0.06)',
  boxShadow: '10px 8px 10px 2px rgba(0, 0, 0, 0.06)',
  marginBottom: 20,
  marginRight: 20,
}));

function ChangePassword() {
  let [activeSetting, setActiveSetting] = useState(null);

  return (
    <NewPageContent
      breadcrumbs={[
        {
          label: 'Change Password',
          to: '',
        },
      ]}
      documentTitle="Change Password"
    >
      <Box
        display="flex"
        flexDirection="row"
        sx={{
          backgroundColor: '#FBF5E9',
          marginBottom: 3,
          paddingY: 2,
          paddingX: 3,
          gap: 5,
        }}
      >
        <ReportProblemIcon
          sx={{
            color: '#FFA500',
            fontSize: 20,
            textAlign: 'center',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        />

        <Typography variant="caption">You will be logged out after changing password.</Typography>
      </Box>

      <WrapperBox>
        <ChangePasswordForm setActiveSetting={setActiveSetting} />
      </WrapperBox>
    </NewPageContent>
  );
}

export default ChangePassword;
