import { useEffect } from 'react';
import Box from '@mui/material/Box';
import { compose } from '@reduxjs/toolkit';
import { useSelector, useDispatch } from 'react-redux';

import withRole from 'hoc/withRole';
import Error from 'core/errors/Error';
import withAppState from 'hoc/withAppState';
import FilterButton from 'core/buttons/FilterButton';
import Customers from 'components/customers/Customers';
import CustomerStat from 'components/customers/CustomerStat';
// import CustomerFilterValues from 'components/customers/CustomerFilterValues';
import CustomerTableFilterValues from 'components/customers/CustomerTableFilterValues';
import CustomerPreviewDetailsModal from 'components/customers/CustomerPreviewDetailsModal';

import acl from 'app/config/acl';
import NewPageContent from 'layouts/NewPageContent';
import { resetGetCustomersFailure, toggleFilter } from 'app/features/customers/customerSlice';

function ListCustomer({ isNetworkError }) {
  const customerState = useSelector((state) => state.customers.index);
  const { isError, errorCode, isFilterOpen } = customerState;

  const dispatch = useDispatch();

  useEffect(() => {
    if (isError) dispatch(resetGetCustomersFailure());
  }, [isNetworkError]);

  if (isError)
    return (
      <Error
        status={errorCode}
        withInDashboard={true}
        onRetry={() => dispatch(resetGetCustomersFailure())}
      />
    );

  return (
    <NewPageContent
      breadcrumbs={[
        {
          label: 'Customers',
          to: '',
        },
      ]}
      documentTitle="Customers"
      rightTopEndContent={
        <FilterButton
          variant="outlined"
          isOpen={isFilterOpen}
          onClick={() => dispatch(toggleFilter())}
          uppercase
        />

      }
    >
      <CustomerTableFilterValues />
      <Customers />
      <Box
        sx={{
          marginTop: 4,
        }}
      >
        <CustomerStat />
      </Box>
      <CustomerPreviewDetailsModal />
    </NewPageContent>
  );
}

export default compose(withAppState, withRole([acl.SUPERADMIN, acl.ADMIN, acl.USER]))(ListCustomer);
