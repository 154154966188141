import * as Yup from 'yup';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import styled from '@mui/material/styles/styled';
import { Link } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, FormProvider } from 'react-hook-form';

import Button from 'core/buttons/Button';
import AuthFormWrapper from 'core/forms/AuthFormWrapper';
import FormTextField from 'hook-form-controls/FormTextField';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import app from 'app/config/app';
import { buildRoute } from 'routes';

const schema = Yup.object().shape({
  email: Yup.string().email('Enter a valid email address').required('Enter a email address'),
});

const SubmitButton = styled(Button)(({ theme }) => ({
  textTransform: 'none',
  borderRadius: 30,
  fontSize: '1rem',
  fontWeight: 600,
  padding: theme.spacing(1, 3),
  transition: 'background-color 0.3s ease',
  '&:hover': {
    backgroundColor: theme.palette.info.main,
  },
  '&.Mui-disabled': {
    backgroundColor: theme.palette.action.disabledBackground,
    color: theme.palette.text.disabled,
  },
}));

function ForgotPasswordForm({ onSubmit, isProcessing, errors: formErrors }) {
  const methods = useForm({
    defaultValues: {
      ...(app.isDevelopmentMode()
        ? {
            email: 'milantarami.dev@gmail.com',
          }
        : {}),
    },
    resolver: yupResolver(schema),
  });

  const { formState, handleSubmit, getValues } = methods;

  const errors = { ...formState.errors, ...formErrors };

  const handleOnSubmit = () => onSubmit(getValues('email'));

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(handleOnSubmit)}>
        <AuthFormWrapper
          title="Forgot Password"
          subtitle="Kindly provide your email address, and we will send you the steps to reset your password."
        >
          <Grid container rowSpacing={2}>
            <Grid item xs={12}>
              <FormTextField
                label="Email"
                name="email"
                error={!!errors?.email}
                helperText={errors?.email?.message ?? ''}
                size="large"
                variant="standard"
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                component={Link}
                to={buildRoute('login')}
                startIcon={<ArrowBackIcon color="inherit" />}
                variant="none"
              >
                <Typography variant="body1">Back to Login</Typography>
              </Button>
            </Grid>
            <Grid item xs={12}>
              <SubmitButton type="submit" size="large" disabled={isProcessing} fullWidth>
                {isProcessing ? 'Processing...' : 'Reset Password'}
              </SubmitButton>
            </Grid>
          </Grid>
        </AuthFormWrapper>
      </form>
    </FormProvider>
  );
}

export default ForgotPasswordForm;

ForgotPasswordForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  isProcessing: PropTypes.bool.isRequired,
  errors: PropTypes.object,
};
