import { useEffect } from 'react';
import { compose } from '@reduxjs/toolkit';
import { useSelector, useDispatch } from 'react-redux';

import withRole from 'hoc/withRole';
import Error from 'core/errors/Error';
import withAppState from 'hoc/withAppState';
import NewPageContent from 'layouts/NewPageContent';
import ZaiTransactions from 'components/zai-transactions/ZaiTransactions';
import MakePaymentModal from 'components/zai-transactions/MakePaymentModal';
import CheckBalanceModal from 'components/zai-transactions/CheckBalanceModal';
import RefundPaymentModal from 'components/zai-transactions/RefundPaymentModal';
import ZaiTransactionTableFilterValues from 'components/zai-transactions/ZaiTransactionTableFilterValues';

import acl from 'app/config/acl';
import routePaths from 'app/config/routePaths';
import { resetGetZaiTransactionsFailure, toggleFilter } from 'app/features/transactions/zaiTransactionSlice';
import FilterButton from 'core/buttons/FilterButton';

function ListZaiTransaction({ isNetworkError }) {
  const transactionState = useSelector((state) => state.zaiTransactions.index);

  const { isError, errorCode, isFilterOpen } = transactionState;

  const dispatch = useDispatch();

  useEffect(() => {
    if (isError) dispatch(resetGetZaiTransactionsFailure());
  }, [isNetworkError]);

  if (isError)
    return (
      <Error
        status={errorCode}
        withInDashboard={true}
        onRetry={() => dispatch(resetGetZaiTransactionsFailure())}
      />
    );

  return (
    <NewPageContent
      breadcrumbs={[
        {
          label: 'Zai Transactions',
          to: routePaths.ZaiTransactions,
        },
      ]}
      documentTitle="Zai Transactions"
      rightTopEndContent={<FilterButton onClick={() => dispatch(toggleFilter())} isOpen={isFilterOpen} />}
    >
      <ZaiTransactionTableFilterValues />
      <ZaiTransactions />
      <CheckBalanceModal />
      <MakePaymentModal />
      <RefundPaymentModal />
    </NewPageContent>
  );
}

export default compose(
  withAppState,
  withRole([acl.SUPERADMIN, acl.ADMIN, acl.USER]),
)(ListZaiTransaction);
