import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import CancelIcon from '@mui/icons-material/Cancel';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
export default function VerifiedBadge({ isVerified, size }) {
  return (
    <Box display="flex" justifyContent="center" alignItems="center">
      {isVerified ? (
        <CheckCircleOutlinedIcon
          sx={{
            color: 'white',
            backgroundColor: 'success.main',
            padding: '1px',
            borderRadius: '4px',
          }}
          fontSize={size}
        />
      ) : (
        <CancelOutlinedIcon
          sx={{
            color: 'white',
            backgroundColor: 'error.main',
            padding: '1px',
            borderRadius: '4px',
          }}
          fontSize={size}
        />
      )}
    </Box>
  );
}

VerifiedBadge.propTypes = {
  isVerified: PropTypes.bool,
  size: PropTypes.oneOf(['inherit', 'large', 'medium', 'small']),
};

VerifiedBadge.defaultProps = {
  isVerified: false,
  size: 'small',
};
