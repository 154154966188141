import { forwardRef } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import ListIcon from '@mui/icons-material/List';
import styled from '@mui/material/styles/styled';
import CircularProgress from '@mui/material/CircularProgress';

const AddBtn = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.white,
  transition: 'background-color 0.3s ease',
  borderRadius: theme.spacing(3),
  padding: (theme) => theme.spacing(1, 3),
  '&:hover': {
    backgroundColor: theme.palette.info.main,
    color: theme.palette.common.white,
  },
  '& .MuiButtonBase-root': {
    ripple: 'none',
  },
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(1, 1.5),

    fontSize: '0.7rem',
  },
}));

const ActionButton = forwardRef(({ typeIcon, isLoading, ...props }, ref) => {
  const iconMap = {
    Add: <AddIcon />,
    Edit: <EditIcon />,
    List: <ListIcon />,
  };

  const { children, sx, ...rest } = props;
  return (
    <AddBtn
      ref={ref}
      startIcon={iconMap[typeIcon]}
      type="submit"
      size="large"
      sx={{
        ...sx,
      }}
      disabled={isLoading}
      {...rest}
    >
      <Box display="flex" alignItems="center" gap={1}>
        <div>{isLoading ? 'Processing' : children}</div>{' '}
        {isLoading ? <CircularProgress color="inherit" size={10} /> : ''}
      </Box>
    </AddBtn>
  );
});

export default ActionButton;

ActionButton.propTypes = {
  isLoading: PropTypes.bool,
  typeIcon: PropTypes.oneOf(['Add', 'Edit', 'List', '']),
};

ActionButton.defaultProps = {
  isLoading: false,
  typeIcon: '',
};
