import * as Yup from 'yup';

export const createRoleSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  description: Yup.string().required('Description is required'),
  remarks: Yup.string().required('Remarks is required'),
});

export const editRoleSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  description: Yup.string().required('Description is required'),
  remarks: Yup.string().required('Remarks is required'),
});
