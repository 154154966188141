import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';

import TextButton from 'core/buttons/TextButton';
import ActionButton from 'core/buttons/ActionButton';
import TableContainerWrapper from 'core/table/TableContainerWrapper';
import RoleDetailsContentForm from 'core/Role/RoleDetailsContentForm';

import RolesTabs from './RolesTabs';
import { createRoleSchema, editRoleSchema } from './schema/rolesSchema';

const RolesForm = ({ onSubmit, onCancel, isAddMode, isSubmitting, defaultValues }) => {
  const methods = useForm({
    defaultValues: isAddMode ? {} : defaultValues,
    resolver: yupResolver(isAddMode ? createRoleSchema : editRoleSchema),
  });

  const { watch, control } = methods;

  useFieldArray({
    name: 'modules',
    control,
  });

  const fields = watch('modules');

  const renderPermission = () => {
    return <RolesTabs modules={fields} />;
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TableContainerWrapper>
              <RoleDetailsContentForm />
            </TableContainerWrapper>
          </Grid>
          <Grid item xs={12}>
            <TableContainerWrapper>
              {renderPermission()}
              <Grid
                sx={{
                  gap: 2,
                  py: 2,
                  display: 'flex',
                  justifyContent: 'flex-end',
                }}
                item
                xs={12}
              >
                <TextButton type="button" color="primary" onClick={onCancel}>
                  Cancel
                </TextButton>
                <ActionButton
                  disabled={isSubmitting}
                  loading={isSubmitting}
                  type="submit"
                  variant="contained"
                >
                  {isAddMode ? 'Create' : 'Update'}
                </ActionButton>
              </Grid>
            </TableContainerWrapper>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
};

RolesForm.propTypes = {
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  isAddMode: PropTypes.bool,
  isSubmitting: PropTypes.bool,
  defaultValues: PropTypes.object,
};

export default RolesForm;
