import { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';

import SidebarBriefModal from 'core/modal/SidebarBriefModal';

import { buildRoute } from 'routes';
import { getFundingSourceInfo } from 'app/features/funding-sources-info/fundingSourceInfoSaga';
import { hideFundingSourceInfoBriefDetails } from 'app/features/funding-sources-info/fundingSourceInfoSlice';
import Modal from 'app/components/Modal';
import SourceDetails from 'core/source-detail/SourceDetails';
import useSourceDetail from 'core/source-detail/useSourceDetail';
function FundingSourceInfoPreviewDetailModal() {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const viewData = useMemo(
    () => [
      {
        label: 'Partner Name',
        data: 'partner_name',
      },
      {
        label: 'Payment Name',
        data: 'payment_name',
      },
      {
        label: 'Payment Value',
        data: 'payment_value',
      },
      {
        label: 'Payment Funding Key',
        data: 'funding_key',
      },
      {
        label: 'Payment Funding Key Value',
        data: 'funding_key_value',
      },
      {
        label: 'Is Active ?',
        get: ({ is_active }) => (is_active ? 'Yes' : 'No'),
      },
    ],
    [],
  );

  const state = useSelector((state) => state.fundingSourcesInfo.show);

  const { showBriefDetailModal, fundingSourceInfoId, isLoading, data } = state;

  useEffect(() => {
    if (fundingSourceInfoId) dispatch(getFundingSourceInfo(fundingSourceInfoId));
  }, [fundingSourceInfoId]);

  if (!showBriefDetailModal && !fundingSourceInfoId) return <></>;

  const viewDetailColumn = useSourceDetail([
    {
      title: '',
      items: [
        { label: 'Partner Name', accessorKey: 'partner_name' },
        { label: 'Payment Name', accessorKey: 'payment_name' },
        { label: 'Payment Value', accessorKey: 'payment_value' },
        { label: 'Payment Funding Key', accessorKey: 'funding_key' },
        { label: 'Payment Funding Key Value', accessorKey: 'funding_key_value' },
        {
          label: 'Is Active?',
          accessorKey: 'is_active',
          cell: ({ is_active }) => (is_active ? 'Yes' : 'No'),
        },
      ],
    },
  ]);

  return (
    <Modal
      open={showBriefDetailModal ?? false}
      onClose={() => dispatch(hideFundingSourceInfoBriefDetails())}
      title="Funding Source Details"
      handleFullDetails={() => (
        dispatch(hideFundingSourceInfoBriefDetails()),
        navigate(buildRoute('funding-sources-info.show', fundingSourceInfoId))
      )}
    >
      <SourceDetails
        showContainerDesign={false}
        definition={viewDetailColumn}
        data={data}
        isLoading={isLoading}
      />
    </Modal>
  );
}

export default FundingSourceInfoPreviewDetailModal;
