import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import { Grid } from '@mui/material';

function SettingItem({ labelPrimary, labelSecondary, content, isActive, actions }) {
  return (
    <Grid
      container
      spacing={2}
      sx={{
        w: '100%',
        px: 2,
        py: 1,
        display: 'inline-flex',
        border: '1px solid',
        borderColor: 'grey.400',
        flexDirection: 'column',
        borderRadius: '4px',
      }}
    >
      <Grid item xs={12} display="inline-block" sx={{ height: 'inherit' }}>
        <div className="label__container">
          <Typography variant="subtitle1" fontWeight="400">
            {labelPrimary}
          </Typography>
          <Typography variant="caption">{labelSecondary}</Typography>
        </div>
      </Grid>
      <Divider />
      <Grid item={12} display="inline-block" sx={{ height: 'inherit', textAlign: 'left' }}>
        {content}
      </Grid>
      <Grid item xs={12} display="inline-block" sx={{ width: '10%', height: 'inherit' }}>
        {isActive ? null : (
          <Box display="flex" justifyContent="flex-end">
            {actions}
          </Box>
        )}
      </Grid>
    </Grid>
  );
}

export default SettingItem;

SettingItem.propTypes = {
  labelPrimary: PropTypes.string,
  labelSecondary: PropTypes.string,
  content: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node, PropTypes.func]),
  actions: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node, PropTypes.func]),
  isActive: PropTypes.bool,
};
