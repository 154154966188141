import { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import SidebarBriefModal from 'core/modal/SidebarBriefModal';

import { buildRoute } from 'routes';
import { getApiConfig } from 'app/features/api-configs/apiConfigSaga';
import { hideApiConfigBriefDetails } from 'app/features/api-configs/apiConfigSlice';
import Modal from 'app/components/Modal';
import SourceDetails from 'core/source-detail/SourceDetails';
import useSourceDetail from 'core/source-detail/useSourceDetail';

function ApiConfigPreviewDetailsModal() {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const viewData = useMemo(
    () => [
      {
        label: 'API Partner Code',
        data: 'api_partner_code',
      },
      {
        label: 'API Url',
        data: 'api_url',
      },
      {
        label: 'API User ID',
        data: 'api_user_id',
      },
      {
        label: 'API User Secret',
        data: 'api_user_secret',
      },
      {
        label: 'Ref1',
        data: 'ref1',
      },
      {
        label: 'Ref2',
        data: 'ref2',
      },
      {
        label: 'Ref3',
        data: 'ref3',
      },
      {
        label: 'Remarks',
        data: 'remarks',
      },
    ],
    [],
  );

  const state = useSelector((state) => state.apiConfigs.show);

  const { showBriefDetailModal, apiConfigId, isLoading, data, isError } = state;

  useEffect(() => {
    if (apiConfigId) dispatch(getApiConfig(apiConfigId));
  }, [apiConfigId]);

  if (!showBriefDetailModal && !apiConfigId) return <></>;

  const viewDetailColumn = useSourceDetail([
    {
      title: 'API Partner Details',
      items: [
        { label: 'API Partner Code', accessorKey: 'api_partner_code' },
        { label: 'API Url', accessorKey: 'api_url' },
        { label: 'API User ID', accessorKey: 'api_user_id' },
        { label: 'API User Secret', accessorKey: 'api_user_secret' },
        { label: 'Ref1', accessorKey: 'ref1' },
        { label: 'Ref2', accessorKey: 'ref2' },
        { label: 'Ref3', accessorKey: 'ref3' },
        { label: 'Remarks', accessorKey: 'remarks' },
      ],
    },
  ]);

  return (
    // <SidebarBriefModal
    //   open={showBriefDetailModal ?? false}
    //   onClose={() => dispatch(hideApiConfigBriefDetails())}
    //   viewData={viewData}
    //   item={data}
    //   isLoading={isLoading}
    //   title="ApiConfig Details"
    //   onClickView={() => (
    //     dispatch(hideApiConfigBriefDetails()), navigate(buildRoute('api-configs.show', apiConfigId))
    //   )}
    // ></SidebarBriefModal>
    <Modal
      open={showBriefDetailModal ?? false}
      onClose={() => dispatch(hideApiConfigBriefDetails())}
      title="Transaction Details"
      handleFullDetails={() => (
        dispatch(hideApiConfigBriefDetails()), navigate(buildRoute('api-configs.show', apiConfigId))
      )}
    >
      <SourceDetails
        showContainerDesign={false}
        definition={viewDetailColumn}
        data={data}
        isLoading={isLoading}
      />
    </Modal>
  );
}

export default ApiConfigPreviewDetailsModal;
