import { Fragment, useRef } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Skeleton from '@mui/material/Skeleton';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import isEmpty from 'helpers/isEmpty';
import { Grid } from '@mui/material';

const DefaultContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'disableSpacing',
})(({ disableSpacing = false }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  ...(disableSpacing ? {} : { gap: '40px', mt: '8px' }),
}));

const ColumnContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
  mt: '18px',
  flexWrap: 'wrap',
}));

const LabelValueContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'rowMode' && prop !== 'enableLabelColon',
})(({ theme, rowMode, enableLabelColon }) => ({
  display: 'flex',
  flexDirection: rowMode === 'row' ? 'row' : 'column',
  gap: '8px',
  alignItems: rowMode === 'row' ? 'center' : 'flex-start',

  ...(rowMode === 'row' && {
    '& .MuiLabel-root': {
      margin: 4,
      width: '15rem',
    },
  }),

  '& .MuiLabel-root:after': enableLabelColon ? { content: '" :"', float: 'right' } : {},

  '& .value-container': {
    wordWrap: 'break-word',
    whiteSpace: 'normal',
    maxWidth: rowMode === 'row' ? 'unset' : '200px',
  },
}));

const SectionContainer = styled(Box)(({ theme, showContainerDesign }) =>
  showContainerDesign
    ? {
        backgroundColor: theme.palette.primary.light,
        padding: 15,
        borderRadius: 15,
        // boxShadow: '1px 3px 6px 1px rgba(0,0,0,0.3)',
        // boxShadow: '0px 8px 0px 0px rgba(0, 0, 0, 0.06)',
        boxShadow: '10px 8px 10px 2px rgba(0, 0, 0, 0.06)',
        marginBottom: 20,
        marginRight: 20,
      }
    : {},
);

export default function SourceDetails({
  definition,
  data,
  isLoading,
  viewMode,
  rowMode,
  enableLabelColon,
  showContainerDesign,
}) {
  const getNestedValue = (obj, keys) =>
    keys.reduce(
      (nestedObj, key) => (nestedObj && typeof nestedObj === 'object' ? nestedObj[key] : undefined),
      obj,
    );

  const dataDefinitionContainsSingleArray = !definition.some((def) => 'title' in def);

  const renderItem = (item) => {
    if ('cell' in item) {
      const value = !isEmpty(data) ? item?.cell(data) : '-';
      return (
        <LabelValueContainer rowMode={rowMode} enableLabelColon={enableLabelColon}>
          <Typography className="MuiLabel-root" color="text.secondary">
            {item.label}
          </Typography>
          {isLoading ? (
            <Skeleton variant="text" width="50px" />
          ) : (
            <Box
                sx={{
                textAlign: 'left',
              }}
              className="value-container"
            >
                <Typography fontWeight={500} color="text.primary">
                {typeof value === 'string' ? value : <>{value}</>}
              </Typography>
            </Box>
          )}
        </LabelValueContainer>
      );
    }

    if ('accessorKey' in item) {
      const value = getNestedValue(data, item.accessorKey.split('.'));
      return (
        <LabelValueContainer rowMode={rowMode} enableLabelColon={enableLabelColon}>
          <Typography className="MuiLabel-root" color="text.secondary">
            {item.label}
          </Typography>
          {isLoading ? (
            <Skeleton variant="text" width="50px" />
          ) : (
            <Typography className="value-container" fontWeight={500} color="text.primary">
              {value?.toString()?.trim() ? value : '-'}
            </Typography>
          )}
        </LabelValueContainer>
      );
    }

    return null;
  };

  let DataContainer = viewMode === 'column' ? ColumnContainer : DefaultContainer;

  return (
    <DataContainer
      disableSpacing={!dataDefinitionContainsSingleArray}
      sx={{
        backgroundColor: 'transparent',
        '& .MuiTypography-root': { lineHeight: '1.429rem' },
      }}
    >
      {definition.map((def, i) =>
        'title' in def ? (
          <Box key={i} width="100%">
            <SectionContainer showContainerDesign={showContainerDesign}>
              {def.title && (
                <Box mb="8px">
                  <Typography fontSize="1.143rem" fontWeight={600} variant="subtitle1">
                    {def.title}
                  </Typography>
                </Box>
              )}
              {viewMode === 'default' ? (
                <Grid container spacing={2}>
                  {def.items.map((item, ik) => (
                    <Grid item xs={12} sm={6} md={3} key={ik}>
                      {' '}
                      {/* Responsive grid columns */}
                      {renderItem(item)}
                    </Grid>
                  ))}
                </Grid>
              ) : (
                def.items.map((item, ik) => <Box key={ik}>{renderItem(item)}</Box>)
              )}
            </SectionContainer>
            {i < definition.length - 1 && <Box my="16px" />}
          </Box>
        ) : (
          <DataContainer key={i}>{renderItem(def)}</DataContainer>
        ),
      )}
    </DataContainer>
  );
}

SourceDetails.propTypes = {
  definition: PropTypes.array.isRequired,
  data: PropTypes.object,
  isLoading: PropTypes.bool,
  viewMode: PropTypes.oneOf(['default', 'column']),
  rowMode: PropTypes.oneOf(['column', 'row']),
  enableLabelColon: PropTypes.bool,
  showContainerDesign: PropTypes.bool,
};

SourceDetails.defaultProps = {
  data: {},
  isLoading: false,
  viewMode: 'default',
  rowMode: 'column',
  enableLabelColon: false,
  showContainerDesign: true,
};
