import { useEffect } from 'react';
import { compose } from '@reduxjs/toolkit';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import withRole from 'hoc/withRole';
import Error from 'core/errors/Error';
import withAppState from 'hoc/withAppState';
import Accounts from 'components/accounts/Accounts';
import ActionButton from 'core/buttons/ActionButton';
import AccountTableFilterValues from 'components/accounts/AccountTableFilterValues';
import AccountPreviewDetailsModal from 'components/accounts/AccountPreviewDetailsModal';

import acl from 'app/config/acl';
import { buildRoute } from 'routes';
import NewPageContent from 'layouts/NewPageContent';
import { resetGetAccountsFailure } from 'app/features/accounts/accountSlice';

function ListAccount({ isNetworkError }) {
  const accountState = useSelector((state) => state.accounts.index);

  const { isError, errorCode, isLoading } = accountState;

  const dispatch = useDispatch();

  const navigate = useNavigate();

  useEffect(() => {
    if (isError) dispatch(resetGetAccountsFailure());
  }, [isNetworkError]);

  if (isError)
    return (
      <Error
        status={errorCode}
        withInDashboard={true}
        onRetry={() => dispatch(resetGetAccountsFailure())}
      />
    );

  return (
    <NewPageContent
      documentTitle="Accounts"
      breadcrumbs={[
        {
          label: 'Accounts',
          to: '',
        },
      ]}
      rightTopEndContent={
        <ActionButton onClick={() => navigate(buildRoute('accounts.create'))}>
          Add Account
        </ActionButton>
      }
    >
      <AccountTableFilterValues />
      <Accounts />
      <AccountPreviewDetailsModal />
    </NewPageContent>
  );
}

export default compose(withAppState, withRole([acl.SUPERADMIN, acl.ADMIN]))(ListAccount);
