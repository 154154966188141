import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import withRole from 'hoc/withRole';
import Error from 'core/errors/Error';
import LicensingPartnerForm from 'components/LicensingPartner/LicensingPartnerForm';
import LicensingPartnerFormSkeleton from 'components/LicensingPartner/LicensingPartnerFormSkeleton';

import acl from 'app/config/acl';
import routePaths from 'app/config/routePaths';
import NewPageContent from 'layouts/NewPageContent';
import ActionStatusModal from 'core/modal/ActionStatusModal';
import { buildRoute } from 'routes';
import ucwords from 'helpers/ucwords';
import {
  setShowEditProcessingModal,
  updateLicensingPartnerReset,
} from 'app/features/licensing-partners/licensingPartnerSlice';
import {
  updateLicensingPartner,
  getLicensingPartner,
} from 'app/features/licensing-partners/licensingPartnerSaga';

function EditLicensingPartner() {
  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();

  const {
    isError,
    errorMessage,
    isLoading,
    errorCode,
    showProcessingModal,
    isUpdating,
    isUpdated,
    isFailed,
  } = useSelector((state) => state.licensingPartners.update);

  const { data: licensingPartnerData } = useSelector((state) => state.licensingPartners.show);

  useEffect(() => {
    if (params.licensingPartnerId) {
      dispatch(getLicensingPartner(params.licensingPartnerId));
    }
  }, [params.licensingPartnerId, dispatch]);

  if (isError)
    return (
      <Error
        status={errorCode}
        message={errorMessage}
        onRetry={() => dispatch(updateLicensingPartner(params.licensingPartnerId))}
      />
    );

  return (
    <NewPageContent
      breadcrumbs={[
        {
          label: 'Licensing Partners',
          to: routePaths.ListLicensingPartners,
        },
        { label: 'Edit Licensing Partner', to: '' },
      ]}
      documentTitle="Edit Licensing partner"
    >
      {isLoading ? (
        <LicensingPartnerFormSkeleton />
      ) : (
        <>
          <LicensingPartnerForm isAddMode={false} licensingPartner={licensingPartnerData} />

          <ActionStatusModal
            showModal={showProcessingModal}
            closeModal={() => dispatch(setShowEditProcessingModal(false))}
            data={licensingPartnerData}
            isSuccess={isUpdated}
            isProcessing={isUpdating}
            isError={isFailed}
            errorMessage={ucwords(errorMessage) ?? 'Failed To Update Licensing Partner'}
            successMessage="Licensing Partner Updated Successfully"
            navigateToView={() =>
              navigate(
                buildRoute(
                  'licensing-partners.show',
                  licensingPartnerData.id ?? params.licensingPartnerId,
                ),
              )
            }
            resetForm={() => dispatch(updateLicensingPartnerReset())}
          />
        </>
      )}
    </NewPageContent>
  );
}

export default withRole([acl.SUPERADMIN, acl.ADMIN])(EditLicensingPartner);
