import { createSlice } from '@reduxjs/toolkit';
import app from 'app/config/app';
import Response from 'app/config/Response';
import isEmpty from 'helpers/isEmpty';

const initialQueryParams = {
  page_size: app.perPage || 10,
  page_number: 1,
  sort_by: 'created_ts',
  order_by: 'desc',
};

const webHookLogsInitialQueryParams = {
  PageSize: app.perPage || 10,
  PageNumber: 1,
  OrderBy: 'created_ts',
  SortBy: 'desc',
};

const refundLogsInitialQueryParams = {
  PageSize: app.perPage || 10,
  PageNumber: 1,
  OrderBy: 'created_ts',
  SortBy: 'desc',
};

const initialState = {
  index: {
    data: [],
    params: initialQueryParams,
    isLoading: false,
    totalCount: 0,
    totalPage: 0,
    isError: false,
    errorCode: null,
    errorMessage: null,
    isFilterOpen: false,
  },
  show: {
    showBriefDetailModal: false,
    transactionId: null,
    isLoading: false,
    isError: false,
    errorCode: null,
    errorMessage: null,
    data: null,
  },
  approve: {
    isLoading: false,
    isError: false,
    errorCode: null,
    errorMessage: null,
  },
  reject: {
    isLoading: false,
    isError: false,
    errorCode: null,
    errorMessage: null,
  },
  enableDisable: {
    isLoading: false,
    isSuccess: false,
    isError: false,
    errorCode: null,
    errorMessage: null,
    data: null,
    status: null,
  },

  checkBalance: {
    isModalOpen: false,
    customerId: null,
    isLoading: false,
    isError: false,
    errorCode: null,
    errorMessage: null,
    data: null,
  },
  makePayment: {
    isModalOpen: false,
    customerName: null,
    customerId: null,
    webhookId: null,
    isLoading: false,
    isSuccess: false,
    isError: false,
    errorCode: null,
    errorMessage: null,
    data: null,
  },
  refundPayment: {
    isModalOpen: false,
    customerName: null,
    customerId: null,
    webhookId: null,
    isLoading: false,
    isSuccess: false,
    isError: false,
    errorCode: null,
    errorMessage: null,
    data: null,
  },
  webhookLogs: {
    data: [],
    params: webHookLogsInitialQueryParams,
    isLoading: false,
    totalCount: 0,
    totalPage: 0,
    isError: false,
    errorCode: null,
    errorMessage: null,
    isFilterOpen: false,
  },
  refundLogs: {
    data: [],
    params: refundLogsInitialQueryParams,
    isLoading: false,
    totalCount: 0,
    totalPage: 0,
    isError: false,
    errorCode: null,
    errorMessage: null,
    isFilterOpen: false,
  },
};

export const transactionSlice = createSlice({
  name: 'zai-transactions',
  initialState,
  reducers: {
    getZaiTransactionsInit: (state) => {
      state.index = {
        ...state.index,
        isLoading: true,
        isError: false,
        errorCode: null,
        errorMessage: null,
      };
    },

    getZaiTransactionsSuccess: (state, { payload }) => {
      let { data, pagination } = payload;

      const { totalCount, totalPage, currentPage, pageSize } = pagination;

      let startSN = (currentPage - 1) * pageSize;

      data = data?.map((item) => ({ sn: (startSN += 1), ...item }));

      state.index = {
        ...state.index,
        isLoading: false,
        isError: false,
        errorCode: null,
        errorMessage: null,
        data,
        totalCount,
        totalPage,
      };
    },

    // region: get all zai transaction

    getZaiTransactionsFailure: (state, { payload }) => {
      state.index = {
        ...state.index,
        isLoading: false,
        isError: true,
        errorCode: payload?.response?.status ?? 0,
        errorMessage: payload?.response?.message,
      };
    },

    resetGetZaiTransactionsFailure: (state) => {
      state.index = {
        ...state.index,
        isLoading: false,
        isError: false,
        errorCode: null,
        errorMessage: null,
      };
    },

    setParam: (state, { payload }) => {
      state.index.params = { ...state.index.params, ...payload };
    },

    resetParam: (state) => {
      state.index.params = {
        ...initialQueryParams,
        page_size: state.index.params.page_size,
        page_number: 1,
      };
    },

    deleteFilterParams: (state, { payload }) => {
      if (Array.isArray(payload)) payload.forEach((key) => delete state.index.params[key]);
      else delete state.index.params[payload];
    },

    // region: get zai transaction by id
    getTransactionInit: (state) => {
      state.show = {
        ...state.show,
        isLoading: true,
        isError: false,
        errorCode: null,
        errorMessage: null,
        data: null,
      };
    },

    getTransactionSuccess: (state, { payload }) => {
      if (isEmpty(payload?.data)) {
        state.show = {
          ...state.show,
          isLoading: false,
          isError: true,
          errorCode: Response.HTTP_NOT_FOUND,
          errorMessage: 'Transaction not found',
          data: null,
        };
      } else {
        state.show = {
          ...state.show,
          isLoading: false,
          isError: false,
          errorCode: null,
          errorMessage: null,
          data: payload.data,
        };
      }
    },

    getTransactionFailure: (state, { payload }) => {
      state.show = {
        ...state.show,
        isLoading: false,
        isError: true,
        errorCode: payload?.response?.status ?? 0,
        errorMessage: payload?.response?.message,
        data: null,
      };
    },

    resetGetTransactionFailure: (state) => {
      state.show = {
        ...state.show,
        isLoading: false,
        isError: false,
        errorCode: null,
        errorMessage: null,
        data: null,
      };
    },

    // region: brief zai transaction details
    getTransactionBriefDetailsInit: (state, { payload }) => {
      state.show = {
        ...state.show,
        showBriefDetailModal: payload.showBriefDetailModal,
        transactionId: payload.transactionId,
      };
    },

    hideTransactionBriefDetails: (state) => {
      state.show = {
        showBriefDetailModal: false,
        transactionId: null,
        isLoading: false,
        isError: false,
        errorCode: null,
        errorMessage: null,
        data: null,
      };
    },

    // region: zai check balance

    openCheckBalanceModal: (state, { payload }) => {
      state.checkBalance = {
        ...state.checkBalance,
        isModalOpen: true,
        customerId: payload.customerId,
      };
    },

    closeCheckBalanceModal: (state) => {
      state.checkBalance = {
        ...initialState.checkBalance,
      };
    },

    checkBalanceInit: (state, { payload }) => {
      state.checkBalance = {
        ...state.checkBalance,
        isLoading: true,
        isError: false,
        errorCode: null,
        errorMessage: null,
        data: null,
      };
    },

    checkBalanceSuccess: (state, { payload }) => {
      state.checkBalance = {
        ...state.checkBalance,
        isLoading: false,
        isError: false,
        errorCode: null,
        errorMessage: null,
        data: payload.data,
      };
    },

    checkBalanceFailure: (state, { payload }) => {
      state.checkBalance = {
        ...state.checkBalance,
        isLoading: false,
        isError: true,
        errorCode: payload?.response?.data?.status ?? 0,
        errorMessage: payload?.response?.data?.message,
      };
    },

    //region: zai transaction enable/disable

    openEnableDisableZaiTransactionModal: (state, { payload }) => {
      state.enableDisable = {
        ...state.enableDisable,
        isModalOpen: true,
        transactionId: payload.transactionId,
      };
    },

    closeEnableDisableZaiTransactionModal: (state) => {
      state.enableDisable = {
        ...initialState.enableDisable,
      };
    },

    enableDisableZaiTransactionInit: (state) => {
      state.enableDisable = {
        ...state.enableDisable,
        isSuccess: false,
        isLoading: true,
        isError: false,
        errorCode: null,
        errorMessage: null,
        data: null,
      };
    },
    enableDisableZaiTransactionSuccess: (state, { payload }) => {
      state.enableDisable = {
        ...state.enableDisable,
        isSuccess: true,
        isLoading: false,
        isError: false,
        errorCode: null,
        errorMessage: null,
        data: payload.data,
        status: payload.status,
      };
    },
    enableDisableZaiTransactionFailure: (state, { payload }) => {
      state.enableDisable = {
        ...state.enableDisable,
        isSuccess: false,
        isLoading: false,
        isError: true,
        errorCode: payload?.response?.data?.status ?? 0,
        errorMessage: payload?.response?.data?.message || 'An error occurred',
      };
    },

    openMakePaymentModal: (state, { payload }) => {
      state.makePayment = {
        ...state.makePayment,
        isModalOpen: true,
        customerId: payload.customerId,
        customerName: payload.customerName,
        transactionId: payload.transactionId,
      };
    },

    closeMakePaymentModal: (state) => {
      state.makePayment = {
        ...initialState.makePayment,
      };
    },

    makePaymentInit: (state, { payload }) => {
      state.makePayment = {
        ...state.makePayment,
        isLoading: true,
        isError: false,
        errorCode: null,
        errorMessage: null,
        data: null,
      };
    },

    makePaymentSuccess: (state, { payload }) => {
      state.makePayment = {
        ...state.makePayment,
        isSuccess: true,
        isLoading: false,
        isError: false,
        errorCode: null,
        errorMessage: null,
        data: payload.data,
      };
    },

    makePaymentFailure: (state, { payload }) => {
      state.makePayment = {
        ...state.makePayment,
        isSuccess: false,
        isLoading: false,
        isError: true,
        errorCode: payload?.response?.data?.status ?? 0,
        errorMessage: payload?.response?.data?.message,
      };
    },

    makePaymentReset: (state, { payload }) => {
      state.makePayment = {
        ...state.makePayment,
        webhookId: null,
        isLoading: false,
        isSuccess: false,
        isError: false,
        errorCode: null,
        errorMessage: null,
        data: null,
      };
    },

    // region: webhook logs

    setWebhookLogsParam: (state, { payload }) => {
      state.webhookLogs.params = { ...state.webhookLogs.params, ...payload };
    },

    resetWebhookLogsParam: (state) => {
      state.webhookLogs.params = {
        ...initialQueryParams,
        PageSize: state.webhookLogs.params.PageSize,
        page_number: 1,
      };
    },

    deleteWebhookLogsFilterParams: (state, { payload }) => {
      if (Array.isArray(payload)) payload.forEach((key) => delete state.webhookLogs.params[key]);
      else delete state.webhookLogs.params[payload];
    },

    getZaiWebhookLogsInit: (state) => {
      state.webhookLogs = {
        ...state.webhookLogs,
        isLoading: true,
        isError: false,
        errorCode: null,
        errorMessage: null,
      };
    },

    getZaiWebhookLogsSuccess: (state, { payload }) => {
      let { data, pagination } = payload;

      const { totalCount, totalPage, currentPage, pageSize } = pagination;

      let startSN = (currentPage - 1) * pageSize;

      data = data?.map((item) => ({ sn: (startSN += 1), ...item }));

      state.webhookLogs = {
        ...state.webhookLogs,
        isLoading: false,
        isError: false,
        errorCode: null,
        errorMessage: null,
        data,
        totalCount,
        totalPage,
      };
    },

    getZaiWebhookLogsFailure: (state, { payload }) => {
      state.webhookLogs = {
        ...state.webhookLogs,
        isLoading: false,
        isError: true,
        errorCode: payload?.response?.status ?? 0,
        errorMessage: payload?.response?.message,
      };
    },

    resetGetZaiWebhookLogsFailure: (state) => {
      state.webhookLogs = {
        ...state.webhookLogs,
        isLoading: false,
        isError: false,
        errorCode: null,
        errorMessage: null,
      };
    },

    // region: refund logs
    setRefundLogsParam: (state, { payload }) => {
      state.refundLogs.params = { ...state.refundLogs.params, ...payload };
    },

    resetRefundLogsParam: (state) => {
      state.refundLogs.params = {
        ...initialQueryParams,
        PageSize: state.refundLogs.params.PageSize,
        page_number: 1,
      };
    },

    deleteRefundLogsFilterParams: (state, { payload }) => {
      if (Array.isArray(payload)) payload.forEach((key) => delete state.refundLogs.params[key]);
      else delete state.refundLogs.params[payload];
    },

    getZaiRefundLogsInit: (state) => {
      state.refundLogs = {
        ...state.refundLogs,
        isLoading: true,
        isError: false,
        errorCode: null,
        errorMessage: null,
      };
    },

    getZaiRefundLogsSuccess: (state, { payload }) => {
      let { data, pagination } = payload;

      const { totalCount, totalPage, currentPage, pageSize } = pagination;

      let startSN = (currentPage - 1) * pageSize;

      data = data?.map((item) => ({ sn: (startSN += 1), ...item }));

      state.refundLogs = {
        ...state.refundLogs,
        isLoading: false,
        isError: false,
        errorCode: null,
        errorMessage: null,
        data,
        totalCount,
        totalPage,
      };
    },

    getZaiRefundLogsFailure: (state, { payload }) => {
      state.refundLogs = {
        ...state.refundLogs,
        isLoading: false,
        isError: true,
        errorCode: payload?.response?.status ?? 0,
        errorMessage: payload?.response?.message,
      };
    },

    resetGetZaiRefundLogsFailure: (state) => {
      state.refundLogs = {
        ...state.refundLogs,
        isLoading: false,
        isError: false,
        errorCode: null,
        errorMessage: null,
      };
    },

    // region: refund payment

    openRefundPaymentModal: (state, { payload }) => {
      state.refundPayment = {
        ...state.refundPayment,
        isModalOpen: true,
        customerId: payload.customerId,
        customerName: payload.customerName,
      };
    },

    closeRefundPaymentModal: (state) => {
      state.refundPayment = {
        ...initialState.refundPayment,
      };
    },

    refundPaymentInit: (state, { payload }) => {
      state.refundPayment = {
        ...state.refundPayment,
        webhookId: payload.webhookId,
        isLoading: true,
        isError: false,
        errorCode: null,
        errorMessage: null,
        data: null,
      };
    },

    refundPaymentSuccess: (state, { payload }) => {
      state.refundPayment = {
        ...state.refundPayment,
        isLoading: false,
        isSuccess: true,
        isError: false,
        errorCode: null,
        errorMessage: null,
        data: payload.data,
      };
    },

    refundPaymentFailure: (state, { payload }) => {
      state.refundPayment = {
        ...state.refundPayment,
        isSuccess: false,
        isLoading: false,
        isError: true,
        errorCode: payload?.response?.data?.status ?? 0,
        errorMessage: payload?.response?.data?.message,
      };
    },

    resetRefundPayment: (state) => {
      state.refundPayment = {
        ...state.refundPayment,
        webhookId: null,
        isLoading: false,
        isSuccess: false,
        isError: false,
        errorCode: null,
        errorMessage: null,
        data: null,
      };
    },

    toggleFilter: (state) => {
      state.index.isFilterOpen = !state.index.isFilterOpen;
    },

    toggleFilterWebHookLogs: (state) => {
      state.webhookLogs.isFilterOpen = !state.webhookLogs.isFilterOpen;
    },

    toggleFilterZaiRefund: (state) => {
      state.refundLogs.isFilterOpen = !state.refundLogs.isFilterOpen;
    },
  },
});

export const {
  getZaiTransactionsInit,
  getZaiTransactionsSuccess,
  getZaiTransactionsFailure,
  resetGetZaiTransactionsFailure,
  setParam,
  resetParam,
  deleteFilterParams,
  setShowCreateProcessingModal,
  setShowEditProcessingModal,
  getTransactionInit,
  getTransactionSuccess,
  getTransactionFailure,
  resetGetTransactionFailure,
  getTransactionBriefDetailsInit,
  hideTransactionBriefDetails,
  //
  openCheckBalanceModal,
  closeCheckBalanceModal,
  checkBalanceInit,
  checkBalanceSuccess,
  checkBalanceFailure,

  openMakePaymentModal,
  closeMakePaymentModal,
  makePaymentInit,
  makePaymentSuccess,
  makePaymentFailure,
  makePaymentReset,

  setWebhookLogsParam,
  resetWebhookLogsParam,
  deleteWebhookLogsFilterParams,
  getZaiWebhookLogsInit,
  getZaiWebhookLogsSuccess,
  getZaiWebhookLogsFailure,
  resetGetZaiWebhookLogsFailure,

  setRefundLogsParam,
  resetRefundLogsParam,
  deleteRefundLogsFilterParams,
  getZaiRefundLogsInit,
  getZaiRefundLogsSuccess,
  getZaiRefundLogsFailure,
  resetGetZaiRefundLogsFailure,

  openRefundPaymentModal,
  closeRefundPaymentModal,
  refundPaymentInit,
  refundPaymentSuccess,
  refundPaymentFailure,
  resetRefundPayment,

  openEnableDisableZaiTransactionModal,
  closeEnableDisableZaiTransactionModal,
  enableDisableZaiTransactionInit,
  enableDisableZaiTransactionSuccess,
  enableDisableZaiTransactionFailure,
  toggleFilter,
  toggleFilterWebHookLogs,
  toggleFilterZaiRefund,
} = transactionSlice.actions;

export default transactionSlice.reducer;
