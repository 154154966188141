import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import styled from '@mui/material/styles/styled';
import Typography from '@mui/material/Typography';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import { history } from 'routes';
import app from 'app/config/app';

const OuterWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'flex-start',
  padding: theme.spacing(2),
  background: theme.palette.grey[50],
  minHeight: 'calc(100vh - 64px)',
}));

const BackButtonWrapper = styled('div')(({ theme }) => ({}));

const InnerWrapper = styled('div')(({ theme }) => {
  const { isDrawerOpen } = useSelector((state) => state.app);

  return {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    // border: '1px solid green',
    maxWidth: `calc(100vw - ${(isDrawerOpen ? app.drawerWith : 64) + 120}px)`,
    // [theme.breakpoints.up('sm')]: {
    //   maxWidth: theme.breakpoints.values.sm - (app.drawerWith + 60) + 'px',
    // },
    // [theme.breakpoints.up('md')]: {
    //   maxWidth: theme.breakpoints.values.md - (app.drawerWith + 60) + 'px',
    // },
    // [theme.breakpoints.up('lg')]: {
    //   maxWidth: theme.breakpoints.values.lg - (app.drawerWith + 60) + 'px',
    // },
    // [theme.breakpoints.up('xl')]: {
    //   maxWidth: theme.breakpoints.values.xl - (app.drawerWith + 60) + 'px',
    // },
  };
});

const BackButton = styled('div')(({ theme }) => ({
  padding: theme.spacing(1),
  '&:hover': {
    cursor: 'pointer',
  },
  marginTop: 4,
  marginLeft: theme.spacing(2),
}));

const PageContentHeader = styled('div')(({ theme }) => ({
  padding: theme.spacing(1, 0),

  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',

  marginBottom: theme.spacing(3),
  alignItems: 'center',
  height: '3rem',
}));

const PageTitle = styled(Typography)(({ theme }) => ({
  fontSize: '1.1rem',
  color: theme.palette.primary.main,
  fontWeight: theme.typography.fontWeightBold,
  letterSpacing: theme.spacing(0.1),
}));

function PageContent({ children, title, documentTitle, breadcrumbs, rightTopEndContent }) {
  useEffect(() => {
    document.title = `${app.name} | ${documentTitle ?? title}`;
  });

  return (
    <OuterWrapper>
      <BackButtonWrapper>
        <BackButton onClick={() => history.back()}>
          <ArrowBackIcon />
        </BackButton>
      </BackButtonWrapper>
      <InnerWrapper id="inner-wrapper">
        <PageContentHeader>
          <PageTitle>{title}</PageTitle>
          <div>{rightTopEndContent}</div>
        </PageContentHeader>
        <div>{children}</div>
      </InnerWrapper>
    </OuterWrapper>
  );
}

export default PageContent;

PageContent.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
  title: (props, propName, componentName) => {
    if (!!props['breadcrumbs'] && props[propName] === 'undefined') {
      return new Error(
        `${propName} is required when breadcrumbs prop is null or undefined in ${componentName} component`,
      );
    }
    // else if (typeof props[propName] !== 'string') {
    //   return new Error(`${propName} value must be string ${componentName} component`);
    // }
  },
  breadcrumbs: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      to: PropTypes.string,
      icon: PropTypes.node,
      onClick: PropTypes.func,
      sx: PropTypes.object,
    }),
  ),
  rightTopEndContent: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
};
