import { useEffect } from 'react';
import { compose } from '@reduxjs/toolkit';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import Error from 'core/errors/Error';
import Button from 'core/buttons/Button';
import withAppState from 'hoc/withAppState';
import ApiConfigs from 'components/api-configs/ApiConfigs';
import ApiConfigFilterValues from 'components/api-configs/ApiConfigFilterValues';
import { resetGetApiConfigsFailure } from 'app/features/api-configs/apiConfigSlice';

import acl from 'app/config/acl';
import { buildRoute } from 'routes';
import withRole from 'hoc/withRole';
import NewPageContent from 'layouts/NewPageContent';
import ApiConfigPreviewDetailsModal from 'components/api-configs/ApiConfigPreviewDetailsModal';
import ActionButton from 'core/buttons/ActionButton';

function ListApiConfig({ isNetworkError }) {
  const apiConfigState = useSelector((state) => state.apiConfigs.index);

  const { isError, errorCode } = apiConfigState;

  const dispatch = useDispatch();

  const navigate = useNavigate();

  useEffect(() => {
    if (isError) dispatch(resetGetApiConfigsFailure());
  }, [isNetworkError]);

  if (isError)
    return (
      <Error
        status={errorCode}
        withInDashboard={true}
        onRetry={() => dispatch(resetGetApiConfigsFailure())}
      />
    );

  return (
    <NewPageContent
      breadcrumbs={[
        {
          label: 'API Configs',
          to: '',
        },
      ]}
      documentTitle="Api Configs"
      rightTopEndContent={
        <ActionButton onClick={() => navigate(buildRoute('api-configs.create'))}>
          Add API Config
        </ActionButton>
      }
    >
      <ApiConfigFilterValues />
      <ApiConfigs />
      <ApiConfigPreviewDetailsModal />
    </NewPageContent>
  );
}

export default compose(withAppState, withRole(acl.SUPERADMIN))(ListApiConfig);
