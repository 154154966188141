/**
 * Calls a function after a specified delay and returns a function to clear the timeout if needed
 *
 *
 * @param {Function} func - The function to execute after the delay.
 * @param {Number} delay = The delay in milliseconds.
 * @throws {Error} - Throws an error if the argument are invalid
 *
 *
 * */

export default function withTimeout(func, delay = 4000) {
  if (typeof func !== 'function' || typeof delay !== 'number') {
    throw new Error('Invalid arguments');
  }

  const timer = setTimeout(() => {
    func();
  }, delay);

  return () => clearTimeout(timer);
}
