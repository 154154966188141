import React from 'react';
import PropTypes from 'prop-types';

import PaidStatusIcon from 'core/status-icon/PaidStatusIcon';
import CreatedStatusIcon from 'core/status-icon/CreatedStatusIcon';
import PendingStatusIcon from 'core/status-icon/PendingStatusIcon';
import ProcessingStatusIcon from 'core/status-icon/ProcessingStatusIcon';
import RejectedStatusIcon from 'core/status-icon/RejectedStatusIcon';
import CompletedStatusIcon from 'core/status-icon/CompletedStatusIcon';

import { transactionStatuses } from 'app/config/TransactionStatus';
import ucwords from 'helpers/ucwords';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';

const TransactionStatusBadge = ({ status, width, height }) => {
  let icon;

  switch (status) {
    case transactionStatuses.created.toUpperCase():
      icon = <CreatedStatusIcon />;
      break;
    case transactionStatuses.pending.toUpperCase():
      icon = <PendingStatusIcon />;
      break;
    case transactionStatuses.processing.toUpperCase():
      icon = <ProcessingStatusIcon />;
      break;
    case transactionStatuses.completed.toUpperCase():
      icon = <CompletedStatusIcon />;
      break;
    case transactionStatuses.rejected.toUpperCase():
      icon = <RejectedStatusIcon />;
      break;
    case transactionStatuses.paid.toUpperCase():
      icon = <PaidStatusIcon />;
      break;
    default:
      icon = null;
  }

  return (
    <>
      {
        <Tooltip title={ucwords(status)} placement="top" arrow>
          <IconButton
            sx={{
              padding: 0,
              margin: 0,
              '& svg': {
                width: width,
                height: height,
              },
            }}
          >
            {icon}
          </IconButton>
        </Tooltip>
      }
    </>
  );
};

TransactionStatusBadge.propTypes = {
  status: PropTypes.oneOf(Object.values(transactionStatuses)),
  width: PropTypes.number,
  height: PropTypes.number,
};

TransactionStatusBadge.defaultProps = {
  width: 30,
  height: 30,
};

export default TransactionStatusBadge;
