import React from 'react';
import withRole from 'hoc/withRole';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import ActionStatusModal from 'core/modal/ActionStatusModal';
import WebhookHeaderForm from 'components/webhook-headers/WebhookHeaderForm';

import acl from 'app/config/acl';
import { buildRoute } from 'routes';
import ucwords from 'helpers/ucwords';
import routePaths from 'app/config/routePaths';
import NewPageContent from 'layouts/NewPageContent';
import {
  setShowCreateProcessingModal,
  addWebhookHeaderReset,
} from 'app/features/webhook-headers/webhookHeaderSlice';

function AddWebhookHeader() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();

  const {
    showProcessingModal,
    isCreating,
    isCreated,
    isFailed,
    errors,
    errorMessage,
    retries,
    data: webhookHeader,
  } = useSelector((state) => state.webhookHeaders.create);

  return (
    <NewPageContent
      breadcrumbs={[
        {
          label: 'Webhook Headers',
          to: routePaths.ListWebhookHeaders,
        },
        {
          label: 'Add Webhook Header',
          to: '',
        },
      ]}
      documentTitle="Add Webhook Header"
    >
      <WebhookHeaderForm isAddMode={true} />
      <ActionStatusModal
        showModal={showProcessingModal}
        closeModal={() => dispatch(setShowCreateProcessingModal(false))}
        data={webhookHeader}
        isSuccess={isCreated}
        isProcessing={isCreating}
        isError={isFailed}
        retriesCount={retries}
        errorMessage={ucwords(errorMessage) ?? 'Failed To Create Webhook Header'}
        successMessage="Webhook Header Created Successfully"
        navigateToView={() =>
          navigate(buildRoute('webhook-headers.show', webhookHeader.id ?? params.webhookHeaderId))
        }
        resetForm={() => dispatch(addWebhookHeaderReset())}
      />
    </NewPageContent>
  );
}

export default withRole([acl.SUPERADMIN, acl.ADMIN])(AddWebhookHeader);
