import { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Step from '@mui/material/Step';
import { connect } from 'react-redux';
import { useDispatch } from 'react-redux';
import { compose } from '@reduxjs/toolkit';
import Stepper from '@mui/material/Stepper';
import StepLabel from '@mui/material/StepLabel';
import Typography from '@mui/material/Typography';
import { FormProvider, useForm } from 'react-hook-form';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

import Spacer from 'core/spacer/Spacer';
import Button from 'core/buttons/Button';
import FormContainer from 'core/forms/FormContainer';
import ValidationErrorMessages from 'core/errors/ValidationErrorMessages';

import app from 'app/config/app';
import steps from './data/partnerFormSteps';
import { createPartner, updatePartner } from 'app/features/partners/partnerSaga';
import TextButton from 'core/buttons/TextButton';
import ActionButton from 'core/buttons/ActionButton';

function PartnerForm({ isAddMode, partner, retries, isCreated }) {
  const dispatch = useDispatch();

  const submitButtonRef = useRef(null);

  const methods = useForm({
    defaultValues: {
      is_verified: false,
      is_active: false,
      ...(partner ?? {}),
    },
  });

  const {
    handleSubmit,
    getValues,
    setError,
    clearErrors,
    reset,
    formState: { errors },
  } = methods;

  const [activeStep, setActiveStep] = useState(0);
  const [skipped, setSkipped] = useState(new Set());

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const validateForm = async (schema) => {
    let isFormValid = true;
    try {
      await schema?.validate(getValues(), { abortEarly: false });
    } catch (err) {
      isFormValid = false;
      err.inner.forEach((error) => setError(error.path, { message: error.message }));
    }
    return isFormValid;
  };

  const handleNext = async () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }
    if (!(await validateForm(steps[activeStep].validationSchema))) return;
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
    clearErrors();
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    clearErrors();
  };

  const handleSkip = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const onSubmit = async (data) => {
    if (!(await validateForm(steps[steps.length - 1].validationSchema))) return;
    dispatch(isAddMode ? createPartner(data) : updatePartner({ ...data, id: partner.id }));
  };

  useEffect(() => {
    if (retries > 0) submitButtonRef.current.click();
  }, [retries]);

  useEffect(() => {
    if (isCreated) {
      handleReset();
      reset({ partner_id: getValues('partner_id') });
    }
  }, [isCreated]);

  return (
    <FormContainer>
      <Box sx={{ width: '100%' }}>
        <Stepper activeStep={activeStep}>
          {steps.map((step, index) => {
            const stepProps = {};
            const labelProps = {};
            if (isStepSkipped(index)) {
              stepProps.completed = false;
            }
            return (
              <Step key={step.label} {...stepProps}>
                <StepLabel {...labelProps}>{step.label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
        <Spacer height={2} />
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            {app.isDevelopmentMode() && <ValidationErrorMessages errors={errors} />}
            {/* render active step from */}
            {steps[activeStep].component}
            <Spacer />
            {activeStep === steps.length ? (
              <>
                <Typography sx={{ mt: 2, mb: 1 }}>
                  All steps completed - you&apos;re finished
                </Typography>
                <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                  <Box sx={{ flex: '1 1 auto' }} />
                  <Button onClick={handleReset}>Reset</Button>
                </Box>
              </>
            ) : (
              <>
                <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                  <TextButton
                    type="button"
                    color="primary"
                    disabled={activeStep === 0}
                    onClick={(e) => (e.preventDefault(), handleBack())}
                    sx={{ mr: 1 }}
                    variant="outlined"
                    startIcon={<ArrowBackIcon />}
                  >
                    PREVIOUS
                  </TextButton>
                  <Box sx={{ flex: '1 1 auto' }} />
                  {steps[activeStep].isSkippable && (
                    <ActionButton
                      type="button"
                      color="inherit"
                      onClick={(e) => (e.preventDefault(), handleSkip())}
                      sx={{ mr: 1 }}
                      variant="contained"
                    >
                      SKIP
                    </ActionButton>
                  )}
                  {activeStep === steps.length - 1 ? (
                    <ActionButton
                      ref={submitButtonRef}
                      type="submit"
                      variant="contained"
                      endIcon={<ArrowForwardIcon />}
                    >
                      {isAddMode ? 'Create New Partner' : 'Update Partner'}
                    </ActionButton>
                  ) : (
                    <ActionButton
                      type="button"
                      onClick={(e) => (e.preventDefault(), handleNext())}
                      variant="contained"
                      endIcon={<ArrowForwardIcon />}
                    >
                      NEXT
                    </ActionButton>
                  )}
                </Box>
              </>
            )}
          </form>
        </FormProvider>
      </Box>
    </FormContainer>
  );
}

export default compose(connect(mapStateToProps))(PartnerForm);

function mapStateToProps(state, ownProps) {
  return {
    ...ownProps,
    retries: ownProps.isAddMode ? state.partners.create.retries : state.partners.update.retries,
    isCreated: state.partners.create.isCreated,
  };
}

PartnerForm.propTypes = {
  isAddMode: PropTypes.bool.isRequired,
  partner: PropTypes.object,
  retries: PropTypes.number,
};
