import { useEffect } from 'react';
import { compose } from '@reduxjs/toolkit';
import EditIcon from '@mui/icons-material/Edit';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';

import Error from 'core/errors/Error';
import Button from 'core/buttons/Button';
import withAppState from 'hoc/withAppState';
import PageContent from 'layouts/PageContent';
import FundingSource from 'components/funding-sources/FundingSource';

import acl from 'app/config/acl';
import { buildRoute } from 'routes';
import withRole from 'hoc/withRole';
import { resetGetFundingSourceFailure } from 'app/features/funding-sources/fundingSourceSlice';
import NewPageContent from 'layouts/NewPageContent';
import routePaths from 'app/config/routePaths';

function ViewFundingSource({ isNetworkError }) {
  const params = useParams();

  const { isError, errorCode } = useSelector((state) => state.fundingSources.show);

  const dispatch = useDispatch();

  const navigate = useNavigate();

  useEffect(() => {
    if (isError) dispatch(resetGetFundingSourceFailure());
  }, [isNetworkError]);

  if (isError)
    return (
      <Error status={errorCode || 0} onRetry={() => dispatch(resetGetFundingSourceFailure())} />
    );

  return (
    <NewPageContent
      breadcrumbs={[
        {
          label: 'Funding Sources',
          to: routePaths.ListFundingSource,
        },
        { label: 'View Funding Source', to: '' },
      ]}
      documentTitle="Funding Source Details"
    >
      <FundingSource fundingSourceId={params.fundingSourceId} />
    </NewPageContent>
  );
}

export default compose(withAppState, withRole([acl.SUPERADMIN, acl.ADMIN]))(ViewFundingSource);
