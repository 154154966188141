import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';

import Spacer from 'core/spacer/Spacer';
import TextButton from 'core/buttons/TextButton';
import SettingItem from '../components/SettingItem';
import SectionTitle from 'core/section/SectionTitle';
import ChangePasswordForm from 'components/auth/ChangePasswordForm';
import { Grid } from '@mui/material';

function SecuritySettingTab() {
  let [activeSetting, setActiveSetting] = useState(null);

  const handleEdit = (setting) => {
    setActiveSetting(setting);
  };

  let _settingItems = [
    {
      key: 'change-password',
      label: 'Change Password',
      content: <div></div>,
      contentSelected: (
        <Grid item xs={12}>
          <Typography color="grey.600">
            Use this form to change your password. Once Changed, your new password will be in effect
            next time you login
          </Typography>
          <Spacer height="1.5rem" />
          <ChangePasswordForm setActiveSetting={setActiveSetting} />
        </Grid>
      ),
      actions: (
        <Grid item xs={12}>
          <TextButton onClick={() => handleEdit('change-password')} startIcon={<EditTwoToneIcon />}>
            Edit
          </TextButton>
        </Grid>
      ),
    },
  ];

  return (
    <Grid container spacing={2}>
      <SectionTitle>Security</SectionTitle>
      {/* <Stack sx={{ mt: 2 }} divider={<Divider orientation="vertical" flexItem />}>
        {_settingItems.map((item) => (
          <SettingItem
            key={item.key}
            labelPrimary={item.label}
            content={activeSetting === item.key ? item.contentSelected : item.content}
            actions={item.actions}
            isActive={activeSetting === item.key}
          />
        ))}
      </Stack> */}

      <Grid item xs={12}>
        {_settingItems.map((item) => (
          <SettingItem
            key={item.key}
            labelPrimary={item.label}
            content={activeSetting === item.key ? item.contentSelected : item.content}
            actions={item.actions}
            isActive={activeSetting === item.key}
          />
        ))}
      </Grid>
    </Grid>
  );
}

export default SecuritySettingTab;
