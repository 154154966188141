import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

import WebhookHeaders from 'components/webhook-headers/WebhookHeaders';

import WebhookHeaderBriefDetailsModal from 'components/webhook-headers/WebhookHeaderBriefDetailsModal';

export default function PartnerWebhookHeadersTab({ partnerId }) {
  const navigate = useNavigate();

  return (
    <>
      <WebhookHeaders preFilterByPartner={true} partnerId={partnerId} />
      <WebhookHeaderBriefDetailsModal />
    </>
  );
}

PartnerWebhookHeadersTab.propTypes = {
  partnerId: PropTypes.string.isRequired,
};
