import { useEffect } from 'react';
import { compose } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import withRole from 'hoc/withRole';
import Error from 'core/errors/Error';
import withAppState from 'hoc/withAppState';
import ActionStatusModal from 'core/modal/ActionStatusModal';
import ApiConfigForm from 'components/api-configs/ApiConfigForm';
import PartnerFormSkeleton from 'components/partners/PartnerFormSkeleton';
import ApiConfigProgressIndicatorModal from 'components/api-configs/ApiConfigProgressIndicatorModal';

import acl from 'app/config/acl';
import ucwords from 'helpers/ucwords';
import routePaths from 'app/config/routePaths';
import NewPageContent from 'layouts/NewPageContent';
import { editApiConfig } from 'app/features/api-configs/apiConfigSaga';

import {
  setShowEditProcessingModal,
  updateApiConfigReset,
} from 'app/features/api-configs/apiConfigSlice';
import { buildRoute } from 'routes';

function EditApiConfig({ isNetworkError }) {
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    data: apiConfig,
    isError,
    errorMessage,
    isLoading,
    errorCode,
  } = useSelector((state) => state.apiConfigs.edit);

  const { showProcessingModal, isUpdating, isUpdated, isFailed, errors } = useSelector(
    (state) => state.apiConfigs.update,
  );

  useEffect(() => {
    dispatch(editApiConfig(params.apiConfigId));
  }, [params.apiConfigId, isNetworkError]);

  if (isError)
    return (
      <Error
        status={errorCode}
        message={errorMessage}
        onRetry={() => dispatch(editApiConfig(params.apiConfigId))}
      />
    );

  return (
    <NewPageContent
      breadcrumbs={[
        {
          label: 'Api Configs',
          to: routePaths.ListApiConfigs,
        },
        {
          label: 'Edit Api Config',
          to: '',
        },
      ]}
      documentTitle="Edit ApiConfig"
    >
      {isLoading ? (
        <PartnerFormSkeleton />
      ) : (
        <ApiConfigForm isAddMode={false} apiConfig={apiConfig} />
      )}
      <ApiConfigProgressIndicatorModal isAddMode={false} />
      <ActionStatusModal
        showModal={showProcessingModal}
        closeModal={() => dispatch(setShowEditProcessingModal(false))}
        data={apiConfig}
        isSuccess={isUpdated}
        isProcessing={isUpdating}
        isError={isFailed}
        errorMessage={ucwords(errorMessage) ?? 'Failed To Update Api Config'}
        successMessage="APi Config Updated Successfully"
        navigateToView={() =>
          navigate(buildRoute('api-configs.show', apiConfig.id ?? params.apiConfigId))
        }
        resetForm={() => dispatch(updateApiConfigReset())}
      />
    </NewPageContent>
  );
}

export default compose(withAppState, withRole(acl.SUPERADMIN))(EditApiConfig);
