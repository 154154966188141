import React from 'react';
import Grid from '@mui/material/Grid';
import PageContent from 'layouts/PageContent';
import MyProfile from 'components/auth/MyProfile';
import NewPageContent from 'layouts/NewPageContent';

export default function Profile() {
  return (
    <NewPageContent breadcrumbs={[{ label: 'My Profile', to: '' }]} documentTitle="My Profile">
      <MyProfile />
    </NewPageContent>
  );
}
