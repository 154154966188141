import GuestContainer from 'layouts/GuestContainer';
import ForgotPassword from 'components/auth/ForgotPassword';

import ForgotPasswordSvg from 'assets/svg/ForgotPassword.svg';

export default function ForgotPasswordPage() {
  return (
    <GuestContainer
      lTitle="Recover your account"
      lImage={ForgotPasswordSvg}
      rChildren={<ForgotPassword />}
    />
  );
}
