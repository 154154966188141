import { useEffect } from 'react';
import { compose } from '@reduxjs/toolkit';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import withRole from 'hoc/withRole';
import Error from 'core/errors/Error';
import Button from 'core/buttons/Button';
import withAppState from 'hoc/withAppState';
import WebhookHeaders from 'components/webhook-headers/WebhookHeaders';
import WebhookHeaderFilterValues from 'components/webhook-headers/WebhookHeaderFilterValues';
import { resetGetWebhookHeadersFailure } from 'app/features/webhook-headers/webhookHeaderSlice';

import acl from 'app/config/acl';
import { buildRoute } from 'routes';
import NewPageContent from 'layouts/NewPageContent';
import WebhookHeaderBriefDetailsModal2 from 'components/webhook-headers/WebhookHeaderBriefDetailsModal2';
import ActionButton from 'core/buttons/ActionButton';

function ListWebhookHeader({ isNetworkError }) {
  const webhookHeaderState = useSelector((state) => state.webhookHeaders.index);

  const { isError, errorCode } = webhookHeaderState;

  const dispatch = useDispatch();

  const navigate = useNavigate();

  useEffect(() => {
    if (isError) dispatch(resetGetWebhookHeadersFailure());
  }, [isNetworkError]);

  if (isError)
    return (
      <Error
        status={errorCode}
        withInDashboard={true}
        onRetry={() => dispatch(resetGetWebhookHeadersFailure())}
      />
    );

  return (
    <NewPageContent
      breadcrumbs={[
        {
          label: 'Webhook Headers',
          to: '',
        },
      ]}
      documentTitle="Webhook Headers"
      rightTopEndContent={
        <ActionButton onClick={() => navigate(buildRoute('webhook-headers.create'))}>
          Add Webhook Header
        </ActionButton>
      }
    >
      <WebhookHeaderFilterValues />
      <WebhookHeaders />
      <WebhookHeaderBriefDetailsModal2 />
    </NewPageContent>
  );
}

export default compose(withAppState, withRole([acl.SUPERADMIN, acl.ADMIN]))(ListWebhookHeader);
