import { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';

import { buildRoute } from 'routes';
import useAuthUser from 'hooks/useAuthUser';
import { getWebhook } from 'app/features/webhooks/webhookSaga';
import { hideWebhookBriefDetails } from 'app/features/webhooks/webhookSlice';
import PartnerClickAwayDetails from 'components/partners/PartnerClickAwayDetails';
import useSourceDetail from 'core/source-detail/useSourceDetail';
import Modal from 'app/components/Modal';
import SourceDetails from 'core/source-detail/SourceDetails';

function WebhookPreviewDetailsModal() {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const user = useAuthUser();

  const viewDetailColumn = useSourceDetail([
    {
      title: '',
      items: [
        ...(user?.isSuperAdmin()
          ? [
              {
                label: 'Partner Id',
                accessorKey: 'partner_id',
                cell: ({ partner_id, partner_name }) => (
                  <PartnerClickAwayDetails partnerId={partner_id} label={partner_name} />
                ),
              },
            ]
          : []),
        {
          label: 'URL',
          accessorKey: 'url',
        },
        {
          label: 'Object Type',
          accessorKey: 'object_type',
        },
        {
          label: 'Description',
          accessorKey: 'description',
        },
        {
          label: 'Is Verified ?',
          accessorKey: 'is_verified',

          cell: ({ is_verified }) => (is_verified ? 'Yes' : 'No'),
        },
        {
          label: 'Is Active ?',
          accessorKey: 'is_active',
          cell: ({ is_active }) => (is_active ? 'Yes' : 'No'),
        },
      ],
    },
  ]);

  const state = useSelector((state) => state.webhooks.show);

  const { showBriefDetailModal, webhookId, isLoading, data } = state;

  useEffect(() => {
    if (webhookId) dispatch(getWebhook(webhookId));
  }, [webhookId]);

  if (!showBriefDetailModal && !webhookId) return <></>;

  return (
    <Modal
      open={showBriefDetailModal ?? false}
      onClose={() => dispatch(hideWebhookBriefDetails())}
      title="Webhook Details"
      handleFullDetails={() => (
        dispatch(hideWebhookBriefDetails()), navigate(buildRoute('webhooks.show', webhookId))
      )}
    >
      <SourceDetails
        showContainerDesign={false}
        definition={viewDetailColumn}
        data={data}
        isLoading={isLoading}
      />
    </Modal>
  );
}

export default WebhookPreviewDetailsModal;
