import { useEffect, useContext } from 'react';
import { compose } from '@reduxjs/toolkit';
import { useSelector, useDispatch } from 'react-redux';

import withRole from 'hoc/withRole';
import Error from 'core/errors/Error';
import withAppState from 'hoc/withAppState';
import Transactions from 'components/transactions/Transactions';

import acl from 'app/config/acl';
import { currentRouteName } from 'routes';
import PendingTransactionsContext from 'app/context/PendingTransactionsContext';
import { resetGetTransactionsFailure, toggleFilter } from 'app/features/transactions/transactionSlice';
import NewPageContent from 'layouts/NewPageContent';
import routePaths from 'app/config/routePaths';
import TransactionFilterValues from 'components/transactions/TransactionFilterValues';
import FilterButton from 'core/buttons/FilterButton';

function ListTransaction({ isNetworkError }) {
  const transactionState = useSelector((state) => state.transactions.index);

  const { isError, errorCode, isFilterOpen } = transactionState;

  const dispatch = useDispatch();

  useEffect(() => {
    if (isError) dispatch(resetGetTransactionsFailure());
  }, [isNetworkError]);

  if (isError)
    return (
      <Error
        status={errorCode}
        withInDashboard={true}
        onRetry={() => dispatch(resetGetTransactionsFailure())}
      />
    );

  return (
    <PendingTransactionsContext.Provider
      value={currentRouteName() === 'pending-bank-transactions.index'}
    >
      <Content dispatch={dispatch} isFilterOpen={isFilterOpen} />
    </PendingTransactionsContext.Provider>
  );
}

export default compose(
  withAppState,
  withRole([acl.SUPERADMIN, acl.ADMIN, acl.USER]),
)(ListTransaction);

function Content({ dispatch, isFilterOpen }) {
  const isPendingTransactionsContext = useContext(PendingTransactionsContext);

  return (
    <NewPageContent
      breadcrumbs={[
        {
          label: 'Transactions',
          to: routePaths.ListTransactions,
        },
      ]}
      documentTitle={isPendingTransactionsContext ? 'Pending Bank Transactions' : 'Transactions'}
      rightTopEndContent={
        <FilterButton
          variant="outlined"
          onClick={() => dispatch(toggleFilter())}
          isOpen={isFilterOpen}
          uppercase
        />
      }
    >
      <TransactionFilterValues />
      <Transactions />
      {/* <Box
        sx={{
          marginTop: 4,
        }}
      >
        <TransactionStat />
      </Box> */}
    </NewPageContent>
  );
}
