import { useMemo, useEffect } from 'react';
import Box from '@mui/material/Box';
import { Outlet } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import styled from '@mui/material/styles/styled';
import CssBaseline from '@mui/material/CssBaseline';
import { useDispatch, useSelector } from 'react-redux';

import AccountBoxOutlinedIcon from '@mui/icons-material/AccountBoxOutlined';
import PublicIcon from '@mui/icons-material/Public';
import NewspaperOutlinedIcon from '@mui/icons-material/NewspaperOutlined';
import PhishingIcon from '@mui/icons-material/Phishing';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import BusinessIcon from '@mui/icons-material/Business';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import ReceiptOutlinedIcon from '@mui/icons-material/ReceiptOutlined';
import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined';
import PersonPinOutlinedIcon from '@mui/icons-material/PersonPinOutlined';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import PeopleOutlinedIcon from '@mui/icons-material/PeopleOutlined';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import GroupsIcon from '@mui/icons-material/Groups';

import Topbar from 'layouts/Topbar';
import Sidebar from 'layouts/Sidebar';
import DrawerContent from './DrawerContent';
import LogoutModal from 'components/auth/LogoutModal';

import Auth from 'utils/Auth';
import Route from 'utils/Route';
import acl from 'app/config/acl';
import constants from 'app/config/constants';
import { buildRoute, currentRouteName } from 'routes';
import { setIsDrawerOpen } from 'app/features/app/appSlice';
import { getLoggedInUser } from 'app/features/auth/authSaga';

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

function AppLayout() {
  const dispatch = useDispatch();

  const { isDrawerOpen: open } = useSelector((state) => state.app);

  const handleDrawerOpen = () => dispatch(setIsDrawerOpen(true));

  const handleDrawerClose = () => dispatch(setIsDrawerOpen(false));

  const location = useLocation();

  const sidebarItems = useMemo(() => {
    let activeRouteName = currentRouteName(location.pathname);
    return [
      {
        type: 'link',
        name: 'Dashboard',
        icon: <DashboardOutlinedIcon />,
        path: buildRoute('home'),
        isActive: activeRouteName === 'home' || activeRouteName === 'dashboard',
      },
      ...(Auth.isAdmin()
        ? [
            {
              type: 'link',
              name: 'Business Profile',
              icon: <BusinessIcon />,
              path: buildRoute('business-profile'),
              isActive: activeRouteName === 'business-profile',
            },
          ]
        : []),
      ...(Auth.isUser()
        ? [
            {
              type: 'link',
              name: 'Funding Sources',
              icon: <BusinessIcon />,
              path: buildRoute('partner-funding-sources.index'),
              isActive: activeRouteName === 'partner-funding-sources.index',
            },
            {
              type: 'link',
              name: 'Countries',
              icon: <PublicIcon />,
              path: buildRoute('partner-countries.index'),
              isActive: activeRouteName === 'partner-countries.index',
            },
          ]
        : []),
      {
        type: 'dropdown',
        name: 'Partners',
        icon: <AccountBoxOutlinedIcon />,
        isActive: Route.is('partners*'),
        children: [
          {
            type: 'link',
            path: buildRoute('partners.index'),
            isActive: activeRouteName === 'partners.index',
            name: 'Partners List',
            roles: [acl.SUPERADMIN],
          },
          {
            type: 'link',
            path: buildRoute('partner-countries.index'),
            isActive: activeRouteName === 'partner-countries.index',
            name: 'Partner Corridors',
            roles: [acl.SUPERADMIN],
          },
          {
            type: 'link',
            path: buildRoute('partner-funding-sources.index'),
            isActive: activeRouteName === 'partner-funding-sources.index',
            name: 'Funding Sources',
            roles: [acl.SUPERADMIN],
          },
        ],
      },
      {
        type: 'dropdown',
        name: 'User Management',
        icon: <GroupsIcon />,
        isActive: Route.is('roles*'),
        children: [
          {
            type: 'link',
            path: buildRoute('accounts.index'),
            isActive: activeRouteName === 'accounts.index',
            name: 'User Setups',
            roles: [acl.SUPERADMIN, acl.ADMIN],
          },
          {
            type: 'link',
            path: buildRoute('roles.index'),
            isActive: activeRouteName === 'roles.index',
            name: 'Roles and Permission',
            roles: [acl.SUPERADMIN, acl.ADMIN],
          },
        ],
      },
      {
        type: 'dropdown',
        name: 'Customers',
        icon: <PersonOutlinedIcon />,
        isActive: Route.is('customers*') || Route.is('customer-kycs*'),
        roles: [],
        children: [
          {
            type: 'link',
            path: buildRoute('customers.index'),
            isActive: activeRouteName === 'customers.index',
            name: 'Customer List',
            roles: [acl.SUPERADMIN, acl.ADMIN, acl.USER],
          },
          {
            type: 'link',
            path: buildRoute('customer-kycs.index'),
            isActive: activeRouteName === 'customer-kycs.index',
            name: 'KYC List',
            roles: [acl.SUPERADMIN, acl.ADMIN, acl.USER],
          },
          {
            type: 'link',
            name: 'Beneficiary List',
            path: buildRoute('beneficiaries.index'),
            isActive: Route.is('beneficiaries*'),
            roles: [acl.ADMIN, acl.SUPERADMIN, acl.USER],
          },
        ],
      },

      {
        type: 'link',
        name: 'Credentials',
        icon: <SettingsSuggestIcon />,
        path: buildRoute('partner-credentials.index'),
        isActive: Route.is('partner-credentials*'),
        roles: [acl.ADMIN],
      },
      {
        type: 'dropdown',
        name: 'Transactions',
        icon: <ReceiptOutlinedIcon />,
        isActive: Route.is('transactions*'),
        children: [
          {
            type: 'link',
            path: buildRoute('pending-bank-transactions.index'),
            isActive: Route.is('transactions/pending-bank-transactions'),
            name: 'Pending Transaction',
            roles: [acl.SUPERADMIN, acl.ADMIN, acl.USER],
          },
          {
            type: 'link',
            path: buildRoute('transactions.index'),
            isActive: Route.is('transactions'),
            name: 'All Transactions',
            roles: [acl.SUPERADMIN, acl.ADMIN, acl.USER],
          },
          {
            type: 'link',
            path: buildRoute('zai-transactions.index'),
            isActive: Route.is('transactions/zai'),
            name: 'Zai Transactions',
            roles: [acl.SUPERADMIN, acl.ADMIN, acl.USER],
          },
        ],
      },
      {
        type: 'dropdown',
        name: 'Reports',
        icon: <NewspaperOutlinedIcon />,
        isActive: Route.is('finmo/*'),
        children: [
          {
            type: 'link',
            path: buildRoute('finmo-payins.index'),
            isActive: activeRouteName === 'finmo-payins.index',
            name: 'Payins',
            roles: [acl.SUPERADMIN],
          },
          {
            type: 'link',
            path: buildRoute('finmo-payouts.index'),
            isActive: activeRouteName === 'finmo-payouts.index',
            name: 'Payouts',
            roles: [acl.SUPERADMIN],
          },
          {
            type: 'link',
            path: buildRoute('aus-track-reports'),
            isActive: activeRouteName === 'aus-track-reports',
            name: 'AusTrack Reports',
            roles: [acl.SUPERADMIN],
          },
        ],
      },
      {
        type: 'dropdown',
        name: 'Master Setups',
        icon: <SettingsOutlinedIcon />,
        isActive: Route.is('api-configs*'),
        children: [
          {
            type: 'link',
            path: buildRoute('licensing-partners.index'),
            isActive: activeRouteName === 'licensing-partners.index',
            name: 'Licensing Partner',
            roles: [acl.SUPERADMIN],
          },
          {
            type: 'link',
            path: buildRoute('funding-sources.index'),
            isActive: activeRouteName === 'funding-sources.index',
            name: 'Funding Sources',
            roles: [acl.SUPERADMIN],
          },
          {
            type: 'link',
            path: buildRoute('funding-sources-info.index'),
            isActive: activeRouteName === 'funding-sources-info.index',
            name: 'Funding Sources Info',
            roles: [acl.SUPERADMIN],
          },
          {
            type: 'link',
            path: buildRoute('api-configs.index'),
            isActive: activeRouteName === 'api-configs.index',
            name: 'API Configs',
            roles: [acl.SUPERADMIN],
          },
          {
            type: 'link',
            path: buildRoute('webhooks.index'),
            isActive: activeRouteName === 'webhooks.index',
            name: 'Webhook List',
            roles: [acl.SUPERADMIN, acl.ADMIN],
          },
          {
            type: 'link',
            path: buildRoute('webhook-headers.index'),
            isActive: activeRouteName === 'webhook-headers.index',
            name: 'Webhook Headers',
            roles: [acl.SUPERADMIN],
          },
        ],
      },

      {
        type: 'dropdown',
        name: 'Logs',
        icon: <ReceiptLongIcon />,
        isActive: Route.is('logs/*'),
        children: [
          {
            type: 'link',
            path: buildRoute('zai-transactions.webhookLogs'),
            isActive: activeRouteName === 'zai-transactions.webhookLogs',
            name: 'Zai Webhook Logs',
            roles: [acl.SUPERADMIN],
          },
          {
            type: 'link',
            path: buildRoute('zai-transactions.refundLogs'),
            isActive: activeRouteName === 'zai-transactions.refundLogs',
            name: 'Zai Refund Logs',
            roles: [acl.SUPERADMIN],
          },
        ],
      },

      // {
      //   type: 'divider'
      // },
      // {
      //   type: 'sub_header',
      //   name: 'setup_and_settings'
      // },
    ];
  }, [location.pathname]);

  useEffect(() => {
    dispatch(getLoggedInUser());
    sessionStorage.clear(constants.INTENDED_PATH);
  }, []);

  return (
    <Box
      sx={{
        display: 'flex',
        backgroundColor: (theme) => theme.palette.grey[100],
        overflowX: 'hidden',
      }}
    >
      <CssBaseline />
      <Topbar />
      <Sidebar
        open={open}
        handleDrawerClose={handleDrawerClose}
        appBar={<DrawerContent open={open} />}
        sidebarItems={sidebarItems}
      />
      <Box component="main" sx={{ flexGrow: 1, paddingY: 3 }}>
        <DrawerHeader />
        <Outlet />
      </Box>
      <LogoutModal />
    </Box>
  );
}

export default AppLayout;
