import { createSlice, current } from '@reduxjs/toolkit';
import app from 'app/config/app';
import Response from 'app/config/Response';
import isEmpty from 'helpers/isEmpty';

const initialState = {
  index: {
    data: [],
    params: {
      page_size: app.perPage || 10,
      page_number: 1,
      sort_by: 'created_at',
      order_by: 'desc',
    },
    isLoading: false,
    totalCount: 0,
    totalPage: 0,
    isError: false,
    errorCode: null,
    errorMessage: null,
  },
  create: {
    showProcessingModal: false,
    isCreating: false,
    isCreated: false,
    isFailed: false,
    errors: null,
    data: null,
    retries: 0,
    errorMessage: null,
  },

  update: {
    showProcessingModal: false,
    isUpdating: false,
    isUpdated: false,
    isFailed: false,
    errors: null,
    data: null,
    retries: 0,
    errorMessage: null,
  },
  show: {
    showBriefDetailModal: false,
    roleId: null,
    isLoading: false,
    isError: false,
    errorCode: null,
    errorMessage: null,
    data: null,
  },
};

export const roleAndPermissionsSlice = createSlice({
  name: 'rolesAndPermissions',
  initialState,
  reducers: {
    getRolesInit: (state) => {
      state.index = {
        ...state.index,
        isLoading: true,
        isError: false,
        errorCode: null,
        errorMessage: null,
      };
    },
    getRolesSuccess: (state, { payload }) => {
      let { data, pagination } = payload;

      const { totalCount, totalPage, currentPage, pageSize } = pagination;

      let startSN = (currentPage - 1) * pageSize;

      data = data?.map((item) => ({ sn: (startSN += 1), ...item }));

      state.index = {
        ...state.index,
        isLoading: false,
        isError: false,
        errorCode: null,
        errorMessage: null,
        data,
        totalCount,
        totalPage,
      };
    },

    getRolesFailure: (state, { payload }) => {
      state.index = {
        ...state.index,
        isLoading: false,
        isError: true,
        errorCode: payload?.response?.status ?? 0,
        errorMessage: payload?.response?.message,
      };
    },

    resetGetRolesFailure: (state) => {
      state.index = {
        ...state.index,
        isLoading: false,
        isError: false,
        errorCode: null,
        errorMessage: null,
      };
    },

    addRolesInit: (state) => {
      state.create = {
        ...state.create,
        showProcessingModal: true,
        isCreating: true,
        isCreated: false,
        isFailed: false,
        errors: null,
        data: null,
        errorMessage: null,
      };
    },
    addRolesSuccess: (state, { payload }) => {
      state.create = {
        ...state.create,
        showProcessingModal: false,
        isCreating: false,
        isCreated: true,
        isFailed: false,
        errors: null,
        data: payload.data,
      };
    },
    addRolesFailure: (state, { payload }) => {
      state.create = {
        ...state.create,
        showProcessingModal: false,
        isCreating: false,
        isCreated: false,
        isFailed: true,
        errors: payload?.response?.errors,
        errorMessage: payload?.response?.message,
      };
    },
    retryToCreate: (state) => {
      state.create.retries += 1;
    },
    setShowCreateProcessingModal: (state, { payload }) => {
      state.create.showProcessingModal = payload;
    },

    updateRolesInit: (state) => {
      state.update = {
        ...state.update,
        showProcessingModal: true,
        isUpdating: true,
        isUpdated: false,
        isFailed: false,
        errors: null,
        data: null,
        errorMessage: null,
      };
    },

    updateRolesSuccess: (state, { payload }) => {
      state.update = {
        ...state.update,
        showProcessingModal: false,
        isUpdating: false,
        isUpdated: true,
        isFailed: false,
        errors: null,
        data: payload.data,
      };
    },

    updateRolesFailure: (state, { payload }) => {
      state.update = {
        ...state.update,
        showProcessingModal: false,
        isUpdating: false,
        isUpdated: false,
        isFailed: true,
        errors: payload?.response?.errors,
        errorMessage: payload?.response?.message || 'Failed to update Role.',
      };
    },

    retryToUpdate: (state) => {
      state.update.retries += 1;
    },

    setShowEditProcessingModal: (state, { payload }) => {
      state.update.showProcessingModal = payload;
    },

    setParam: (state, { payload }) => {
      state.index.params = {
        ...state.index.params,
        ...payload,
      };
    },

    resetParams: (state) => {
      state.index.params = {
        page_size: state.index.params.page_size,
        page_number: 1,
      };
    },

    deleteFilterParams: (state, { payload }) => {
      if (Array.isArray(payload)) payload.forEach((key) => delete state.index.params[key]);
      else delete state.index.params[payload];
    },

    getRoleInit: (state) => {
      state.show = {
        ...state.show,
        isLoading: true,
        isError: false,
        errorCode: null,
        errorMessage: null,
      };
    },

    getRoleSuccess: (state, { payload }) => {
      state.show = {
        ...state.show,
        isLoading: false,
        isError: false,
        errorCode: null,
        errorMessage: null,
        data: payload.data,
      };
    },

    getRoleFailure: (state, { payload }) => {
      state.show = {
        ...state.show,
        isLoading: false,
        isError: true,
        errorCode: payload?.response?.status ?? 0,
        errorMessage: payload?.response?.message,
        data: null,
      };
    },

    resetGetRoleFailure: (state, { payload }) => {
      state.show = {
        ...state.show,
        isLoading: false,
        isError: false,
        errorCode: null,
        errorMessage: null,
        data: null,
      };
    },

    getRolesBriefDetailsInit: (state, { payload }) => {
      state.show = {
        ...state.show,
        showBriefDetailModal: payload.showBriefDetailModal,
        roleId: payload.roleId,
      };
    },

    hideRolesBriefDetails: (state) => {
      state.show = {
        showBriefDetailModal: false,
        rolesId: null,
        isLoading: false,
        isError: false,
        errorCode: null,
        errorMessage: null,
        data: null,
      };
    },
  },
});

export const {
  getRolesInit,
  getRolesSuccess,
  getRolesFailure,
  resetGetRolesFailure,
  addRolesInit,
  addRolesSuccess,
  addRolesFailure,
  retryToCreate,
  setShowCreateProcessingModal,
  updateRolesInit,
  updateRolesSuccess,
  updateRolesFailure,
  retryToUpdate,
  setShowEditProcessingModal,
  setParam,
  resetParams,
  deleteFilterParams,
  getRoleInit,
  getRoleSuccess,
  getRoleFailure,
  resetGetRoleFailure,
  getRolesBriefDetailsInit,
  hideRolesBriefDetails,
} = roleAndPermissionsSlice.actions;

export default roleAndPermissionsSlice.reducer;
