import React from 'react';
import { useNavigate } from 'react-router-dom';

import withRole from 'hoc/withRole';
import PartnerFundingSourceForm from 'components/partner-funding-sources/PartnerFundingSourceForm';
import PartnerFundingSourceProgressIndicatorModal from 'components/partner-funding-sources/PartnerFundingSourceProgressIndicatorModal';

import acl from 'app/config/acl';
import routePaths from 'app/config/routePaths';
import NewPageContent from 'layouts/NewPageContent';

function AddPartnerFundingSource() {
  const navigate = useNavigate();

  return (
    <NewPageContent
      breadcrumbs={[
        {
          label: 'Partner Funding Source',
          to: routePaths.ListPartnerFundingSource,
        },
        {
          label: 'View Partner funding Sources',
          to: '',
        },
      ]}
      documentTitle="Add Partner Funding Source"
    >
      <PartnerFundingSourceForm isAddMode={true} />
      <PartnerFundingSourceProgressIndicatorModal isAddMode={true} />
    </NewPageContent>
  );
}

export default withRole([acl.SUPERADMIN, acl.ADMIN])(AddPartnerFundingSource);
