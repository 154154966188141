import { useState, createElement } from 'react';
import PropTypes from 'prop-types';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import InfoTwoToneIcon from '@mui/icons-material/InfoTwoTone';
import SecurityTwoToneIcon from '@mui/icons-material/SecurityTwoTone';

import AboutSettingTab from './tabs/AboutSettingTab';
import SecuritySettingTab from './tabs/SecuritySettingTab';

import useQueryParams from 'hooks/useQueryParams';
import styled from '@emotion/styled';

let _tabs = [
  {
    name: 'about',
    label: 'About',
    icon: <InfoTwoToneIcon />,
    content: AboutSettingTab,
  },
  {
    name: 'security',
    label: 'Security',
    icon: <SecurityTwoToneIcon />,
    content: SecuritySettingTab,
  },
];

const TabWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexGrow: 1,
  backgroundColor: theme.palette.background.paper,
  borderRadius: 15,
  boxShadow: '10px 8px 10px 2px rgba(0, 0, 0, 0.06)',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
  },
}));

function Settings() {
  const [params, updateQuery] = useQueryParams();

  let activeIndex = _tabs.findIndex((tab) => tab.name === params.tab);

  const [value, setValue] = useState(activeIndex !== -1 ? activeIndex : 0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    updateQuery({ tab: _tabs[newValue].name });
  };

  return (
    <TabWrapper>
      <Tabs
        orientation="vertical"
        variant="scrollable"
        value={value}
        onChange={handleChange}
        aria-label="Vertical tabs"
        sx={{ borderRight: 1, borderColor: 'divider', py: 3, minWidth: '250px' }}
      >
        {_tabs.map((tab, index) => (
          <Tab
            key={index}
            label={tab.label}
            icon={tab.icon}
            {...a11yProps(index)}
            iconPosition="start"
            sx={{
              display: 'flex',
              justifyContent: 'start',
              fontSize: '1rem',
              m: '0.5rem',
              py: 1,
              px: 3,
              minHeight: 0,
              '&:hover': {
                bgcolor: 'grey.200',
                borderRadius: '0.2rem',
              },
            }}
          />
        ))}
      </Tabs>
      {_tabs.map((tab, index) => (
        <TabPanel key={index} value={value} index={index}>
          {/* you can pass props in below {} */}
          {createElement(tab.content, {})}
        </TabPanel>
      ))}
    </TabWrapper>
  );
}

export default Settings;

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
      style={{ width: '100%' }}
    >
      {value === index && (
        <Box className="setting-tab-item__container" sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}
