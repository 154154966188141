import { createSlice } from '@reduxjs/toolkit';
import app from 'app/config/app';
import Response from 'app/config/Response';
import isEmpty from 'helpers/isEmpty';

const initialState = {
  index: {
    data: [],
    params: {
      page_size: app.perPage || 10,
      page_number: 1,
      sort_by: 'created_ts',
      order_by: 'desc',
    },
    isLoading: false,
    totalCount: 0,
    totalPage: 0,
    isError: false,
    errorCode: null,
    errorMessage: null,
  },
  create: {
    showProcessingModal: false,
    isCreating: false,
    isCreated: false,
    isFailed: false,
    errors: null,
    data: null,
    retries: 0,
    errorMessage: null,
  },
  edit: {
    isLoading: false,
    isError: false,
    errorCode: null,
    errorMessage: null,
    data: null,
  },
  update: {
    showProcessingModal: false,
    isUpdating: false,
    isUpdated: false,
    isFailed: false,
    errors: null,
    data: null,
    retries: 0,
    errorMessage: null,
  },
  show: {
    showBriefDetailModal: false,
    accountId: null,
    isLoading: false,
    isError: false,
    errorCode: null,
    errorMessage: null,
    data: null,
  },
};

export const accountSlice = createSlice({
  name: 'accounts',
  initialState,
  reducers: {
    getAccountsInit: (state) => {
      state.index = {
        ...state.index,
        isLoading: true,
        isError: false,
        errorCode: null,
        errorMessage: null,
      };
    },

    getAccountsSuccess: (state, { payload }) => {
      let { data, pagination } = payload;

      const { totalCount, totalPage, currentPage, pageSize } = pagination;

      let startSN = (currentPage - 1) * pageSize;

      data = data?.map((item) => ({ sn: (startSN += 1), ...item }));

      state.index = {
        ...state.index,
        isLoading: false,
        isError: false,
        errorCode: null,
        errorMessage: null,
        data,
        totalCount,
        totalPage,
      };
    },

    getAccountsFailure: (state, { payload }) => {
      state.index = {
        ...state.index,
        isLoading: false,
        isError: true,
        errorCode: payload?.response?.status ?? 0,
        errorMessage: payload?.response?.message,
      };
    },

    resetGetAccountsFailure: (state) => {
      state.index = {
        ...state.index,
        isLoading: false,
        isError: false,
        errorCode: null,
        errorMessage: null,
      };
    },

    addAccountInit: (state) => {
      state.create = {
        ...state.create,
        showProcessingModal: true,
        isCreating: true,
        isCreated: false,
        isFailed: false,
        errors: null,
        data: null,
        errorMessage: null,
      };
    },

    addAccountSuccess: (state, { payload }) => {
      state.create = {
        ...state.create,
        showProcessingModal: true,
        isCreating: false,
        isCreated: true,
        isFailed: false,
        errors: null,
        data: payload.data,
      };
    },

    addAccountFailure: (state, { payload }) => {
      state.create = {
        ...state.create,
        showProcessingModal: true,
        isCreating: false,
        isCreated: false,
        isFailed: true,
        errors: null,
        data: null,
        errorMessage: payload?.response?.data?.message || '',
      };
    },

    addAccountReset: (state) => {
      state.create = {
        showProcessingModal: false,
        isCreating: false,
        isCreated: false,
        isFailed: false,
        errors: null,
        data: null,
        errorMessage: null,
      };
    },

    retryToCreate: (state) => {
      state.create.retries += 1;
    },

    setShowCreateProcessingModal: (state, { payload }) => {
      state.create.showProcessingModal = payload;
    },

    editAccountInit: (state) => {
      state.edit = {
        ...state.edit,
        isLoading: true,
        isError: false,
        errorCode: null,
        data: null,
        errorMessage: null,
      };

      state.update = {
        ...state.update,
        showProcessingModal: false,
      };
    },

    editAccountSuccess: (state, { payload }) => {
      if (isEmpty(payload?.data)) {
        state.edit = {
          ...state.edit,
          isLoading: false,
          isError: true,
          errorCode: Response.HTTP_NOT_FOUND,
          errorMessage: 'Account not found',
          data: null,
        };
      } else {
        state.edit = {
          ...state.edit,
          isLoading: false,
          isError: false,
          errorCode: null,
          data: payload.data,
        };
      }
    },

    editAccountFailure: (state, { payload }) => {
      state.edit = {
        ...state.edit,
        isLoading: false,
        isError: true,
        errorCode: payload?.response?.status ?? 0,
        data: null,
        errorMessage: payload?.response?.data?.message ?? null,
      };
    },

    updateAccountInit: (state) => {
      state.update = {
        ...state.update,
        showProcessingModal: true,
        isUpdating: true,
        isUpdated: false,
        isFailed: false,
        errors: null,
        data: null,
        errorMessage: null,
      };
    },

    updateAccountSuccess: (state, { payload }) => {
      state.update = {
        ...state.update,
        showProcessingModal: true,
        isUpdating: false,
        isUpdated: true,
        isFailed: false,
        errors: null,
        data: payload.data,
      };
    },

    updateAccountFailure: (state, { payload }) => {
      state.update = {
        ...state.update,
        showProcessingModal: true,
        isUpdating: false,
        isUpdated: false,
        isFailed: true,
        errors: payload,
        data: null,
        errorMessage: payload?.response?.data?.message || '',
      };
    },

    updateAccountReset: (state) => {
      state.update = {
        showProcessingModal: false,
        isUpdating: false,
        isUpdated: false,
        isFailed: false,
        errors: null,
        data: null,
        errorMessage: null,
      };
    },

    retryToUpdate: (state) => {
      state.update.retries += 1;
    },

    setShowEditProcessingModal: (state, { payload }) => {
      state.update.showProcessingModal = payload;
    },

    setParam: (state, { payload }) => {
      state.index.params = { ...state.index.params, ...payload };
    },

    resetParam: (state) => {
      state.index.params = {
        page_size: state.index.params.page_size,
        page_number: 1,
      };
    },

    deleteFilterParams: (state, { payload }) => {
      if (Array.isArray(payload)) payload.forEach((key) => delete state.index.params[key]);
      else delete state.index.params[payload];
    },

    getAccountInit: (state) => {
      state.show = {
        ...state.show,
        isLoading: true,
        isError: false,
        errorCode: null,
        errorMessage: null,
        data: null,
      };
    },

    getAccountSuccess: (state, { payload }) => {
      if (isEmpty(payload?.data)) {
        state.show = {
          ...state.show,
          isLoading: false,
          isError: true,
          errorCode: Response.HTTP_NOT_FOUND,
          errorMessage: 'Account not found',
        };
      } else {
        state.show = {
          ...state.show,
          isLoading: false,
          isError: false,
          errorCode: null,
          errorMessage: null,
          data: payload.data,
        };
      }
    },

    getAccountFailure: (state, { payload }) => {
      state.show = {
        ...state.show,
        isLoading: false,
        isError: true,
        errorCode: payload?.response?.status ?? 0,
        errorMessage: payload?.response?.message,
        data: null,
      };
    },

    resetGetAccountFailure: (state, { payload }) => {
      state.show = {
        ...state.show,
        isLoading: false,
        isError: false,
        errorCode: null,
        errorMessage: null,
        data: null,
      };
    },

    getAccountBriefDetailsInit: (state, { payload }) => {
      state.show = {
        ...state.show,
        showBriefDetailModal: payload.showBriefDetailModal,
        accountId: payload.accountId,
      };
    },

    hideAccountBriefDetails: (state) => {
      state.show = {
        showBriefDetailModal: false,
        accountId: null,
        isLoading: false,
        isError: false,
        errorCode: null,
        errorMessage: null,
        data: null,
      };
    },

    deleteAccountInit: (state, { payload: accountId }) => {
      state.index.data = state.index.data.map((item) => {
        if (item.id == accountId) {
          return {
            ...item,
            isDeleting: true,
          };
        }
        return item;
      });
    },

    deleteAccountSuccess: (state, { payload: accountId }) => {
      const { page_number: currentPage, page_size: pageSize } = state.index.params;

      let startSN = (currentPage - 1) * pageSize;

      let data = state.index.data.filter((item) => item.id !== accountId);

      state.index.data = data.map((item) => ({ ...item, sn: (startSN += 1) }));
    },

    deleteAccountFailure: (state, { payload: accountId }) => {
      state.index.data = state.index.data.map((item) => {
        if (item.id == accountId) {
          return {
            ...item,
            isDeleting: false,
          };
        }
        return item;
      });
    },

    updateIsActiveStatusInit: (state, { payload: { accountId, isActive } }) => {
      state.index.data = state.index.data.map((item) => {
        if (item.id == accountId) {
          return {
            ...item,
            is_active: isActive,
            isTogglingIsActiveStatus: true,
          };
        }
        return item;
      });
    },

    updateIsActiveStatusSuccess: (state, { payload: { accountId, isActive } }) => {},

    updateIsActiveStatusFailure: (state, { payload: { accountId, isActive } }) => {
      state.index.data = state.index.data.map((item) => {
        if (item.id == accountId) {
          return {
            ...item,
            is_active: !isActive,
            isTogglingIsActiveStatus: false,
          };
        }
        return item;
      });
    },
  },
});

export const {
  getAccountsInit,
  getAccountsSuccess,
  getAccountsFailure,
  resetGetAccountsFailure,
  addAccountInit,
  addAccountSuccess,
  addAccountFailure,
  addAccountReset,
  retryToCreate,
  updateAccountInit,
  updateAccountSuccess,
  updateAccountFailure,
  updateAccountReset,
  retryToUpdate,
  setParam,
  resetParam,
  deleteFilterParams,
  setShowCreateProcessingModal,
  setShowEditProcessingModal,
  getAccountInit,
  getAccountSuccess,
  getAccountFailure,
  resetGetAccountFailure,
  editAccountInit,
  editAccountSuccess,
  editAccountFailure,
  getAccountBriefDetailsInit,
  hideAccountBriefDetails,
  deleteAccountInit,
  deleteAccountSuccess,
  deleteAccountFailure,
  updateIsActiveStatusInit,
  updateIsActiveStatusSuccess,
  updateIsActiveStatusFailure,
} = accountSlice.actions;

export default accountSlice.reducer;
