import qs from 'qs';
import { createAction } from '@reduxjs/toolkit';
import { takeLatest, put } from 'redux-saga/effects';

import Notify from 'utils/Notify';
import http from 'app/services/httpService';
import { callWrapperSaga } from 'app/services/sagaService';
import apiEndpoints, { buildApiRoute } from 'routes/apiEndpoints';

import {
  getPartnerCountriesInit,
  getPartnerCountriesSuccess,
  getPartnerCountriesFailure,
  addPartnerCountryInit,
  addPartnerCountrySuccess,
  addPartnerCountryFailure,
  updatePartnerCountryInit,
  updatePartnerCountrySuccess,
  updatePartnerCountryFailure,
  getPartnerCountryInit,
  getPartnerCountryFailure,
  getPartnerCountrySuccess,
  editPartnerCountryInit,
  editPartnerCountrySuccess,
  editPartnerCountryFailure,
  deletePartnerCountryInit,
  deletePartnerCountrySuccess,
  deletePartnerCountryFailure,
  updateIsActiveStatusInit,
  updateIsActiveStatusFailure,
  updateIsActiveStatusSuccess,
} from './partnerCountrySlice';

const getPartnerCountry = createAction('partnerCountries/get');
const createPartnerCountry = createAction('partnerCountries/create');
const updatePartnerCountry = createAction('partnerCountries/update');
const getPartnerCountries = createAction('partnerCountries/list');
const editPartnerCountry = createAction('partnerCountries/edit');
const deletePartnerCountry = createAction('partnerCountries/delete');
const updateIsActiveStatus = createAction('partnerCountries/update-is-active-status');

/**
 * create a partnerCountry
 *
 * @param {object} data
 */
function* createPartnerCountrySaga({ payload: data }) {
  yield put(addPartnerCountryInit());
  try {
    let response = yield callWrapperSaga(() => http.post(apiEndpoints.createPartnerCountry, data));
    yield put(addPartnerCountrySuccess(response.data));
    Notify.success('New Partner Country created successfully.');
  } catch (err) {
    yield put(addPartnerCountryFailure(err));
    Notify.error('Failed to create new partner country.');
  }
}

/**
 * update a partnerCountry
 *
 * @param {object} { id, ...data }
 */
function* updatePartnerCountrySaga({ payload: { id, ...data } }) {
  try {
    yield put(updatePartnerCountryInit());
    let endpoint = buildApiRoute(apiEndpoints.updatePartnerCountryIsActiveStatus, id);
    let response = yield callWrapperSaga(() => http.patch(endpoint, data));
    yield put(updatePartnerCountrySuccess(response.data));
  } catch (err) {
    console.log('The update partner countries error is', err);

    yield put(updatePartnerCountryFailure(err));
    Notify.error('Partner Country update failed');
  }
}

/**
 * get a single partnerCountry
 */
function* getPartnerCountrySaga({ payload: partnerCountryId }) {
  try {
    yield put(getPartnerCountryInit());
    let endpoint = buildApiRoute(apiEndpoints.getPartnerCountry, partnerCountryId);
    let response = yield callWrapperSaga(() => http.get(endpoint, partnerCountryId));
    yield put(getPartnerCountrySuccess(response.data));
  } catch (err) {
    yield put(getPartnerCountryFailure(err));
  }
}

/**
 * get a partnerCountries list
 *
 */
function* getPartnerCountriesSaga({ payload }) {
  yield put(getPartnerCountriesInit());
  try {
    let endpoint = `${apiEndpoints.getPartnerCountries}?${new URLSearchParams(payload)}`;
    let response = yield callWrapperSaga(() => http.get(endpoint));
    yield put(getPartnerCountriesSuccess(response.data));
  } catch (err) {
    yield put(getPartnerCountriesFailure(err));
  }
}

/**
 * initiate edit partnerCountry
 * */
function* editPartnerCountrySaga({ payload: partnerCountryId }) {
  try {
    yield put(editPartnerCountryInit());
    let endpoint = buildApiRoute(apiEndpoints.getPartnerCountry, partnerCountryId);
    let response = yield callWrapperSaga(() => http.get(endpoint, partnerCountryId));
    yield put(editPartnerCountrySuccess(response.data));
  } catch (err) {
    yield put(editPartnerCountryFailure(err));
  }
}

function* deletePartnerCountrySaga({ payload: partnerCountryId }) {
  try {
    yield put(deletePartnerCountryInit(partnerCountryId));
    let endpoint = buildApiRoute(apiEndpoints.deletePartnerCountry, partnerCountryId);
    yield callWrapperSaga(() => http.delete(endpoint));
    Notify.success('Partner Country deleted successfully.');
    yield put(deletePartnerCountrySuccess(partnerCountryId));
  } catch (err) {
    Notify.error(err?.response?.data?.data ?? 'Failed to delete partner country');
    yield put(deletePartnerCountryFailure(err));
  }
}

function* updateIsActiveStatusSaga({ payload: { partnerCountryId, isActive } }) {
  try {
    yield put(updateIsActiveStatusInit({ partnerCountryId, isActive }));
    let endpoint = buildApiRoute(apiEndpoints.updatePartnerCountryIsActiveStatus, partnerCountryId);
    yield callWrapperSaga(() => http.patch(`${endpoint}?${qs.stringify({ is_active: isActive })}`));
    Notify.success('Partner Country status changed successfully.');
    yield put(updateIsActiveStatusSuccess({ partnerCountryId, isActive }));
  } catch (err) {
    Notify.error('Failed to change partner country status');
    yield put(updateIsActiveStatusFailure({ partnerCountryId, isActive }));
  }
}

function* partnerCountrySaga() {
  yield takeLatest(createPartnerCountry, createPartnerCountrySaga);
  yield takeLatest(updatePartnerCountry, updatePartnerCountrySaga);
  yield takeLatest(getPartnerCountry, getPartnerCountrySaga);
  yield takeLatest(getPartnerCountries, getPartnerCountriesSaga);
  yield takeLatest(editPartnerCountry, editPartnerCountrySaga);
  yield takeLatest(deletePartnerCountry, deletePartnerCountrySaga);
  yield takeLatest(updateIsActiveStatus, updateIsActiveStatusSaga);
}

export default partnerCountrySaga;
export {
  createPartnerCountry,
  updatePartnerCountry,
  getPartnerCountry,
  getPartnerCountries,
  editPartnerCountry,
  deletePartnerCountry,
  updateIsActiveStatus,
};
