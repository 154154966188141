import { useState } from 'react';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import Stack from '@mui/material/Stack';
import Button from 'core/buttons/Button';
import styled from '@mui/material/styles/styled';
import Typography from '@mui/material/Typography';
import ReplayIcon from '@mui/icons-material/Replay';
import CachedIcon from '@mui/icons-material/Cached';
import { useSelector, useDispatch } from 'react-redux';
import WifiOffIcon from '@mui/icons-material/WifiOff';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

import Spacer from 'core/spacer/Spacer';
import { checkInternetConnection } from 'app/features/app/appSaga';
import ActionButton from 'core/buttons/ActionButton';
import TextButton from 'core/buttons/TextButton';

const Wrapper = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: 0,
  right: 0,
  zIndex: '9999',
  background: theme.palette.grey[100],
  height: '100vh',
  width: '100vw',
  display: 'grid',
  placeItems: 'center',
}));

const Content = styled('div')(({ theme }) => ({
  zIndex: '99999',
  bottom: theme.spacing(4),
  left: theme.spacing(4),
  padding: theme.spacing(3),
  borderRadius: theme.spacing(1),
  display: 'flex',
  textAlign: 'center',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
}));

export default function NoInternetConnection({ onReload }) {
  const dispatch = useDispatch();

  const { isNetworkError, isCheckingInternetConnection } = useSelector((state) => state.app);

  const handleOnReload = () => {
    if (Object.prototype.toString.call(onReload) === '[object Function]') {
      onReload();
    } else {
      window.location.reload();
    }
  };

  if (!isNetworkError) return <></>;

  return (
    <Wrapper>
      <Content>
        <WifiOffIcon sx={{ fontSize: '3rem', color: (theme) => theme.palette.grey[500] }} />
        <Typography variant="h6" fontWeight={600} color="grey.500" letterSpacing={1}>
          No Internet Connection
        </Typography>
        <Stack sx={{ width: '100%' }}>
          <Typography variant="subtitle1" color="grey.500">
            Try these steps to get back online
          </Typography>
          <Spacer />
          <Box
            sx={{
              margin: 'auto',
            }}
          >
            <Box sx={{ display: 'flex', gap: 1 }}>
              <CheckCircleOutlineIcon sx={{ color: (theme) => theme.palette.grey[500] }} />
              <Typography variant="subtitle1" color="grey.500">
                Check your modem or router
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', gap: 1 }}>
              <CheckCircleOutlineIcon sx={{ color: (theme) => theme.palette.grey[500] }} />
              <Typography variant="subtitle1" color="grey.500">
                Reconnect to your wifi
              </Typography>
            </Box>
          </Box>
        </Stack>
        <Spacer />
        <Box sx={{ display: 'flex', gap: 2 }}>
          <TextButton
            variant="outlined"
            startIcon={<CachedIcon />}
            sx={{ textTransform: 'none' }}
            onClick={handleOnReload}
          >
            Reload Page
          </TextButton>
          <ActionButton
            startIcon={<ReplayIcon />}
            sx={{ textTransform: 'none' }}
            onClick={() => dispatch(checkInternetConnection())}
            disabled={isCheckingInternetConnection}
          >
            {isCheckingInternetConnection ? 'Reconnecting ....' : 'Try to reconnect'}
          </ActionButton>
        </Box>
      </Content>
    </Wrapper>
  );
}

NoInternetConnection.propTypes = {
  onReload: PropTypes.func,
};
