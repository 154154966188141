import { createSlice } from '@reduxjs/toolkit';

import app from 'app/config/app';
import isEmpty from 'helpers/isEmpty';
import Response from 'app/config/Response';

const initialState = {
  index: {
    data: [],
    params: {
      page_size: app.perPage || 10,
      page_number: 1,
      sort_by: 'created_ts',
      order_by: 'desc',
    },
    isLoading: false,
    totalCount: 0,
    totalPage: 0,
    isError: false,
    errorCode: null,
    errorMessage: null,
  },
  create: {
    showProcessingModal: false,
    isCreating: false,
    isCreated: false,
    isFailed: false,
    errors: null,
    data: null,
    retries: 0,
    errorMessage: null,
  },
  edit: {
    isLoading: false,
    isError: false,
    errorCode: null,
    data: null,
    errorMessage: null,
  },
  update: {
    showProcessingModal: false,
    isUpdating: false,
    isUpdated: false,
    isFailed: false,
    errors: null,
    data: null,
    retries: 0,
    errorMessage: null,
  },
  show: {
    showBriefDetailModal: false,
    webhookId: null,
    isLoading: false,
    isError: false,
    errorCode: null,
    errorMessage: null,
    data: null,
  },
};

export const webhookSlice = createSlice({
  name: 'webhooks',
  initialState,
  reducers: {
    getWebhooksInit: (state) => {
      state.index = {
        ...state.index,
        isLoading: true,
        isError: false,
        errorCode: null,
        errorMessage: null,
      };
    },

    getWebhooksSuccess: (state, { payload }) => {
      let { data, pagination } = payload;

      const { totalCount, totalPage, currentPage, pageSize } = pagination;

      let startSN = (currentPage - 1) * pageSize;

      data = data?.map((item) => ({ sn: (startSN += 1), ...item }));

      state.index = {
        ...state.index,
        isLoading: false,
        isError: false,
        errorCode: null,
        errorMessage: null,
        data,
        totalCount,
        totalPage,
      };
    },

    getWebhooksFailure: (state, { payload }) => {
      state.index = {
        ...state.index,
        isLoading: false,
        isError: true,
        errorCode: payload?.response?.status ?? 0,
        errorMessage: payload?.response?.message,
      };
    },

    resetGetWebhooksFailure: (state) => {
      state.index = {
        ...state.index,
        isLoading: false,
        isError: false,
        errorCode: null,
        errorMessage: null,
      };
    },

    addWebhookInit: (state) => {
      state.create = {
        ...state.create,
        showProcessingModal: true,
        isCreating: true,
        isCreated: false,
        isFailed: false,
        errors: null,
        data: null,
      };
    },

    addWebhookSuccess: (state, { payload }) => {
      state.create = {
        ...state.create,
        showProcessingModal: true,
        isCreating: false,
        isCreated: true,
        isFailed: false,
        errors: null,
        data: payload.data,
      };
    },

    addWebhookReset: (state, { payload }) => {
      state.create = {
        ...state.create,
        showProcessingModal: false,
        isCreating: false,
        isCreated: false,
        isFailed: false,
        errors: null,
        data: null,
      };
    },

    addWebhookFailure: (state, { payload }) => {
      state.create = {
        ...state.create,
        showProcessingModal: true,
        isCreating: false,
        isCreated: false,
        isFailed: true,
        errors: null,
        data: null,
        errorMessage: payload?.response?.data?.message || '',
      };
    },

    retryToCreate: (state) => {
      state.create.retries += 1;
    },

    setShowCreateProcessingModal: (state, { payload }) => {
      state.create.showProcessingModal = payload;
    },

    editWebhookInit: (state) => {
      state.edit = {
        ...state.edit,
        isLoading: true,
        isError: false,
        errorCode: null,
        data: null,
        errorMessage: null,
      };

      state.update = {
        ...state.update,
        showProcessingModal: false,
      };
    },

    editWebhookSuccess: (state, { payload }) => {
      if (isEmpty(payload?.data)) {
        state.edit = {
          ...state.edit,
          isLoading: false,
          isError: true,
          errorCode: Response.HTTP_NOT_FOUND,
          data: null,
          errorMessage: 'Webhook not found',
        };
      } else {
        state.edit = {
          ...state.edit,
          isLoading: false,
          isError: false,
          errorCode: null,
          data: payload.data,
        };
      }
    },

    editWebhookFailure: (state, { payload }) => {
      state.edit = {
        ...state.edit,
        isLoading: false,
        isError: true,
        errorCode: payload?.response?.status ?? 0,
        data: payload,
      };
    },

    updateWebhookInit: (state) => {
      state.update = {
        ...state.update,
        showProcessingModal: true,
        isUpdating: true,
        isUpdated: false,
        isFailed: false,
        errors: null,
        data: null,
      };
    },

    updateWebhookSuccess: (state, { payload }) => {
      state.update = {
        ...state.update,
        showProcessingModal: true,
        isUpdating: false,
        isUpdated: true,
        isFailed: false,
        errors: null,
        data: payload.data,
      };
    },

    updateWebhookFailure: (state, { payload }) => {
      state.update = {
        ...state.update,
        showProcessingModal: true,
        isUpdating: false,
        isUpdated: false,
        isFailed: true,
        errors: payload,
        data: null,
        errorMessage: payload?.response?.data?.message || '',
      };
    },

    updateWebhookReset: (state, { payload }) => {
      state.update = {
        ...state.update,
        showProcessingModal: false,
        isUpdating: false,
        isUpdated: false,
        isFailed: false,
        errors: null,
        data: null,
      };
    },

    retryToUpdate: (state) => {
      state.update.retries += 1;
    },

    setShowEditProcessingModal: (state, { payload }) => {
      state.update.showProcessingModal = payload;
    },

    setParam: (state, { payload }) => {
      state.index.params = { ...state.index.params, ...payload };
    },

    resetParam: (state) => {
      state.index.params = {
        page_size: state.index.params.page_size,
        page_number: 1,
      };
    },

    deleteFilterParams: (state, { payload }) => {
      if (Array.isArray(payload)) payload.forEach((key) => delete state.index.params[key]);
      else delete state.index.params[payload];
    },

    getWebhookInit: (state) => {
      state.show = {
        ...state.show,
        isLoading: true,
        isError: false,
        errorCode: null,
        errorMessage: null,
        data: null,
      };
    },

    getWebhookSuccess: (state, { payload }) => {
      if (isEmpty(payload?.data)) {
        state.show = {
          ...state.show,
          isLoading: false,
          isError: true,
          errorCode: Response.HTTP_NOT_FOUND,
          errorMessage: 'Webhook not found',
        };
      } else {
        state.show = {
          ...state.show,
          isLoading: false,
          isError: false,
          errorCode: null,
          errorMessage: null,
          data: payload.data,
        };
      }
    },

    getWebhookFailure: (state, { payload }) => {
      state.show = {
        ...state.show,
        isLoading: false,
        isError: true,
        errorCode: payload?.response?.status ?? 0,
        errorMessage: payload?.response?.message,
        data: null,
      };
    },

    resetGetWebhookFailure: (state) => {
      state.show = {
        ...state.show,
        isLoading: false,
        isError: false,
        errorCode: null,
        errorMessage: null,
        data: null,
      };
    },

    getWebhookBriefDetailsInit: (state, { payload }) => {
      state.show = {
        ...state.show,
        showBriefDetailModal: payload.showBriefDetailModal,
        webhookId: payload.webhookId,
      };
    },

    hideWebhookBriefDetails: (state) => {
      state.show = {
        showBriefDetailModal: false,
        webhookId: null,
        isLoading: false,
        isError: false,
        errorCode: null,
        errorMessage: null,
        data: null,
      };
    },

    deleteWebhookInit: (state, { payload: webhookId }) => {
      state.index.data = state.index.data.map((item) => {
        if (item.id == webhookId) {
          return {
            ...item,
            isDeleting: true,
          };
        }
        return item;
      });
    },

    deleteWebhookSuccess: (state, { payload: webhookId }) => {
      const { page_number: currentPage, page_size: pageSize } = state.index.params;

      let startSN = (currentPage - 1) * pageSize;

      let data = state.index.data.filter((item) => item.id !== webhookId);

      state.index.data = data.map((item) => ({ ...item, sn: (startSN += 1) }));
    },

    deleteWebhookFailure: (state, { payload: webhookId }) => {
      state.index.data = state.index.data.map((item) => {
        if (item.id == webhookId) {
          return {
            ...item,
            isDeleting: false,
          };
        }
        return item;
      });
    },
  },
});

export const {
  getWebhooksInit,
  getWebhooksSuccess,
  getWebhooksFailure,
  resetGetWebhooksFailure,
  addWebhookInit,
  addWebhookSuccess,
  addWebhookFailure,
  addWebhookReset,
  retryToCreate,
  updateWebhookInit,
  updateWebhookSuccess,
  updateWebhookFailure,
  updateWebhookReset,
  retryToUpdate,
  setParam,
  resetParam,
  deleteFilterParams,
  setShowCreateProcessingModal,
  setShowEditProcessingModal,
  getWebhookInit,
  getWebhookSuccess,
  getWebhookFailure,
  resetGetWebhookFailure,
  editWebhookInit,
  editWebhookSuccess,
  editWebhookFailure,
  getWebhookBriefDetailsInit,
  hideWebhookBriefDetails,
  deleteWebhookInit,
  deleteWebhookSuccess,
  deleteWebhookFailure,
} = webhookSlice.actions;

export default webhookSlice.reducer;
