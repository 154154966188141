import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid';
import styled from '@mui/material/styles/styled';
import Paper from '@mui/material/Paper';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from 'core/buttons/Button';

const CustomerCardContainer = styled(Paper)(({ theme }) => ({
  borderRadius: 12,
  boxShadow: '1px 3px 6px 1px rgba(0,0,0,0.2)',
  maxWidth: 350,
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
}));

const CustomerLogo = styled(Avatar)(({ theme }) => ({
  height: 42,
  width: 42,
  background: theme.palette.primary.light,
  color: theme.palette.primary.main,
  fontWeight: theme.typography.fontWeightBold,
}));

const CustomerDetails = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  gap: 10,
  display: 'flex',
  flexDirection: 'row',
}));

const CustomerInformation = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: 4,
}));

const CustomerActions = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-start',
  gap: '12px',
  padding: theme.spacing(2),
}));

const CustomerButton = styled(Button)({
  borderRadius: '8px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
  padding: '8px',
  minWidth: '90px',
});

export const DetailItem = ({ label, value }) => (
  <Box>
    <Typography variant="body2" fontWeight="bold">
      {label}:
    </Typography>
    <Typography variant="body2">{value || 'N/A'}</Typography>
  </Box>
);

export const BeneficiaryCard = ({
  beneficiary,
  handleBeneficiaryFullView,
  handleBeneficiaryBriefView,
}) => {
  const { id, first_name, last_name, relation, country, partner_name, partner_id } = beneficiary;
  const fullName = `${first_name} ${last_name}`;
  const customerShortName = `${first_name?.charAt(0)}${last_name?.charAt(0)}`;
  const customerRelation = relation || 'N/A';

  return (
    <Grid item xs={12} sm={6} md={4} lg={3} key={id}>
      <CustomerCardContainer elevation={2}>
        <Box
          sx={{ backgroundColor: (theme) => theme.palette.primary.soft }}
          display="flex"
          flexDirection="column"
          gap={2}
        >
          <CustomerDetails>
            <CustomerLogo>{customerShortName}</CustomerLogo>
            <CustomerInformation>
              <Typography variant="subtitle1" fontWeight="bold">
                {fullName}
              </Typography>
              <Typography variant="body2" color="textSecondary">
                {customerRelation}
              </Typography>
            </CustomerInformation>
          </CustomerDetails>
          <CustomerActions>
            <CustomerButton
              onClick={handleBeneficiaryBriefView}
              variant="contained"
              color="primary"
            >
              <RemoveRedEyeOutlinedIcon fontSize="small" />
              <Typography variant="caption">Preview</Typography>
            </CustomerButton>
            <CustomerButton variant="contained">
              <DescriptionOutlinedIcon fontSize="small" />
              <Typography onClick={handleBeneficiaryFullView} variant="caption">
                View Details
              </Typography>
            </CustomerButton>
          </CustomerActions>
        </Box>

        <CustomerInformation sx={{ padding: (theme) => theme.spacing(2), gap: 2 }}>
          <DetailItem label="Country" value={country} />
          <DetailItem label="Partner" value={partner_name} />
          <DetailItem label="Partner Beneficiary ID" value={partner_id} />
        </CustomerInformation>
      </CustomerCardContainer>
    </Grid>
  );
};
