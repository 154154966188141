import PropTypes from 'prop-types';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';

export default function ActiveBadge({ isActive, onClick, tooltipTitle, tooltipPlacement, size }) {
  return (
    <>
      {tooltipTitle ? (
        <>
          <Tooltip title={tooltipTitle} placement={tooltipPlacement} arrow>
            <IconButton onClick={onClick}>
              {isActive ? (
                <CheckCircleOutlinedIcon
                  sx={{
                    color: 'white',
                    backgroundColor: 'success.main',
                    padding: '1px',
                    borderRadius: '4px',
                  }}
                  fontSize={size}
                />
              ) : (
                <CancelOutlinedIcon
                  sx={{
                    color: 'white',
                    backgroundColor: 'error.main',
                    padding: '1px',
                    borderRadius: '4px',
                  }}
                  fontSize={size}
                />
              )}
            </IconButton>
          </Tooltip>
        </>
      ) : (
        <>
          {isActive ? (
            <CheckCircleOutlinedIcon
              sx={{
                color: 'white',
                backgroundColor: 'success.main',
                padding: '1px',
                borderRadius: '4px',
              }}
              fontSize={size}
            />
          ) : (
            <CancelOutlinedIcon
              sx={{
                color: 'white',
                backgroundColor: 'error.main',
                padding: '1px',
                borderRadius: '4px',
              }}
              fontSize={size}
            />
          )}
        </>
      )}
    </>
  );
}

ActiveBadge.propTypes = {
  isActive: PropTypes.bool,
  onClick: PropTypes.func,
  tooltipTitle: PropTypes.string,
  tooltipPlacement: PropTypes.string,
  size: PropTypes.oneOf(['inherit', 'large', 'medium', 'small']),
};

ActiveBadge.defaultProps = {
  isActive: false,
  tooltipPlacement: 'top',
  size: 'small',
};
