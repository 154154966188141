import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import Modal from 'app/components/Modal';
import SourceDetails from 'core/source-detail/SourceDetails';
import useSourceDetail from 'core/source-detail/useSourceDetail';
import PartnerClickAwayDetails from 'components/partners/PartnerClickAwayDetails';

import Auth from 'utils/Auth';
import { buildRoute } from 'routes';
import DateUtils from 'utils/DateUtils';
import { getZaiTransaction } from 'app/features/transactions/zaiTransactionSaga';
import { hideTransactionBriefDetails } from 'app/features/transactions/zaiTransactionSlice';

function ZaiTransactionPreviewDetailsModal() {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const state = useSelector((state) => state.zaiTransactions.show);

  const { showBriefDetailModal, transactionId, isLoading, data } = state;

  const viewDetailColumn = useSourceDetail([
    {
      title: '',
      items: [
        {
          label: 'Transaction Id',
          accessorKey: 'id',
        },
        {
          label: 'Order Id',
          accessorKey: 'order_id',
        },
        {
          label: 'Customer Name',
          accessorKey: 'user_name',
        },
        ...(Auth.isSuperAdmin()
          ? [
              {
                label: 'Partner',
                accessorKey: 'partner_id',
                cell: ({ partner_id, partner_name }) => (
                  <PartnerClickAwayDetails partnerId={partner_id} label={partner_name} />
                ),
              },
            ]
          : []),
        {
          label: 'Purpose',
          accessorKey: 'txn_purpose',
        },
        {
          label: 'Sending Amount',
          accessorKey: 'amount',
        },
        {
          label: 'Payout Amount',
          accessorKey: 'payout_amount',
        },
        {
          label: 'Status',
          accessorKey: 'status',
        },
        {
          label: 'Created Date',
          accessorKey: 'created_ts',
          cell: ({ created_ts }) => <>{DateUtils.isoToString(created_ts)}</>,
        },
        {
          label: 'Updated Date',
          accessorKey: 'updated_ts',
          cell: ({ updated_ts }) => <>{updated_ts ? DateUtils.isoToString(updated_ts) : '-'}</>,
        },
      ],
    },
  ]);

  useEffect(() => {
    if (transactionId) dispatch(getZaiTransaction(transactionId));
  }, [transactionId]);

  if (!showBriefDetailModal && !transactionId) return <></>;

  return (
    <Modal
      open={showBriefDetailModal ?? false}
      onClose={() => dispatch(hideTransactionBriefDetails())}
      title="Zai Transaction Details"
      handleFullDetails={() => (
        dispatch(hideTransactionBriefDetails()),
        navigate(buildRoute('zai-transactions.show', transactionId))
      )}
    >
      <SourceDetails
        showContainerDesign={false}
        definition={viewDetailColumn}
        data={data}
        isLoading={isLoading}
      />
    </Modal>
  );
}

export default ZaiTransactionPreviewDetailsModal;
