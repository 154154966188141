import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import acl from 'app/config/acl';
import withRole from 'hoc/withRole';
import routePaths from 'app/config/routePaths';
import NewPageContent from 'layouts/NewPageContent';
import ActionStatusModal from 'core/modal/ActionStatusModal';
import FundingSourceInfoForm from 'components/Funding-source-info/FundingSourcesInfoForm';
import { buildRoute } from 'routes';
import ucwords from 'helpers/ucwords';
import {
  retryToCreate,
  addFundingSourceInfoReset,
  setShowCreateProcessingModal,
} from 'app/features/funding-sources-info/fundingSourceInfoSlice';

function AddFundingSourceInfo() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();

  const {
    showProcessingModal,
    isCreating,
    isCreated,
    isFailed,
    errors,
    errorMessage,
    retries,
    data: fundingSourcesInfo,
  } = useSelector((state) => state.fundingSourcesInfo.create);

  const finalData = useSelector((state) => state.fundingSourcesInfo.create);

  return (
    <NewPageContent
      documentTitle="Add  Funding Source Info"
      breadcrumbs={[
        {
          label: 'Funding Source Info',
          to: routePaths.ListFundingSourceInfo,
        },
        {
          label: 'Add Funding Source Info',
        },
      ]}
    >
      <FundingSourceInfoForm isAddMode={true} />
      <ActionStatusModal
        showModal={showProcessingModal}
        closeModal={() => dispatch(setShowCreateProcessingModal(false))}
        data={fundingSourcesInfo}
        isSuccess={isCreated}
        isProcessing={isCreating}
        isError={isFailed}
        retriesCount={retries}
        errorMessage={ucwords(errorMessage) ?? 'Failed To Create Funding Source Information'}
        successMessage="Funding Source Information Created Successfully"
        retry={() => dispatch(retryToCreate())}
        navigateToView={() =>
          navigate(
            buildRoute(
              'funding-sources-info.show',
              fundingSourcesInfo.id ?? params.fundingSourceId,
            ),
          )
        }
        resetForm={() => dispatch(addFundingSourceInfoReset())}
      />
    </NewPageContent>
  );
}

export default withRole([acl.SUPERADMIN, acl.ADMIN])(AddFundingSourceInfo);
