import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import styled from '@mui/material/styles/styled';
import Typography from '@mui/material/Typography';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Link from '@mui/material/Link';
import { Link as RouterLink } from 'react-router-dom';

import { history } from 'routes';
import app from 'app/config/app';
import { Box } from '@mui/material';
import isEmpty from 'helpers/isEmpty';
import MuiBreadcrumbs from '@mui/material/Breadcrumbs';

const OuterWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'flex-start',
  padding: theme.spacing(2),
  paddingLeft: theme.spacing(4),
  [theme.breakpoints.down('sm')]: {},

  background: theme.palette.grey[100],
  //background: 'red',
  minHeight: 'calc(100vh - 64px)',
}));

const BackButtonWrapper = styled('div')(({ theme }) => ({}));

const InnerWrapper = styled('div')(({ theme }) => {
  const { isDrawerOpen } = useSelector((state) => state.app);

  return {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    // border: '1px solid green',
    maxWidth: `calc(100vw - ${isDrawerOpen ? app.drawerWith + 50 : 110}px)`,

    // [theme.breakpoints.up('sm')]: {
    //   maxWidth: theme.breakpoints.values.sm - (app.drawerWith + 60) + 'px',
    // },
    // [theme.breakpoints.up('md')]: {
    //   maxWidth: theme.breakpoints.values.md - (app.drawerWith + 60) + 'px',
    // },
    // [theme.breakpoints.up('lg')]: {
    //   maxWidth: theme.breakpoints.values.lg - (app.drawerWith + 60) + 'px',
    // },
    // [theme.breakpoints.up('xl')]: {
    //   maxWidth: theme.breakpoints.values.xl - (app.drawerWith + 60) + 'px',
    // },
  };
});

const PageContentHeader = styled('div')(({ theme }) => ({
  padding: theme.spacing(1, 0),

  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
  [theme.breakpoints.down('sm')]: {
    marginBottom: theme.spacing(4),
  },

  marginBottom: theme.spacing(6),
  alignItems: 'center',
  height: '3rem',

  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    paddingBottom: theme.spacing(12),
  },
}));

const Breadcrumbs = styled(MuiBreadcrumbs)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    fontSize: '0.9rem',
  },
  p: (theme) => theme.spacing(1),
  '& li:not(:last-of-type)': {
    color: theme.palette.text.secondary,
    p: {
      color: theme.palette.text.secondary,
    },
  },
  '& li:last-of-type': {
    color: theme.palette.text.primary,
    p: {
      color: theme.palette.text.primary,
    },
  },
}));

const BreadcrumbText = styled(Typography)(({ theme }) => ({
  color: `${theme.palette.grey[600]} !important`,
  [theme.breakpoints.down('sm')]: {
    fontSize: '0.9rem',
  },
}));

const TitleContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  '& .MuiTypography-root': {
    color: theme.palette.primary.main,
    fontSize: '1.4rem',
  },
  gap: theme.spacing(1),
  '& .MuiSvgIcon-root': {
    fill: theme.palette.primary.main,
    fontSize: '1.4rem',
  },
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
}));

const RightEndWrapper = styled('Box')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  flexGrow: 1,
  [theme.breakpoints.down('sm')]: {
    justifyContent: 'flex-start',
    marginTop: theme.spacing(2),
  },
}));

const TitleHeader = styled('Typography')(({ theme }) => ({
  fontSize: '1.4rem',
  fontWeight: 600,
  [theme.breakpoints.down('sm')]: {
    fontSize: '1.2rem',
  },
}));

function NewPageContent({ children, title, documentTitle, breadcrumbs, rightTopEndContent }) {
  useEffect(() => {
    document.title = `${app.name} | ${documentTitle ?? title}`;
  }, [documentTitle, title]);

  return (
    <OuterWrapper>
      <InnerWrapper id="inner-wrapper">
        <PageContentHeader>
          <Box marginTop={2}>
            <Breadcrumbs separator="/" aria-label="breadcrumb">
              <Link component={RouterLink} underline="hover" color="inherit" to="/">
                Dashboard
              </Link>

              {breadcrumbs.map((breadcrumb) => {
                if (isEmpty(breadcrumb?.to)) {
                  return <BreadcrumbText key={breadcrumb.label}>{breadcrumb.label}</BreadcrumbText>;
                }
                return (
                  <Link
                    key={breadcrumb.label}
                    component={RouterLink}
                    underline="hover"
                    to={breadcrumb.to}
                  >
                    <BreadcrumbText key={breadcrumb.label}>{breadcrumb.label}</BreadcrumbText>
                  </Link>
                );
              })}
            </Breadcrumbs>

            {documentTitle && <TitleHeader>{documentTitle}</TitleHeader>}
            {!documentTitle && title && <TitleHeader>{title}</TitleHeader>}
          </Box>

          <RightEndWrapper>{rightTopEndContent}</RightEndWrapper>
          <Box display="flex" flexDirection="row" justifyContent="space-between">
            <TitleContainer />
          </Box>
        </PageContentHeader>
        <div>{children}</div>
      </InnerWrapper>
    </OuterWrapper>
  );
}

export default NewPageContent;

NewPageContent.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
  title: PropTypes.string.isRequired,
  documentTitle: PropTypes.string,

  breadcrumbs: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      to: PropTypes.string,
    }),
  ),
  rightTopEndContent: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
};

NewPageContent.defaultProps = {
  documentTitle: null,
  breadcrumbs: [],
  rightTopEndContent: null,
};
