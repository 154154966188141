import React from 'react';

const PaidStatusIcon = (props) => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M0 20C0 8.95431 8.95431 0 20 0C31.0457 0 40 8.95431 40 20C40 31.0457 31.0457 40 20 40C8.95431 40 0 31.0457 0 20Z"
        fill="#F4FCF7"
      />
      <path
        d="M20 23.25C18.21 23.25 16.75 21.79 16.75 20C16.75 18.21 18.21 16.75 20 16.75C21.79 16.75 23.25 18.21 23.25 20C23.25 21.79 21.79 23.25 20 23.25ZM20 18.25C19.04 18.25 18.25 19.04 18.25 20C18.25 20.96 19.04 21.75 20 21.75C20.96 21.75 21.75 20.96 21.75 20C21.75 19.04 20.96 18.25 20 18.25Z"
        fill="#23C965"
      />
      <path
        d="M26.5 23.25C26.09 23.25 25.75 22.91 25.75 22.5V17.5C25.75 17.09 26.09 16.75 26.5 16.75C26.91 16.75 27.25 17.09 27.25 17.5V22.5C27.25 22.91 26.91 23.25 26.5 23.25Z"
        fill="#23C965"
      />
      <path
        d="M13 30.75C11.34 30.75 9.78 29.87 8.94 28.44C8.49 27.72 8.25 26.87 8.25 26C8.25 23.38 10.38 21.25 13 21.25C15.62 21.25 17.75 23.38 17.75 26C17.75 26.87 17.51 27.72 17.06 28.45C16.22 29.87 14.66 30.75 13 30.75ZM13 22.75C11.21 22.75 9.75 24.21 9.75 26C9.75 26.59 9.91 27.17 10.22 27.67C10.8 28.65 11.87 29.25 13 29.25C14.13 29.25 15.2 28.65 15.78 27.68C16.09 27.17 16.25 26.6 16.25 26C16.25 24.21 14.79 22.75 13 22.75Z"
        fill="#23C965"
      />
      <path
        d="M12.4299 27.7401C12.2399 27.7401 12.0499 27.6701 11.8999 27.5201L10.9099 26.5301C10.6199 26.2401 10.6199 25.76 10.9099 25.47C11.1999 25.18 11.6799 25.18 11.9699 25.47L12.4499 25.9501L14.0499 24.47C14.3499 24.19 14.8299 24.2101 15.1099 24.5101C15.3899 24.8101 15.3699 25.2901 15.0699 25.5701L12.9399 27.54C12.7899 27.67 12.6099 27.7401 12.4299 27.7401Z"
        fill="#23C965"
      />
      <path
        d="M25 28.75H16.5C16.09 28.75 15.75 28.41 15.75 28C15.75 27.59 16.09 27.25 16.5 27.25H25C27.86 27.25 29.25 25.86 29.25 23V17C29.25 14.14 27.86 12.75 25 12.75H15C12.14 12.75 10.75 14.14 10.75 17V23.3C10.75 23.71 10.41 24.05 10 24.05C9.59 24.05 9.25 23.71 9.25 23.3V17C9.25 13.35 11.35 11.25 15 11.25H25C28.65 11.25 30.75 13.35 30.75 17V23C30.75 26.65 28.65 28.75 25 28.75Z"
        fill="#23C965"
      />
    </svg>
  );
};

export default PaidStatusIcon;
