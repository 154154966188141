import qs from 'qs';
import { createAction } from '@reduxjs/toolkit';
import { takeLatest, put } from 'redux-saga/effects';

import Notify from 'utils/Notify';
import http from 'app/services/httpService';
import { callWrapperSaga } from 'app/services/sagaService';
import apiEndpoints, { buildApiRoute } from 'routes/apiEndpoints';

import {
  getRolesInit,
  getRolesSuccess,
  getRolesFailure,
  addRolesInit,
  addRolesSuccess,
  addRolesFailure,
  updateRolesInit,
  updateRolesSuccess,
  updateRolesFailure,
} from './roleAndPermissionsSlice';

const getRoleAndPermission = createAction('rolesAndPermissions/get');
const createRolesAndPermissions = createAction('rolesAndPermissions/create');
const updateRolesAndPermissions = createAction('rolesAndPermissions/update');
const getRolesAndPermissions = createAction('rolesAndPermissions/list');

function* createRolesAndPermissionsSaga({ payload: data }) {
  yield put(addRolesInit());
  try {
    let response = yield callWrapperSaga(() => http.post(apiEndpoints.createRole, data));
    yield put(addRolesSuccess(response.data));
    Notify.success('New Role created successfully.');
  } catch (err) {
    yield put(addRolesFailure(err));
    Notify.error('Failed to create new Role.');
  }
}

function* updateRolesAndPermissionsSaga({ payload: { roleId, data } }) {
  try {
    yield put(updateRolesInit());
    let endpoint = buildApiRoute(apiEndpoints.updateRole, roleId);
    let response = yield callWrapperSaga(() => http.put(endpoint, data));
    yield put(updateRolesSuccess(response.data));
  } catch (err) {
    yield put(updateRolesFailure(err));
    Notify.error('Failed to update Role.');
  }
}

function* getRoleAndPermissionSaga({ payload: roleId }) {
  try {
    yield put(getRolesInit());
    let endpoint = buildApiRoute(apiEndpoints.getRole, roleId);
    let response = yield callWrapperSaga(() => http.get(endpoint, roleId));
    yield put(getRolesSuccess(response.data));
  } catch (err) {
    yield put(getRolesFailure(err));
  }
}

function* getRolesAndPermissionsSaga({ payload }) {
  yield put(getRolesInit());
  try {
    let endpoint = `${apiEndpoints.getRoles}?${new URLSearchParams(payload)}`;
    let response = yield callWrapperSaga(() => http.get(endpoint));
    yield put(getRolesSuccess(response.data));
  } catch (err) {
    yield put(getRolesFailure(err));
  }
}

function* rolesSaga() {
  yield takeLatest(getRolesAndPermissions, getRolesAndPermissionsSaga);
  yield takeLatest(getRoleAndPermission, getRoleAndPermissionSaga);
  yield takeLatest(createRolesAndPermissions, createRolesAndPermissionsSaga);
  yield takeLatest(updateRolesAndPermissions, updateRolesAndPermissionsSaga);
}

export default rolesSaga;

export {
  getRolesAndPermissions,
  getRoleAndPermission,
  createRolesAndPermissions,
  updateRolesAndPermissions,
};
