import { useEffect } from 'react';
import { compose } from '@reduxjs/toolkit';
import { useSelector, useDispatch } from 'react-redux';

import withRole from 'hoc/withRole';
import Error from 'core/errors/Error';
import withAppState from 'hoc/withAppState';
import CustomerKycs from 'components/customer-kycs/CustomerKycs';

import acl from 'app/config/acl';
import NewPageContent from 'layouts/NewPageContent';
import FilterButton from 'core/buttons/FilterButton';
import CustomerKycsTableFilterValues from 'components/customer-kycs/CustomerKycsTableFilterValues';
import CustomerKycBriefDetailsModal2 from 'components/customer-kycs/CustomerKycPreviewDetailsModal';
import { resetGetCustomerKycsFailure, toggleFilter } from 'app/features/customer-kycs/customerKycSlice';

function ListCustomerKyc({ isNetworkError }) {
  const customerKycState = useSelector((state) => state.customerKycs.index);

  const { isError, errorCode, isFilterOpen } = customerKycState;

  const dispatch = useDispatch();

  useEffect(() => {
    if (isError) dispatch(resetGetCustomerKycsFailure());
  }, [isNetworkError]);

  if (isError)
    return (
      <Error
        status={errorCode}
        withInDashboard={true}
        onRetry={() => dispatch(resetGetCustomerKycsFailure())}
      />
    );

  return (
    <NewPageContent
      breadcrumbs={[
        {
          label: 'Customer KYCs',
          to: '',
        },
      ]}
      documentTitle="Customer KYCs"
      rightTopEndContent={
        <FilterButton
          variant="outlined"
          isOpen={isFilterOpen}
          onClick={() => dispatch(toggleFilter())}
        />
      }
    >
      {/* <CustomerKycFilterValues /> */}
      <CustomerKycsTableFilterValues />
      <CustomerKycs />
      <CustomerKycBriefDetailsModal2 />
    </NewPageContent>
  );
}

export default compose(
  withAppState,
  withRole([acl.SUPERADMIN, acl.ADMIN, acl.USER]),
)(ListCustomerKyc);
