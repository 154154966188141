import PropTypes from 'prop-types';

import LogoSvg from 'assets/logo/logo_horizontal.svg';
import MainLogo from 'assets/logo/logo_passpay_main.svg';

function Logo({ width, height }) {
  return <img src={MainLogo} width={width} height={height} />;
}

export default Logo;

Logo.propTypes = {
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

Logo.defaultProps = {
  width: 'auto',
  height: 'auto',
};
