import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import MuiPagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import { useTheme } from '@emotion/react';
import useMediaQuery from '@mui/material/useMediaQuery';

export default function Pagination(props) {
  const { page, count, onChange, onRowPerPageChange, enableRowPerPage, perPageValue, sx } = props;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const perPageOptions = [
    { value: 10, label: '10' },
    { value: 25, label: '25' },
    { value: 50, label: '50' },
    { value: 100, label: '100' },
  ];

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: isMobile ? 'column' : 'row',
        alignItems: 'center',
        p: 1,
        justifyContent: enableRowPerPage ? 'space-between' : 'center',
        gap: isMobile ? 2 : 0,
        ...sx,
      }}
    >
      {enableRowPerPage && (
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <Typography
            sx={{
              color: 'grey.700',
              fontSize: isMobile ? '0.875rem' : '1rem',
            }}
          >
            Rows per page:
          </Typography>
          <FormControl variant="outlined" size="small">
            <Select
              sx={{
                fontSize: isMobile ? '0.875rem' : '1rem',
                border: (theme) => `1px solid ${theme.palette.grey[300]}`,
                borderRadius: '12px',
                color: (theme) => theme.palette.grey[600],
                padding: '8px 8px',
              }}
              value={perPageValue ?? 10}
              onChange={(e) => onRowPerPageChange(e.target.value)}
            >
              {perPageOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      )}

      <MuiPagination
        color="primary"
        size={isMobile ? 'medium' : 'large'}
        variant="text"
        shape="rounded"
        page={page}
        count={count}
        renderItem={(props2) => <PaginationItem {...props2} disableRipple />}
        onChange={(_, value) => onChange(value)}
        sx={{
          '& .MuiPaginationItem-root': {
            fontSize: isMobile ? '0.75rem' : '1rem',
          },
        }}
      />
    </Box>
  );
}

Pagination.propTypes = {
  page: PropTypes.number.isRequired,
  count: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  onRowPerPageChange: PropTypes.func,
  enableRowPerPage: PropTypes.bool,
  perPageValue: PropTypes.number,
  sx: PropTypes.object,
};

Pagination.defaultProps = {
  enableRowPerPage: true,
  sx: {},
};
