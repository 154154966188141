import { useEffect } from 'react';
import { compose } from '@reduxjs/toolkit';
import EditIcon from '@mui/icons-material/Edit';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';

import Error from 'core/errors/Error';
import Button from 'core/buttons/Button';
import withAppState from 'hoc/withAppState';
import PageContent from 'layouts/PageContent';
import PartnerFundingSource from 'components/partner-funding-sources/PartnerFundingSource';

import acl from 'app/config/acl';
import { buildRoute } from 'routes';
import withRole from 'hoc/withRole';
import { resetGetPartnerFundingSourceFailure } from 'app/features/partner-funding-sources/partnerFundingSourceSlice';
import NewPageContent from 'layouts/NewPageContent';
import routePaths from 'app/config/routePaths';

function ViewPartnerFundingSource({ isNetworkError }) {
  const params = useParams();

  const { isError, errorCode } = useSelector((state) => state.partnerFundingSources.show);

  const dispatch = useDispatch();

  const navigate = useNavigate();

  useEffect(() => {
    if (isError) dispatch(resetGetPartnerFundingSourceFailure());
  }, [isNetworkError]);

  if (isError)
    return (
      <Error
        status={errorCode || 0}
        onRetry={() => dispatch(resetGetPartnerFundingSourceFailure())}
      />
    );

  return (
    <NewPageContent
      breadcrumbs={[
        {
          label: 'Partner Funding Sources',
          to: routePaths.ListPartnerFundingSource,
        },
        {
          label: 'Partner Funding Source Details',
          to: '',
        },
      ]}
      documentTitle="Partner Funding Source Details"
    >
      <PartnerFundingSource partnerFundingSourceId={params.partnerFundingSourceId} />
    </NewPageContent>
  );
}

export default compose(
  withAppState,
  withRole([acl.SUPERADMIN, acl.ADMIN]),
)(ViewPartnerFundingSource);
