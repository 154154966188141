import PropTypes from 'prop-types';
import MuiListItem from '@mui/material/ListItem';
import styled from '@mui/material/styles/styled';

import ListItemContexts from './ListItemContexts';

/**
 * Reference : MUI 5 styled() pass props along with theme object
 *
 * https://github.com/mui/material-ui/issues/29207#issuecomment-949283534
 */

const ListItem = styled(MuiListItem, {
  shouldForwardProp: (prop) => !['isActive', 'context', 'isDrawerOpen'].includes(prop),
})(({ theme, isActive, context, isDrawerOpen }) => {
  const defaultStyles = {
    backgroundColor: theme.palette.transparent,
    hoverBackgroundColor: theme.palette.transparent,
    color: theme.palette.common.white,
    fontSize: '0.95rem',
    svgIconColor: theme.palette.text.primary,
  };

  const activeStyles = {
    fontSize: '1rem',
    svgIconColor: theme.palette.common.white,
    color: theme.palette.common.white,
    backgroundColor: theme.palette['menuActive']['main'],
    hoverBackgroundColor: theme.palette['menuActive']['main'],
  };

  const dropdownStyles = {
    fontSize: '0.95rem',
    svgIconColor: theme.palette.primary.main,
    color: theme.palette.primary.main,
    backgroundColor: theme.palette['subMenuActive']['main'],
    hoverBackgroundColor: theme.palette['subMenuActive']['main'],
  };

  const closedDrawerDropdownStyles = {
    backgroundColor: theme.palette.grey[900],
    color: theme.palette.grey[100],
    hoverBackgroundColor: theme.palette.grey[800],
  };

  let itemStyles = { ...defaultStyles };

  if (isActive) {
    if (context === ListItemContexts.item) {
      itemStyles = { ...activeStyles };
    } else if (context === ListItemContexts.dropdownItem) {
      itemStyles = { ...dropdownStyles };
    }
  }

  if (!isDrawerOpen && context === ListItemContexts.dropdownItem) {
    itemStyles = {
      ...itemStyles,
      ...closedDrawerDropdownStyles,
    };
  }

  return {
    padding: !isDrawerOpen ? theme.spacing(1.5, 1, 1.5, 0) : theme.spacing(0.5, 1),
    marginRight: context === ListItemContexts.item ? 15 : 0,
    borderRadius: 6,
    backgroundColor: open ? itemStyles.backgroundColor : 'red',

    color: itemStyles.color,
    fontSize: itemStyles.fontSize,

    '&:hover': {
      backgroundColor: itemStyles.hoverBackgroundColor,
      color: itemStyles.svgIconColor,

      '& .MuiSvgIcon-root': {
        color: theme.palette.common.white,
      },
      '& .MuiListItemText-primary': {
        color: itemStyles.color,
      },
    },

    '& .MuiListItemIcon-root': {
      display: 'flex',
      justifyContent: 'center',
    },

    '& .MuiSvgIcon-root': {
      fontSize: '1.5rem',
      color: theme.palette.common.white,
    },

    '& .MuiListItemText-primary': {
      fontSize: itemStyles.fontSize,
      // color: !isDrawerOpen ? itemStyles.color : 'black',

      // color:
      // !isDrawerOpen && context === ListItemContexts.dropdownItem
      // ? theme.palette.common.white
      // : itemStyles.color,
    },
  };
});

export default ListItem;

ListItem.propTypes = {
  isActive: PropTypes.bool,
  isDrawerOpen: PropTypes.bool,
  context: PropTypes.oneOf([ListItemContexts.item, ListItemContexts.dropdownItem]),
};

ListItem.defaultProps = {
  isActive: false,
  isDrawerOpen: false,
};
